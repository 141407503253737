import React, { useState, useRef } from 'react';
import { StepContainer, Title, TitleDescription } from 'components/atoms';
import { StepProps, ValidationConfirmation } from 'interfaces/Props';
import { CurrentPage } from './StepSeven.types';
import { useAppStore } from 'stores/appStore';
import { postSyncBulk } from 'api/locations';
import { showAlert } from 'components/Alert';
import HorizontalDividers from 'components/CustomDividers/HorizontalDividers';
import UseDocumentTitle from 'hooks/UseDocumentTitle';
import List from './List';
import DetailForm from './DetailForm';
import BrokerForm from './BrokerForm';
import AccountingList from './Accounting/Index';
import ImportStock from './Accounting/ImportStock';

type Pages = {
  [key in CurrentPage]: React.ReactElement;
};
const StepSeven: React.FunctionComponent<StepProps & ValidationConfirmation> = ({
  submitRef,
  beforeRef,
  confirmationRef
}) => {
  UseDocumentTitle('Pengaturan Lokasi dan Stok');
  const { updateStep, setShowConfirmation, isAccounting, listWarehouse } = useAppStore();
  const [currentPage, setCurrentPage] = useState<CurrentPage>('main');
  const saveMappingImport = useRef(null);

  const getFuncSave = (func) => {
    saveMappingImport.current = func;
  };

  let pages: Pages = {
    ['main']: <List pageSetter={setCurrentPage} />,
    ['location']: <DetailForm pageSetter={setCurrentPage} />,
    ['thirdparty_location']: <BrokerForm pageSetter={setCurrentPage} />,
    ['accounting']: <AccountingList getSave={getFuncSave} pageSetter={setCurrentPage} />,
    ['import_stock']: <ImportStock pageSetter={setCurrentPage} />
  };

  const handleSyncBulk = async () => {
    try {
      let payload = {
        isSync: false
      };
      const res: any = await postSyncBulk(payload);
      if (res.status === 200) {
        updateStep('INCREASE');
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const handleClick = () => {
    if (currentPage === 'main') {
      if (Number(isAccounting) === 0 && listWarehouse.length === 2) {
        setCurrentPage('import_stock');
      } else {
        setCurrentPage('accounting');
      }
    } else if (currentPage === 'accounting') {
      setShowConfirmation(true);
    } else {
      handleSyncBulk();
    }
  };

  const handleBeforeClick = () => {
    if (currentPage === 'main') {
      updateStep('DECREASE');
    } else if (currentPage === 'accounting') {
      setCurrentPage('main');
    } else if (currentPage === 'import_stock') {
      setCurrentPage('accounting');
    }
  };

  const handleClickConfirmation = async () => {
    if (currentPage === 'accounting') {
      let valid = await saveMappingImport.current();
      if (valid) {
        setCurrentPage('import_stock');
      }
    }
  };

  return (
    <>
      <StepContainer>
        <Title>Atur Lokasi dan Stok</Title>
        <TitleDescription>Pada step ini, Kamu akan melakukan pembuatan Lokasi/Gudang.</TitleDescription>
        <HorizontalDividers marginBlock={true} />
        {pages[currentPage]}
      </StepContainer>

      <button ref={confirmationRef} onClick={handleClickConfirmation} style={{ display: 'none' }}>
        Confirmation Ref
      </button>
      <button ref={submitRef} onClick={handleClick} style={{ display: 'none' }}>
        CLICK
      </button>
      <button ref={beforeRef} onClick={handleBeforeClick} style={{ display: 'none' }}>
        CLICK
      </button>
    </>
  );
};

export default StepSeven;
