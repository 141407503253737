import React from 'react';
import { Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AsyncButton } from 'components/ButtonNew/Button';
import { DownloadSVG } from 'assets/img';

interface Props {
  isShow: boolean;
  isDisabled: boolean;
  currentTime: any;
  refreshTime: () => void;
  onClose?: () => void;
  onSubmit: () => void;
}

export const getMonthDate = (transaction_date) => {
  const date = new Date(transaction_date);
  const day = date.getDate();
  const month = date.toLocaleString('id-ID', { month: 'short' });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

export const getHourDate = (transaction_date) => {
  const date = new Date(transaction_date);
  const hour = date.getHours();
  const minute = date.getMinutes();
  return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
};

export const ModalExport: React.FC<Props> = ({ isShow, isDisabled, currentTime, refreshTime, onClose, onSubmit }) => {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isShow}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className="font-weight-bold" id="alert-dialog-title" style={{ fontSize: 30 }}>
        <div className="d-flex justify-content-between d-flex">
          <span className="font-weight-bold" style={{ fontSize: 30 }}>
            Export
          </span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className="p-0 font-weight-bold" style={{ fontSize: '14px' }}>
        <Grid container className="d-flex align-items-center pt-3 px-4">
          <Grid item md={3}>
            Jenis Export
          </Grid>
          <Grid item md={5}>
            Tanggal
          </Grid>
        </Grid>

        <Divider sx={{ my: '10px' }} />

        <Grid container className="d-flex align-items-center pb-4 px-4">
          <Grid item md={3}>
            Produk
          </Grid>
          <Grid item md={5}>
            <div className="d-flex flex-column">
              <span>{getMonthDate(currentTime)}</span>
              <span>{getHourDate(currentTime)}</span>
            </div>
          </Grid>
          <Grid item md={4}>
            <Grid container spacing={1}>
              <Grid item md={5}>
                <AsyncButton
                  disabled={isDisabled}
                  onClick={async () => refreshTime()}
                  variant="outlined"
                  type="button"
                  color="inherit"
                  className="px-3 w-100"
                >
                  Refresh
                </AsyncButton>
              </Grid>
              <Grid item md={5}>
                <AsyncButton
                  disabled={isDisabled}
                  onClick={async () => onSubmit()}
                  variant="contained"
                  type="button"
                  color="primary"
                  className="px-2"
                  icon={<DownloadSVG className="text-white" />}
                >
                  Download
                </AsyncButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
