import React, { ReactElement } from 'react';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from 'components/Button';
// import Popover from "../../components/Popover/Popover";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import whatsappLogo from 'assets/img/whatsapp.png';
import youtubeLogo from 'assets/img/youtube.png';
import styles from './PageContentContainerFooter.module.scss';
import { useAppStore } from 'stores/appStore';
import { Tooltip, TooltipAction, TooltipActionButton, TooltipQuote } from 'components/ToolTip';
import { Check } from '@mui/icons-material';
interface Props {
  prevRef?: React.RefObject<HTMLButtonElement>;
  submitRef?: React.RefObject<HTMLButtonElement>;
  confirmationRef?: React.RefObject<HTMLButtonElement>;
  confirmationChildren?: ReactElement;
  submitValidation?: ReactElement;
}

const PageContentContainerFooter: React.FunctionComponent<Props> = ({
  prevRef,
  submitRef,
  confirmationRef,
  submitValidation,
  confirmationChildren
}) => {
  const {
    disableNextButton,
    disablePrevButton,
    activeStep,
    showSubmitValidation,
    toggleSubmitValidation,
    validationComplete,
    showConfirmation,
    setShowConfirmation,
    updateStep
  } = useAppStore();

  const handleClickNext = () => {
    submitRef?.current?.click();
  };

  const handleCloseValidation = () => {
    toggleSubmitValidation(!showSubmitValidation);
  };

  const handleConfirm = () => {
    confirmationRef?.current?.click();
    setShowConfirmation(!showConfirmation);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(!showConfirmation);
  };

  const handleSubmitValidation = () => {
    updateStep('INCREASE');
    setShowConfirmation(false);
    toggleSubmitValidation(false);
  };

  const handleClickPrevious = () => {
    if (prevRef) {
      prevRef?.current?.click();
    } else {
      updateStep('DECREASE');
    }
  };

  return (
    <Box
      sx={{
        mt: 0.5,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box className="footer-container">
        <Box className="footer-container">
          <Box className="item-footer">
            <img className={styles.whatsappLogo} src={whatsappLogo} alt="whatsapp" />
            <Link target={'_blank'} href="https://wa.me/6285215839386" className={styles.footerText} variant="body2">
              Butuh Bantuan?
            </Link>
          </Box>
          <Box className="item-footer">
            <img className={styles.youtubeLogo} src={youtubeLogo} alt="youtube" />
            <Link
              target={'_blank'}
              href="https://www.youtube.com/@JubelioOfficial"
              className={styles.footerText}
              variant="body2"
            >
              Melakukan Pengaturan Awal
            </Link>
          </Box>
        </Box>
        <Box className={styles.buttonContainer} sx={{ position: 'relative' }}>
          {activeStep !== 1 && activeStep !== 10 && (
            <Button
              className={styles.buttonPrevious}
              variant={'contained'}
              name={'Sebelumnya'}
              onClick={handleClickPrevious}
              startIcon={<KeyboardArrowLeftIcon className={styles.buttonPreviousArrow} />}
              disabled={disablePrevButton}
            />
          )}
          {activeStep === 10 ? (
            <Button
              className={styles.buttonNext}
              variant={'contained'}
              name={'Selesai'}
              sx={{ padding: '11px 30px !important' }}
              onClick={handleClickNext}
              startIcon={<Check />}
              disabled={disableNextButton}
            />
          ) : (
            <Button
              className={styles.buttonNext}
              variant={'contained'}
              name={'Selanjutnya'}
              onClick={handleClickNext}
              endIcon={<KeyboardArrowRightIcon />}
              disabled={disableNextButton}
            />
          )}
          <Tooltip width="408px" show={false}>
            <h4>Oops!</h4>
            {activeStep === 1 && (
              <p style={{ fontSize: '14px' }}>
                Kamu belum dapat melanjutkan step jika belum melengkapi data informasi perusahaan.
              </p>
            )}
            {activeStep === 2 && (
              <p style={{ fontSize: '14px' }}>
                Kamu belum dapat melanjutkan step jika belum mengintegrasikan minimal 1 channel.
              </p>
            )}
            {/* {activeStep === 3 && (
              <p style={{ fontSize: '14px' }}>Kamu belum dapat melanjutkan step jika belum memilih toko master.</p>
            )} */}
            <TooltipQuote />
          </Tooltip>
          <Tooltip width="408px" style={{ zIndex: 999 }} show={showConfirmation} autoHeight={true}>
            {confirmationChildren}
            <TooltipAction>
              <TooltipActionButton onClick={handleCloseConfirmation} bgColor="transparent" textColor="#C3EBFF">
                batalkan
              </TooltipActionButton>
              <TooltipActionButton onClick={handleConfirm} bgColor="white" textColor="#163A50">
                {activeStep === 9 ? 'ok' : 'lanjutkan'}
              </TooltipActionButton>
            </TooltipAction>
            <TooltipQuote />
          </Tooltip>

          <Tooltip width="408px" style={{ zIndex: 999 }} show={showSubmitValidation} autoHeight={true}>
            {submitValidation}
            <TooltipAction>
              <TooltipActionButton onClick={handleCloseValidation} bgColor="transparent" textColor="#C3EBFF">
                batalkan
              </TooltipActionButton>
              <TooltipActionButton
                onClick={handleSubmitValidation}
                disabled={!validationComplete}
                bgColor="white"
                textColor="#163A50"
              >
                lanjutkan
              </TooltipActionButton>
            </TooltipAction>
            <TooltipQuote />
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default PageContentContainerFooter;
