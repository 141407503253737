import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField, CircularProgress, Button, Box } from '@mui/material';

interface BarcodeScannerInterface {
  message: string;
  onChange: () => void;
  onBarcodeClick: (text: string) => Promise<boolean>;
  className?: string;
  busy?: boolean;
  noBtn?: boolean;
  label?: string;
  placeholder?: string;
}

export function BarcodeScanner(props: BarcodeScannerInterface) {
  const { message, onBarcodeClick, onChange, className, busy, label, placeholder, noBtn } = props;
  const inputRef = React.useRef(null);
  const handleSend = async () => {
    const send = await onBarcodeClick(inputRef.current.value);
    if (send) {
      inputRef.current.value = '';
    }
    inputRef.current.focus();
  };

  return (
    <Box>
      <Box className="d-flex align-items-center">
        <TextField
          inputRef={inputRef}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.preventDefault();
              handleSend();
            }
          }}
          onFocus={(e) => {
            e.target.select();
          }}
          variant="outlined"
          error={message !== ''}
          defaultValue=""
          label={label}
          size="small"
          fullWidth
          placeholder={placeholder || 'Scan produk'}
          margin="normal"
          // helperText={message}
          className={`text-inline-button px-0 no-notch mr-2 ${props.className}`}
          onChange={() => {
            onChange();
          }}
          // InputProps={{
          //   endAdornment: (
          //     <InputAdornment position="end">
          //       {!busy ? (
          //         <Button
          //           variant="outlined"
          //           className="inline-button border-left"
          //           style={{ borderRadius: '0 8px 8px 0', background: '#F1F9FF', color: '#163A50' }}
          //           onClick={handleSend}
          //           size="small"
          //           color="inherit"
          //         >
          //           Scan
          //         </Button>
          //       ) : (
          //         <IconButton title="Scan" onClick={() => null}>
          //           <CircularProgress className="p-2" />
          //         </IconButton>
          //       )}
          //     </InputAdornment>
          //   ),
          // }}
        />
        {/* {noBtn ? null :
          !busy ? (
            <Button
              variant="contained"
              className="inline-button border-left"
              style={{ borderRadius: '8px', background: '#4B68EF', color: '#FFFFFF', padding: 19, top: 1 }}
              onClick={handleSend}
              // size="small"
              color="inherit"
            >
              Scan
            </Button>
          ) : (
            <IconButton title="Scan" onClick={() => null}>
              <CircularProgress className="p-2" />
            </IconButton>
          )} */}
      </Box>
      {message && <span style={{ color: 'red', fontSize: 12 }}>{message}</span>}
    </Box>
  );
}

BarcodeScanner.defaultProps = {
  className: '',
  busy: false,
  label: null
};
