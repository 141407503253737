import React from 'react';
import { Pagination, PaginationItem, Select } from '@mui/material';
import { PaginationLastSVG } from 'assets/img/pagination-last';
import { PaginationFirstSVG } from 'assets/img/pagination-first';
import { PaginationNextSVG } from 'assets/img/pagination-next';
import { PaginationPreviousSVG } from 'assets/img/pagination-previous';

type SinglePaginationProps = {
  pagination: any;
  onPageSizeChange: (e: any, value: any) => void;
  totalItems: number;
  onChangePage: (e: any, value: any) => void;
};
export const SinglePagination: React.FC<SinglePaginationProps> = ({
  pagination,
  onPageSizeChange,
  totalItems,
  onChangePage
}) => {
  return (
    <div className="border-light-gray d-flex align-items-center justify-content-between">
      <div
        style={{
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        Baris per halaman &nbsp;
        <Select
          native
          value={pagination.pageSize}
          variant="outlined"
          className="no-hover ml-2 bg-secondary no-notch"
          style={{
            justifyContent: 'space-between',
            gap: '19px'
          }}
          onChange={(e) => onPageSizeChange(totalItems, e.target.value)}
          inputProps={{ className: 'font-size-sm p-4' }}
        >
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={200}>200</option>
        </Select>
      </div>
      {/* {totalItems > pagination.pageSize && ()} */}
      <Pagination
        className="d-inline-flex"
        count={Math.ceil(totalItems / pagination.pageSize)}
        onChange={onChangePage}
        page={pagination.page}
        showFirstButton
        showLastButton
        renderItem={(item) => (
          <PaginationItem
            sx={{
              gap: '10px'
            }}
            components={{
              last: () => <PaginationLastSVG width={11} height={12} />,
              first: () => <PaginationFirstSVG width={11} height={12} />,
              next: () => <PaginationNextSVG width={11} height={12} />,
              previous: () => <PaginationPreviousSVG width={11} height={12} />
            }}
            {...item}
          />
        )}
      />
    </div>
  );
};
