import React from 'react';

export const DownloadSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00015 9.125V13.3144L10.5001 11.8144L11.5608 12.875L8.78047 15.6553C8.48752 15.9482 8.0127 15.9482 7.71975 15.6553L4.93945 12.875L6.00011 11.8144L7.50015 13.3144V9.125H9.00015Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.4167 4.25C6.86576 4.25 4.75 6.40568 4.75 9.125V9.55767C4.75 9.96545 4.42425 10.2984 4.0166 10.3075C3.07261 10.3284 2.25 11.1426 2.25 12.1539C2.25 13.2003 3.05992 14 4 14H4.75V15.5H4C2.17865 15.5 0.75 13.9749 0.75 12.1539C0.75 10.5929 1.83004 9.27545 3.2535 8.90862C3.36361 5.51296 6.0556 2.75 9.4167 2.75C12.1088 2.75 14.3755 4.52711 15.2239 6.97565C16.4499 7.80956 17.25 9.24117 17.25 10.8558C17.25 13.3938 15.2617 15.5 12.75 15.5H12V14H12.75C14.3804 14 15.75 12.6192 15.75 10.8558C15.75 9.66807 15.1236 8.64485 14.2134 8.11032C14.0496 8.01417 13.9284 7.85941 13.8742 7.67733C13.279 5.6751 11.4943 4.25 9.4167 4.25Z"
        fill="white"
      />
    </svg>
  );
};
