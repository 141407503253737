/* eslint-disable react/display-name */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Switch,
  TextField
} from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getChannelList } from 'api/integrationStep';
import { postSaveCutoff, postSyncPerStore, postValidateStoreStock } from 'api/masterStore';
import { EditDataSVG, HistorySVG, SaveSVG, WarningSVG } from 'assets/img';
import { showAlert } from 'components/Alert';
import { DateInput } from 'components/DateInput/DateInput';
import { Column } from 'components/LiteGrid/DataGrid.types';
import { LiteGrid } from 'components/LiteGrid/LiteGrid';
import UseDocumentTitle from 'hooks/UseDocumentTitle';
import { StepProps, ValidationConfirmation } from 'interfaces/Props';
import { QueryParameters } from 'lib/types';
import React, { useMemo, useRef, useState } from 'react';
import { channelDetail, dateFormatRegex, timeFormatRegex } from 'shared/constants';
import { useAppStore } from 'stores/appStore';
import styles from './StepEight.module.scss';

import CloseIcon from '@mui/icons-material/Close';
import { postSyncBulk } from 'api/locations';
import { convertDate, mergeDateTime } from 'lib/timeUtils';
import { useFormWithSchema } from 'hooks/useFormWithSchema';
import Joi from 'joi';
import { Form } from 'components/Form/Form';
import moment from 'moment';

const schema = Joi.object({
  cutoff: Joi.array().items(
    Joi.object({
      date_cutoff: Joi.any().allow(null),
      time_cutoff: Joi.any().allow(null)
    })
  )
});

const StepEight: React.FunctionComponent<StepProps & ValidationConfirmation> = ({ submitRef, confirmationRef }) => {
  UseDocumentTitle('Pengaturan Lokasi dan Stok');
  const { updateStep, setShowConfirmation } = useAppStore();

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [busy, setBusy] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [arrayData, setArrayData] = useState([]);
  const [ableEdit, setAbleEdit] = useState([]);
  const [cutoffDate, setCutoffDate] = useState(null);
  const [cutoffTime, setCutofTime] = useState(null);
  const [dataValidate, setDataValidate] = useState([]);
  const [isSyncAll, setIsSyncAll] = useState(false);
  const [toAll, setToAll] = useState(false);

  const query = useRef<QueryParameters>();
  const refresher = useRef<() => void>();
  const setQuery = useRef<(e: QueryParameters) => QueryParameters>();
  const modalValidate = useRef<boolean>(false);
  const readyStock = useRef<any>([]);
  const idStock = useRef<any>([]);

  const HistorySVGCoxmponent = () => <HistorySVG width={18} className="mt-1" height={18} />;

  const getQuery = (q: QueryParameters) => {
    query.current = q;
  };

  const getSetQuery = (fn) => {
    setQuery.current = fn;
  };

  const getFetcher = async (options: QueryParameters, initialFetch = false) => {
    setBusy(true);
    let newOptions: any = { ...options };
    newOptions.type = 'variant';

    const res = await getChannelList();

    const data = {
      data: res?.data.filter((val) => val.store_id !== -2) || [],
      totalCount: res?.data?.length || '0'
    };

    setArrayData(
      data.data.map((val) => {
        if (val.store_id !== -2) {
          const dateCutoff = val.extra_info && val.extra_info.cutoffDate && moment(val.extra_info.cutoffDate).toDate();

          return {
            date_cutoff: dateCutoff,
            time_cutoff: dateCutoff,
            store_id: val.store_id,
            pause_sales_download: val.pause_sales_download,
            channel_full_name: val.channel_full_name
          };
        }
      })
    );

    setBusy(false);
    return data;
  };

  const fetcher = useMemo(() => {
    return (options: QueryParameters) => {
      return getFetcher(options);
    };
  }, []);

  const handleSwitchTable = async (param) => {
    try {
      let payload = {
        syncOrder: param.pause_sales_download,
        storeId: param.store_id
      };
      const res = await postSyncPerStore(payload);
      if (res.data.status === 'ok') {
        showAlert('success', 'Sync stok berhasil', 5000);
        refresher.current();
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const removeDuplicate = async (data) => {
    let uniqueCombination = {};

    let newData = data.filter((item) => {
      const key = item.store_id + item.store_name;
      if (!uniqueCombination[key]) {
        uniqueCombination[key] = true;
        return true;
      }
      return false;
    });

    return newData;
  };

  const validateStock = async (data, type) => {
    try {
      setBusy(true);
      if (type === 'single') {
        const res = await postValidateStoreStock(data.store_id);
        if (res.status === 200) {
          if (!res.data.has_stock) {
            let oldData = [...dataValidate];
            oldData.push({
              store_id: res.data.store_id,
              store_name: res.data.store_name,
              has_stock: res.data.has_stock
            });
            modalValidate.current = true;
            let newData = await removeDuplicate(oldData);
            setDataValidate(newData);
          } else {
            handleSwitchTable(data);
          }
        }
      } else {
        if (!isSyncAll) {
          let oldData = [];
          let hasStockData = [];
          for (let i = 0; i <= data.length; i++) {
            if (data[i]?.store_id && data[i]?.store_id !== -2) {
              const res = await postValidateStoreStock(data[i].store_id);
              if (res.status === 200) {
                if (!res.data.has_stock) {
                  oldData.push({
                    store_id: res.data.store_id,
                    store_name: res.data.store_name,
                    has_stock: res.data.has_stock
                  });
                } else {
                  hasStockData.push({
                    store_id: res.data.store_id,
                    store_name: res.data.store_name
                  });
                  let payload = {
                    syncOrder: true,
                    storeId: res.data.store_id
                  };
                  await postSyncPerStore(payload);
                }
              }
            }
          }
          modalValidate.current = true;
          let newData = await removeDuplicate(oldData);
          setDataValidate(newData);
          readyStock.current = hasStockData;
        } else {
          setIsSyncAll(false);
        }
      }
      setBusy(false);
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  // const syncAll = async () => {
  //   try {
  //     let payload = readyStock.current.map((val) => val.store_id);
  //     const res = await postSyncAllStore(payload);
  //     if (res.data.status === 'ok') {
  //       setIsSyncAll(true);
  //       showAlert('success', 'Sync stock berhasil', 5000);
  //       refresher.current();
  //     }
  //   } catch (err) {
  //     const error = err as Error;
  //     console.log(error);
  //     showAlert('error', `${error}`, 5000);
  //   }
  // };

  // useEffect(() => {
  //   if (readyStock.current.length > 0 && readyStock.current.length === arrayData.length && dataValidate.length === 0) {
  //     syncAll();
  //   }
  // }, [readyStock.current && dataValidate]);

  const closeModalValidate = () => {
    modalValidate.current = false;
    readyStock.current = [];
    refresher.current();
    // setDataValidate([]);
  };

  const onEditCutoff = (format: 'date' | 'time', rowId: number) => {
    setAbleEdit((prev) => {
      if (format === 'date') {
        prev[rowId] = { ...prev[rowId], date: !prev[rowId]?.date };
      } else {
        prev[rowId] = { ...prev[rowId], time: !prev[rowId]?.time };
      }

      return [...prev];
    });
  };

  const onSaveCutoff = async (rowId: number, storeId: number | null, format: 'date' | 'time') => {
    const values = arrayData[rowId];

    try {
      let payload = {
        id: storeId ?? null,
        body: mergeDateTime(values.date_cutoff || new Date(), values.time_cutoff)
      };

      const res = await postSaveCutoff(payload);
      if (res.status === 200) {
        showAlert('success', 'Update data cutoff berhasil!', 5000);
        onEditCutoff(format, rowId);
        refresher.current();
      }
    } catch (error) {
      showAlert('error', 'Gagal update data cutoff!', 5000);
      console.log(error, 'error');
    }
  };

  const renderProduct = (_cellData: undefined, rD: any) => {
    const selected = dataValidate.find((dat) => dat.store_id == rD.store_id);

    return (
      <div className="d-flex align-items-center font-size-table py-2">
        <img className="d-20" src={channelDetail[rD.channel_id].channelLogo} alt={rD.channel_full_name} />
        <div
          className="d-flex flex-column ml-3 text-left"
          style={{ color: selected?.has_stock === false ? '#DF4D4D' : '' }}
        >
          <span className={selected?.has_stock === false ? 'font-weight-normal' : styles.font14}>
            {rD.store_name || rD.channel_full_name}
          </span>
          {selected?.has_stock === false && (
            <span className="font-weight-normal" style={{ fontSize: '10px' }}>
              Produk belum terdownload atau transaksi adjustment belum tersedia
            </span>
          )}
        </div>
      </div>
    );
  };

  const onChangeDateCutoff = (format: 'date' | 'time', rowId: number, val: any) => {
    setArrayData((prev) => {
      if (format === 'date') {
        prev[rowId].date_cutoff = val;
      } else {
        prev[rowId].time_cutoff = val;
      }

      return [...prev];
    });
  };
  const colTemplateDate = (format: 'date' | 'time') => (ctx, record) => {
    let dateCutoff: string = '-';
    const isEdit = format === 'date' ? ableEdit[ctx.row_id]?.date : ableEdit[ctx.row_id]?.time;
    const value = format === 'date' ? arrayData[ctx.row_id]?.date_cutoff : arrayData[ctx.row_id]?.time_cutoff;

    if (record.extra_info?.cutoffDate) {
      if (format === 'date') {
        dateCutoff = convertDate(record.extra_info.cutoffDate, 'DD MMM YYYY');
      } else {
        dateCutoff = convertDate(record.extra_info.cutoffDate, 'hh:mm:ss');
      }
    }

    const renderChildCutoff = () => {
      if (isEdit) {
        if (format === 'date') {
          return (
            <DateInput
              key={`cutoff.${ctx.row_id}.date_cutoff`}
              label=""
              onChange={(e) => onChangeDateCutoff('date', ctx.row_id, e)}
              placeholder="Pilih tanggal cutoff"
              className="MuiDatePicker date-cutoff-picker mt-0"
              margin="dense"
              value={value}
            />
          );
        } else {
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                value={value}
                onChange={(e) => onChangeDateCutoff('time', ctx.row_id, e)}
                className="MuiDatePicker date-cutoff-picker"
                ampm={false}
                ampmInClock={false}
                renderInput={({ inputProps, ...params }) => (
                  <TextField
                    {...params}
                    className="no-notch TimePicker"
                    placeholder="Pilih jam cutoff"
                    fullWidth
                    inputProps={{
                      ...inputProps,
                      placeholder: 'Pilih jam cutoff'
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          );
        }
      } else {
        return <span className="font-weight-normal font-size-table"> {dateCutoff}</span>;
      }
    };

    return (
      <div className={`${styles.cutoffDate}`}>
        {renderChildCutoff()}
        <IconButton
          onClick={() =>
            isEdit ? onSaveCutoff(ctx.row_id, record.store_id, format) : onEditCutoff(format, ctx.row_id)
          }
          className={`m-0 p-0 ${styles.fitContent}`}
        >
          {isEdit ? <SaveSVG className="text-second" /> : <EditDataSVG />}
        </IconButton>
      </div>
    );
  };

  const columns: Column[] = [
    {
      header: 'Nama Toko',
      headerClassName: 'font-weight-lightbold',
      binding: 'channel_id',
      format: 'string',
      allowSorting: false,
      freezeCol: true,
      template: renderProduct
    },
    {
      header: 'Tgl. Cutoff',
      binding: 'extra_info',
      headerClassName: 'font-weight-lightbold',
      allowSorting: false,
      template: colTemplateDate('date')
    },
    {
      header: 'Jam Cutoff',
      binding: 'extra_info',
      headerClassName: 'font-weight-lightbold',
      allowSorting: false,
      template: colTemplateDate('time')
    },
    {
      header: 'Aktif',
      headerClassName: 'text-center text-second text-nowrap font-weight-lightbold',
      allowSorting: false,
      binding: 'pause_sales_download',
      template: (ctx, data) => {
        const selected = arrayData.find((dat) => dat.store_id == data.store_id);
        return (
          <div className="text-center">
            <Switch
              disabled={data.store_id === -2}
              onChange={() => validateStock(data, 'single')}
              checked={!selected.pause_sales_download}
              className="switch-small m-1"
            />
          </div>
        );
      }
    }
  ];

  const handleSyncAllStore = async () => {
    validateStock(arrayData, 'all');
  };

  const handleCloseEdit = () => {
    setModalEdit(false);
    setCutoffDate(null);
  };

  const handleSaveSync = async () => {
    try {
      setBusy(true);
      if (!cutoffDate || !cutoffTime) {
        showAlert('error', 'Silahkan pilih waktu cuttoff terlebih dahulu', 5000);
      } else {
        if (toAll) {
          let payload = {
            isSync: true,
            extraInfo: null,
            storeIds: []
          };
          let body = mergeDateTime(cutoffDate, cutoffTime);
          for (let i = 0; i <= arrayData.length; i++) {
            if (arrayData[i]?.store_id && arrayData[i]?.store_id !== -2) {
              payload.storeIds.push(arrayData[i].store_id);
            }
          }
          payload.extraInfo = { cutoffDate: body };
          const res = await postSyncBulk(payload);
          if (res.status === 200) {
            showAlert('success', 'Update data cutoff berhasil!', 5000);
            refresher.current();
            handleCloseEdit();
          }
        } else {
          let payload = {
            id: idStock.current[0],
            body: mergeDateTime(cutoffDate, cutoffTime)
          };
          const res = await postSaveCutoff(payload);
          if (res.status === 200) {
            showAlert('success', 'Update data cutoff berhasil!', 5000);
            refresher.current();
            handleCloseEdit();
          }
        }
      }
      setBusy(false);
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const handleClick = () => {
    if (!isConfirmed) {
      setShowConfirmation(true);
    }
  };

  const handleClickConfirmation = () => {
    setIsConfirmed(true);
    updateStep('INCREASE');
  };

  return (
    <>
      <Grid>
        <div className={`mb-3 ${styles.title}`}>Sinkronisasi Pesanan</div>
        <div className={`mb-3 ${styles.titleDescription}`}>
          Untuk memastikan semua pesanan dari channel penjualan terintegrasi dengan Jubelio, Perlu dilakukan
          sinkronisasi pesanan. Untuk mengatur proses ini, diperlukan waktu cut-off yang menentukan kapan pesanan mulai
          masuk.
          <br />
          <br />
          Penting untuk memastikan bahwa waktu cut-off tidak di bawah waktu selesainya pengisian persediaan awal, karena
          stok yang sudah diinput sebelumnya akan sinkron ke channel penjualan ketika pesanan diaktifkan.
        </div>
        <Divider sx={{ mt: '10px', mb: '20px' }} />
        <Switch disabled={busy} checked={isSyncAll} onChange={handleSyncAllStore} className="switch-small" />
        <span className={`${styles.titleDescription} ml-3`}>Sinkronisasi pesanan dari semua toko ke Jubelio</span>

        <Grid container className="mt-3">
          <Grid item md={12}>
            <LiteGrid
              busy={busy}
              havePagination={false}
              flexibleWidth={true}
              showRowSelect={false}
              columns={columns}
              data={fetcher}
              getRefresher={(e) => (refresher.current = e)}
              getQuery={getQuery}
              getSetQuery={getSetQuery}
            />
          </Grid>
        </Grid>
      </Grid>

      <Dialog maxWidth="sm" open={modalValidate.current === true}>
        <div className="d-flex justify-content-center align-items-center flex-column" style={{ padding: '30px 80px' }}>
          <WarningSVG />
          <span className={`text-center my-3 ${styles.font14}`}>
            Kamu belum dapat mengaktifkan sinkronisasi pesanan di
            {dataValidate?.length > 1 ? (
              <span className="font-weight-bold">
                {' '}
                {dataValidate[0]?.store_name} dan {dataValidate?.length - 1} toko lainnya{' '}
              </span>
            ) : (
              <span className="font-weight-bold"> {dataValidate[0]?.store_name} </span>
            )}
            karena produk belum terdownload atau transaksi adjustment belum tersedia
          </span>
          <Button sx={{ width: '50px' }} onClick={closeModalValidate} type="button" variant="contained" color="primary">
            Ok
          </Button>
        </div>
      </Dialog>

      <button ref={confirmationRef} onClick={handleClickConfirmation} style={{ display: 'none' }}>
        Confirmation Ref
      </button>
      <button ref={submitRef} onClick={handleClick} style={{ display: 'none' }}>
        CLICK
      </button>
    </>
  );
};

export default StepEight;
