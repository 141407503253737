import React from 'react';

export const ExportSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M11 17V6H13V17H11Z" fill="#163A50" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.58594 10L11.293 5.2929C11.4806 5.10536 11.7349 5 12.0002 5C12.2654 5 12.5197 5.10536 12.7073 5.2929L17.4144 10L16.0002 11.4142L12.0002 7.41422L8.00015 11.4142L6.58594 10Z"
        fill="#163A50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.79289 2.79289C2.98043 2.60536 3.23478 2.5 3.5 2.5H8.75V4.5H4.5V19.5H19.5V4.5H15.25V2.5H20.5C21.0523 2.5 21.5 2.94772 21.5 3.5V20.5C21.5 21.0523 21.0523 21.5 20.5 21.5H3.5C2.94772 21.5 2.5 21.0523 2.5 20.5V3.5C2.5 3.23478 2.60536 2.98043 2.79289 2.79289Z"
        fill="#163A50"
      />
    </svg>
  );
};
