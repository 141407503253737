import { List, ListItem, ListItemText, Paper, Popover } from '@mui/material';
import React from 'react';

interface DataArray {
  label: string;
  icon?: any;
}

interface PopOverInterface {
  icon?: any;
  label: string;
  dataArray: DataArray[];
}

const PopOver = ({ label, icon, dataArray }: PopOverInterface) => {
  const [openRowAction, setOpenRowAction] = React.useState({
    anchor: null,
    data: null
  });

  return (
    <div>
      {dataArray.length > 1 ? (
        <div
          className="cursor-pointer text-primary"
          onClick={(e) =>
            setOpenRowAction({
              anchor: e.currentTarget,
              data: dataArray
            })
          }
        >
          {icon}
          {label}
        </div>
      ) : (
        <div>
          {dataArray[0]?.icon && <div>{dataArray[0]?.icon}</div>}
          <div>{dataArray[0]?.label || '-'}</div>
        </div>
      )}
      <Popover
        id={'bill-no'}
        open={Boolean(openRowAction.anchor)}
        anchorEl={openRowAction.anchor}
        onClose={() => setOpenRowAction({ ...openRowAction, anchor: null })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        sx={{ height: '210px' }}
      >
        <Paper>
          <List
            dense
            sx={{
              position: 'relative',
              '&::before': {
                backgroundColor: 'white',
                content: '""',
                display: 'block',
                position: 'absolute',
                width: 12,
                height: 12,
                top: -6,
                transform: 'rotate(45deg)',
                left: 'calc(50% - 6px)'
              }
            }}
          >
            {openRowAction?.data?.length > 1 &&
              openRowAction?.data?.map((item, i) => (
                <ListItem key={i}>
                  <ListItemText
                    primary={
                      <span>
                        {i + 1}. {item?.icon} {item?.label}
                      </span>
                    }
                  />
                </ListItem>
              ))}
          </List>
        </Paper>
      </Popover>
    </div>
  );
};

export default PopOver;
