import { StepContainer, TextLink, Title, TitleDescription } from 'components/atoms';
import HorizontalDividers from 'components/CustomDividers/HorizontalDividers';
import React, { useEffect, useRef, useState } from 'react';
import { useAppStore } from 'stores/appStore';
import UseDocumentTitle from 'hooks/UseDocumentTitle';
import Beginning from './Beginning';
import InitialDownload from './InitialDownload';
import { showAlert } from 'components/Alert';
import { postProductList, setMasterChannel, setWizardSetup } from 'api/masterStore';
import { StepProps, ValidationConfirmation } from 'interfaces/Props';

const StepThree: React.FunctionComponent<StepProps & ValidationConfirmation> = ({
  submitRef,
  confirmationRef,
  setValidationState
}) => {
  const { toggleSubmitValidation, setShowConfirmation, masterShop } = useAppStore();
  const setupWizard = useRef<boolean>();
  const [isMasterSelected, setMaster] = useState(null);
  UseDocumentTitle('Pemilihan Toko Master');

  const setCompleteValidation = (key: string) => {
    setValidationState((prevState) => {
      const updatedValidations = prevState.masterDownload.map((validation) => {
        if (validation.key === key) {
          return {
            ...validation,
            completed: true
          };
        }
        return validation;
      });
      return {
        ...prevState,
        masterDownload: updatedValidations
      };
    });
  };

  const handleClick = () => {
    if (isMasterSelected) {
      toggleSubmitValidation(true);
    }
    if (!isMasterSelected) {
      setShowConfirmation(true);
    }
  };

  const handleClickConfirmation = async () => {
    if (masterShop) {
      try {
        const {
          data: { status: dataStatus },
          status,
          statusText
        } = await setMasterChannel(masterShop.store_id, true);
        if (status === 200 && dataStatus === 'ok') {
          const {
            data: { status: dataPostStatus },
            status: postStatus,
            statusText: postStatusText
          } = await postProductList(masterShop.channel_id, masterShop.store_id, true, false);
          if (postStatus === 200 && dataPostStatus === 'ok') {
            setMaster(masterShop.store_id);
          } else {
            console.error(`Something went wrong ${postStatusText !== '' ? postStatusText : 'Unknown Errors!'}`);
          }
        } else {
          console.error(`Something went wrong ${statusText !== '' ? statusText : 'Unknown Errors!'}`);
        }
      } catch (err) {
        const error = err as Error;
        console.error(error);
      }
    }
  };

  const getOnBoardingSetup = async () => {
    try {
      const res = await setWizardSetup();
      const setupData = res?.data?.setup;
      const masterSelected = setupData.master_channel_id;
      setMaster(masterSelected);
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  useEffect(() => {
    getOnBoardingSetup();
  }, []);

  return (
    <>
      <StepContainer>
        <Title>Pemilihan Toko Master</Title>
        <TitleDescription>
          Toko Master adalah toko yang produk-produknya didownload pertama kali di Jubelio, sehingga detail produk
          (nama, deskripsi, foto dll) dengan SKU Produk yang sama dari toko lain akan mengikuti produk Toko Master. Kami
          sarankan untuk memilih toko dengan produk dan detail produk yang paling lengkap (Nama, SKU Produk, deskripsi
          dan foto) supaya produk yang didownload pertama kali di Jubelio rapi. Untuk memahami lebih lanjut konsep toko
          master, &nbsp;
          <TextLink
            href="https://v2.edu.jubelio.com/documentation/fitur-katalog/produk-di-jubelio-2/produk-master/"
            target={'_blank'}
          >
            klik disini
          </TextLink>
        </TitleDescription>
        <HorizontalDividers marginBlock={true} />
        {isMasterSelected === null ? (
          <Beginning />
        ) : (
          <InitialDownload setCompleteValidation={setCompleteValidation} data={isMasterSelected} />
        )}
      </StepContainer>
      <button ref={confirmationRef} onClick={handleClickConfirmation} style={{ display: 'none' }}>
        Confirmation Ref
      </button>
      <button ref={submitRef} onClick={handleClick} style={{ display: 'none' }}>
        CLICK
      </button>
    </>
  );
};

export default StepThree;
