import React from 'react';
import { Alert, Snackbar } from '@mui/material';
import Portal from '@mui/material/Portal';

type ErrorLevel = 'error' | 'info' | 'warning' | 'success';
interface AlertState {
  level: ErrorLevel;
  message: string;
  messageKey: number;
  duration: number;
}

let setAlert: any;

function useAlert(callback: any) {
  setAlert = callback;
}

export function showAlert(level: ErrorLevel, message: string, duration?: number) {
  if (!setAlert) {
    return;
  }
  setAlert({ level, message, messageKey: new Date().getTime(), duration: duration ?? 2000 });
}

export function AlertMessage() {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState<AlertState | null>(null);

  useAlert(setMessage);

  React.useEffect(() => {
    if (message && message.message) {
      setOpen(true);
    }
  }, [message]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    message && (
      <Portal>
        <Snackbar
          key={message.messageKey}
          open={open}
          onClose={handleClose}
          autoHideDuration={message.duration}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity={message.level}>{message.message}</Alert>
        </Snackbar>
      </Portal>
    )
  );
}
