import React from 'react';
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  Theme,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
  Tooltip,
  Checkbox
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ErrorActionSVG } from 'assets/img/icon-error';
import { ProcessSVG, SuccessActionSVG } from 'assets/img';
import { AsyncButton } from 'components/ButtonNew/Button';
import { createStyles, makeStyles } from '@mui/styles';
import { deleteDataProduct } from 'api/masterStore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      width: '100%',
      borderRadius: 8
    },
    tableContainer: {
      width: '100%',
      '& .MuiTableRow-head': {
        background: '#ffffff'
      },
      '& .MuiTableCell-sizeSmall': {
        padding: '14px 25px'
      }
    },
    processIcon: {
      animation: '$spin 2s linear infinite'
    },
    '@keyframes spin': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(-360deg)' }
    }
  })
);

type Props = {
  isShow: boolean;
  title: string;
  data: any;
  btnSubmitColor?: 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  onSubmit?: (data?) => void;
  onClose?: () => void;
  maxWidth?: DialogProps['maxWidth'];
  fullWidth?: boolean;
  urlApi?: string;
  method?: string;
  mappingRequest?: any;
  getUrlApi?: any;
  isLoading?: boolean;
  btnLabel?: string;
  btnIcon?: any;
};

let indexes = 0;
let stopLoop = false;

export const ModalAction: React.FC<Props> = ({
  isShow,
  title,
  data,
  onSubmit,
  onClose,
  btnSubmitColor = 'inherit',
  fullWidth = true,
  btnLabel,
  isLoading = false,
  btnIcon
}) => {
  const classes = useStyles();
  const [show, setShow] = React.useState(isShow);
  const [datas, setDatas] = React.useState(data);
  const [busy, setBusy] = React.useState(false);
  const [errorCnt, setErrorCnt] = React.useState(0);
  const [successCnt, setSuccessCnt] = React.useState(0);

  const handleContinue = async () => {
    const modData = datas.filter((x) => !x.success);
    const checkingData = modData.map((x) => {
      x.checked = true;
      return x;
    });
    setDatas(checkingData);
  };

  const setItemLoading = (index, isLoad, isSuccess, msg = '') => {
    const del = datas.slice();
    del[index].busy = isLoad;
    del[index].success = isSuccess;
    del[index].msg = msg;
    setDatas(del);
  };

  const handleErrorRequest = (index) => {
    setBusy(true);
    setItemLoading(index, true, null);
    const ids = data.map((row) => row.id);
    let payload = {
      channelId: datas[indexes].channelId,
      storeId: datas[indexes].storeId,
      isMaster: datas[indexes].isMaster,
      isVariant: datas[indexes].isVariant,
      ids
    };
    deleteDataProduct(payload)
      .then((val) => {
        setItemLoading(index, false, true);
        setBusy(false);
      })
      .catch((err) => {
        setItemLoading(index, false, false, err.message);
        setBusy(false);
      });
  };

  const handleErrorBatch = async () => {
    stopLoop = false;
    setBusy(true);
    const errDatas = datas.filter((x) => x.success == false && x.checked);
    for (let i = 0; i < errDatas.length; i++) {
      handleErrorRequest(i);
    }
  };

  const handleStop = async () => {
    stopLoop = true;
    setBusy(false);
  };

  const handleClose = async () => {
    stopLoop = true;
    setShow(false);
    onClose();
  };

  const handleConfirm = async () => {
    if (datas[indexes].checked && !datas[indexes].disabled) {
      setItemLoading(indexes, true, null);
      const ids = data.map((row) => row.id);
      let payload = {
        channelId: datas[indexes].channelId,
        storeId: datas[indexes].storeId,
        isMaster: datas[indexes].isMaster,
        isVariant: datas[indexes].isVariant,
        ids
      };
      deleteDataProduct(payload)
        .then((val) => {
          setItemLoading(indexes, false, true);
          indexes++;
          if (indexes < datas.length && !stopLoop) {
            handleConfirm();
            return null;
          } else {
            console.log('Loop Stoped');
            setBusy(false);
            onSubmit(data);
          }
        })
        .catch((err) => {
          setItemLoading(indexes, false, false, err.message);
          indexes++;
          if (indexes < datas.length && !stopLoop) {
            handleConfirm();
            return null;
          } else {
            console.log('Loop Stoped');
            onSubmit(data);
            setBusy(false);
          }
        });
    } else {
      indexes++;
      if (indexes < datas.length && !stopLoop) {
        handleConfirm();
        return null;
      } else {
        setBusy(false);
      }
    }
    setBusy(false);
  };

  const handleSubmit = async () => {
    stopLoop = false;
    indexes = 0;
    setBusy(true);
    await handleConfirm();
  };

  const handleSelectAll = (e) => {
    const checkData = datas.slice();
    checkData.map((x) => {
      x.checked = e.target.checked;
    });
    setDatas(checkData);
  };

  const handleChecked = (e, val) => {
    const checkData = datas.slice();
    checkData.map((x) => {
      if (x.id == val.id) {
        x.checked = e.target.checked;
      }
    });
    setDatas(checkData);
  };

  React.useEffect(() => {
    if (datas.length > 0) {
      setErrorCnt(datas.filter((x) => x.success == false).length);
      setSuccessCnt(datas.filter((x) => x.success == true).length);
    }
  }, [datas]);

  React.useEffect(() => {
    if (data.length > 0) {
      setDatas(
        data.map((x) => {
          x.checked = true;
          return x;
        })
      );
    }
  }, [data]);

  React.useEffect(() => {
    setShow(isShow);
    if (!isShow) {
      setDatas([]);
    }
  }, [isShow]);

  const renderListUploadChannel = () => {
    const selRow = datas.filter((x) => x.checked == true).length;

    function getColor(x) {
      if (x.disabled) {
        return '#eeeff8';
      } else if (x.busy) {
        return '#fff';
      } else {
        if (x.success == true) {
          return 'rgba(76,175,80,0.1)';
        } else if (x.success == false) {
          return 'rgba(244,67,54,0.1)';
        }
      }
    }
    const cardUpChannel = (item, indx) => (
      <TableRow
        key={`rows-${indx}`}
        style={{
          background: getColor(item)
        }}
      >
        <TableCell>
          <Checkbox
            checked={item.checked == true && !item.disabled}
            onClick={(e) => handleChecked(e, item)}
            color="primary"
          />
        </TableCell>
        <TableCell style={{ whiteSpace: 'pre-wrap', fontSize: 12 }}><span style={{ fontWeight: 'bold' }}>{item.label}</span> <br/>
        {item.skus && item.skus.map((sku, index) => (
          <span key={index}>{sku.item_code} <br/></span>
        ))}
        </TableCell>
        <TableCell className="text-center" style={{ fontSize: 12 }}>
          {item.disabled ? (
            <span className="text-danger">{`Tidak dapat di${title.toLowerCase()}`}</span>
          ) : item.busy ? (
            <ProcessSVG className={classes.processIcon} style={{ width: '20px', height: 'auto' }} />
          ) : item.success == true ? (
            <SuccessActionSVG />
          ) : item.success == false ? (
            <Tooltip arrow placement="top" title={item.msg}>
              <IconButton style={{ padding: 5 }}>
                <ErrorActionSVG />
              </IconButton>
            </Tooltip>
          ) : null}
        </TableCell>
      </TableRow>
    );

    return (
      <div className={`${classes.tableContainer} position-relative px-4`}>
        {/* <TableContainer style={{ minHeight: 'calc(100vh - 435px)', border: '1px solid #555555' }}> */}
        <TableContainer style={{ border: '1px solid #E0E0E0', borderRadius: 6 }}>
          <Table size="small" className="table-more-small">
            <TableHead className="w-100 transition-base">
              <TableRow className="w-100">
                <TableCell className="border-top border-bottom">
                  <Checkbox
                    checked={selRow == datas.length}
                    indeterminate={selRow > 0 && selRow < datas.length}
                    onClick={handleSelectAll}
                    color="primary"
                  />
                </TableCell>
                <TableCell
                  style={{ minWidth: 200 }}
                  className="w-100 border-top border-bottom font-weight-lightbold text-nowrap"
                >
                  <span style={{ fontSize: 14 }}>Produk</span>
                </TableCell>
                <TableCell
                  style={{ minWidth: 200 }}
                  className="text-center w-100 border-top border-bottom font-weight-lightbold text-nowrap"
                >
                  <span style={{ fontSize: 14 }}>Status</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading
                ? [1, 2, 3].map((x) => (
                    <TableRow key={`rows-${x}`}>
                      <TableCell>
                        <div className="shine shine-image"></div>
                      </TableCell>
                      <TableCell>
                        <div className="shine shine-line"></div>
                      </TableCell>
                      <TableCell>
                        <div className="shine shine-line"></div>
                      </TableCell>
                    </TableRow>
                  ))
                : datas.map((x, key) => {
                    return cardUpChannel(x, key);
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  function toFirstUpperCase(str) {
    const arrStr = str.split(' ');
    const upStr = arrStr.map((x) => {
      return x.charAt(0).toUpperCase() + x.slice(1);
    });
    return upStr.join(' ');
  }

  const renderActionButton = () => {
    const selRow = datas.filter((x) => x.checked == true && !x.disabled).length;
    if (isLoading) {
      return null;
    }

    if (busy) {
      return (
        <AsyncButton busy={busy} variant="contained" type="submit" onClick={handleStop} color={btnSubmitColor}>
          Stop
        </AsyncButton>
      );
    }

    if (datas.length > 0 && selRow > 0 && successCnt == 0 && errorCnt == 0) {
      return (
        <AsyncButton
          busy={busy}
          variant="contained"
          startIcon={btnIcon}
          type="submit"
          onClick={handleSubmit}
          color={btnSubmitColor}
          className="bg-primary"
        >
          {btnLabel ? toFirstUpperCase(btnLabel) : toFirstUpperCase(title)}
        </AsyncButton>
      );
    }

    if (selRow > 0 && successCnt < datas.length && errorCnt == 0) {
      return (
        <AsyncButton busy={busy} variant="contained" type="submit" onClick={handleContinue} color={btnSubmitColor}>
          {`Lanjut ${toFirstUpperCase(title)}`}
        </AsyncButton>
      );
    } else if (errorCnt > 0) {
      return (
        <AsyncButton busy={busy} variant="contained" type="submit" onClick={handleErrorBatch} color={btnSubmitColor}>
          {`${toFirstUpperCase(title)} Ulang`}
        </AsyncButton>
      );
    }

    if (selRow > 0) {
      return (
        <AsyncButton
          busy={busy}
          variant="contained"
          type="submit"
          className="bg-primary"
          onClick={handleClose}
          color={btnSubmitColor}
        >
          Ok
        </AsyncButton>
      );
    }
  };

  return (
    <Dialog fullWidth={fullWidth} maxWidth={'md'} open={show} onClose={handleClose} aria-labelledby="upload-ke-channel">
      <DialogTitle
        id="simple-dialog-title"
        style={{ alignItems: 'center', padding: '16px 0px' }}
        className="border-bottom mb-4"
      >
        <div className="justify-content-between d-flex align-items-center mx-4" style={{ width: 'auto' }}>
          <span className="font-weight-bold" style={{ fontSize: 30 }}>
            {`${toFirstUpperCase(title)}`}
          </span>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent style={{ padding: '0px 0px' }}>{renderListUploadChannel()}</DialogContent>
      <DialogActions style={{ borderTop: 0, padding: '16px 0px' }} className="mt-4 border-top">
        <div className="mx-4">{renderActionButton()}</div>
      </DialogActions>
    </Dialog>
  );
};
