import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  alertContainer: {
    width: 434,
    height: 308,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alertContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alertMessage: {
    marginTop: 23,
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '19px',
    textAlign: 'center',
    color: '#163A50',
    justifyContent: 'center',
    marginBottom: 10,
  },
}));
