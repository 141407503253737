import React from 'react';
import styles from './ErrorMessage.module.scss';

type Props = {
  icon: React.ReactElement,
  message: string | undefined,
};

const ErrorMessage: React.FunctionComponent<Props> = ({ icon, message }) => {
  return (
    <span className={styles.errorContainer}>
        {icon}
        <span>{message}</span>
    </span>
  );
};

export default ErrorMessage;
