import React, { ReactElement } from 'react';
import { ErrorMessage, InputContainer, InputLabel, InputValue } from './Style';
interface Props {
  label: ReactElement;
  input: ReactElement;
  errorMessage?: ReactElement | boolean;
  top?: boolean;
  wide?: boolean;
}

const InputGrid: React.FunctionComponent<Props> = ({ label, input, errorMessage, top, wide }) => {
  return (
    <InputContainer wide={wide}>
      <InputLabel top={top}>{label}</InputLabel>
      <InputValue>{input}</InputValue>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </InputContainer>
  );
};

export default InputGrid;