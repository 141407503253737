import React from 'react';

export const EmptyFolderSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="168" height="168" viewBox="0 0 168 168" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="84" cy="84" r="84" fill="#F5FAFF" />
      <path
        d="M35.0145 56.0143C35.0065 55.4565 35.4565 55 36.0144 55H59.9793C60.3057 55 60.6114 55.1592 60.7986 55.4265L67.2014 64.5735C67.3886 64.8408 67.6943 65 68.0207 65H115.913C116.498 65 116.958 65.5 116.91 66.083L112.076 124.083C112.033 124.601 111.6 125 111.08 125H36.9858C36.4391 125 35.9937 124.561 35.9859 124.014L35.0145 56.0143Z"
        fill="#A9B8C3"
      />
      <path
        d="M52.7721 80.6838C52.9082 80.2754 53.2903 80 53.7208 80H133.67C134.336 80 134.816 80.6381 134.631 81.2775L122.209 124.278C122.085 124.705 121.693 125 121.248 125H39.3874C38.7049 125 38.2229 124.331 38.4387 123.684L52.7721 80.6838Z"
        fill="#B1BABF"
      />
      <path
        d="M52.7721 80.6838C52.9082 80.2754 53.2903 80 53.7208 80H133.67C134.336 80 134.816 80.6381 134.631 81.2775L122.209 124.278C122.085 124.705 121.693 125 121.248 125H39.3874C38.7049 125 38.2229 124.331 38.4387 123.684L52.7721 80.6838Z"
        fill="#C9D2D8"
      />
      <path
        d="M57.8106 86.7575C57.9219 86.3123 58.3219 86 58.7808 86H77.7192C78.3698 86 78.8472 86.6114 78.6894 87.2425L78.1894 89.2425C78.0781 89.6877 77.6781 90 77.2192 90H58.2808C57.6302 90 57.1528 89.3886 57.3106 88.7575L57.8106 86.7575Z"
        fill="#A0AFBA"
      />
    </svg>
  );
};
