import React from 'react';

export const PaginationFirstSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6 2.25L2.25 6L6 9.75"
        stroke="#BBBBBB"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 2.25L7.5 6L11.25 9.75"
        stroke="#BBBBBB"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
