import styled from 'styled-components';

interface RightFormProps {
  no_padding?: boolean;
}
export const RightForm = styled.form<RightFormProps>`
  display: grid;
  gap: 1rem;
  padding: ${({ no_padding }) => !no_padding ? '2rem 1.3rem' : 'initial'} ;
`;
export const Bottom = styled.div``;
export const BottomActions = styled.div`
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const ConnectButton = styled.button`
  border: none;
  outline: none;
  padding: 10px 1rem;
  background-color: #4B68EF;
  color: #fff;
  border-radius: 8px;
  font-size: 12px;
  cursor: pointer;
`;