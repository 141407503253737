import { onBoardingAPI } from './axiosClient';

export const getCurrentStep = () => {
  return onBoardingAPI({
    method: 'GET',
    url: 'wizard/get-current-step',
  });
};

export const wipeData = () => {
  return onBoardingAPI({
    method: 'POST',
    url: 'tenant/wipe-data',
  });
};

export const updateCurrentStep = (lastOnboardingStep: string, onboardingLastModified: string, increaseOrDecreaseStep: string) => {
  return onBoardingAPI({
    method: 'POST',
    url: 'wizard/update-step',
    data: {
      lastOnboardingStep, onboardingLastModified, increaseOrDecreaseStep,
    },
  });
};