import React, { useRef, ReactElement, useState } from 'react';
import { CheckCircleOutline, HighlightOff } from '@mui/icons-material';
import { Box } from '@mui/material';
import { StepFive, StepOne, StepSeven, StepTwo, StepEight } from 'pages/Onboarding/index';
import { useAppStore } from 'stores/appStore';
import StepFour from 'pages/Onboarding/StepFour/StepFour';
import StepNine from 'pages/Onboarding/StepNine/StepNine';
import StepSix from 'pages/Onboarding/StepSix';
import StepTen from 'pages/Onboarding/StepTen/StepTen';
import StepThree from 'pages/Onboarding/StepThree/StepThree.';
import PageContentContainerFooter from './PageContentContainerFooter';

interface Step {
  ref: React.RefObject<HTMLButtonElement>;
  prevRef?: React.RefObject<HTMLButtonElement>;
  component: ReactElement;
  submitValidation?: ReactElement;
  confirmationChildren?: ReactElement;
}
interface Steps {
  [key: number]: Step;
}

const ValidationChildren = ({ validations }) => {
  return (
    <>
      <p style={{ fontSize: '16px', fontWeight: '600', margin: 0 }}>
        {'Sebelum melanjutkan ke step berikutnya, pastikan bahwa:'}
      </p>
      <ul style={{ fontSize: '16px', fontWeight: '600', padding: '0px 25px' }}>
        {validations.map((validation, index) => (
          <li key={index}>
            {validation.text} {validation.completed ? <CheckCircleOutline /> : <HighlightOff />}
          </li>
        ))}
      </ul>
      <p style={{ fontSize: '13px' }}>Setelah dilanjutkan, Kamu tidak dapat mengulang proses ini</p>
    </>
  );
};

const ConfirmationChildren = ({ title, subTitle }: { title: string; subTitle: string }) => {
  return (
    <>
      <p style={{ fontSize: '16px', fontWeight: '600' }}>{title}</p>
      <p style={{ fontSize: '13px' }}>{subTitle}</p>
    </>
  );
};

const PageContentContainer: React.FunctionComponent = (): JSX.Element => {
  const submitRef1 = useRef(null);
  const submitRef2 = useRef(null);
  const submitRef3 = useRef(null);
  const submitRef4 = useRef(null);
  const submitRef5 = useRef(null);
  const submitRef6 = useRef(null);
  const submitRef7 = useRef(null);
  const submitRef8 = useRef(null);
  const submitRef9 = useRef(null);
  const submitRef10 = useRef(null);

  const beforeRef7 = useRef(null);
  const confirmationRef = useRef(null);

  const { activeStep, masterShop, integratedChannels } = useAppStore();

  let channelLeft = integratedChannels?.filter((val) => !val.is_downloaded);

  interface Validation {
    key: string;
    text: string;
    completed: boolean;
    optional: boolean;
  }
  interface ValidationList {
    [key: string]: Validation[];
  }

  const [validationState, setValidationState] = useState<ValidationList>({
    masterDownload: [
      {
        key: 'VALID_SKU',
        text: 'SKU produk tidak kosong (mandatory)',
        completed: false,
        optional: false
      },
      {
        key: 'DUPLICATE_SKU',
        text: 'SKU produk tidak duplikat (opsional)',
        completed: false,
        optional: true
      },
      {
        key: 'DOWNLOADED',
        text: 'Semua produk berhasil terdownload (opsional)',
        completed: false,
        optional: true
      }
    ],
    nonMasterdownload: [
      {
        key: 'VALID_SKU',
        text: 'SKU produk tidak kosong (mandatory)',
        completed: false,
        optional: false
      },
      {
        key: 'DUPLICATE_SKU',
        text: 'SKU produk tidak duplikat (opsional)',
        completed: false,
        optional: true
      },
      {
        key: 'DOWNLOADED',
        text: 'Semua produk berhasil terdownload (opsional)',
        completed: false,
        optional: true
      }
    ]
  });

  const steps: Steps = {
    1: {
      ref: submitRef1,
      component: <StepOne key={'Step-1'} submitRef={submitRef1} />
    },
    2: {
      ref: submitRef2,
      component: <StepTwo key={'Step-2'} submitRef={submitRef2} />
    },
    3: {
      ref: submitRef3,
      component: (
        <StepThree
          key={'Step-3'}
          submitRef={submitRef3}
          validationState={validationState}
          setValidationState={setValidationState}
          confirmationRef={confirmationRef}
        />
      ),
      confirmationChildren: (
        <ConfirmationChildren
          title={`Kamu akan mendownload produk dari toko ${masterShop?.store_name}.`}
          subTitle="Toko master yang sudah dipilih tidak dapat diubah ke toko lain setelah melanjutkan ke proses download
        produk."
        />
      ),
      submitValidation: <ValidationChildren validations={validationState.masterDownload} />
    },
    4: {
      ref: submitRef4,
      component: (
        <StepFour
          key={'Step-4'}
          validationState={validationState}
          setValidationState={setValidationState}
          confirmationRef={confirmationRef}
          submitRef={submitRef4}
        />
      ),
      confirmationChildren: (
        <ConfirmationChildren
          title={`Produk dari toko ${channelLeft.length} lainnya belum didownload.`}
          subTitle="Apakah Kamu yakin ingin melanjutkan?"
        />
      ),
      submitValidation: <ValidationChildren validations={validationState.nonMasterdownload} />
    },
    5: {
      ref: submitRef5,
      component: <StepFive key={'Step-5'} confirmationRef={confirmationRef} submitRef={submitRef5} />,
      confirmationChildren: (
        <ConfirmationChildren
          title="Kamu belum menggabungkan produk!"
          subTitle="Produk yang belum digabungkan akan tampil pada page “Product Channel” setelah onboarding selesai."
        />
      )
    },
    6: {
      ref: submitRef6,
      component: <StepSix key={'Step-6'} confirmationRef={confirmationRef} submitRef={submitRef6} />,
      confirmationChildren: (
        <ConfirmationChildren
          title="Produk bundle yang tidak memiliki komposisi akan mengakibatkan produk tersebut tidak bisa diisi/diimport stok-nya."
          subTitle="Tambahkan komposisi pada produk bundle yang telah diatur agar Kamu dapat melanjutkan ke step berikutnya."
        />
      )
    },
    7: {
      ref: submitRef7,
      prevRef: beforeRef7,
      component: (
        <StepSeven key={'Step-7'} beforeRef={beforeRef7} submitRef={submitRef7} confirmationRef={confirmationRef} />
      ),
      confirmationChildren: (
        <ConfirmationChildren
          title="Pastikan pengaturan lokasi gudang dan alokasi stok telah selesai dan sesuai."
          subTitle="Apakah Kamu yakin ingin melanjutkan?"
        />
      )
    },
    8: {
      ref: submitRef8,
      component: <StepEight key={'Step-8'} submitRef={submitRef8} confirmationRef={confirmationRef} />,
      confirmationChildren: (
        <ConfirmationChildren
          title="Setelah sinkronisasi pesanan diaktifkan semua pesanan akan bisa diproses di Jubelio dan tidak bisa kembali ke tahap sebelumnya."
          subTitle="Apakah Kamu yakin ingin melanjutkan?"
        />
      )
    },
    9: {
      ref: submitRef9,
      component: <StepNine key={'Step-9'} confirmationRef={confirmationRef} submitRef={submitRef9} />,
      confirmationChildren: (
        <ConfirmationChildren
          title={'Konfirmasi data onboarding belum lengkap!'}
          subTitle="Lengkapi konfirmasi data onboarding agar Kamu dapat menyelesaikan step ini."
        />
      )
    },
    10: {
      ref: submitRef10,
      component: <StepTen key={'Step-10'} submitRef={submitRef10} />
    }
  };

  const currentStep = steps[activeStep];
  return (
    <>
      <Box
        sx={{
          boxShadow: '0px 2px 10px rgba(175, 137, 255, 0.15)',
          borderRadius: 3,
          bgcolor: '#FFFFFF',
          display: 'flex',
          flexDirection: 'column',
          padding: '25px 35px',
          height: '100%'
        }}
      >
        {currentStep.component}
      </Box>
      <PageContentContainerFooter
        prevRef={currentStep.prevRef}
        submitRef={currentStep.ref}
        confirmationRef={confirmationRef}
        submitValidation={currentStep.submitValidation}
        confirmationChildren={currentStep.confirmationChildren}
      />
    </>
  );
};

export default PageContentContainer;
