import React from 'react';
import styled from 'styled-components';
import { ChannelDetail } from 'interfaces/Channels';
import { channelDetail } from 'shared/constants';

interface LeftProps {
  channelId: number;
  currentType?: string;
}
export const Container = styled.div`
  display: grid;
  gap: 1rem;
  font-size: 14px;
  h3 {
    font-size: 20px;
  }
`;
const List = styled.ul`
  padding-left: 1rem;
`;
export const Guide = styled.a`
  padding: 0 0 3.2rem 0.5rem;
  color: blue;
  font-size: 14px;
  text-decoration: none;
`;

const LeftContent: React.FunctionComponent<LeftProps> = ({ channelId, currentType }) => {
  const channels = channelDetail as ChannelDetail;
  const selectedChannel = channels[channelId];
  const channelName = selectedChannel.channelName;
  const eduLink = selectedChannel.eduLink;
  return (
    <>
      <Container>
        <h3>Sambungkan Toko</h3>
        <p>
          Silahkan klik “Sambungkan” untuk melakukan otorisasi agar Jubelio bisa terhubung dengan Toko kamu di{' '}
          {channelName}
        </p>
        <div>
          <h4>Catatan Keamanan</h4>
          <List>
            <li>Jubelio tidak pernah menyimpan password login kamu ke {channelName}.</li>
            <li>
              Semua proses otorisasi toko di lakukan sesuai standard otorisasi {channelName} yang menjamin keamanan dan
              privasi akun kamu.
            </li>
          </List>
        </div>
      </Container>
      <Guide href={eduLink} target={'_blank'}>
        {channelId === 128 ? (
          <>
            Panduan Integrasi Jubelio ke {currentType === '0' ? 'Official Store' : 'Power Merchant'} {channelName}
          </>
        ) : (
          <>Panduan Integrasi Jubelio ke {channelName}</>
        )}
      </Guide>
    </>
  );
};

export { LeftContent };
