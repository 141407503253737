import styled from 'styled-components';

interface Props {
  show: boolean;
  autoHeight?: boolean;
  width: string;
}
interface TooltipActionButtonProps {
  bgColor: string;
  textColor: string;
  disabled?: boolean;
}

export const Tooltip = styled.div<Props>`
    width: ${({ width }) => width};
    height: ${({ autoHeight }) => autoHeight ? 'auto' : '117px' };
    border-radius: 1rem;
    background-color: #4B68EF; 
    position: absolute;
    bottom: 50px;
    right: 0;
    padding: 24px 32px;
    display: ${(props) => props.show ? 'grid' : 'none'};
    gap: 5px;
    & > * {
        color: white;
    }
`;

export const TooltipQuote = styled.div`
    position: absolute;
    bottom: -15px;
    right: 4%;
    background-color: #4B68EF;
    width: 30px;
    height: 25px;
    clip-path: polygon(0 0, 100% 0, 53% 100%, 47% 100%);
    border-radius: 5px;
`;
export const TooltipAction = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;
    justify-content: end;
    margin-top: 12px;
`;
export const TooltipActionButton = styled.button<TooltipActionButtonProps>`
    padding: 6px 1.5rem;
    border-radius: 1rem;
    text-transform: capitalize;
    border: 1px solid ${({ bgColor, disabled }) => disabled ? bgColor : 'white'};
    outline: none;
    background-color: ${({ bgColor, disabled }) => disabled ? '#DBDBDB' : bgColor};
    color: ${({ textColor, disabled }) => disabled ? '#C3C3C3' : textColor};
    font-size: 14px;
    cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
`;