import React, { useEffect, useRef } from 'react';
import Contents from './Contents';
import Connected from 'components/Connected/Connected';
import UseDocumentTitle from 'hooks/UseDocumentTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppStore } from 'stores/appStore';
import { getCurrentStep, updateCurrentStep } from 'api/onboardingStep';
import { StepContainer, Title, TitleDescription } from 'components/atoms';
import HorizontalDividers from 'components/CustomDividers/HorizontalDividers';
import { showAlert } from 'components/Alert';
import { bukalapakAuthorize } from 'api/marketPlaces';

interface Props {
  submitRef: React.LegacyRef<HTMLButtonElement> | undefined;
}

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const StepTwo: React.FunctionComponent<Props> = ({ submitRef }) => {
  UseDocumentTitle('Integrasi Channel');
  const params = useQuery();
  const navigate = useNavigate();
  const bukalapakCodeRef = useRef(null);
  const integratedRef = useRef(null);
  const isMounted = useRef(true);
  const { activeStep, setActiveStep, onboardingModifiedDate, setOnboardingModifiedDate, setShowConnectedPopup } =
    useAppStore();

  const integratedNotification = async () => {
    if (integratedRef.current && !bukalapakCodeRef.current) {
      setShowConnectedPopup(true);
      params.delete('integrated');
      navigate('/');
    }

    if (bukalapakCodeRef.current) {
      try {
        const authorizeRes = await bukalapakAuthorize(bukalapakCodeRef.current);
        if (authorizeRes.data.error) {
          showAlert('error', authorizeRes.data.error.error_description, 5000);
          navigate('/');
        } else {
          setShowConnectedPopup(true);
          window.location.reload()
        }
      } catch (error) {
        showAlert('error', error.message, 5000);
        navigate('/');
      }
    }
  };

  const handleClick = async () => {
    try {
      const { status: statusUpdate, statusText: statusTextUpdate } = await updateCurrentStep(
        activeStep.toString(),
        onboardingModifiedDate,
        'INCREASE'
      );
      if (statusUpdate === 200) {
        const {
          data: { lastOnboardingStep, onboardingLastModified },
          status: statusGetCurrent,
          statusText: statusTextGetCurrent
        } = await getCurrentStep();
        if (statusGetCurrent === 200) {
          setActiveStep(lastOnboardingStep);
          setOnboardingModifiedDate(onboardingLastModified);
        } else {
          console.error(`Something went wrong ${statusTextGetCurrent}`);
        }
      } else {
        console.error(`Something went wrong ${statusTextUpdate}`);
      }
    } catch (err) {
      console.error(`ERROR: ${err}`);
    }
  };

  useEffect(() => {
    const initiateFlag = () => {
      const integratedParams = params.get('integrated');
      const bukalapakCode = params.get('?code');
      bukalapakCodeRef.current = bukalapakCode;
      integratedRef.current = integratedParams;
    };
    if (isMounted.current) {
      initiateFlag();
      integratedNotification();
      isMounted.current = false;
    }
  }, []);

  return (
    <>
      <StepContainer>
        <Title>Integrasi Channel</Title>
        <TitleDescription>
          Sambungkan akun Kamu ke channel penjualan yang terintegrasi dengan Jubelio minimal 1 (satu) channel penjualan.
        </TitleDescription>
        <HorizontalDividers marginBlock={true} />
        <Contents />
      </StepContainer>
      <button ref={submitRef} onClick={handleClick} style={{ display: 'none' }}>
        CLICK
      </button>
      <Connected />
    </>
  );
};

export default StepTwo;
