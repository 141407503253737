import React, { useState } from 'react';
import { Button, Popover, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { AsyncButton } from 'components/ButtonNew/Button';
import { ArrowDownSVG } from 'assets/img/arrow-down';

const SelectButton = ({ options, label, busy }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget);
    return Promise.resolve();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelect = async (handler) => {
    await handler();
    handleClose();
  };

  return (
    <>
      <Button onClick={handleClick} variant="contained" color="primary" className="px-4" sx={{ gap: '40px' }} disabled={busy}>
        {label} <ArrowDownSVG fill='#fff' />
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <List>
          {options.map((option, index) => (
            <ListItem sx={{ padding: 0 }} key={index}>
              <ListItemButton onClick={() => handleOptionSelect(option.handler)}>
                <ListItemText primary={option.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};

export default SelectButton;
