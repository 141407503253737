import React from 'react';

export const UploadSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0)">
        <path
          d="M16.125 8.36683C15.8451 6.94826 15.0813 5.67088 13.9642 4.75287C12.8471 3.83486 11.4459 3.33317 10 3.3335C7.59167 3.3335 5.5 4.70016 4.45833 6.70016C3.23353 6.83252 2.10084 7.41284 1.27791 8.3296C0.454976 9.24636 -0.000143085 10.4349 3.3744e-08 11.6668C3.3744e-08 12.9929 0.526784 14.2647 1.46447 15.2024C2.40215 16.14 3.67392 16.6668 5 16.6668H15.8333C16.3805 16.6668 16.9223 16.5591 17.4278 16.3497C17.9334 16.1403 18.3927 15.8334 18.7796 15.4464C19.1665 15.0595 19.4734 14.6002 19.6828 14.0947C19.8922 13.5892 20 13.0473 20 12.5002C20 10.3002 18.2917 8.51683 16.125 8.36683ZM15.8333 15.0002H5C4.11594 15.0002 3.2681 14.649 2.64298 14.0239C2.01786 13.3987 1.66667 12.5509 1.66667 11.6668C1.66667 9.9585 2.94167 8.5335 4.63333 8.3585L5.525 8.26683L5.94167 7.47516C6.3249 6.72914 6.90656 6.10334 7.6226 5.66666C8.33865 5.22997 9.1613 4.99934 10 5.00016C12.1833 5.00016 14.0667 6.55016 14.4917 8.69183L14.7417 9.94183L16.0167 10.0335C16.6431 10.0756 17.2303 10.3535 17.6602 10.8112C18.09 11.2688 18.3305 11.8723 18.3333 12.5002C18.3333 13.1632 18.0699 13.7991 17.6011 14.2679C17.1323 14.7368 16.4964 15.0002 15.8333 15.0002ZM6.66667 10.8335H8.79167V13.3335H11.2083V10.8335H13.3333L10 7.50016L6.66667 10.8335Z"
          fill="#8999A5"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
