import { create } from 'zustand';
import { FormType as LocationTypeBroker, LocationFormType } from './type';

type WarehouseType = {
  store_id: number;
  warehouse_id: any;
  warehouse_name: string;
};

type LocationType = {
  infoBroker: LocationTypeBroker;
  info: LocationFormType;
  pinMap: any;
  thirdContent: any;
  // automaticTransfer: number;
  multiWarehouse: WarehouseType[];
  locationDetail: object;
  setInfoBroker: (newFormValue) => void;
  setInfo: (newFormValue) => void;
  setPinMap: (pinMap: any) => void;
  setThirdContent: (thirdContent: any) => void;
  // setAutomaticTransfer: (automaticTransfer: number) => void;
  setMultiWarehouse: (multiWarehouse: WarehouseType[]) => void;
  clearLocation: () => void;
  setLocationDetail: (newDetail) => void;
};

const useLocationStore = create<LocationType>()(
  (set) => ({
    infoBroker: {} as LocationTypeBroker,
    info: {} as LocationFormType,
    pinMap: {},
    thirdContent: {},
    // automaticTransfer: null,
    multiWarehouse: [],
    locationDetail: {},
    setInfoBroker: (infoBroker: LocationTypeBroker) => {
      set({ infoBroker });
    },
    setInfo: (info: LocationFormType) => {
      set({ info });
    },
    setPinMap: (pinMap: any) => {
      set({ pinMap });
    },
    setThirdContent: (thirdContent: any) => {
      set({ thirdContent })
    },
    // setAutomaticTransfer: (automaticTransfer: number) => {
    //   set({ automaticTransfer });
    // },
    setMultiWarehouse: (multiWarehouse: WarehouseType[]) => {
      set({ multiWarehouse });
    },
    setLocationDetail: (locationDetail) => {
      set({ locationDetail });
    },
    clearLocation: () => {
      set({
        infoBroker: {} as LocationTypeBroker,
        info: {} as LocationFormType,
        pinMap: {},
        thirdContent: {},
        // automaticTransfer: null,
        multiWarehouse: [],
      });
    },
  })
);

export default useLocationStore;
