import React from 'react';

export const SuccessActionSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99996 1.6665C5.41663 1.6665 1.66663 5.4165 1.66663 9.99984C1.66663 14.5832 5.41663 18.3332 9.99996 18.3332C14.5833 18.3332 18.3333 14.5832 18.3333 9.99984C18.3333 5.4165 14.5833 1.6665 9.99996 1.6665ZM9.99996 16.6665C6.32496 16.6665 3.33329 13.6748 3.33329 9.99984C3.33329 6.32484 6.32496 3.33317 9.99996 3.33317C13.675 3.33317 16.6666 6.32484 16.6666 9.99984C16.6666 13.6748 13.675 16.6665 9.99996 16.6665ZM13.825 6.3165L8.33329 11.8082L6.17496 9.65817L4.99996 10.8332L8.33329 14.1665L15 7.49984L13.825 6.3165Z"
        fill="#4CAF50"
      />
    </svg>
  );
};
