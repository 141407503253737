import React from 'react';
import {
  Button,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Grow,
  Paper,
  Popper,
  ClickAwayListener,
  TextField,
  InputAdornment,
  MenuList
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Refresh, FilterList } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { PrimaryButton, SelectInput } from './DataGrid.types';
import { QueryParameters } from '../../lib/types';
import { DeleteSVG, SearchSVG } from '../../assets/img';
import { BarcodeScanner } from '../BarcodeScanner/BarcodeScanner';

const useStyles = makeStyles(() => ({
  grow: {
    flexGrow: 1
  },
  buttons: {
    transition: '0.3s',
    overflow: 'visible',
    alignSelf: 'center',
    display: 'flex',
    height: '35px'
  },
  searchActive: {
    height: 0,
    overflow: 'hidden'
  }
}));

interface ToolbarProps {
  selectedRows: number[] | any[];
  onDelete: () => void;
  showSearch: boolean;
  callRefresh?: () => void | false;
  primaryButtons?: Array<PrimaryButton>;
  secondaryButtons?: Array<PrimaryButton>;
  customSelect?: Array<SelectInput>;
  isRemote: boolean;
  allowDelete?: boolean;
  query: QueryParameters;
  title?: string;
  className?: string;
  showRefresh?: boolean;
  type: 'litegrid' | 'datagrid';
  filter?: React.ReactElement;
  onToolbarSearch?: (string) => void;
  barcodeMessage?: string;
  barcodeLabel?: string;
  barcodeChange?: () => void;
  onBarcodeClick?: (text: string) => Promise<boolean>;
  barcodeBusy?: boolean;
}

export const GridToolbar: React.FC<ToolbarProps> = (props: ToolbarProps) => {
  const classes = useStyles();
  const { t } = useTranslation('components');

  const {
    selectedRows,
    onDelete,
    allowDelete,
    showSearch,
    callRefresh,
    primaryButtons,
    customSelect,
    showRefresh,
    isRemote,
    className,
    filter,
    title,
    type,
    query,
    onToolbarSearch,
    barcodeMessage,
    barcodeBusy,
    barcodeLabel,
    barcodeChange,
    onBarcodeClick
  } = props;
  const [btnGroupAnchorEl, setBtnGroupAnchorEl] = React.useState([]);
  const [anchorElFilter, setAnchorElFilter] = React.useState(null);

  const handleClickFilter = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
  };

  const handleToggle = (index) => (event) => {
    setBtnGroupAnchorEl(() => {
      const newAnchor = [];
      for (let i = 0; i <= index; i += 1) {
        if (i === index) {
          newAnchor.push(event.currentTarget);
        } else {
          newAnchor.push(null);
        }
      }
      return newAnchor;
    });
  };

  const handleMenuItemClick = (childItemAction) => {
    childItemAction();
    handleToggle(-1)(null);
  };

  const handleGroupClose = () => {
    handleToggle(-1)(null);
  };

  return (
    <>
      <AppBar
        color="transparent"
        className={`shadow-none py-3 ${customSelect && primaryButtons ? 'px-0' : 'px-2'} ${className}`}
        position="static"
      >
        <Toolbar variant="dense" className="px-0">
          {showSearch && (
            <div>
              <TextField
                variant="outlined"
                size="small"
                className="bg-white rounded-sm"
                placeholder="Pencarian"
                onChange={(e) => onToolbarSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchSVG />
                    </InputAdornment>
                  )
                }}
              />
            </div>
          )}
          <div className="font-size-lg font-weight-bold">{title}</div>
          {!barcodeChange && <div className={classes.grow} />}
          {onBarcodeClick && (
            <div className="w-35" style={{ width: '35%' }}>
              <BarcodeScanner
                message={barcodeMessage}
                onChange={barcodeChange}
                onBarcodeClick={onBarcodeClick}
                busy={barcodeBusy}
                className="m-0"
                label={barcodeLabel}
              />
            </div>
          )}
          <div
            className={`${classes.buttons} MuiTypography-noWrap w-100 ${
              customSelect && primaryButtons ? 'justify-content-between' : 'justify-content-end'
            }`}
          >
            {Boolean(filter) && (
              <Button
                color="primary"
                className="ml-3"
                variant="contained"
                title="Filter"
                size="small"
                onClick={handleClickFilter}
              >
                <FilterList />
              </Button>
            )}
            {allowDelete && type === 'datagrid' && (
              <Button
                disabled={selectedRows.length === 0}
                size="small"
                className="ml-3 pt-1 pb-1 px-1"
                color="inherit"
                style={{ borderColor: `${selectedRows.length !== 0 ? '#f83245' : 'unset'}` }}
                variant="outlined"
                onClick={onDelete}
                title="Delete"
              >
                <DeleteSVG height={18} className={`${selectedRows.length !== 0 && 'text-danger'} my-2 mx-1`} />
              </Button>
            )}
            {customSelect &&
              customSelect.map((item, key) => (
                <div key={key} className="d-flex flex-row custom-select">
                  {item.label && <div className="mr-2 custom-label">{item.label}</div>}
                  <Button variant="outlined" onClick={(e) => item.onClickOpen(e)}>
                    <div style={{ marginRight: '4px' }}>{`${
                      item.defaultValue.label.split('-').length > 1
                        ? item.defaultValue.label.split('-')[1]
                        : item.defaultValue.label
                    }`}</div>
                    <KeyboardArrowDownIcon fontSize="small" />
                  </Button>
                </div>
              ))}
            <div>
              {primaryButtons &&
                primaryButtons.map((item, key) => (
                  <Button
                    variant={item.color === 'secondary' ? 'outlined' : 'contained'}
                    key={key}
                    className={`ml-3 shadow-none ${item.color === 'secondary' ? 'btn-outline-dark' : 'text-white'}`}
                    color={item.color}
                    disabled={item.disabled}
                    title={(item.title && item.title) || (item.child && item.child[0] && item.child[0].title)}
                    startIcon={item.icon}
                    onClick={(e) => {
                      if (item.label && !item.action) {
                        return handleToggle(key)(e);
                      }
                      if (item.action) {
                        return item.action(query, e);
                      }
                      item.child[0].action(query, e);
                    }}
                  >
                    {item.label || item.child[0].label}
                  </Button>
                ))}
            </div>
            {isRemote && showRefresh && (
              <Button
                color="primary"
                variant="contained"
                size="small"
                title="Refresh"
                className="ml-3 bg-warning shadow-none"
                onClick={callRefresh}
              >
                <Refresh />
              </Button>
            )}
          </div>
        </Toolbar>
      </AppBar>
      {Boolean(filter) && (
        <Menu
          anchorEl={anchorElFilter}
          keepMounted
          // getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={Boolean(anchorElFilter)}
          classes={{ list: 'p-0' }}
          onClose={handleCloseFilter}
        >
          <div className="dropdown-menu-xxl overflow-hidden p-0">{filter}</div>
        </Menu>
      )}
      {primaryButtons &&
        primaryButtons.map(
          (item, key) =>
            item.child &&
            item.child.length > 1 && (
              <Popper
                open={Boolean(btnGroupAnchorEl[key])}
                anchorEl={btnGroupAnchorEl[key]}
                role={undefined}
                transition
                key={key}
                placement="bottom-end"
                style={{ zIndex: 999 }}
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom'
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleGroupClose}>
                        <MenuList id="split-button-menu">
                          {item.child.map((childItem, childKey) => (
                            <MenuItem key={childKey} onClick={() => handleMenuItemClick(childItem.action)}>
                              {childItem.label}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            )
        )}
    </>
  );
};
