import { onBoardingAPI, coreAPI, impexApi } from './axiosClient';

interface Location {
  location_id: number,
  location_code: string,
  location_name: string,
  address: string,
  subdistrict: string,
  area: string,
  city: string,
  province: string,
  province_id: string,
  city_id: string,
  district_id: string,
  subdistrict_id: string,
  post_code: string,
  phone: string,
  email: string,
  is_fbl: boolean,
  is_tcb: boolean,
  is_sbs: boolean,
  is_warehouse: boolean,
  is_multi_origin: boolean,
  stores: null,
  warehouse_store_id: string,
  registers: [],
  warehouse: [],
  coordinate: null
}

export const getLocationList = (options: any) => {
  let newOptions: any = {
    page: options.page,
    pageSize: options.pageSize,
    sortBy: options.sortBy,
    sortDirection: options.sortDirection,
    type: options.type,
    q: options.q
  }
  return coreAPI({
    method: 'GET',
    url: 'locations/list',
    params: newOptions
  })
};

export const newGetLocationList = (options: any) => {
  let newOptions: any = {
    page: options.page,
    pageSize: options.pageSize,
    type: options.type,
    sortBy: options.sortBy,
    sortDirection: options.sortDirection,
  }
  if (options.q) {
    newOptions.q = options.q
  } else {
    delete newOptions.q
  }
  return onBoardingAPI({
    method: 'GET',
    url: 'tenant/get-location',
    params: newOptions
  })
};

export const getDetailData = (id: number) => {
  return coreAPI({
    method: 'GET',
    url: `locations/${id}`
  })
}

export const getListRegions = (params: string) => {
  return coreAPI({
    method: 'GET',
    url: `${params}`
  });
};

export const saveLocation = (payload: Location) => {
  return coreAPI({
    method: 'POST',
    url: 'locations/',
    data: payload
  });
};

export const postStatusLocation = (payload: Location) => {
  return coreAPI({
    method: 'POST',
    url: 'locations/v2/update-location-status-active',
    data: payload
  });
};

export const deleteLocationSetting = (data: object) => {
  return coreAPI({
    method: 'DELETE',
    url: 'locations/',
    data,
  });
};

export const getInBoundList = () => {
  return coreAPI({
    method: 'GET',
    url: 'locations/in-bound/?page=1&pageSize=25&sortBy=location_name&sortDirection=asc&q='
  });
};

export const getListStoreIntegration = () => {
  return coreAPI({
    method: 'GET',
    url: 'locations/tokopedia-integration/'
  });
};

export const getListImportStock = (options: any) => {
  let newOptions: any = {
    page: options.page,
    pageSize: options.pageSize,
    sortBy: options.sortBy,
    sortDirection: options.sortDirection,
    locationId: options.locationId,
  }
  return onBoardingAPI({
    method: 'GET',
    url: 'product/stock-by-location',
    params: newOptions
  });
};

export const getListMappingLocation = () => {
  return onBoardingAPI({
    method: 'GET',
    url: 'marketplace/get-stock-location',
  });
};

export const postMappingLocation = (payload: object) => {
  return onBoardingAPI({
    method: 'POST',
    url: 'marketplace/update-stock-location',
    data: payload
  });
};

export const postSaveImportStock = (payload: object) => {
  return coreAPI({
    method: 'POST',
    url: 'opening-balance/items/',
    data: payload
  });
};

export const getExportImportList = (options: any) => {
  let newOptions: any = {
    locationId: options.locationId,
    page: options.page,
    // pageSize: options.pageSize,
    sortBy: options.sortBy,
    sortDirection: options.sortDirection,
    csv: true,
  }
  return coreAPI({
    method: 'GET',
    url: 'opening-balance/items/',
    params: newOptions
  });
};

export const postImportFromMarketplace = () => {
  return onBoardingAPI({
    method: 'POST',
    url: 'import/import-marketplace',
  });
};

export const getImportFromMarketplaceProgress = () => {
  return onBoardingAPI({
    method: 'GET',
    url: 'import/import-marketplace-progress',
  });
};

export const postImportOpeningBalance = (payload: object, url: string) => {
  return coreAPI({
    method: 'POST',
    url,
    data: payload
  })
};

export const getUrlExportStock = () => {
  return coreAPI({
    method: 'GET',
    url: 'reports/exports/',
  });
}

export const exportOpeningBalanceTemplate = (locationName: string) => {
  return impexApi({
    method: 'POST',
    url: 'export/inventory',
    data: {
      exportType: 'inventory-stock-template',
      location: locationName
    }
  });
}

export const getExportStock = (options: any) => {
  let newOptions: any = {
    page: options.page,
    pageSize: options.pageSize,
    sortBy: 'item_name',
    sortDirection: 'ASC',
    csv: true,
  }
  return coreAPI({
    method: 'GET',
    url: 'inventory/',
    params: newOptions
  });
};

export const postSyncBulk = (payload: any) => {
  let newPayload: any = {
    extraInfo: payload.extraInfo,
    storeIds: payload.storeIds
  }
  return onBoardingAPI({
    method: 'POST',
    url: 'marketplace/update-channel-order-sync-bulk',
    ...payload.isSync && {
      data: newPayload
    }
  });
};


export const getLatestImportHistory = async () => {
  const importHistory = await coreAPI({
    method: 'GET',
    url: '/reports/V2/imports'
  })

  return importHistory.data.imports[0];
}

export const getImportDetail = (activityId) => {
  return coreAPI({
    method: 'GET',
    url: `/reports/import-detail/${activityId}`,
  });
}