import { coreAPI } from './axiosClient';

export const dealPosAuthorize = (host: string, client_id: string, client_secret: string, adjust_inventory: boolean) => {
  return coreAPI({
    method: 'POST',
    url: 'dealpos/register',
    // params: {
    //   state_url: config.V2_URL,
    // },
    data: {
      host,
      client_id,
      client_secret,
      adjust_inventory,
    },
  });
};

export const jubelioPOSAuthorize = () => {
  return coreAPI({
    method: 'POST',
    url: 'jubeliopos/register',
  });
};