import React, { useState, useMemo, useEffect } from 'react';
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import {
  Box,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Modal,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { SaveSVG, SearchSVG } from 'assets/img';
import { AsyncButton } from 'components/ButtonNew/Button';
import config from 'config';

interface ModalProps {
  open?: boolean;
  close?: () => void;
  onSave?: (selected: any, value: string) => void;
  initCoordinate?: any; // format accepted [lat, lng]
  id?: any;
  isInit?: boolean; // if true, will show the initCoordinate
}

const useStyles = makeStyles(() => ({
  addressList: {
    position: 'absolute',
    zIndex: 1,
    backgroundColor: '#fff',
    width: 'calc(100% - 3rem)',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)'
  }
}));

function Map(props: ModalProps): React.ReactElement<ModalProps> {
  const { open, close, onSave, initCoordinate, id, isInit } = props;
  const classes = useStyles();
  const center = useMemo(() => ({ lat: -6.2, lng: 106.84 }), []); // default center at DKI Jakarta
  const [selected, setSelected] = useState(null);
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions
  } = usePlacesAutocomplete();

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24
  };

  const mapStyles = {
    width: '100%',
    height: '400px',
    borderRadius: '8px'
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setSelected({ lat, lng });
  };

  const handleDragMarker = (coordinat) => {
    const { latLng } = coordinat;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setSelected({ lat, lng });

    // get description from lng lat
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, stat) => {
      if (stat === 'OK') {
        if (results[0]) {
          setValue(results[0].formatted_address);
        }
      }
    });
  };

  useEffect(() => {
    if ((id !== 'new' || isInit) && initCoordinate) {
      const lat = parseFloat(initCoordinate[0]);
      const lng = parseFloat(initCoordinate[1]);
      setSelected({ lat, lng });

      // get description from lng lat
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, sts) => {
        if (sts === 'OK') {
          if (results[0]) {
            setValue(results[0].formatted_address);
          }
        }
      });
    }
  }, [open]);

  return (
    <>
      <Modal open={open} onClose={close}>
        <Box sx={{ ...style, width: 800 }} style={{ borderRadius: 8 }}>
          <Box className="border-bottom">
            <div className="p-4 w-100 d-flex justify-content-between align-items-center ">
              <h2 className="mb-0" style={{ fontSize: 30, fontWeight: 700 }}>
                Pin Lokasi
              </h2>
              <IconButton onClick={close}>
                <CloseIcon />
              </IconButton>
            </div>
          </Box>

          <Box className="px-4 pt-3 pb-4 body" style={{ position: 'relative' }}>
            <div className="search mb-3">
              <label htmlFor="inputSearch" className="font-weight-lightbold mb-2">
                Alamat
              </label>
              <TextField
                variant="outlined"
                size="small"
                id="inputSearch"
                placeholder='Cari alamat, misal "Jl. Sunter Hijau Raya II"'
                className="rounded-sm w-100 no-notch"
                disabled={!ready}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchSVG color="#163A50" className="mr-2" height={18} width={18} />
                    </InputAdornment>
                  )
                }}
              />
              <ClickAwayListener onClickAway={clearSuggestions}>
                <List className={classes.addressList}>
                  {status === 'OK' &&
                    data.map(({ place_id, description }) => (
                      <ListItem disablePadding key={place_id}>
                        <ListItemButton onClick={() => handleSelect(description)}>
                          <ListItemText primary={description} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                </List>
              </ClickAwayListener>
            </div>
            <GoogleMap
              zoom={selected ? 15 : 10}
              center={selected || center}
              mapContainerStyle={mapStyles}
              options={{
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControl: false
              }}
            >
              {selected && <MarkerF position={selected} draggable onDragEnd={handleDragMarker} />}
            </GoogleMap>
          </Box>
          <Box className="d-flex justify-content-end p-4 border-top footer">
            <AsyncButton
              variant="contained"
              color="primary"
              type="submit"
              icon={<SaveSVG width={18} height={18} />}
              onClick={async () => onSave(selected, value)}
            >
              Simpan
            </AsyncButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export function ModalMaps(props: ModalProps) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: config.GMAPS_API_KEY,
    libraries: ['places']
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <Map {...props} />;
}
