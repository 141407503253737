import React from 'react';
import { Tooltip } from '@mui/material';

const SkuChildItem: React.FunctionComponent<any> = ({ child }) => {
  const hasVariations = child.variation_values?.length > 0
  const skuWithVariation = hasVariations ? child.item_code + ' - ' + child.variation_values.map((y) => y.value).join(', ') : child.item_code
  return (
      <div className="d-flex flex-column">
        <Tooltip arrow placement="bottom-start" title={skuWithVariation}>
          <div style={{ fontSize: '14px' }} className={'item-code'}>
            {skuWithVariation}
          </div>
        </Tooltip>
      </div>
  );
}

export default SkuChildItem