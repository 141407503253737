import React from 'react';
import { Button, Dialog, DialogContent, Grid, Icon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FailedSVG, SuccessFilled, WarningSVG } from 'assets/img';
import { AsyncButton } from 'components/ButtonNew/Button';
import { DefaultColor } from 'lib/types';

import Portal from '@mui/material/Portal';

interface ConfirmState {
  title: string;
  subtitle: string;
  multiText?: React.ReactElement;
  onConfirm?: () => Promise<void>;
  onCancel?: () => any;
  showCancel?: boolean;
  confirmText?: string;
  cancelText?: string;
  alertKey?: string | number;
  confirmColor?: DefaultColor;
  sizeAlert?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  type?: 'error' | 'success' | 'warning';
}

let setConfirm;

function useConfirm(callback) {
  setConfirm = callback;
}

export function showConfirm(args: ConfirmState) {
  const {
    title,
    subtitle,
    onConfirm,
    onCancel,
    cancelText,
    confirmText,
    confirmColor,
    showCancel = true,
    sizeAlert = 'md',
    multiText,
    type
  } = args;
  if (!setConfirm) {
    return;
  }
  setConfirm({
    title,
    subtitle,
    multiText,
    alertKey: new Date().getTime(),
    onConfirm,
    onCancel,
    cancelText,
    confirmText,
    confirmColor: confirmColor ?? 'primary',
    showCancel: showCancel,
    sizeAlert: sizeAlert,
    type
  });
}

const useStyles = makeStyles({
  container: {
    minWidth: 400
  }
});

const useIconStyles = makeStyles({
  root: {
    fontSize: '3em'
  }
});

export function ConfirmMessage() {
  const classes = useStyles();
  const iconClasses = useIconStyles();
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState<ConfirmState | null>(null);
  const [busy, setBusy] = React.useState(false);

  useConfirm(setMessage);

  React.useEffect(() => {
    if (message && (message.title || message.multiText || message.subtitle)) {
      setOpen(true);
    }
  }, [message]);

  const handleClose = async (value: boolean) => {
    if (value) {
      if (message.onConfirm) {
        setBusy(true);
        await message.onConfirm();
        setBusy(false);
      }
    } else {
      if (message.onCancel) {
        message.onCancel();
      }
    }
    setOpen(false);
  };

  const RenderIconType = () => {
    if (message.type === 'success') {
      return <SuccessFilled />;
    } else if (message.type === 'error') {
      return <FailedSVG height={53} width={53} />;
    } else {
      return <WarningSVG />;
    }
  };

  return (
    message && (
      <Portal>
        <Dialog
          key={message.alertKey}
          classes={{ paper: `${classes.container}` }}
          onClose={(reason) => null}
          disableEscapeKeyDown
          open={open}
          maxWidth={message.sizeAlert}
        >
          <DialogContent dividers>
            <Grid container alignItems="center" justifyContent="center" className="flex-column">
              <Grid item xs={12} className="text-center">
                <Icon color="primary" className={iconClasses.root}>
                  <RenderIconType />
                </Icon>
              </Grid>
              <Grid item xs={12} className="font-weight-bold text-center mt-3">
                {message.title}
              </Grid>
              {message.multiText ? (
                <Grid item xs={12} className="font-weight-bold text-center mt-3">
                  {message.multiText}
                </Grid>
              ) : null}
              {message.subtitle && (
                <Grid item xs={12} className="text-center text-light-gray">
                  {message.subtitle}
                </Grid>
              )}
            </Grid>
            <Grid container spacing={3} className="d-flex justify-content-center mt-3">
              {message.showCancel && (
                <Grid item xs={6} className="text-center">
                  <Button variant="outlined" color="secondary" className="w-100" onClick={() => handleClose(false)}>
                    {message.cancelText ?? 'Batal'}
                  </Button>
                </Grid>
              )}
              <Grid item xs={6} className="text-center">
                <AsyncButton
                  type="button"
                  onClick={() => handleClose(true)}
                  color={message.confirmColor}
                  className="w-100"
                >
                  {message.confirmText ?? 'OK'}
                </AsyncButton>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Portal>
    )
  );
}
