import React, { useRef } from 'react';
import { Grid } from '@mui/material';
import { useStyles } from './Editor/style';
import { EditSVG, SaveSVG } from 'assets/img';
import { AsyncButton } from 'components/ButtonNew/Button';
import { getDetailData } from 'api/locations';
import { useAppStore } from 'stores/appStore';

import LocationDetailInfoThirdParty from './Editor/InfoBroker';
import MultiWarehouse from './Editor/Multiwarehouse';
import CloseIcon from '@mui/icons-material/Close';
import useAddLocationHook from './Editor/useAddLocationHook';

const sideMenuBroker = [
  {
    id: 0,
    title: 'Informasi Lokasi',
    Component: LocationDetailInfoThirdParty
  },
  {
    id: 1,
    title: 'Multi Warehouse',
    Component: MultiWarehouse
  }
];

type LocationDetailProps = {
  pageSetter?: any;
};

const BrokerForm: React.FC<LocationDetailProps> = ({ pageSetter }) => {
  const { detailParam, modeEdit, setModeEdit } = useAppStore();
  const classes = useStyles();
  const locationId = detailParam.location_id || '0';

  const [selectedTab, setSelectedTab] = React.useState(sideMenuBroker[0]);
  const { locationDetail, setLocationDetail } = useAddLocationHook(locationId);
  const submitForm = useRef(null);

  const isAddLocation = React.useMemo(() => {
    return locationId ? Number(locationId) <= 0 : true;
  }, [locationId]);

  const getLocationDetail = async () => {
    const res: any = await getDetailData(locationId);
    if (res.status === 200) {
      setLocationDetail(res.data);
    }
  };

  const editMode = () => {
    setModeEdit(true);
  };

  React.useEffect(() => {
    if (!locationId || locationId === '0') {
      setModeEdit(true);
    } else if (!isAddLocation) {
      getLocationDetail();
    }
  }, [locationId]);

  const handleChangeTab = (item) => {
    setSelectedTab(item);
    if (locationId === '0' && modeEdit === true) {
      setModeEdit(true);
    } else {
      setModeEdit(false);
    }
  };

  return (
    <>
      <Grid container className="d-flex align-items-center justify-content-between mb-3">
        <Grid item md={3} className="font-size-md font-weight-bold">
          Lokasi {isAddLocation ? 'Pihak Ketiga' : detailParam.location_name}
        </Grid>
        <Grid item md={9} className="text-right">
          <AsyncButton
            color="error"
            className="btn-danger mr-1"
            type="button"
            icon={<CloseIcon height={18} />}
            onClick={() => pageSetter('main')}
          >
            Tutup
          </AsyncButton>
          {!modeEdit ? (
            <AsyncButton
              type="button"
              color="primary"
              busy={false}
              startIcon={<EditSVG height={18} width={18} className="text-white" />}
              onClick={async () => editMode()}
            >
              Edit
            </AsyncButton>
          ) : (
            <AsyncButton
              type="button"
              color="primary"
              busy={false}
              startIcon={<SaveSVG height={18} width={18} />}
              onClick={async () => submitForm.current()}
            >
              Simpan
            </AsyncButton>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={3}>
          {sideMenuBroker.map((item, idx) => {
            return (
              <div
                key={idx}
                role="button"
                aria-hidden
                onClick={() => handleChangeTab(item)}
                className="cursor-pointer mt-1"
              >
                <div
                  className={`d-flex align-items-center justify-content-between side-menu ${classes.sideMenu} ${
                    selectedTab.id === item.id && classes.activeMenu
                  }`}
                >
                  <div>
                    <span className={`font-weight-bold ${classes.font14}`}>{item.title}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </Grid>
        <Grid item md={9}>
          <selectedTab.Component
            pageSetter={pageSetter}
            locationId={locationId}
            locationDetail={locationDetail}
            getLocationDetail={getLocationDetail}
            setSubmitForm={submitForm}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BrokerForm;
