import { ClickAwayListener, Divider, Grow, InputAdornment, Paper, Popper, TextField, Typography } from '@mui/material';
import { SearchSVG } from 'assets/img';
import React, { useEffect, useRef, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Image } from 'components/Image/Image';
import { getMasterSKUS, getRecommendationSkus } from 'api/masterStore';
import { debounce } from 'lodash';
import styled from 'styled-components';
import { ItemDetails } from 'components/ItemDetails/ItemDetails';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectedItemWrapper = styled.div`
  display: flex;
  border: 1px solid #d4d5d6;
  gap: 8px;
  height: 100%;
  padding: 9px;
  border-radius: 8px;
  cursor: pointer;

  & img {
    height: 47px;
    width: 46px;
    border-radius: 4px;
  }

  & > div {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
  }

  & .rounded-sm {
    border-radius: 4px;
  }

  & svg {
    justify-self: center;
    margin-left: auto;
    align-self: center;
  }
`;

const TextFieldWrapper = styled(TextField)`
  &.no-notch {
    border: none;
  }

  & svg {
    font-size: small;
  }
`;

const GroupWrapper = styled.div`
  margin-bottom: 10px;

  & h6 {
    font-weight: bold;
    margin-bottom: 1px;
    border-bottom: 1px solid #d4d5d6;
  }
`;

const ItemWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    background-color: #f0f0f0; /* Add your desired hover background color */
  }
`;

interface Option {
  image: string;
  item_code: string;
  item_name: string;
  channel_group_id: number;
  variation_values: null | any;
}

interface Group {
  group: string;
  options: Option[];
}

interface ItemProps {
  options: Group[];
  getRecommendation?: string;
  onChange: (value: Option | null) => void;
}

const ItemFilter: React.FunctionComponent<ItemProps> = ({
  options: initialOptions,
  onChange,
  getRecommendation,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [search, setSearch] = React.useState('');
  const [selected, setSelected] = React.useState<Option | null>(null);
  const [searchResults, setSearchResults] = React.useState<Option[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const isMounted = useRef(true);
  const [items, setItems] = useState<Group[]>(initialOptions);
  const [recommendationItems, setRecommendationItems] = useState<Group[]>([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSearchResults([]);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchResults([]);
  };

  const handleClickItem = (value) => {
    setSelected(value);
    handleClose();
    onChange(value);
    console.log(value);
  };

  const handleSearch = async (query: string, page: number = 1) => {
    console.log('Handle search:', query);
    try {
      setLoading(true);
      const response = await getMasterSKUS({
        page,
        pageSize: 10,
        q: query,
        sortBy: 'channel_group_id',
        sortDirection: 'ASC'
      });
      setSearchResults(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching search results:', error);
    } finally {
      setLoading(false);
    }
  };
  const fetchMasterSkus = async (page: number = 1) => {
    try {
      const response = await getMasterSKUS({
        page,
        pageSize: 10,
        q: '',
        sortBy: 'channel_group_id',
        sortDirection: 'ASC'
      });

      setItems((prevOptions) => {
        if (page === 1) {
          return [
            ...prevOptions,
            {
              group: 'SKU Lainnya',
              options: response.data.data || []
            }
          ];
        } else {
          return prevOptions.map((group, index) =>
            group.group === 'SKU Lainnya'
              ? {
                ...group,
                options: [...group.options, ...response.data.data]
              }
              : group
          );
        }
      });
      setTotalPages(response.data.totalCount / 10);
    } catch (error) {
      console.error('Error fetching master SKUs:', error);
    }
  };

  const handleLoadMore = () => {
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      fetchMasterSkus(nextPage);
    }
  };

  const getRecommendationBySkus = async (skus: string[]) => {
    try {
      const res = await getRecommendationSkus(skus);
      setRecommendationItems(res.data.data[0].sku_recomendation);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      fetchMasterSkus();
      getRecommendationBySkus([getRecommendation]);
    }
    isMounted.current = false;
  }, []);

  const debouncedSearch = React.useMemo(() => debounce((query: string) => handleSearch(query), 1000), []);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' + Math.floor(Math.random() * (100 - 0 + 1)) + 0 : undefined;
  const displayedItems = search
    ? [
      {
        group: 'SKU Lainnya',
        options: searchResults
      }
    ]
    : recommendationItems && recommendationItems.length > 0
      ? [
        {
          group: 'Rekomendasi Gabung',
          options: recommendationItems
        },
        ...items
      ]
      : items;
  return (
    <Container {...props}>
      {selected ? (
        <SelectedItemWrapper onClick={() => setSelected(null)}>
          <Image height={47} width={46} src={selected.image} className="rounded-sm" />

          <div key={selected.item_code}>
            <div style={{ fontWeight: 'bold' }}>{selected.item_name}</div>
            <div>{selected.item_code}</div>
            <div>{selected.variation_values && selected.variation_values.map((o) => o.label + ' - ' + o.value)}</div>
          </div>
          <KeyboardArrowDownIcon />
        </SelectedItemWrapper>
      ) : (
        <TextFieldWrapper
          type="text"
          value={search}
          className="no-notch"
          placeholder="Pilih"
          onFocus={(e) => handleClick(e)}
          onChange={(e) => {
            setSearch(e.target.value);
            debouncedSearch(e.target.value);
          }}
          aria-describedby={id}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <KeyboardArrowDownIcon fontSize="small" />
              </InputAdornment>
            )
          }}
        />
      )}
      {open && (
        <ClickAwayListener
          onClickAway={(event) => {
            const targetNode = event.target as Node;
            if (!anchorEl || (anchorEl instanceof Node && anchorEl.contains(targetNode))) {
              return;
            }
            handleClose();
          }}
        >
          <Popper
            open={open}
            anchorEl={anchorEl}
            role={undefined}
            transition
            placement="bottom-start"
            className="popup-filter-channel"
            modifiers={[
              {
                name: 'flip',
                enabled: false
              }
            ]}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'right top' : 'left top'
                }}
              >
                <Paper>
                  <div
                    style={{
                      border: '1px solid #EAEAEA',
                      boxShadow: '0px 2px 10px rgba(175, 137, 255, 0.15)',
                      borderRadius: '15px',
                      padding: '19px 20px',
                      maxHeight: '360px',
                      width: anchorEl.offsetWidth + 20 + 'px',
                      overflowY: 'scroll'
                    }}
                  >
                    {displayedItems.map((group, index) => (
                      <GroupWrapper key={`${group.group}-${index}`}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                          {group.group}
                        </Typography>
                        <div>
                          {group.options.map((item, index2) => (
                            <ItemDetails
                              image={item.image}
                              itemCode={item.item_code}
                              itemName={item.item_name}
                              variations={item.variation_values}
                              key={`${item.item_code}-${index}-${index2}`}
                              onClick={() => handleClickItem(item)}
                            />
                          ))}
                        </div>
                      </GroupWrapper>
                    ))}
                    {!search && <ItemWrapper onClick={handleLoadMore}>Load more ...</ItemWrapper>}
                  </div>
                </Paper>
              </Grow>
            )}
          </Popper>
        </ClickAwayListener>
      )}
    </Container>
  );
};

export default ItemFilter;
