import React, { useEffect, useRef, useState } from 'react';
import UseDocumentTitle from 'hooks/UseDocumentTitle';
import styles from './StepFive.module.scss';
import TutorDrag from 'assets/img/tutor-drag.gif';
import TutorKlik from 'assets/img/tutor-klik.gif';
import LinkIcon from '@mui/icons-material/Link';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  Checkbox,
  TextField,
  Tooltip
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { debounce } from 'lodash';
import {
  getDataNonMaster,
  getListProductChannel,
  getListProductMaster,
  getMasterSKUS,
  getRecommendationSkus,
  getStoreLocation,
  postMergeItem,
  postSetMaster,
  setWizardSetup
} from 'api/masterStore';
import GifPlayer from 'react-gif-player';
import CloseIcon from '@mui/icons-material/Close';
import ForwardIcon from '@mui/icons-material/Forward';
import { Search } from '@mui/icons-material';
import { useAppStore } from 'stores/appStore';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { channelDetail } from 'shared/constants';
import { ChannelDetail } from 'interfaces/Channels';

import { Image } from 'components/Image/Image';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { showAlert } from 'components/Alert';
import { AsyncButton } from 'components/ButtonNew/Button';
import { PaginationChannel } from 'components/SinglePagination/PaginationChannel';
import { PaginationMaster } from 'components/SinglePagination/PaginationMaster';

import DropContainer from 'components/ReactDnD/DropContainer';
import DragContainer from 'components/ReactDnD/DragContainer';
import DragExample from 'assets/img/drag-example.png';
import { LiteGrid } from 'components/LiteGrid/LiteGrid';
import { Column } from 'components/LiteGrid/DataGrid.types';
import ItemFilter from 'components/FilterBar/ItemFilter';
import { ItemDetails } from 'components/ItemDetails/ItemDetails';

interface Props {
  submitRef: React.LegacyRef<HTMLButtonElement> | undefined;
  confirmationRef?: any;
}

const defaultPagination = {
  q: '',
  page: 1,
  pageSize: 25,
  sortBy: 'channel_group_id',
  sortDirection: 'ASC'
};

const defaultPaginate = {
  q: '',
  page: 1,
  pageSize: 25,
  showSameSku: false,
  sortBy: 'item_group_name',
  sortDirection: 'ASC'
};

const useStyles = makeStyles((theme) => ({
  img: {
    borderRadius: 6
  },
  imgBorder: {
    border: '1px solid rgba(22, 58, 80, 0.12)',
    padding: '1px'
  },
  cardTitle: {
    width: '100%',
    color: '#002642',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  variantTitle: {
    width: '100%',
    color: '#002642',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  storeSelected: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#4B68EF !important'
  },
  cardContent: {
    padding: '8px 12px !important',
    position: 'relative',
    minHeight: 58,
    fontSize: 12
  }
}));

const StepFive: React.FunctionComponent<Props> = ({ submitRef, confirmationRef }) => {
  const classes = useStyles();
  const { setShowConfirmation, updateStep, setDisablePrevButton } = useAppStore();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isMasterSelected, setMaster] = useState(null);
  const [channelForMerge, setChannelForMerge] = useState<any>([]);
  const [masterForMerge, setMasterForMerge] = useState<any>([]);
  const [pagination, setPagination] = useState(defaultPagination);
  const [paginateMaster, setPaginateMaster] = useState(defaultPaginate);
  const [totalChannel, setTotalChannel] = useState(0);
  const [totalMaster, setTotalMaster] = useState(0);
  const [searchProduct, setSearchProduct] = useState<string>('');
  const [initialRowStates, setInitialRowStates] = useState([]);
  const [clickId, setClickId] = useState({
    source: [],
    destination: null
  });

  const [confirmState, setConfirmState] = React.useState({
    openConfirm: false,
    message: '',
    data: null
  });

  const searchChannel = useRef<string>('');
  const searchMaster = useRef<any>(null);
  const selectedMasterId = useRef<any>(0);
  const isMounted = useRef<boolean>(true);

  UseDocumentTitle('Penggabungan SKU Produk');

  const onPaginationChanged = (newPaginate) => {
    setPagination(newPaginate);
  };

  const getDropdownMergeMaster = async (value) => {
    console.log(value, 'dropdownval');
    let newVal = [];
    if (value.skus) {
      newVal = value.skus?.map((val) => ({
        label: `${val.item_code}`,
        value: val.channel_group_id
      }));
    }
    let newData = {
      data: newVal,
      totalCount: value.skus.length
    };
    return newData;
  };

  const formatSku = (sku) => {
    console.log('sku', sku);
    if (sku.text) {
      return { value: sku.id, label: sku.text };
    }
    return sku;
  };

  const getDropdownNonMaster = async () => {
    const res = await getDataNonMaster(false);
    const newRes = {
      data: res.data,
      totalCount: res.data.length
    };
    return newRes;
  };

  const getFetchProductMaster = async (data) => {
    const res = await getListProductMaster(data);
    setTotalMaster(res.data.totalCount);
    setMasterForMerge(res?.data?.data);
  };

  const formatStore = (store) => {
    if (store.text) {
      return { value: store.id, label: store.text };
    }
    return store;
  };

  const getFetchProductChannel = async (id, newQuery) => {
    const res = await getListProductChannel(id, newQuery);
    setTotalChannel(res.data.totalCount);
    setChannelForMerge(res.data.data);
  };

  const onStoreChange = async (item) => {
    const newQuery: any = {
      q: '',
      page: 1,
      pageSize: 25,
      showSameSku: false,
      sortBy: 'channel_group_id',
      sortDirection: 'ASC'
    };
    selectedMasterId.current = item.id;
    setPagination(newQuery);
    getFetchProductChannel(item.id, newQuery);
  };

  const getSearchChannel = (value) => {
    searchChannel.current = value;
  };

  const getSearchMaster = (value) => {
    searchMaster.current.value = value;
  };

  const clickedMaster = (item) => {
    setClickId((prev) => ({
      ...prev,
      destination: prev.destination !== item ? item : null
    }));
    // setValid(clickId.source.length > 0 && item !== null && clickId.destination !== item);
  };

  const onChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    let newPaginate = { ...pagination, page: value };
    setClickId({
      source: [],
      destination: null
    });
    setPagination(newPaginate);
    getFetchProductChannel(selectedMasterId.current, newPaginate);
  };

  const onChangePageMaster = (event: React.ChangeEvent<unknown>, value: number) => {
    let newPaginate = { ...paginateMaster, page: value };
    setClickId({
      source: [],
      destination: null
    });
    setPaginateMaster(newPaginate);
    getFetchProductMaster(newPaginate);
  };

  const debounceSearchChannel = debounce(getSearchChannel, 500);
  const debounceSearchMaster = debounce(getSearchMaster, 500);

  const clickedStatus = (item) => {
    let exist = clickId.source[0]?.item_group_id === item.item_group_id;
    let result = [];
    if (exist) {
      result = [];
    } else {
      result = [item];
    }

    setClickId((prev) => ({
      ...prev,
      source: result
    }));
  };

  const handleSearchChannel = async () => {
    let newPaginate = { ...pagination, q: searchChannel.current, page: 1 };
    await getFetchProductChannel(selectedMasterId.current, newPaginate);
  };

  const handleSearchMaster = async () => {
    const isDialogOpen = confirmState.openConfirm;
    let q = searchMaster.current.value;
    if (isDialogOpen) q = searchProduct;
    let newPaginate = { ...paginateMaster, q: q, page: 1 };
    await getFetchProductMaster(newPaginate);
  };

  const searchSimilar = (value) => {
    searchMaster.current.value = value;
    handleSearchMaster();
  };

  const openConfirmDialog = (isConfirm, message, data) => {
    setConfirmState({
      openConfirm: isConfirm,
      message: message,
      data: data
    });
  };

  const handleSetMaster = async (id) => {
    try {
      const res: any = await postSetMaster(id);
      if (res.data.status === 'ok') {
        getFetchProductChannel(selectedMasterId.current, pagination);
        getFetchProductMaster(paginateMaster);
        showAlert('success', 'Jadikan master berhasil!', 5000);
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const SearchGroupStatus = () => {
    if (channelForMerge.length > 0) {
      return (
        <>
          {channelForMerge?.map((item, index) => {
            return (
              <DragContainer
                handleMaster={handleSetMaster}
                key={index}
                item={item}
                fnMerge={(payload, isConfirm, message, data) => {
                  setConfirmState({
                    openConfirm: isConfirm,
                    message: message,
                    data: { source: data.source, destination: data.destination }
                  });
                  setClickId({
                    source: data.source,
                    destination: data.destination
                  });
                }}
                fnSearchSimilar={searchSimilar}
                fnClickStatus={clickedStatus}
                getSelected={(x) => {
                  const tempArr = clickId.source.length > 0 ? [clickId.source[clickId.source.length - 1]] : [];
                  return tempArr.filter((y) => y.item_group_id == x.item_group_id).length > 0;
                }}
              />
            );
          })}
        </>
      );
    } else {
      return null;
    }
  };

  const handleMergeItem = async () => {
    try {
      for (const key in initialRowStates) {
        if (initialRowStates[key]) {
          const entry = initialRowStates[key];

          // Access properties for each entry
          const targetDropdownValue = entry.targetDropdownValue;
          const source = entry.source;
          const isMasterCheckboxChecked = entry.isMasterCheckboxChecked;
          if (isMasterCheckboxChecked) {
            await handleSetMaster(source.channel_group_id);
            showAlert('success', 'Berhasil menambahkan Produk Master!', 5000);
          } else {
            const toMerge = {
              idSource: source.channel_group_id,
              iocId: source.ioc_id,
              itemIdNew: targetDropdownValue
            };
            const res: any = await postMergeItem(toMerge);
            if (res.data.status === 'ok') {
              getFetchProductChannel(selectedMasterId.current, defaultPaginate);
              getFetchProductMaster(paginateMaster);
              setConfirmState((prev) => ({
                ...prev,
                openConfirm: false
              }));
              showAlert('success', 'Gabungkan SKU Produk berhasil!', 5000);
            }
          }

          // reset after complete
          setConfirmState((prev) => ({
            ...prev,
            openConfirm: false
          }));
          setInitialRowStates([]);
        }
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const getStoreDetail = async (storeId) => {
    try {
      const res = await getStoreLocation(storeId);
      if (res.data) {
        setMaster(res.data);
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const getOnBoardingSetup = async () => {
    try {
      const res = await setWizardSetup();
      const setupData = res?.data?.setup;
      const storeId = setupData.master_channel_id;
      if (storeId) {
        await getStoreDetail(storeId);
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const handleClick = () => {
    if (!isConfirmed) {
      setShowConfirmation(true);
    }
  };

  const handleClickConfirmation = () => {
    setIsConfirmed(true);
    updateStep('INCREASE');
  };

  const options = [
    {
      group: 'SKU Master',
      options: confirmState.data?.destination?.skus?.length ? confirmState.data.destination.skus : []
    }
  ];

  const columns: Column[] = [
    {
      header: 'SKU Produk',
      binding: 'item_code',
      template(contect, rowData) {
        return (
          <ItemDetails image={rowData.image}  itemCode={rowData.item_code} itemName={rowData.item_name} variations={rowData.variation_values} />
        );
      }
    },
    {
      header: 'Gabung SKU Produk',
      binding: 'target',
      template(context, rowData) {
        const definedOptions = confirmState.data?.destination?.skus?.length ? options : []
        return (
          <ItemFilter
            options={definedOptions}
            getRecommendation={rowData.item_code}
            onChange={(item) => {
              setInitialRowStates((prevRowStates) => ({
                ...prevRowStates,
                [context.row_id]: {
                  source: rowData,
                  targetDropdownValue: item.channel_group_id,
                  isMasterCheckboxChecked: false
                }
              }));
            }}
          />
        );
      }
    }
  ];

  const handleChangeDestination = (e, value) => {
    setConfirmState((prev) => ({
      ...prev,
      data: {
        source: prev.data.source,
        destination: value
      }
    }));
  };

  useEffect(() => {
    setDisablePrevButton(true);
    setPagination(defaultPagination);
    setPaginateMaster(defaultPaginate);
    if (isMounted.current) {
      getOnBoardingSetup();
      getFetchProductMaster(defaultPaginate);
      isMounted.current = false;
    }
  }, []);

  React.useEffect(() => {
    if (isMounted.current) onPaginationChanged(pagination);
  }, [pagination]);

  useEffect(() => {
    handleSearchMaster();
  }, [searchProduct]);

  const debounceSetSearchProduct = debounce(setSearchProduct, 500);
  const channels = channelDetail as ChannelDetail;
  const showBtnClickMerge = clickId.destination !== null && clickId.source.length > 0;


  return (
    <>
      <Grid style={{ minHeight: '1340px', height: '100vh' }}>
        <div className={`mb-3 ${styles.title}`}>Penggabungan SKU Produk</div>

        <div className={`mb-3 ${styles.titleDescription}`}>
          Step ini dapat digunakan apabila produk dari Toko nonmaster tidak memiliki SKU Produk atau SKU Produk tidak
          sama dengan toko master namun secara produk adalah produk yang sama.
          <br />
          Penggabungan SKU Produk sendiri bertujuan agar aktivitas produk seperti : transaksi persediaan, pemrosesan
          pesanan hingga pencatatan laporan menjadi lebih rapi dengan mengambil 1 SKU Produk yang sama yaitu SKU Produk
          Master.
          <br />
          Untuk memahami penggabungan SKU Produk lebih lanjut, silakan&nbsp;
          <a
            href="https://v2.edu.jubelio.com/documentation/fitur-katalog/produk-di-jubelio-2/gabung-sku-produk/"
            target={'_blank'}
            rel="noreferrer"
          >
            klik disini
          </a>
        </div>
        <Divider sx={{ my: '15px' }} />
        <Grid container spacing={1} className="mb-1">
          <Grid item md={6}>
            <div className={`mb-2 ${styles.subTitle}`}>Tutorial Drag & Drop</div>
          </Grid>
          <Grid item md={6}>
            <div className={`mb-2 ${styles.subTitle}`}>Tutorial Drag & Drop</div>
          </Grid>
          <Grid item md={6}>
            <GifPlayer className="gif_player" gif={TutorDrag} still={DragExample} />
          </Grid>
          <Grid item md={6}>
            <GifPlayer className="gif_player" gif={TutorKlik} still={DragExample} />
          </Grid>
        </Grid>
        <Grid container className="d-flex align-items-center">
          <Grid item md={5} className="mt-3">
            <div className={`mb-1 ${styles.subTitle}`}>Produk Non Master</div>
          </Grid>
          <Grid item md={2} />
            <Grid item md={5} className="mt-3">
              <div className={`mb-1 ${styles.subTitle}`}>Produk Master</div>
            </Grid>
          <Grid item md={5}>
            <Dropdown
              label=""
              placeholder="Pilih"
              data={getDropdownNonMaster}
              itemMapper={formatStore}
              name="sku"
              className="notch-dropdown"
              useOnChange
              onChange={(item) => onStoreChange(item)}
            />
          </Grid>
          <Grid item md={2} />
          <Grid item md={5}>
            <div className="d-flex align-items-center">
              {isMasterSelected && channels[isMasterSelected?.channel_id]?.channelLogo && (
                <Image
                  height={25}
                  width={25}
                  src={channels[isMasterSelected?.channel_id]?.channelLogo}
                  className="rounded-sm mt-2 mb-2"
                />
              )}
              {isMasterSelected && (
                <div className="ml-2 font-weight-bold" style={{ fontSize: '14px' }}>
                  {isMasterSelected?.store_name}
                </div>
              )}
            </div>
          </Grid>
        </Grid>

        <Grid className="w-100 mt-2" style={{ display: 'flex' }}>
          <DndProvider backend={HTML5Backend}>
            <Grid container>
              <Grid item md={5}>
                <Grid container spacing={1} className="d-flex align-items-center">
                  <Grid item md={8}>
                    <TextField
                      variant="outlined"
                      className="w-100 no-notch"
                      style={{ paddingTop: '8px' }}
                      size="small"
                      placeholder="Cari produk, SKU Produk, nama..."
                      onChange={(e) => debounceSearchChannel(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <AsyncButton onClick={handleSearchChannel} fullWidth type="button" color="primary">
                      Terapkan
                    </AsyncButton>
                  </Grid>
                </Grid>

                <div style={{ height: '750px', overflowY: 'auto', paddingTop: '2px', marginTop: '5px' }}>
                  <SearchGroupStatus />
                </div>
              </Grid>

              <Grid item md={2}>
                <div className="d-flex flex-row justify-content-center align-items-center" style={{ height: '100%' }}>
                  <Tooltip
                    open={!showBtnClickMerge || confirmState.openConfirm ? false : true}
                    title="Klik untuk gabungkan"
                    placement="top"
                    arrow
                  >
                    <IconButton
                      aria-label="link"
                      size="medium"
                      className="shadow-sm"
                      disabled={!showBtnClickMerge ? true : false}
                      onClick={() => { 
                        openConfirmDialog(true, 'Kamu akan menggabungkan ', {
                          source: clickId.source[0],
                          destination: clickId.destination
                        });
                      }}
                    >
                      <LinkIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </Grid>

              <Grid item md={5}>
                <Grid container spacing={1} className="d-flex align-items-center">
                  <Grid item md={8}>
                    <TextField
                      inputRef={searchMaster}
                      variant="outlined"
                      className="w-100 no-notch"
                      style={{ paddingTop: '8px' }}
                      size="small"
                      placeholder="Cari produk, SKU Produk, nama..."
                      onChange={(e) => debounceSearchMaster(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <AsyncButton onClick={handleSearchMaster} fullWidth type="button" color="primary">
                      Terapkan
                    </AsyncButton>
                  </Grid>
                </Grid>
                <div style={{ height: '750px', overflowY: 'auto', paddingTop: '2px', marginTop: '5px' }}>
                  {masterForMerge?.map((item, index) => (
                    <DropContainer
                      key={'master' + index}
                      allowedDropEffect="any"
                      item={item}
                      fnClickMaster={clickedMaster}
                      selectMaster={clickId.destination?.item_group_id}
                    />
                  ))}
                </div>
              </Grid>
            </Grid>
          </DndProvider>
        </Grid>

        <Grid container spacing={3} className="mt-1">
          <Grid item md={6}>
            {channelForMerge?.length > 0 && (
              <PaginationChannel pagination={pagination} totalItems={totalChannel} onChangePage={onChangePage} />
            )}
          </Grid>
          <Grid item md={6}>
            {masterForMerge?.length > 0 && (
              <PaginationMaster
                pagination={paginateMaster}
                totalItems={totalMaster}
                onChangePage={onChangePageMaster}
                position="justify-content-center"
              />
            )}
          </Grid>
        </Grid>

        <Dialog
          fullWidth
          maxWidth="lg"
          open={confirmState.openConfirm}
          onClose={() =>
            setConfirmState((prev) => ({
              ...prev,
              openConfirm: false
            }))
          }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className="font-weight-bold" id="alert-dialog-title" style={{ fontSize: 30 }}>
            <div className="d-flex justify-content-between d-flex">
              <span className="font-weight-bold" style={{ fontSize: 30 }}>
                Penggabungan SKU Produk
              </span>
              <IconButton
                onClick={() =>
                  setConfirmState((prev) => ({
                    ...prev,
                    openConfirm: false
                  }))
                }
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            {confirmState.data && (
              <Grid container direction="column" className="d-flex w-100 justify-content-between py-4 px-4">
                <Grid item className="w-100">
                  <Grid container spacing={2} className="w-100 justify-content-between">
                    <Grid item sm={5}>
                      <div style={{ fontSize: '24px', paddingTop: '20px' }}>Produk Non Master</div>
                    </Grid>
                    <Grid item sm={2}></Grid>
                    <Grid item sm={5}>
                      {confirmState.data.destination && (
                        <div style={{ fontSize: '24px', paddingTop: '20px' }}>Produk Master</div>
                      )}
                    </Grid>
                    <Grid item sm={5}>
                      <div className="d-flex justify-content-start">
                        <Image
                          width={120}
                          height={120}
                          className={classes.img}
                          src={confirmState.data.source.skus[0].image}
                        />
                        <div className="d-flex flex-column ml-4">
                          <span className="font-weight-bold" style={{ fontSize: '24px' }}>
                            {confirmState.data.source.item_group_name}
                          </span>
                          <span className="font-size-md">
                            {confirmState.data.source.skus.length} Variasi SKU Produk
                          </span>
                        </div>
                      </div>
                    </Grid>
                    {confirmState.data.destination && (
                      <>
                        <Grid item sm={2} className="pl-0 pt-0 d-flex justify-content-center align-items-center">
                          <ForwardIcon sx={{ fontSize: '80px' }} color="primary" />
                        </Grid>
                        <Grid item sm={5}>
                          <div className="d-flex justify-content-start">
                            <Image
                              width={120}
                              height={120}
                              className={classes.img}
                              src={confirmState.data.destination.skus[0].image}
                            />
                            <div className="d-flex flex-column ml-4">
                              <span className="font-weight-bold" style={{ fontSize: '24px' }}>
                                {confirmState.data.destination.item_group_name}
                              </span>
                              <span className="font-size-md">
                                {confirmState.data.destination.skus?.length || 0} Variasi SKU Produk
                              </span>
                            </div>
                          </div>
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Grid container className="mt-5">
                    <Grid item md={12}>
                      <LiteGrid
                        columns={columns}
                        data={confirmState.data.source.skus}
                        gridName="merge_sku"
                        havePagination={false}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions className="px-4">
            <Button
              variant="contained"
              color="primary"
              onClick={handleMergeItem}
              // onClick={() => console.log(initialRowStates)}
            >
              Gabungkan
            </Button>
          </DialogActions>
        </Dialog>

        <button ref={confirmationRef} onClick={handleClickConfirmation} style={{ display: 'none' }}>
          Confirmation Ref
        </button>
        <button ref={submitRef} onClick={handleClick} style={{ display: 'none' }}>
          CLICK
        </button>
      </Grid>
    </>
  );
};

export default StepFive;
