import { coreAPI } from './axiosClient';

type DataLogin = {
  email: string,
  password: string
};

export const doLogin = (data: DataLogin) => {
  return coreAPI({
    method: 'POST',
    url: 'login',
    data,
  });
};

export const doLogout = () => {
  return coreAPI({
    method: 'POST',
    url: 'logout',
  });
};

export const verifyToken = () => {
  return coreAPI({
    method: 'POST',
    url: 'verify'
  });
};

export const verifyAccountSupport = () => {
  return coreAPI({
    method: 'GET',
    url: 'systemsetting/users/?page=1&pageSize=25&sortBy=email&sortDirection=ASC'
  });
};

export const getProfileUser = (data) => {
  return coreAPI({
    method: 'GET',
    url: `systemsetting/logo/${data}`
  });
};

export const postAddSupport = (data) => {
  return coreAPI({
    method: 'POST',
    url: 'add-user',
    data
  })
}