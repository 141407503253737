import React from 'react';

export const EmptySearchSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="168" height="168" viewBox="0 0 168 168" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="84" cy="84" r="84" fill="#F5FAFF" />
      <path d="M95.5898 92.7279L105.832 102.97" stroke="#A0AFBA" strokeWidth="14" />
      <path
        d="M123.283 133.718C126.992 137.428 133.008 137.428 136.718 133.718C140.428 130.008 140.428 123.992 136.718 120.283L123.283 133.718ZM98.7009 109.136L123.283 133.718L136.718 120.283L112.136 95.7009L98.7009 109.136Z"
        fill="#C9D2D8"
      />
      <circle
        opacity="0.1"
        cx="69.1351"
        cy="66.1353"
        r="42.1523"
        transform="rotate(-45 69.1351 66.1353)"
        fill="#D3D9DD"
      />
      <circle
        cx="69.1351"
        cy="66.1353"
        r="36.6523"
        transform="rotate(-45 69.1351 66.1353)"
        stroke="#C9D2D8"
        strokeWidth="11"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.5 53.5C65.7386 53.5 63.5 55.7386 63.5 58.5V60.0789C63.5 61.4597 62.3807 62.5789 61 62.5789C59.6193 62.5789 58.5 61.4597 58.5 60.0789V58.5C58.5 52.9772 62.9772 48.5 68.5 48.5C74.0228 48.5 78.5 52.9772 78.5 58.5V58.9845C78.5 62.8116 76.4147 66.3347 73.0595 68.1759L71.8824 68.8219V72C71.8824 73.3807 70.7631 74.5 69.3824 74.5C68.0016 74.5 66.8824 73.3807 66.8824 72V68.5259C66.8824 66.8833 67.7774 65.3712 69.2174 64.5809L70.6541 63.7925C72.4092 62.8294 73.5 60.9865 73.5 58.9845V58.5C73.5 55.7386 71.2614 53.5 68.5 53.5Z"
        fill="#A9B8C3"
      />
      <path
        d="M72 80.5C72 81.8807 70.8807 83 69.5 83C68.1193 83 67 81.8807 67 80.5C67 79.1193 68.1193 78 69.5 78C70.8807 78 72 79.1193 72 80.5Z"
        fill="#A9B8C3"
      />
    </svg>
  );
};
