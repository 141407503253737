import { PropsWithError } from 'interfaces/Props';
import styled, { css } from 'styled-components';

const Textarea = styled.textarea<PropsWithError>`
    resize: none;
    width: 100%;
    height: 7rem;
    padding: 10px;
    border-radius: 5px;
    font: inherit;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
    &:hover {
        border: 1px solid rgba(0, 0, 0, 0.6);
    }
    &:focus {
        border: 1px solid blue;
    }
    &::placeholder {
        color: rgba(0, 0, 0, 0.2);
    }
    ${(props) => props.isError && css`
        && {
            border: 1px solid red;
        }    
    `}
`;

export default Textarea;