import { Grid, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

type Props = {
  title: string | React.ReactElement;
  children: React.ReactNode;
  desc?: string;
  center?: boolean;
  disableLabelMarginTop?: boolean;
  className?: string;
  isRequired?: boolean;
  requiredNote?: string | React.ReactNode;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexWrapper: {
      display: 'flex',
      flexFlow: 'row wrap'
    },
    centerAlign: {
      alignItems: 'center'
    },
    boxContainer: {
      background: '#FFFFFF',
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.05)',
      borderRadius: '8px'
    }
  })
);

export const HorizontalLabel: React.FC<Props> = ({
  title,
  desc,
  children,
  center = false,
  disableLabelMarginTop = false,
  className,
  isRequired,
  requiredNote
}) => {
  const classes = useStyles();

  return (
    <Grid container className={`${classes.flexWrapper} ${center && classes.centerAlign} ${className}`}>
      <Grid item md={4}>
        <div
          className={`mb-0 ${!disableLabelMarginTop && !center && 'mt-3'}`}
          style={{
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '19px'
          }}
        >
          {title}
          {isRequired && <span className="text-danger">*</span>}
        </div>
        {desc && (
          <p
            className="mb-0"
            style={{
              fontSize: '12px',
              lineHeight: '16px',
              color: '#7F8084'
            }}
          >
            {desc}
          </p>
        )}
        {requiredNote}
      </Grid>
      <Grid item md={8}>
        {children}
      </Grid>
    </Grid>
  );
};
