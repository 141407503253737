import React from 'react';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { dealPosAuthorize } from 'api/pos';
import { DialogContentStyled, Label, LeftContentWrapper, RightContentWrapper } from 'components/Dialog/DialogContent';
import { LeftContent } from 'components/Dialog/DialogLeftContent';
import { Bottom, BottomActions, ConnectButton, RightForm } from 'components/Dialog/DialogRightContent';
import { Input, InputGrid } from 'components/Form';
import { SubmitHandler, useForm } from 'react-hook-form';
import { channels } from 'shared/constants';
import { useAppStore } from 'stores/appStore';
import { showAlert } from 'components/Alert';
import config from 'config';

import HorizontalDividers from 'components/CustomDividers/HorizontalDividers';
import VerticalDividers from 'components/CustomDividers/VerticalDividers';

interface FormValues {
  store_name: string;
  dealpos_url: string;
  client_id: string;
  client_secret: string;
}
const DealPOSContent: React.FunctionComponent = () => {
  const { register, handleSubmit } = useForm<FormValues>();
  const { setShowConnectedPopup, disablePrevButton } = useAppStore();

  const onSubmit: SubmitHandler<FormValues> = async (formData) => {
    try {
      const { data, status, statusText } = await dealPosAuthorize(
        formData.dealpos_url,
        formData.client_id,
        formData.client_secret,
        false
      );
      if (status === 200 && data.status === 'ok') {
        if (!disablePrevButton) {
          setShowConnectedPopup(true);
        } else {
          location.replace(config.baseUrl);
        }
      } else {
        showAlert('error', `Something went wrong ${statusText}`, 5000);
      }
    } catch (err) {
      const error = err as Error;
      console.error(error);
    }
  };

  return (
    <DialogContentStyled>
      <LeftContentWrapper>
        <LeftContent channelId={channels.DEALPOS} />
      </LeftContentWrapper>
      <VerticalDividers />
      <RightContentWrapper>
        <RightForm id="dealpos_form" onSubmit={handleSubmit(onSubmit)}>
          <InputGrid
            label={<Label>Nama Toko</Label>}
            input={
              <Input isError={false} type={'text'} placeholder={'Masukkan Nama Toko'} {...register('store_name')} />
            }
          />
          <InputGrid
            label={<Label>URL DealPOS</Label>}
            input={<Input isError={false} type={'text'} placeholder={'URL DealPOS'} {...register('dealpos_url')} />}
          />
          <InputGrid
            label={<Label>Client ID</Label>}
            input={<Input isError={false} type={'text'} placeholder={'Client ID'} {...register('client_id')} />}
          />
          <InputGrid
            label={<Label>Client Secret</Label>}
            input={<Input isError={false} type={'text'} placeholder={'Client Secret'} {...register('client_secret')} />}
          />
          <InputGrid
            label={<Label></Label>}
            input={
              <FormGroup className="ml-2">
                <FormControlLabel
                  control={<Switch className="switch-extrasmall m-1" defaultChecked />}
                  label={<p className="m-0">Sinkronisasi stok ke DealPOS</p>}
                />
              </FormGroup>
            }
          />
        </RightForm>
        <Bottom>
          <HorizontalDividers />
          <BottomActions>
            <ConnectButton form="dealpos_form">Sambungkan</ConnectButton>
          </BottomActions>
        </Bottom>
      </RightContentWrapper>
    </DialogContentStyled>
  );
};

export default DealPOSContent;
