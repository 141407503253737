import React from 'react';

export const CompanySVG: React.FC<React.SVGProps<SVGSVGElement>> = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M13.4583 1.41666H6.375C5.59371 1.41666 4.95833 2.05203 4.95833 2.83332V7.08332H3.54167C2.76037 7.08332 2.125 7.7187 2.125 8.49999V14.875C2.125 15.0629 2.19963 15.243 2.33247 15.3759C2.4653 15.5087 2.64547 15.5833 2.83333 15.5833H14.1667C14.3545 15.5833 14.5347 15.5087 14.6675 15.3759C14.8004 15.243 14.875 15.0629 14.875 14.875V2.83332C14.875 2.05203 14.2396 1.41666 13.4583 1.41666ZM3.54167 8.49999H7.79167V14.1667H3.54167V8.49999ZM13.4583 14.1667H9.20833V8.49999C9.20833 7.7187 8.57296 7.08332 7.79167 7.08332H6.375V2.83332H13.4583V14.1667Z" fill="#163A50"/>
      <path d="M7.79159 4.25H9.20825V5.66667H7.79159V4.25ZM10.6249 4.25H12.0416V5.66667H10.6249V4.25ZM10.6249 7.10529H12.0416V8.5H10.6249V7.10529ZM10.6249 9.91667H12.0416V11.3333H10.6249V9.91667ZM4.95825 9.91738H6.37492V11.334H4.95825V9.91738Z" fill="#163A50"/>
    </svg>

  );
};
