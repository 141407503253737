import { coreAPI } from './axiosClient';

export const getChannelList = () => {
  return coreAPI({
    method: 'GET',
    url: 'marketplace/integration-status',
  });
};

export const getWarehouseList = (value) => {
  return coreAPI({
    method: 'GET',
    url: `location/warehouse-list/?store_id=${value}&page=1&pageSize=25&sortBy=warehouse_name&sortDirection=asc&q=`,
  });
};