import HorizontalDividers from 'components/CustomDividers/HorizontalDividers';
import VerticalDividers from 'components/CustomDividers/VerticalDividers';
import { DialogContentStyled, Label, LeftContentWrapper, RightContentWrapper } from 'components/Dialog/DialogContent';
import { LeftContent } from 'components/Dialog/DialogLeftContent';
import { Bottom, BottomActions, ConnectButton, RightForm } from 'components/Dialog/DialogRightContent';
import { ErrorMessage, Input, InputGrid } from 'components/Form';
import WarningIcon from '@mui/icons-material/Warning';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getZaloraAuthorizeUrl } from 'api/marketPlaces';
import { useAppStore } from 'stores/appStore';
import { showAlert } from 'components/Alert';
import { DialogProps } from 'interfaces/Props';
import { channels } from 'shared/constants';
import config from 'config';

interface FormValues {
  store_name: string;
  email: string;
  api_key: string;
}
const ZaloraContent: React.FunctionComponent<DialogProps> = ({ handleClosePrimary, getListChannel }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>();
  const { setShowConnectedPopup, disablePrevButton } = useAppStore();

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      let payload = {
        store_name: data.store_name,
        apiKey: data.api_key,
        email: data.email
      };
      const res: any = await getZaloraAuthorizeUrl(payload);
      if (res && res?.data && res?.data?.status == 'ok') {
        if (!disablePrevButton) {
          setShowConnectedPopup(true);
          getListChannel();
          handleClosePrimary();
        } else {
          location.replace(config.baseUrl);
        }
      } else {
        showAlert('error', `${res?.data?.message}`, 5000);
      }
    } catch (err) {
      const error = err as Error;
      showAlert('error', `${error}`, 5000);
    }
  };

  return (
    <DialogContentStyled>
      <LeftContentWrapper>
        <LeftContent channelId={channels.ZILINGO} />
      </LeftContentWrapper>
      <VerticalDividers />
      <RightContentWrapper>
        <RightForm id="zalora_form" onSubmit={handleSubmit(onSubmit)}>
          <InputGrid
            label={<Label>Nama Toko</Label>}
            input={
              <Input
                isError={errors.store_name?.message ? true : false}
                type={'text'}
                placeholder={'Masukkan Nama Toko'}
                {...register('store_name', {
                  required: { value: true, message: 'Nama Toko harus di isi' }
                })}
              />
            }
            errorMessage={
              errors?.store_name && (
                <ErrorMessage
                  icon={<WarningIcon fontSize="small" color="error" />}
                  message={errors.store_name.message}
                />
              )
            }
          />
          <InputGrid
            label={<Label className="requiredField">Email</Label>}
            input={
              <Input
                isError={errors.email?.message ? true : false}
                type={'email'}
                placeholder={'Masukkan email'}
                {...register('email', {
                  required: { value: true, message: 'Email harus di isi' },
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Email tidak valid'
                  }
                })}
              />
            }
            errorMessage={
              errors?.email && (
                <ErrorMessage icon={<WarningIcon fontSize="small" color="error" />} message={errors.email.message} />
              )
            }
          />
          <InputGrid
            label={<Label className="requiredField">API Key</Label>}
            input={
              <Input
                isError={errors.api_key?.message ? true : false}
                type={'text'}
                placeholder={'API Key'}
                {...register('api_key', {
                  required: { value: true, message: 'API Key harus di isi' }
                })}
              />
            }
            errorMessage={
              errors?.api_key && (
                <ErrorMessage icon={<WarningIcon fontSize="small" color="error" />} message={errors.api_key.message} />
              )
            }
          />
        </RightForm>
        <Bottom>
          <HorizontalDividers />
          <BottomActions>
            <ConnectButton form="zalora_form">Sambungkan</ConnectButton>
          </BottomActions>
        </Bottom>
      </RightContentWrapper>
    </DialogContentStyled>
  );
};

export default ZaloraContent;
