/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useEffect } from 'react';
import { useFormContext, Controller, FieldError } from 'react-hook-form';
import {
  Autocomplete,
  createFilterOptions,
  debounce,
  TextField,
  CircularProgress,
  IconButton,
  Tooltip,
  TextFieldProps
} from '@mui/material';
import { Launch } from '@mui/icons-material';
import { DropdownProps } from './Dropdown.types';
import { DataFetcher, QueryParameters } from '../../lib/types';
import DOMPurify from 'dompurify';

const filter = createFilterOptions();
// eslint-disable-next-line import/prefer-default-export
export function Dropdown<T>(props: DropdownProps<T> & TextFieldProps): React.ReactElement<DropdownProps<T>> {
  const pageSize = 50;

  const {
    data,
    onChange,
    itemMapper,
    itemFormatter,
    disabled,
    label,
    name,
    onNew,
    newTooltip,
    value,
    labelFormatter,
    multiple,
    useOnChange,
    useArrayField = false,
    useSearchedText = false,
    getOptionDisabled,
    helperText,
    margin = 'normal',
    filterKeys,
    limit,
    chipColor,
    ChipProps
    // ...args
  } = props;

  const [options, setOptions] = React.useState<Array<T>>([]);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [searchText, setSearchText] = useState('');

  const ctx = useFormContext();
  const errors = ctx ? ctx.formState.errors : null;

  const limitOptions = (option, state) => {
    let filtered = filter(option, state);
    if (limit) {
      filtered = filter(option, state).slice(0, limit);
    }
    if (useSearchedText) {
      if (state.inputValue !== '') {
        filtered.push({
          inputValue: state.inputValue,
          title: `Gunakan "${state.inputValue}"? Tekan Enter`
        });
      }
    }
    return filtered;
  };

  // get error message using props {name} and its index
  const getErrorFieldName = () => {
    try {
      const names = name;
      const splitStr = names.split('[');
      const namex = splitStr[0];
      const index = parseInt(splitStr[1].replace(']', ''), 10);

      if (errors[namex].message) {
        return errors[namex];
      }
      return errors[namex][index];
    } catch (e) {
      return errors[name];
    }
  };

  const error = (ctx && errors && name
    ? !useArrayField
      ? errors[name]
      : getErrorFieldName()
    : null) as any as FieldError;

  function startSearch(text: string) {
    if (!useSearchedText) {
      setSearchText(text);
    }
  }

  function formatItem(item: any) {
    let optionItem: any;
    if (item?.label) {
      optionItem = { label: item.label, value: item.value || item };
    } else if (itemMapper) {
      optionItem = itemMapper(item);
    } else if (ctx) {
      optionItem = options.find((o: any) => o.value == item);
    }
    return optionItem?.label || '';
  }

  function compareItem(option, selection) {
    if (option && selection) {
      if (itemMapper) {
        if (props.multiple) {
          return itemMapper(option)[label] == itemMapper(selection)[label];
        } else {
          if (itemMapper(selection)[value]) {
            return itemMapper(option)[value] == itemMapper(selection)[value];
          }
          if (selection.value) {
            return itemMapper(option)[value] == selection.value;
          }
          return itemMapper(option)[value] == selection;
        }
      }
      if (option.value) {
        if (selection.value) {
          return option.value == selection.value;
        }
        return option.value == selection;
      }
    }
    return option == selection;
  }

  function renderOptions(propsOpt, option, state) {
    return <li {...propsOpt}>{itemFormatter(option, state)}</li>;
  }

  function onSelectedChanged(e, item) {
    if (onChange) {
      onChange(item);
    }
  }

  const searchFn = debounce(startSearch, 500);

  useEffect(() => {
    setOptions([]);
    const fetchData = async () => {
      if (loading || !open) {
        return;
      }

      setLoading(true);
      try {
        const fetcher = data as DataFetcher<T>;
        const req: QueryParameters = { pageSize, page: 1, q: searchText };
        const res = await fetcher(req);
        if (res instanceof Array) {
          setOptions(res);
        } else {
          setOptions(res.data);
        }
      } finally {
        setLoading(false);
      }
    };

    if (Array.isArray(data)) {
      setOptions(data);
    } else {
      fetchData();
    }
  }, [open, searchText]);

  function handleChange(argsChange, item) {
    // stimulate onChange props to its parent when Dropdown have a context
    if (useOnChange && ctx) {
      onSelectedChanged(argsChange, item);
    }

    if (ctx) {
      argsChange.onChange(item?.value || item);
    } else {
      onSelectedChanged(argsChange, item);
    }
  }

  function renderField(val) {
    const argsField = val.field;
    let newValue = null;
    let showPlaceholder = true;

    if (ctx && argsField.value) {
      newValue = argsField.value;
    } else if (props.multiple) {
      newValue = [];
    } else {
      newValue = null;
    }

    if (props.multiple && Array.isArray(argsField.value)) {
      if (argsField.value.length > 0) showPlaceholder = false;
    }
    let errorMessage = '';
    if (error) {
      switch (error.type) {
        case 'string.empty':
          errorMessage = `${props.label || ''} Tidak boleh kosong`;
          break;
        case 'object.base':
          errorMessage = `${props.label || ''} Tidak boleh kosong`;
          break;
        case 'array.includesRequiredUnknowns' || 'array.sparse':
          errorMessage = `${props.label || ''} Tidak boleh kosong`;
          break;
        case 'any.required':
          errorMessage = `${props.label || ''} Tidak boleh kosong`;
          break;
        default:
          errorMessage = error.message.replace(name, props.label || '');
          break;
      }
    }
    return (
      <Autocomplete
        className={props.className}
        freeSolo={useSearchedText}
        disabled={disabled}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => {
          setSearchText(DOMPurify.sanitize(''));
          setOpen(false);
        }}
        value={newValue}
        options={options}
        getOptionLabel={formatItem}
        renderTags={labelFormatter}
        isOptionEqualToValue={compareItem}
        loading={loading}
        getOptionDisabled={getOptionDisabled}
        multiple={multiple}
        onChange={(e, item) => handleChange(argsField, item)}
        filterOptions={limitOptions}
        renderOption={itemFormatter ? renderOptions : null}
        classes={{ root: chipColor }}
        ChipProps={ChipProps}
        limitTags={5}
        renderInput={(params) => (
          <TextField
            variant="outlined"
            margin={margin}
            {...params}
            // {...argsField.margin}
            classes={{ root: 'dropdown-placeholder' }}
            label={label}
            error={Boolean(error)}
            className="border-none no-notch"
            helperText={error ? errorMessage : helperText}
            onChange={(e) => searchFn(DOMPurify.sanitize(e.target.value))}
            {...(showPlaceholder && { placeholder: props.placeholder })}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              ...props.InputProps,
              sx: {
                '& .MuiOutlinedInput-notchedOutline': {
                  bgcolor: props.disabled ? 'rgb(0,0,0,0.05)' : 'none'
                }
              },
              // argsField.InputProps && (...argsField.InputProps),
              endAdornment: (
                <>
                  {onNew && (
                    <Tooltip title={newTooltip}>
                      <IconButton onClick={onNew}>
                        <Launch />
                      </IconButton>
                    </Tooltip>
                  )}
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
    );
  }

  function renderControlled() {
    return (
      <Autocomplete
        className={props.className}
        value={value}
        onChange={(e, item) => onChange(item)}
        onOpen={() => setOpen(true)}
        onClose={() => {
          setSearchText(DOMPurify.sanitize(''));
          setOpen(false);
        }}
        // inputValue={value.label}
        loading={loading}
        disabled={disabled}
        multiple={multiple}
        isOptionEqualToValue={compareItem}
        getOptionLabel={formatItem}
        getOptionDisabled={getOptionDisabled}
        options={options}
        limitTags={5}
        ChipProps={ChipProps}
        filterOptions={
          filterKeys
            ? createFilterOptions({
                stringify: (option) => {
                  let filterValue = '';
                  filterKeys.forEach((fk) => {
                    filterValue += option[fk] + ' ';
                  });
                  return filterValue;
                }
              })
            : createFilterOptions()
        }
        renderInput={(params) => (
          <TextField
            label={label}
            className="border-none no-notch"
            placeholder={props.placeholder}
            onChange={(e) => searchFn(DOMPurify.sanitize(e.target.value))}
            {...params}
            margin={margin}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...params.InputProps,
              ...props.InputProps,
              // argsField.InputProps && (...argsField.InputProps),
              endAdornment: (
                <>
                  {onNew && (
                    <Tooltip title={newTooltip}>
                      <IconButton onClick={onNew}>
                        <Launch />
                      </IconButton>
                    </Tooltip>
                  )}
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
        renderOption={itemFormatter ? renderOptions : null}
      />
    );
  }

  if (ctx && name) {
    return <Controller name={name} control={ctx.control} render={renderField} />;
  }
  return renderControlled();
}
