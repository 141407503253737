import React from 'react';
import { Button, Grid, Icon, Dialog, DialogContent } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ConfirmationProps } from './Confirmation.types';
import { WarningSVG } from '../../assets/img';

const useIconStyles = makeStyles({
  root: {
    fontSize: '3em'
  }
});

export function Confirmation(props: ConfirmationProps): React.ReactElement<ConfirmationProps> {
  const iconClasses = useIconStyles();
  const { open, text, subText, subSubText, primaryButton, maxWidth, onAction } = props;

  return (
    <Dialog onClose={(reason) => null} disableEscapeKeyDown open={open} maxWidth={maxWidth || 'xs'} fullWidth>
      <DialogContent dividers style={{ padding: '50px 60px' }}>
        <Grid container alignItems="center" justifyContent="center" className="flex-column">
          <Grid item xs={12} className="text-center">
            <Icon color="primary" className={iconClasses.root}>
              <WarningSVG />
            </Icon>
          </Grid>
          <Grid item xs={12} className="font-weight-bold text-center my-2" style={{ fontSize: '14px' }}>
            {text}
          </Grid>
          {subText && (
            <Grid item xs={12} className="text-center text-light-gray" style={{ fontSize: '14px' }}>
              {subText}
            </Grid>
          )}
          {subSubText && (
            <Grid item xs={12} className="text-center text-light-gray mt-3" style={{ fontSize: '14px' }}>
              {subSubText}
            </Grid>
          )}
        </Grid>
        <Grid container spacing={4} className="mt-2">
          <Grid item xs={6} className="text-center">
            <Button variant="outlined" color="secondary" className="w-75" onClick={() => onAction(false)}>
              Batal
            </Button>
          </Grid>
          <Grid item xs={6} className="text-center">
            <Button
              onClick={() => onAction(true)}
              color="secondary"
              className={`w-75 ${(primaryButton && primaryButton.color) || 'btn-danger'}`}
            >
              {(primaryButton && primaryButton.label) || 'Hapus'}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
