import React, { useLayoutEffect, useState } from 'react';
import Joi from 'joi';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import { SaveSVG } from 'assets/img';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { TextInput } from 'components/Form/TextInput';
import { getListRegions } from 'api/locations';
import { Form } from 'components/Form/Form';
import { createStyles, makeStyles } from '@mui/styles';
import { AsyncButton } from 'components/ButtonNew/Button';
import CloseIcon from '@mui/icons-material/Close';
import { AddressForm as IAddressForm } from 'lib/types';

interface Props {
  open: boolean;
  handleClose: () => void;
  handleAddress: (data: IAddressForm) => void;
  isAddressCompleted: boolean;
  profilleData: any;
  parentData: any;
}

const schema = Joi.object({
  address: Joi.string().required(),
  province: Joi.string().required(),
  province_id: Joi.string(),
  city: Joi.string(),
  city_id: Joi.string(),
  district: Joi.string(),
  district_id: Joi.string(),
  postcode: Joi.string().required()
});

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      fontWeight: 600,
      fontFamily: 'Nunito Sans',
      lineHeight: '19px',
      color: '#163A50',
      fontSize: '14px'
    },
    description: {
      fontWeight: 400,
      fontFamily: 'Nunito Sans',
      lineHeight: '19px',
      color: '#163A50'
    }
  })
);

const defaultValues = {
  address: '',
  province: '',
  province_id: '',
  city: '',
  city_id: '',
  district: '',
  district_id: '',
  postcode: ''
};

let formhooks: any;
const AddressModal: React.FunctionComponent<Props> = ({
  open,
  handleClose,
  handleAddress,
  isAddressCompleted,
  profilleData,
  parentData
}) => {
  const classes = useStyles();
  const [optProvince, setOptProvince] = useState([]);
  const [optCity, setOptCity] = useState([]);
  const [optDistrict, setOptDistrict] = useState([]);
  const [data, setData] = useState<any>(defaultValues);
  const [isEdit, setIsEdit] = useState(false);

  const onSubmit = (datas) => {
    setIsEdit(true);
    setData({
      address: datas.address,
      province: datas.province,
      province_id: datas.province_id,
      city: datas.city,
      city_id: datas.city_id,
      district: datas.district,
      district_id: datas.district_id,
      postcode: datas.postcode
    });
    handleAddress({
      address: datas.address,
      city: datas.city,
      district: datas.district,
      postcode: datas.postcode,
      state: datas.province,
      country: 'Indonesia'
    });
  };

  const dialogTitle = isAddressCompleted ? 'Edit Alamat' : 'Tambah Alamat';

  const getRegions = async (apiUrl, keyValue, setOpt, isChange?) => {
    const res = await getListRegions(apiUrl);
    let opt = [];
    if (res.status === 200) {
      opt = res?.data?.map((obj) => ({ value: obj[keyValue], label: obj.name }));
    }
    if (!isChange) {
      return opt;
    } else {
      setOpt(opt);
    }
  };

  const getListSelectedRegions = async (province_id?, city_id?) => {
    let options: any[] = [];
    if (province_id) {
      options = await getRegions(`region/cities?province_id=${province_id}`, 'city_id', setOptCity);
    }
    if (city_id) {
      options = await getRegions(`region/districts?city_id=${city_id}`, 'district_id', setOptDistrict);
    }
    return options;
  };

  const getDetailDistrict = async (province, dataCity, cityOpt, districtOpt) => {
    let dataDistrict = districtOpt.find((val) => val.label == profilleData.district);
    setOptCity(cityOpt);
    setOptDistrict(districtOpt);
    setData({
      address: isEdit ? data.address : profilleData.address,
      postcode: isEdit ? data.postcode : profilleData.postcode,
      province: isEdit ? data.province : province.label,
      province_id: isEdit ? data.province_id : province.value,
      city: isEdit ? data.city : dataCity.label,
      city_id: isEdit ? data.city_id : dataCity.value,
      district: isEdit ? data.district : dataDistrict.label,
      district_id: isEdit ? data.district_id : dataDistrict.value
    });
  };

  const getDetailCity = async (dataProvice, cityOpt) => {
    let dataCity = cityOpt.find((val) => val.label == profilleData.city);
    if (dataCity) {
      const districtOpt = await getListSelectedRegions(undefined, dataCity.value);
      getDetailDistrict(dataProvice, dataCity, cityOpt, districtOpt);
    }
  };

  const getDetailProvince = async (listProvince) => {
    let dataProvice = listProvince.find((val) => val.label == profilleData.state);
    const cityOpt = await getListSelectedRegions(dataProvice.value, undefined);
    getDetailCity(dataProvice, cityOpt);
  };

  const loadData = async () => {
    getDetailProvince(optProvince);
  };

  const AddressForm: any = [
    {
      label: 'Alamat Lengkap',
      field_name: 'address',
      value: data.address || '',
      isMandatory: true,
      type: 'text',
      placeholder: 'Jalan, No, Blok, dll'
    },
    {
      label: 'Provinsi',
      field_name: 'province_id',
      value: data.province_id || '-',
      type: 'select',
      isMandatory: true,
      selectData: optProvince,
      placeholder: 'Pilih provinsi'
    },
    {
      label: 'Kota / Kab',
      field_name: 'city_id',
      value: data.city_id || '-',
      type: 'select',
      isMandatory: true,
      selectData: optCity,
      placeholder: 'Pilih kota'
    },
    {
      label: 'Kecamatan',
      field_name: 'district_id',
      value: data.district_id || '-',
      type: 'select',
      isMandatory: true,
      selectData: optDistrict,
      placeholder: 'Pilih kecamatan'
    },
    {
      label: 'Kode Pos',
      field_name: 'postcode',
      value: data.postcode || '-',
      type: 'text',
      isMandatory: true,
      placeholder: 'Kode pos'
    }
  ];

  const onChangeRegions = (obj, name) => {
    if (name === 'province_id') {
      setOptCity([]);
      setOptDistrict([]);
      formhooks.setValue('city_id', '');
      formhooks.setValue('district_id', '');
      formhooks.setValue('province', obj.label);
      getRegions(`region/cities?province_id=${obj.value}`, 'city_id', setOptCity, true);
    } else if (name === 'city_id') {
      setOptDistrict([]);
      formhooks.setValue('district_id', '');
      formhooks.setValue('city', obj.label);
      getRegions(`region/districts?city_id=${obj.value}`, 'district_id', setOptDistrict, true);
    } else if (name === 'district_id') {
      formhooks.setValue('district', obj.label);
    }
  };

  const getFormHooks = (formhook) => {
    formhooks = formhook;
  };

  const getProvinceList = async () => {
    const provOptions = await getRegions('region/provinces', 'province_id', setOptProvince);
    setOptProvince(provOptions);
  };

  useLayoutEffect(() => {
    if (optProvince.length === 0) {
      getProvinceList();
    }
  }, []);

  React.useEffect(() => {
    if (open === true) {
      loadData();
    }
  }, [open]);

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'sm'}>
      <Form schema={schema} values={data} getFormHooks={getFormHooks} shouldUnregister={false} onSubmit={onSubmit}>
        <DialogTitle>
          <p style={{ fontWeight: 'bold' }}>{dialogTitle}</p>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              fontWeight: 'bold',
              color: (theme) => theme.palette.grey[700]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid style={{ flex: 1 }}>
            <>
              {AddressForm.map((item, i) => (
                <div key={i}>
                  {item.separator && (
                    <div className="pt-4 pb-3">
                      <p className="mb-0 font-weight-bold" style={{ fontSize: 20 }}>
                        {item.separator}
                      </p>
                    </div>
                  )}
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid
                      item
                      xs={3}
                      className={`align-self-start mt-3 pt-1 ${classes.label}`}
                      style={{ paddingBottom: 20 }}
                    >
                      {item.label}
                      <span className="text-danger">*</span>
                    </Grid>
                    <Grid item xs={9} className={classes.description} style={{ paddingBottom: 20 }}>
                      <Grid>
                        {item.type === 'select' && (
                          <Dropdown
                            key={`${data[item.field_name]}_${item.selectData.length}`}
                            disabled={item.selectData.length === 0}
                            placeholder={item.placeholder}
                            label={item.label}
                            data={item.selectData}
                            name={item.field_name}
                            useOnChange
                            onChange={(obj) => onChangeRegions(obj, item.field_name)}
                            className="notch-dropdown multiple-dropdown__primary"
                          />
                        )}
                        {item.type === 'text' && (
                          <TextInput
                            multiline={item?.field_name === 'address'}
                            rows={item?.field_name === 'address' && 3}
                            placeholder={item.placeholder}
                            name={item.field_name}
                            value={item.value}
                            label={item.label}
                            variant="outlined"
                            className="no-notch"
                            disabled={item.disabled}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              ))}
            </>
          </Grid>
        </DialogContent>
        <DialogActions>
          <AsyncButton type="submit" color="primary" icon={<SaveSVG height={18} />}>
            Simpan
          </AsyncButton>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default AddressModal;
