import React from 'react';
import { getTiktokAuthorizeUrl } from 'api/marketPlaces';
import { showAlert } from 'components/Alert';
import { DialogContentStyled, Label, LeftContentWrapper, RightContentWrapper } from 'components/Dialog/DialogContent';
import { LeftContent } from 'components/Dialog/DialogLeftContent';
import { Bottom, BottomActions, ConnectButton, RightForm } from 'components/Dialog/DialogRightContent';
import { Input, InputGrid } from 'components/Form';
import { SubmitHandler, useForm } from 'react-hook-form';
import { channels } from 'shared/constants';
import { useAppStore } from 'stores/appStore';
import HorizontalDividers from 'components/CustomDividers/HorizontalDividers';
import VerticalDividers from 'components/CustomDividers/VerticalDividers';

interface FormValues {
  app_key: string;
  app_secret: string;
}

const TiktokContent: React.FunctionComponent = () => {
  const { register, handleSubmit } = useForm<FormValues>();
  const { indexAccordion, disablePrevButton } = useAppStore();

  const onSubmit: SubmitHandler<FormValues> = async (value) => {
    try {
      let payload = {
        app_key: value.app_key,
        app_secret: value.app_secret
      };
      const res: any = await getTiktokAuthorizeUrl(payload, indexAccordion, disablePrevButton);
      if (res && res?.data && res?.data?.status == 'ok') {
        window.location.href = res?.data?.redirect;
      } else {
        showAlert('error', `${res?.data?.message}`, 5000);
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  return (
    <DialogContentStyled>
      <LeftContentWrapper>
        <LeftContent channelId={channels.TIKTOK} />
      </LeftContentWrapper>
      <VerticalDividers />
      <RightContentWrapper>
        <RightForm id="tiktok_form" onSubmit={handleSubmit(onSubmit)}>
          <InputGrid
            label={<Label>App Key</Label>}
            input={
              <Input
                // isError={errors.app_key?.message ? true : false}
                isError={false}
                type={'text'}
                placeholder={'App Key'}
                {...register('app_key')}
              />
            }
            // errorMessage={errors?.app_key && <ErrorMessage icon={<WarningIcon fontSize='small' color='error'/>} message={errors.app_key.message} /> }
          />
          <InputGrid
            label={<Label>App Secret</Label>}
            input={
              <Input
                // isError={errors.app_secret?.message ? true : false}
                isError={false}
                type={'text'}
                placeholder={'API Key'}
                {...register('app_secret')}
              />
            }
            // errorMessage={errors?.app_secret && <ErrorMessage icon={<WarningIcon fontSize='small' color='error'/>} message={errors.app_secret.message} /> }
          />
        </RightForm>
        <Bottom>
          <HorizontalDividers />
          <BottomActions>
            <ConnectButton form="tiktok_form">Sambungkan</ConnectButton>
          </BottomActions>
        </Bottom>
      </RightContentWrapper>
    </DialogContentStyled>
  );
};

export default TiktokContent;
