import { DialogContent } from '@mui/material';
import styled from 'styled-components';

export const DialogContentStyled = styled(DialogContent)`
  padding: 0;
  display: grid;
  grid-template-columns: 1fr auto 1.2fr;
`;
export const LeftContentWrapper = styled.div`
  padding: 1.3rem;
  display: grid;
  align-content: space-between;
`;
export const RightContentWrapper = styled.div`
  display: grid;
  align-content: space-between;
`;
export const Label = styled.p`
  font-size: 14px;
  font-weight: 600;
`;