import HorizontalDividers from 'components/CustomDividers/HorizontalDividers';
import VerticalDividers from 'components/CustomDividers/VerticalDividers';
import { DialogContentStyled, Label, LeftContentWrapper, RightContentWrapper } from 'components/Dialog/DialogContent';
import { Container, Guide } from 'components/Dialog/DialogLeftContent';
import { Bottom, BottomActions, ConnectButton, RightForm } from 'components/Dialog/DialogRightContent';
import { ErrorMessage, Input, InputGrid } from 'components/Form';
import WarningIcon from '@mui/icons-material/Warning';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getFacebookAuthorizeUrl } from 'api/marketPlaces';
import { showAlert } from 'components/Alert';
import { useAppStore } from 'stores/appStore';

interface FormValues {
  business_manager_id: string;
}

const FacebookContent: React.FunctionComponent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>();
  const { indexAccordion, disablePrevButton } = useAppStore();

  const onSubmit: SubmitHandler<FormValues> = async (value) => {
    try {
      const res: any = await getFacebookAuthorizeUrl(value.business_manager_id, indexAccordion, disablePrevButton);
      if (res && res?.data && res?.data?.status == 'ok') {
        window.location.href = res?.data?.redirect;
      } else {
        showAlert('error', `${res?.data?.message}`, 5000);
      }
    } catch (err) {
      const error = err as Error;
      showAlert('error', `${error}`, 5000);
    }
  };

  return (
    <DialogContentStyled>
      <LeftContentWrapper>
        <Container>
          <h3>Sambungkan Toko</h3>
          <p>
            Kamu memerlukan Akun Pengelola Bisnis dan Katalog di Facebook. Bila Kamu belum mempunyai Akun Pengelola
            Bisnis ataupun Katalog, mohon membuatnya terlebih dahulu dengan mengikuti panduan berikut:
          </p>
          <p style={{ color: 'blue', cursor: 'pointer' }}>Panduan Membuat Pengelola Bisnis</p>
          <p style={{ color: 'blue', cursor: 'pointer' }}>Panduan Membuat Katalog</p>
          <p>Untuk jenis Katalog, pilih Produk.</p>
        </Container>
        <Guide>Panduan Menemukan ID Pengelola Bisnis</Guide>
      </LeftContentWrapper>
      <VerticalDividers />
      <RightContentWrapper>
        <RightForm id="facebook_form" onSubmit={handleSubmit(onSubmit)}>
          <InputGrid
            label={<Label className="requiredField">ID Pengelola Bisnis</Label>}
            input={
              <Input
                isError={errors.business_manager_id?.message ? true : false}
                type={'text'}
                placeholder={''}
                {...register('business_manager_id', {
                  required: { value: true, message: 'ID Pengelola Bisnis harus di isi' }
                })}
              />
            }
            errorMessage={
              errors?.business_manager_id && (
                <ErrorMessage
                  icon={<WarningIcon fontSize="small" color="error" />}
                  message={errors.business_manager_id.message}
                />
              )
            }
            wide={true}
          />
        </RightForm>
        <Bottom>
          <HorizontalDividers />
          <BottomActions>
            <ConnectButton form="facebook_form">Sambungkan</ConnectButton>
          </BottomActions>
        </Bottom>
      </RightContentWrapper>
    </DialogContentStyled>
  );
};

export default FacebookContent;
