import React, { useRef } from 'react';
import {
  Button,
  ButtonBase,
  Card,
  CardContent,
  CardMedia,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip
} from '@mui/material';

import NoImage from 'assets/img/no-image.png';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import DoneIcon from '@mui/icons-material/DoneRounded';
import { AsyncButton } from 'components/ButtonNew/Button';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { getListBundle } from 'api/masterStore';
import { PaginationMaster } from 'components/SinglePagination/PaginationMaster';

interface Props {
  isShow: boolean;
  selectedData: any;
  onClose?: () => void;
  onSubmit: (data) => void;
  valueDetail?: any;
}

const paginateDetail = {
  q: '',
  page: 1,
  pageSize: 12,
  type: 'composition'
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      width: '100%',
      borderRadius: 16
    },
    bundleCard: {
      height: '100%',
      display: 'flex',
      flexFlow: 'column'
    },
    bundleCardAdd: {
      display: 'flex',
      justifyContent: 'end',
      cursor: 'pointer',
      height: '100%',
      paddingTop: '10px',
      paddingRight: '10px'
    },
    bundleWrapper: {
      width: '100%',
      display: 'block',
      textAlign: 'initial',
      background: '#FFFFFF',
      border: '1px solid #EAEAEA',
      boxShadow: '0px 2px 10px rgba(175, 137, 255, 0.15)',
      borderRadius: '15px',
      padding: '10px'
    },
    bundleContentWrap: {
      display: 'flex',
      flexFlow: 'column',
      flexGrow: 1,
      justifyContent: 'space-between'
    },
    bundleImg: {
      width: '100%',
      aspectRatio: '1',
      objectFit: 'contain',
      borderRadius: '8px'
    },
    bundleDescWrapper: {
      width: '100%',
      padding: '12px'
    },
    bundleRemoveWrapper: {
      position: 'absolute',
      top: '0px',
      right: '0px',
      cursor: 'pointer',
      background: 'transparent',
      width: '30px',
      height: '30px',
      lineHeight: '30px'
    },
    bundleRemoveIcon: {
      color: '#7a83a8',
      maxWidth: '200px',
      maxHeight: '200px',
      width: '30px !important',
      height: '30px !important'
    },
    bundleAddIcon: {
      color: '#fff',
      maxWidth: '15px',
      maxHeight: '15px',
      width: '15px !important',
      height: '15px !important',
      background: '#4B68EF',
      border: '1.5px solid #4B68EF',
      borderRadius: '4px'
    },
    bundleTextWrapper: {
      marginBottom: '8px'
    },
    bundleCounterWrapper: {
      justifyContent: 'space-between',
      marginTop: '5px',
      marginBottom: '`15px',
      backgroundColor: '#f3f3f3',
      borderRadius: '30px',
      padding: '0.3rem'
    },
    bundleCounterOperator: {
      flexBasis: 'auto !important',
      justifyContent: 'center',
      alignSelf: 'center',
      display: 'flex'
    },
    bundleCounterInput: {
      border: 'none',
      textAlign: 'center',
      fontSize: '18px',
      width: '100%',
      backgroundColor: 'transparent'
    },
    bundleWrapText: {
      wordBreak: 'break-all',
      minHeight: '98px'
    },
    itemCode: {
      fontWeight: 'bold',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 12,
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical'
    },
    itemName: {
      fontSize: 12,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical'
    },
    blocked: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      backgroundColor: 'rgba(195, 201, 205, 0.4)',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      borderRadius: '15px'
    },
    processIcon: {
      animation: '$spin 2s linear infinite'
    },
    '@keyframes spin': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(-360deg)' }
    }
  })
);

export const ModalComposition: React.FC<Props> = ({ isShow, selectedData = [], onClose, onSubmit, valueDetail }) => {
  const classes = useStyles();
  const [show, setShow] = React.useState(isShow);
  const [datas, setDatas] = React.useState([]);
  const [selDatas, setSelDatas] = React.useState([]);
  const [pagination, setPagination] = React.useState({ ...paginateDetail });
  const [totalItems, setTotalItems] = React.useState(0);

  const searchDetail = useRef<string>('');

  const getDataBundle = async (selectedDatas) => {
    let newPagination: any = { ...pagination };
    newPagination.bundle_item_id = valueDetail.item_id;
    const res: any = await getListBundle(newPagination);
    const ds = res.data?.data?.map((x) => {
      const selDats = selectedDatas.find((y) => y.item_code == x.item_code);
      return {
        ...x,
        qty: 1,
        thumbnail: x.thumbnail ?? NoImage,
        checked: selDats ? true : false
      };
    });
    setTotalItems(res.data?.total_count);
    setDatas(ds);
  };

  const handleCheckedChange = (item_code, value) => {
    const bundleItemx = datas.slice();
    const selBundleItemx = selDatas.slice();

    // bundleItemx.find((x) => x.item_code == item_code).checked
    let selItem = bundleItemx.find((x) => x.item_code == item_code);
    if (!selItem) {
      selItem = selBundleItemx.find((x) => x.item_code == item_code);
    }
    selItem.checked = value;
    setDatas(bundleItemx);
    if (selItem.checked) {
      setSelDatas((prev) => [...prev, selItem]);
    } else {
      const removeData = selDatas.filter((x) => x.item_code != selItem.item_code);
      setSelDatas(removeData);
    }
    // setSelDatas(bundleItemx.filter((x) => x.checked));
  };

  const onPageSizeChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPagination((prevState) => ({
      ...prevState,
      page: value
    }));
  };

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const handleConfirm = async () => {
    setShow(false);
    onSubmit(selDatas);
  };

  const handleSearch = () => {
    let newPaginate = { ...pagination, q: searchDetail.current, page: 1 };
    setPagination(newPaginate);
  };

  const startSearch = (text) => {
    searchDetail.current = text;
  };

  const onSearch = debounce(startSearch, 800);

  React.useEffect(() => {
    setShow(isShow);
    if (isShow) {
      setSelDatas(selectedData.map((x) => ({ ...x, checked: true })));
      setPagination({ ...paginateDetail });
    }
  }, [isShow]);

  React.useEffect(() => {
    if (isShow) {
      getDataBundle(selectedData);
    }
  }, [pagination]);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={show}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className="font-weight-bold" id="alert-dialog-title" style={{ fontSize: 30 }}>
        <div className="d-flex justify-content-between d-flex">
          <span className="font-weight-bold" style={{ fontSize: 30 }}>
            Pilih Komposisi Produk
          </span>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className="pt-4 d-flex align-items-center">
          <Grid item md={11}>
            <TextField
              variant="outlined"
              size="small"
              className="no-notch"
              placeholder="Cari produk,SKU, nama..."
              onChange={(e) => onSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item md={1}>
            <Button
              onClick={handleSearch}
              className="py-3 mb-1"
              fullWidth
              type="button"
              variant="contained"
              color="primary"
            >
              Terapkan
            </Button>
          </Grid>
        </Grid>
        <Grid container className="py-2 w-100">
          {datas.map((x, key) => {
            const checked = Boolean(selDatas.find((data) => data.item_id == x.item_id));
            return (
              <Grid item key={key} md={3} lg={2} className="p-2">
                <Card className={`${classes.bundleCard}`}>
                  <ButtonBase
                    className="bundle-card"
                    onClick={() => {
                      if (!checked) {
                        handleCheckedChange(x.item_code, !checked);
                      }
                    }}
                  >
                    <CardMedia className={`${classes.bundleImg}`} image={x.thumbnail} />
                    <CardContent className={`${classes.bundleWrapText} px-0 pb-1`}>
                      <Tooltip title={x.item_code}>
                        <span className={classes.itemCode}>{x.item_code}</span>
                      </Tooltip>
                      <Tooltip title={x.item_name}>
                        <span className={classes.itemName}>{x.item_name}</span>
                      </Tooltip>
                    </CardContent>
                  </ButtonBase>
                  {checked && (
                    <div
                      className={`${classes.bundleCardAdd} ${classes.blocked}`}
                      onClick={() => handleCheckedChange(x.item_code, !checked)}
                    >
                      <DoneIcon className={classes.bundleAddIcon} />
                    </div>
                  )}
                </Card>
              </Grid>
            );
          })}
        </Grid>
        <PaginationMaster
          position="justify-content-end"
          pagination={pagination}
          totalItems={totalItems}
          onChangePage={onPageSizeChange}
        />
      </DialogContent>
      <DialogActions>
        <div className="mr-2 font-weight-bold">
          <span style={{ fontSize: '14px' }}>{`${selDatas.length} Bundle terpilih`}</span>
        </div>
        <AsyncButton onClick={handleConfirm} variant="contained" color="primary" type="submit">
          Simpan
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
};
