import moment from 'moment';

export function convertDate(date: Date | string, format: string) {
  return moment(date).format(format).replace('Invalid date', '');
}

export function mergeDateTime(date: Date | undefined, time: Date | undefined) {
  date = new Date(date);
  time = new Date(time);
  const mergedDate = moment(date).hours(time.getHours()).minutes(time.getMinutes()).toDate();

  return mergedDate;
}
