import { StepContainer, Title, TitleDescription } from 'components/atoms';
import HorizontalDividers from 'components/CustomDividers/HorizontalDividers';
import React, { useRef, useState } from 'react';
import UseDocumentTitle from 'hooks/UseDocumentTitle';
import InitialDownload from './InitialDownload';
import { useAppStore } from 'stores/appStore';
import { StepProps, ValidationConfirmation } from 'interfaces/Props';

const StepFour: React.FunctionComponent<StepProps & ValidationConfirmation> = ({
  submitRef,
  setValidationState,
  confirmationRef
}) => {
  const setupWizard = useRef<string>();
  const { setShowConfirmation, toggleSubmitValidation, integratedChannels, setValidationComplete } = useAppStore();
  const [isConfirmed, setIsConfirmed] = useState(false);
  UseDocumentTitle('Pemilihan Toko Nonmaster');

  const setCompleteValidation = (key: string, value: boolean) => {
    setValidationState((prevState) => {
      const updatedValidations = prevState.nonMasterdownload.map((validation) => {
        if (validation.key === key) {
          return {
            ...validation,
            completed: value
          };
        }
        return validation;
      });
      return {
        ...prevState,
        nonMasterdownload: updatedValidations
      };
    });
  };

  const handleClick = () => {
    if (!isConfirmed) {
      setShowConfirmation(true);
    }
    if (isConfirmed) {
      toggleSubmitValidation(true);
    }
  };

  const handleClickConfirmation = () => {
    if (integratedChannels.length === 0) {
      setCompleteValidation('VALID_SKU', true);
      setValidationComplete(true);
    }
    setIsConfirmed(true);
    toggleSubmitValidation(true);
  };

  return (
    <>
      <StepContainer>
        <Title>Download Produk Toko Nonmaster</Title>
        <TitleDescription>
          Produk toko nonmaster akan otomatis tergabung ke toko master apabila memiliki SKU Produk yang sama. <br />
          mohon untuk melakukan penggabungan SKU Produk produk dengan SKU Produk Master di step 5 apabila kamu memiliki
          produk dengan SKU Produk kosong/SKU tidak sama dengan master namun secara produk adalah satu produk yang sama.{' '}
          <br />
          <br />
          Lewati step ini jika Kamu belum ingin mendownload produk dari toko nonmaster.
        </TitleDescription>
        <HorizontalDividers marginBlock={true} />
        <InitialDownload setCompleteValidation={setCompleteValidation} data={setupWizard.current} />
      </StepContainer>

      <button ref={confirmationRef} onClick={handleClickConfirmation} style={{ display: 'none' }}>
        Confirmation Ref
      </button>
      <button ref={submitRef} onClick={handleClick} style={{ display: 'none' }}>
        CLICK
      </button>
    </>
  );
};

export default StepFour;
