import React from 'react';
import './App.scss';
import './assets/styles/base.scss';
import { createTheme, ThemeProvider } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import { AlertMessage } from 'components/Alert';
import { ConfirmMessage } from 'components/Modal/ConfrimAlert';
import PageContainer from 'pages/Core/PageContainer';

const theme = createTheme({
  typography: {
    fontFamily: ['Nunito Sans', 'sans-serif'].join(',')
  },
  palette: {
    primary: { main: '#4B68EF' },
    text: {
      primary: '#163A50',
      secondary: '#4B68EF',
      disabled: '#E4E7EC'
    }
  },
  components: {
    MuiStepConnector: {
      styleOverrides: {
        lineVertical: {
          minHeight: 36,
          borderColor: '#E4E7EC'
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          // backgroundColor: '#eee',
        },
        inputRoot: {
          fontSize: '14px'
          // backgroundColor: 'yellow',
        },
        input: {
          // backgroundColor: 'green',
        },
        paper: {
          fontSize: '13px'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // backgroundColor: '#695fc2',
          padding: '2rem'
        },
        input: {
          // backgroundColor: 'red',
          padding: '2rem'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // backgroundColor: 'green',
          // padding: '0',
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // backgroundColor: 'yellow',
          padding: '0'
        },
        input: {
          // backgroundColor: 'blue',
          padding: '2px'
        }
      }
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AlertMessage />
      <ConfirmMessage />
      <Routes>
        <Route>
          <Route key={'stepOne'} path={'/'} element={<PageContainer />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
