import React from 'react';
import { DialogContentStyled, Label, LeftContentWrapper, RightContentWrapper } from 'components/Dialog/DialogContent';
import { LeftContent } from 'components/Dialog/DialogLeftContent';
import { Bottom, BottomActions, ConnectButton, RightForm } from 'components/Dialog/DialogRightContent';
import { ErrorMessage, Input, InputGrid } from 'components/Form';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAppStore } from 'stores/appStore';
import { showAlert } from 'components/Alert';
import { getEvermoseAuthorizeUrl } from 'api/marketPlaces';
import { DialogProps } from 'interfaces/Props';
import { channels } from 'shared/constants';

import HorizontalDividers from 'components/CustomDividers/HorizontalDividers';
import VerticalDividers from 'components/CustomDividers/VerticalDividers';
import WarningIcon from '@mui/icons-material/Warning';
import config from 'config';

interface FormValues {
  store_name: string;
  client_key: string;
  client_secret: string;
  brand_id: string;
}
const EvermosContent: React.FunctionComponent<DialogProps> = ({ handleClosePrimary, getListChannel }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>();
  const { setShowConnectedPopup, disablePrevButton } = useAppStore();

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      let payload = {
        storeName: data.store_name,
        clientKey: data.client_key,
        clientSecret: data.client_secret,
        url: '',
        brandId: data.brand_id
      };
      const res: any = await getEvermoseAuthorizeUrl(payload);
      if (res && res?.data && res?.data?.status == 'ok') {
        if (!disablePrevButton) {
          getListChannel();
          handleClosePrimary();
          setShowConnectedPopup(true);
        } else {
          location.replace(config.baseUrl);
        }
      } else {
        showAlert('error', `${res?.data?.message}`, 5000);
      }
    } catch (err) {
      const error = err as Error;
      showAlert('error', `${error}`, 5000);
    }
  };

  return (
    <DialogContentStyled>
      <LeftContentWrapper>
        <LeftContent channelId={channels.EVERMOS} />
      </LeftContentWrapper>
      <VerticalDividers />
      <RightContentWrapper>
        <RightForm id="evermos_form" onSubmit={handleSubmit(onSubmit)}>
          <InputGrid
            label={<Label className="requiredField">Nama Toko</Label>}
            input={
              <Input
                isError={errors.store_name?.message ? true : false}
                type={'text'}
                placeholder={'Nama Toko'}
                {...register('store_name', {
                  required: { value: true, message: 'Nama Toko harus di isi' }
                })}
              />
            }
            errorMessage={
              errors?.store_name && (
                <ErrorMessage
                  icon={<WarningIcon fontSize="small" color="error" />}
                  message={errors.store_name.message}
                />
              )
            }
          />
          <InputGrid
            label={<Label className="requiredField">Client Key</Label>}
            input={
              <Input
                isError={errors.client_key?.message ? true : false}
                type={'text'}
                placeholder={'Client Key'}
                {...register('client_key', {
                  required: { value: true, message: 'Client Key harus di isi' }
                })}
              />
            }
            errorMessage={
              errors?.client_key && (
                <ErrorMessage
                  icon={<WarningIcon fontSize="small" color="error" />}
                  message={errors.client_key.message}
                />
              )
            }
          />
          <InputGrid
            label={<Label className="requiredField">Client Secret</Label>}
            input={
              <Input
                isError={errors.client_secret?.message ? true : false}
                type={'text'}
                placeholder={'Client Secret'}
                {...register('client_secret', {
                  required: { value: true, message: 'Client Secret harus di isi' }
                })}
              />
            }
            errorMessage={
              errors?.client_secret && (
                <ErrorMessage
                  icon={<WarningIcon fontSize="small" color="error" />}
                  message={errors.client_secret.message}
                />
              )
            }
          />
          <InputGrid
            label={<Label className="requiredField">Brand ID</Label>}
            input={
              <Input
                isError={errors.brand_id?.message ? true : false}
                type={'text'}
                placeholder={'Brand ID'}
                {...register('brand_id', {
                  required: { value: true, message: 'Brand ID harus di isi' }
                })}
              />
            }
            errorMessage={
              errors?.brand_id && (
                <ErrorMessage icon={<WarningIcon fontSize="small" color="error" />} message={errors.brand_id.message} />
              )
            }
          />
        </RightForm>
        <Bottom>
          <HorizontalDividers />
          <BottomActions>
            <ConnectButton form="evermos_form">Sambungkan</ConnectButton>
          </BottomActions>
        </Bottom>
      </RightContentWrapper>
    </DialogContentStyled>
  );
};

export default EvermosContent;
