/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useForm, FormProvider, UseFormReturn, useWatch } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

type RequireAtLeastOne<T, R extends keyof T = keyof T> = Omit<T, R> &
  { [P in R]: Required<Pick<T, P>> & Partial<Omit<T, P>> }[R];

type AllFormProps = {
  values?: any;
  onSubmit?: any;
  children: React.ReactNode;
  onChange?: any;
  getFormHooks?: (hooks: UseFormReturn<Record<string, any>>) => void;
  onDirtyChanged?: ({ isDirty }) => void;
  onError?: any;
  shouldUnregister?: boolean;
  formHooks?: UseFormReturn;
  schema: any;
};

// only require either schema or formHooks
export type FormProps = RequireAtLeastOne<AllFormProps, 'schema' | 'formHooks'>;

export const Form: React.FC<FormProps> = ({
  children,
  schema,
  values,
  onSubmit,
  getFormHooks,
  onChange,
  onDirtyChanged,
  onError,
  shouldUnregister = true,
  formHooks,
}: FormProps) => {
  let hooks = formHooks;

  if (!hooks) {
    hooks = useForm({
      resolver: joiResolver(schema),
      defaultValues: values,
      shouldUnregister: shouldUnregister,
    });
  }

  const { isDirty } = hooks.formState;

  React.useEffect(() => {
    hooks.reset(values);
  }, [values]);

  React.useEffect(() => {
    if (onDirtyChanged) {
      onDirtyChanged({ isDirty });
    }
  }, [isDirty]);

  const onInvalid = (e) => {
    if (onError) {
      onError(e);
    }
    console.warn(e);
  };

  if (onChange) {
    const watcher = useWatch({
      control: hooks.control,
    });
    onChange(watcher);
  }

  if (getFormHooks) {
    getFormHooks(hooks);
  }

  return (
    <FormProvider {...hooks}>
      <form onSubmit={hooks.handleSubmit(onSubmit, onInvalid)}>{children}</form>
    </FormProvider>
  );
};
