import ModalConfirmation from 'components/ModalConfirmation';
import React from 'react';

type Props = {
  isAdd: boolean;
  callbackSubmit: (formVal) => void;
  locationDetail: any;
};

export default function useModalConfirmSubmit({ isAdd, callbackSubmit, locationDetail }: Props) {
  const [isShowConfirm, setIsShowConfirm] = React.useState(false);
  const [tempFormValue, setTempFormValue] = React.useState({});

  const onShowModalConfrim = async (formVal) => {
    setTempFormValue(formVal);
    if (isAdd) {
      setIsShowConfirm(true);
    } else {
      callbackSubmit({ ...formVal, isActive: locationDetail.is_active });
    }
  };

  const ModalConfirmSubmit = (
    <ModalConfirmation
      open={isShowConfirm}
      title={'Aktifkan Lokasi?'}
      onClose={() => setIsShowConfirm(false)}
      onCancel={() => callbackSubmit({ ...tempFormValue, isActive: false })}
      onConfirm={() => callbackSubmit({ ...tempFormValue, isActive: true })}
      btnCancelText="Nanti Saja"
      btnOkText="Aktifkan Sekarang"
      description="Kamu dapat mengaktifkan lokasi ini kapan saja."
    />
  );

  return {
    ModalConfirmSubmit,
    onShowModalConfrim,
    onCloseModalConfirm: () => setIsShowConfirm(false)
  };
}
