import React, { useState, useEffect, useRef } from 'react';
import {
  ActionButtonWrapper,
  DescTitle,
  FilterSearch,
  FilterSearchWrap,
  InitialDownloadContent,
  LeftContentWrap,
  MainContent,
  MainTitleWrap,
  RadioOption,
  TextItem,
  TopContent
} from './StepThreeStyle';
import { useAppStore } from 'stores/appStore';
import { Title, TitleDescription } from 'components/atoms';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineRounded from '@mui/icons-material/ErrorOutlineRounded';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import {
  getInvalidToken,
  getNewDataNonMaster,
  getProductListing,
  getStoreLocation,
  getVariationsList,
  postProductList,
  postRetryDownload,
  redownloadAllEmptySkus,
  setWizardSetup
} from 'api/masterStore';
import { LiteGrid } from 'components/LiteGrid/LiteGrid';
import { Column, RowButton } from 'components/LiteGrid/DataGrid.types';
import { QueryParameters } from 'lib/types';
import { showAlert } from 'components/Alert';
import { Image } from 'components/Image/Image';
import { Grid, InputAdornment, TextField } from '@mui/material';
import { DeleteSVG, SearchSVG } from 'assets/img';
import { AsyncButton } from 'components/ButtonNew/Button';
import { ModalAction } from 'components/ModalAction/ModalAction';
import { useForm } from 'react-hook-form';
import FilterBar from 'components/FilterBar/FilterBar';
import LightTooltip from 'components/LightTooltip';
import Box from '@mui/material/Box';
import LineProgress from 'components/LineProgress';
import SelectButton from 'components/SelectButton/SelectButton';

interface FormValues {
  q: string;
}

interface OtherFilterProps {
  status?: string | null;
  type?: string | null;
}

const InitialDownload = (props: any) => {
  const query = useRef<QueryParameters>();
  const refresher = useRef<() => void>();
  const setQuery = useRef<(e: QueryParameters) => QueryParameters>();
  const { setCompleteValidation } = props;
  const [totalDownloaded, setTotalDownloaded] = useState(0);
  const [totalProduct, setTotalProduct] = useState(0);
  const [busy, setBusy] = useState(true);
  const [deletingItem, setDeletingItem] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [sku, setSku] = useState('all');
  const [statusDownload, setStatusDownload] = useState('all');
  const [detailStore, setDetailStore] = useState<any>({});
  const [isTableOpen, setTableOpen] = useState(false);
  const [downloadState, setDownloadState] = useState({});
  const [downloadPercentage, setDownloadPercentage] = useState(0);
  const [isError, setIsError] = useState(false);
  const isMounted = useRef<boolean>(true);
  const {
    setDisablePrevButton,
    setValidationComplete,
    setChannelIdOto,
    cantSkip,
    setActiveStep,
    integratedChannels,
    setIntegratedChannels
  } = useAppStore();
  const { register, handleSubmit, setValue } = useForm<FormValues>({
    defaultValues: {
      q: ''
    }
  });

  const handleChangeStatusDownload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatusDownload((event.target as HTMLInputElement).value);
  };
  const handleChangeSKU = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSku((e.target as HTMLInputElement).value);
  };

  const getStoreDetail = async (storeId) => {
    try {
      const res = await getStoreLocation(storeId);
      setDetailStore(res.data);
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const handleActionTable = async (data) => {
    try {
      setBusy(true);
      const ids = data.map((row) => row.channel_group_id);
      let payload = {
        channelId: data[0].channel_id,
        storeId: data[0].store_id,
        isMaster: data[0].is_master,
        ids
      };
      const res: any = await postRetryDownload(payload);
      if (res.status === 200) {
        refresher.current();
        setBusy(false);
        showAlert('success', 'Berhasil download ulang');
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      setBusy(false);
      showAlert('error', `${error}`, 5000);
    }
  };

  const handleDelete = async (data) => {
    const newData = [];
    for (let idx = 0; idx < data.length; idx++) {
      const o = data[idx] as any;
      let result: any = {
        indx: idx,
        id: o.channel_item_id ? o.channel_item_id : o.channel_group_id,
        label: `${o.item_name}`,
        channelId: o.channel_id,
        storeId: o.store_id,
        isMaster: false,
        busy: null,
        success: null,
        isVariant: true,
        skus: [
          {
            item_code: o.item_code
          }
        ]
      };

      if (o.is_error) {
        const variations = await getVariationsList(o.channel_id, o.store_id, o.channel_group_id);
        result.skus = variations.data.data[0].skus;
        result.isVariant = false;
        result.id = o.channel_group_id;
      }

      newData.push(result);
    }

    setDeletingItem(newData);
    setDeleteConfirmation(true);
  };


  const getOnBoardingSetup = async () : Promise<number> => {
    try {
      const res = await setWizardSetup();
      const setupData = res?.data?.setup;
      const masterSelected = setupData.master_channel_id;
      return masterSelected;
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const getIntegratedChannels = async () => {
    try {
      const res = await getNewDataNonMaster();
      const masterStoreId = await getOnBoardingSetup();

      const excludeMaster = res?.data?.data?.filter((val) => Number(val.store_id) !== masterStoreId && val.channel_id !== 1);
      let newRes = excludeMaster.map((val) => ({
        store_name: val.store_name,
        store_id: val.store_id,
        channel_id: val.channel_id,
        is_downloaded: val.is_downloaded,
        total_downloaded: val.total_downloaded,
        all_product: val.all_product,
        on_download_process: val.on_download_process
      }));
      setIntegratedChannels(newRes);
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      setDisablePrevButton(true);
      getIntegratedChannels();
      isMounted.current = false;
    }
  }, []);

  const renderProduct = (
    _cellData: undefined,
    rD: { img_url: string; item_name: string; item_code: string; is_bundle: boolean; item_id: string }
  ) => {
    const name = rD.item_name.length > 30 ? `${rD.item_name.slice(0, 30)}...` : rD.item_name;
    return (
      <div className="d-flex align-items-center font-size-table">
        <Image src={rD.img_url} height={35} width={35} className="border 1 border-light-gray rounded-sm mt-1 mb-1" />
        <div className="d-flex flex-column ml-3">
          <span className="text-nowrap">{name}</span>
          <span className="font-weight-normal">{rD.item_code}</span>
        </div>
      </div>
    );
  };

  const renderProductMaster = (
    _cellData: undefined,
    rD: { img_url: string; master_item_name: string; master_item_code: string; is_bundle: boolean; item_id: string }
  ) => {
    const name = rD.master_item_name?.length > 30 ? `${rD.master_item_name?.slice(0, 30)}...` : rD?.master_item_name;
    return (
      <>
        {rD.master_item_code !== null ? (
          <div className="d-flex align-items-center font-size-table">
            <Image
              src={rD.img_url}
              height={35}
              width={35}
              className="border 1 border-light-gray rounded-sm mt-1 mb-1"
            />
            <div className="d-flex flex-column ml-3">
              <span className="text-nowrap">{name}</span>
              <span className="font-weight-normal">{rD.master_item_code}</span>
            </div>
          </div>
        ) : (
          '-'
        )}
      </>
    );
  };

  const columns: Column[] = [
    {
      header: 'Produk',
      binding: 'item_name',
      format: 'string',
      allowSorting: true,
      freezeCol: true,
      template: renderProduct
    },
    {
      header: 'Status',
      headerClassName: 'text-center text-second text-nowrap',
      width: 240,
      allowSorting: false,
      binding: 'status',
      template: (row, data) => {
        const renderProgress = () => {
          if (data.status === 'failed') {
            return (
              <LightTooltip title={data.error_message} placement="bottom" arrow>
                <ErrorOutlineRounded fontSize="medium" className="text-danger" />
              </LightTooltip>
            );
          } else if (data.status === 'downloaded') {
            return <CheckCircleOutlineIcon fontSize="medium" style={{ color: '#4CAF50' }} />;
          } else {
            return <WarningAmberIcon fontSize="medium" style={{ color: 'rgb(255, 152, 0)' }} />;
          }
        };
        return <div className="text-center py-2">{renderProgress()}</div>;
      }
    },
    {
      header: 'Produk Master',
      binding: 'master_item_name',
      format: 'string',
      allowSorting: true,
      freezeCol: false,
      template: renderProductMaster
    },
    {
      header: 'Tindakan',
      binding: 'channel_item_id',
      headerClassName: 'text-center text-second text-nowrap',
      className: 'font-size-table text-center',
      allowSorting: false,
      template: (row, data, isChecked) => {
        const shouldRefresh = isChecked || data?.status === 'waiting';

        return (
          <RefreshIcon
            color={shouldRefresh ? 'disabled' : 'primary'}
            style={shouldRefresh ? {} : { cursor: 'pointer' }}
            onClick={() => {
              if (!shouldRefresh) {
                handleActionTable([data]);
              }
            }}
          />
        );
      }
    },
    {
      header: '',
      binding: '',
      template: (row, data, isChecked) => {
        const renderDelete = () => {
          if (isChecked) {
            return <DeleteSVG className="disabled" />;
          } else if (data.status !== 'waiting') {
            return <DeleteSVG className="text-danger" onClick={() => handleDelete([data])} />;
          } else if (isChecked || data?.status === 'waiting') {
            return <DeleteSVG className="disabled" />;
          }
        };
        return (
          <div className="text-center py-2" style={{ cursor: 'pointer' }}>
            {renderDelete()}
          </div>
        );
      }
    }
  ];

  const getQuery = (q: QueryParameters) => {
    query.current = q;
  };

  const getSetQuery = (fn) => {
    setQuery.current = fn;
  };

  const validationCheck = async (state) => {
    const currentStore = detailStore.store_id;
    if (state === 'finished') {
      setCompleteValidation('DOWNLOADED', true);
    } else {
      setCompleteValidation('DOWNLOADED', false);
    }

    const getEmptySku = await getProductListing(currentStore, { page: 1, pageSize: 10, type: 1, q: '' });
    const response = getEmptySku.data.data;
    const emptySkusFound = Number(response.count) > 0;
    const currentStoreState = integratedChannels.find((val) => val.store_id === currentStore)

    if ((!emptySkusFound && state === 'finished') || currentStoreState.is_downloaded) {
      setCompleteValidation('VALID_SKU', true);
      setValidationComplete(true);
    } else {
      setCompleteValidation('VALID_SKU', false);
      setValidationComplete(false);
    }

    const getDuplicateSku = await getProductListing(currentStore, { page: 1, pageSize: 10, type: 3, q: '' });
    const responseDuplicate = getDuplicateSku.data.data;
    const duplicateSkuFound = Number(responseDuplicate.count) === 0;
    if (duplicateSkuFound) {
      setCompleteValidation('DUPLICATE_SKU', true);
    } else {
      setCompleteValidation('DUPLICATE_SKU', false);
    }
  };

  const onCheckInvalidToken = async (storeId) => {
    const res = await getInvalidToken(storeId);
    if (res.data[0].invalid_token) {
      setIsError(res.data[0].invalid_token);
    } else {
      return;
    }
  };

  const getFetcher = async (options: QueryParameters & OtherFilterProps) => {
    const res = await getProductListing(detailStore.store_id, options, 'non_master');
    const responseData = res?.data;
    setTotalProduct(Number(responseData?.data?.count));
    setTotalDownloaded(Math.floor(Number(responseData?.data?.count) * (responseData?.data?.percent / 100)));
    const isSearching = query.current.q !== '' || (options?.type !== '' && typeof options?.type !== 'undefined');
    const downloadStatus =
      responseData?.data.count > 0 && responseData?.data?.percent > 0 && responseData?.state === 'idle'
        ? 'finished'
        : responseData?.state;
    const openTable =
      downloadStatus === 'counting' || downloadStatus === 'downloading' || downloadStatus === 'finished' || isSearching;
    setDownloadPercentage(responseData?.data?.percent);
    setTableOpen(openTable);
    setDownloadState(downloadStatus);
    await validationCheck(downloadStatus);
    const data = {
      ...res,
      data: res?.data?.data?.products || [],
      totalCount: res?.data?.data?.count || '0'
    };

    if (downloadStatus === 'idle' || downloadStatus === 'finished') {
      onCheckInvalidToken(detailStore.store_id ?? props.data);
    }

    return data;
  };

  const fetcher = React.useMemo(() => {
    return (options: QueryParameters) => {
      return getFetcher(options);
    };
  }, [detailStore]);

  function handleRowAction(item: any, binding, index) {}

  const isRowLocked = (row) => {
    if (row.status === 'waiting') {
      return true;
    } else {
      return false;
    }
  };

  const rowButton: RowButton<any>[] = [
    {
      text: 'Hapus',
      icon: <DeleteSVG />,
      iconColor: '#DF4D4D',
      format: 'button',
      action: handleDelete,
      onlyOnSelect: true
    },
    {
      text: 'Download Ulang',
      icon: <RefreshIcon />,
      iconColor: '#4B68EF',
      iconHeaderWithText: true,
      format: 'button',
      action: handleActionTable,
      onlyOnSelect: true
    }
  ];

  const handleReset = async () => {
    const newQuery: QueryParameters & OtherFilterProps = {
      ...query.current,
      q: '',
      page: 1
    };

    newQuery.status = '';
    newQuery.type = '';

    setValue('q', '');
    setStatusDownload('all');
    setSku('all');

    setQuery.current(newQuery);
  };

  const handleSubmitSearch = async (formvalue) => {
    const newQuery: QueryParameters & OtherFilterProps = {
      ...query.current,
      q: formvalue.q,
      page: 1
    };

    newQuery.type = sku === 'all' ? '' : sku;
    newQuery.status = statusDownload === 'all' ? '' : statusDownload;

    setQuery.current(newQuery);
  };

  const handleDownloadAll = async () => {
    try {
      setTableOpen(true);
      const res = await postProductList(detailStore.channel_id, detailStore.store_id, false, false);
      if (res.data.status === 'ok') {
        showAlert('success', `${res.data.message}`, 5000);
        refresher.current();
      }
    } catch (err) {
      const error = err as Error;
      showAlert('error', `${error}`, 5000);
    }
  };

  const handleDownloadEmptySku = async () => {
    try {
      const storeId = detailStore.store_id;
      const res: any = await redownloadAllEmptySkus(storeId, detailStore.channel_id, false);
      if (res.data.status === 'ok') {
        showAlert('success', `${res.data.message}`, 5000);
        refresher.current();
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const onDeleteClose = async () => {
    setDeleteConfirmation(false);
    refresher.current();
  };

  const handleSelectedStore = async (selectedStore) => {
    handleReset();
    await getStoreDetail(selectedStore.store_id);
    setBusy(false);
    setTableOpen(true);
  };

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (isTableOpen && (downloadState === 'downloading' || downloadState === 'counting')) {
      interval = setInterval(() => {
        getIntegratedChannels();
        refresher.current();
      }, 10000);
    }

    return () => clearInterval(interval);
  }, [isTableOpen, downloadState]);

  const handleOtoriation = () => {
    setChannelIdOto(detailStore.channel_id);
    setDisablePrevButton(true);
    cantSkip();
    setActiveStep(2);
  };

  const downloadButtonStatus = busy || downloadState === 'downloading' || downloadState === 'counting';
  const titleText = downloadState === 'downloading' || downloadState === 'counting' ? 'Proses' : 'Selesai';

  const downloadOptions = [
    {
      label: 'Download Semua Produk',
      handler: () => handleDownloadAll()
    },
    {
      label: 'Download Produk SKU Kosong',
      handler: () => handleDownloadEmptySku()
    }
  ];

  return (
    <div>
      <InitialDownloadContent>
        <TopContent>
          <FilterSearchWrap>
            <p className="mr-4">Download produk dari toko</p>
            <div style={{ width: '300px' }}>
              <FilterBar
                totalDownloaded={totalDownloaded}
                totalProduct={totalProduct}
                stores={integratedChannels}
                handleSelectedStore={handleSelectedStore}
              />
            </div>
            {isError && (
              <LightTooltip
                title={
                  <span>
                    Toko ini perlu otorisasi ulang.{' '}
                    <span className="text-link" onClick={handleOtoriation}>
                      Klik disini
                    </span>{' '}
                    untuk melakukan otorisasi ulang toko
                  </span>
                }
                placement="bottom"
                arrow
              >
                <ErrorOutlineRounded fontSize="medium" className="text-danger ml-2" />
              </LightTooltip>
            )}
          </FilterSearchWrap>
          <ActionButtonWrapper>
            <AsyncButton
              onClick={async () => refresher.current()}
              variant="outlined"
              type="button"
              hidden={!isTableOpen}
              color="inherit"
              className="px-4"
            >
              Refresh
            </AsyncButton>
            <SelectButton label={'Download'} busy={downloadButtonStatus} options={downloadOptions} />
          </ActionButtonWrapper>
        </TopContent>
        {(downloadState === 'finished' || isTableOpen) && (
          <MainContent>
            <MainTitleWrap>
              <div>
                <Title>
                  {titleText} Mendownload Produk dari {detailStore?.store_name}
                </Title>
                {busy ? (
                  <TitleDescription>{`${totalProduct} produk`}</TitleDescription>
                ) : (
                  <TitleDescription>{`${totalDownloaded} dari ${totalProduct}`}</TitleDescription>
                )}
              </div>
            </MainTitleWrap>
          </MainContent>
        )}
      </InitialDownloadContent>

      {(downloadState === 'finished' || isTableOpen) && (
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Box sx={{ width: '100%' }}>
              <LineProgress value={downloadPercentage} />
            </Box>
          </Grid>
          <Grid item md={3}>
            <LeftContentWrap>
              <form onSubmit={handleSubmit(handleSubmitSearch)}>
                <FilterSearch>
                  <FilterSearchWrap>
                    <p className="m-0">Filter</p>
                    <AsyncButton
                      color="inherit"
                      type="button"
                      className="text-danger px-0 hover-none"
                      onClick={handleReset}
                    >
                      Reset
                    </AsyncButton>
                  </FilterSearchWrap>
                  <TextField
                    disabled={busy}
                    variant="outlined"
                    size="small"
                    className="bg-white rounded-sm no-notch mb-3"
                    placeholder="Cari Produk, SKU Produk"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchSVG />
                        </InputAdornment>
                      )
                    }}
                    {...register('q')}
                  />
                </FilterSearch>
                <RadioOption>
                  <DescTitle>Status Download</DescTitle>
                  <FormControl>
                    <RadioGroup value={statusDownload} onChange={handleChangeStatusDownload}>
                      <FormControlLabel
                        disabled={busy}
                        value="all"
                        className="mb-1"
                        control={<Radio sx={{ padding: '2px', marginLeft: '10px' }} size="small" />}
                        label={<TextItem>Semua</TextItem>}
                      />
                      <FormControlLabel
                        disabled={busy}
                        value="failed"
                        className="mb-1"
                        control={<Radio sx={{ padding: '2px', marginLeft: '10px' }} size="small" />}
                        label={<TextItem>Gagal Download</TextItem>}
                      />
                    </RadioGroup>
                  </FormControl>
                </RadioOption>
                <RadioOption>
                  <DescTitle>Kelengkapan SKU Produk</DescTitle>
                  <FormControl>
                    <RadioGroup value={sku} onChange={handleChangeSKU}>
                      <FormControlLabel
                        disabled={busy}
                        value="all"
                        className="mb-1"
                        control={<Radio sx={{ padding: '2px', marginLeft: '10px' }} size="small" />}
                        label={<TextItem>Semua Produk</TextItem>}
                      />
                      <FormControlLabel
                        disabled={busy}
                        value="2"
                        className="mb-1"
                        control={<Radio sx={{ padding: '2px', marginLeft: '10px' }} size="small" />}
                        label={<TextItem>Produk dengan SKU Produk</TextItem>}
                      />
                      <FormControlLabel
                        disabled={busy}
                        value="1"
                        className="mb-1"
                        control={<Radio sx={{ padding: '2px', marginLeft: '10px' }} size="small" />}
                        label={<TextItem>Tidak ada SKU Produk</TextItem>}
                      />
                      <FormControlLabel
                        disabled={busy}
                        value="3"
                        className="mb-1"
                        control={<Radio sx={{ padding: '2px', marginLeft: '10px' }} size="small" />}
                        label={<TextItem>SKU Duplikat</TextItem>}
                      />
                    </RadioGroup>
                  </FormControl>
                </RadioOption>
                <div className="mt-3">
                  <AsyncButton fullWidth type="submit" color="primary" disabled={busy}>
                    Terapkan
                  </AsyncButton>
                </div>
              </form>
            </LeftContentWrap>
          </Grid>
          <Grid item md={9}>
            <LiteGrid
              flexibleWidth={true}
              columns={columns}
              data={fetcher}
              showRowSelect
              getRefresher={(e) => (refresher.current = e)}
              rowButton={rowButton}
              getQuery={getQuery}
              getSetQuery={getSetQuery}
              onRowAction={handleRowAction}
              isRowLocked={isRowLocked}
              gridName="initial_download_grid"
              customHeight="calc(100vh - 327px)"
              isEmpty={
                statusDownload === 'all' && downloadState === 'idle'
                  ? 'Produk yang belum ada disini akan di refresh oleh sistem per 10 detik atau silakan klik refresh untuk meloading data yang sudah terdownload.'
                  : ''
              }
            />
          </Grid>
        </Grid>
      )}
      {/* @TODO: enable after store selected * */}
      <ModalAction
        title="Hapus"
        method="POST"
        urlApi={'product/delete-onboarding-products'}
        btnSubmitColor="secondary"
        mappingRequest={(data) => ({ ids: [data.channel_group_id] })}
        data={deletingItem}
        onClose={onDeleteClose}
        isShow={deleteConfirmation}
      />
    </div>
  );
};

export default InitialDownload;
