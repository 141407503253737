import React from 'react';

export const StoreSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.70329 1.01534C1.83795 0.634475 2.24076 0.425208 2.62468 0.524616C2.68652 0.50867 2.75136 0.500189 2.81818 0.500189H15.0352C15.6925 0.500189 16.278 0.916037 16.4944 1.53674L17.4452 4.26316C17.4815 4.36714 17.5 4.47647 17.5 4.5866V4.77661C17.5 5.51018 17.2051 6.17486 16.7273 6.65854V15.9546C16.7273 16.8081 16.0353 17.5 15.1818 17.5H2.81818C1.96465 17.5 1.27273 16.8081 1.27273 15.9546V6.64293C0.794723 6.15514 0.5 5.48707 0.5 4.75016V4.41855L1.70329 1.01534ZM7.45455 15.9546H10.5455V12.091H7.45455V15.9546ZM12.0909 15.9546V11.5758C12.0909 11.0068 11.6296 10.5455 11.0606 10.5455H6.93939C6.37037 10.5455 5.90909 11.0068 5.90909 11.5758V15.9546H2.81818V7.42729C2.94437 7.44534 3.07337 7.45468 3.20455 7.45468C3.96132 7.45468 4.64549 7.14386 5.13637 6.64292C5.62726 7.14385 6.31142 7.45466 7.06818 7.45466C7.82495 7.45466 8.50912 7.14384 9 6.64291C9.49088 7.14384 10.1751 7.45466 10.9318 7.45466C11.6983 7.45466 12.3904 7.13578 12.8825 6.62344C13.3703 7.13552 14.0588 7.45466 14.8219 7.45466C14.944 7.45466 15.0641 7.44649 15.1818 7.43068V15.9546H12.0909ZM8.22727 4.36378V4.75014C8.22727 5.39028 7.70833 5.90922 7.06818 5.90922C6.42803 5.90922 5.90909 5.39028 5.90909 4.75014V4.36378C5.90909 3.93702 5.56313 3.59106 5.13636 3.59106C4.7096 3.59106 4.36364 3.93702 4.36364 4.36378V4.75014C4.36364 5.39028 3.84469 5.90924 3.20455 5.90924C2.5644 5.90924 2.04545 5.3903 2.04545 4.75016V4.68371L2.97822 2.04563L15.0352 2.04563L15.9545 4.68198V4.77661C15.9545 5.40213 15.4474 5.90922 14.8219 5.90922C14.1964 5.90922 13.6893 5.40213 13.6893 4.77661V4.55111C13.6893 4.44376 13.6674 4.34153 13.6278 4.24862C13.5722 3.87648 13.2513 3.59106 12.8636 3.59106C12.4369 3.59106 12.0909 3.93702 12.0909 4.36378V4.75014C12.0909 5.39028 11.572 5.90922 10.9318 5.90922C10.2917 5.90922 9.77273 5.39028 9.77273 4.75014V4.36378C9.77273 3.93702 9.42676 3.59106 9 3.59106C8.57323 3.59106 8.22727 3.93702 8.22727 4.36378Z"
        fill="#8999A5"
      />
    </svg>
  );
};
