import * as React from 'react';
import { HelpOutline, Logout } from '@mui/icons-material';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grow,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  TextField
} from '@mui/material';
import { ProAccountSVG } from 'assets/img/pro-account';
import { CompanySVG } from 'assets/img/company-logo';
import { ArrowDownSVG, SearchSVG } from 'assets/img';
import { Image } from 'components/Image/Image';
import { ClickAwayListener } from '@mui/material';
import { Link } from 'react-router-dom';
import { useGetCompany } from 'hooks/useGetCompany';
import { useAppStore } from 'stores/appStore';
import { postAddSupport, verifyToken } from 'api/auth';
import { getProfileSettings, postSwitchCompany } from 'api/companyProfile';

import JubelioIcon from 'assets/img/jubelio/jubelio-icon.png';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import ClearIcon from '@mui/icons-material/Clear';

export default function MenuAppBar({ setLogoutConfirmation, ...props }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showProfileMenu, setShowProfileMenu] = React.useState(false);
  const [btnDropdown, setBtnDropdown] = React.useState<HTMLElement | null>(null);
  const [achorElCompany, setAchorElCompany] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState('');
  const [selectedCompany, setSelectedCompany] = React.useState<any>({});
  const [user, setUser] = React.useState<any>({
    fullName: '',
    username: '',
    packageId: 0,
    trialLeft: null,
    photoProfile: '',
    userId: '',
    company: {}
  });
  const companyId = React.useRef<any>(null);

  const { dataAccountSupport, setDataAccountSupport } = useAppStore();

  const { execute, data, busy, search, onSearchChanged } = useGetCompany();

  const getProfileImg = async () => {
    const res = await getProfileSettings();
    user.photoProfile = res.data.logo_url ?? '';
  };

  const verify = async () => {
    try {
      let currentUser: any;
      const dataVal: any = await verifyToken();
      if (dataVal?.data != null) {
        user.fullName = dataVal?.data.fullName;
        user.username = dataVal?.data.userName;
        user.packageId = dataVal?.data.packageId;
        user.trialLeft = dataVal?.data.trialLeft;
        user.email = dataVal?.data.email;
        currentUser = {
          userName: dataVal?.data.userName,
          fullName: dataVal?.data.fullName,
          companies: dataVal?.data.companies,
          acls: dataVal?.data.acls,
          roles: dataVal?.data.roles,
          currentLocation: dataVal?.data.currentLocation,
          currentLocationName: dataVal?.data.currentLocationName,
          location_ids: dataVal?.data.location_ids,
          location_names: dataVal?.data.location_names,
          currentCompany: dataVal?.data.currentCompany,
          packageId: dataVal?.data.packageId,
          picking_type: dataVal?.data.picking_type,
          picking_type_name: dataVal?.data.picking_type_name,
          isOwner: dataVal?.data.isOwner,
          enableFulfilment: dataVal?.data.enableFulfilment,
          loadLimitedData: dataVal?.data.loadLimitedData,
          showOnboardingWizard: dataVal?.data.showOnboardingWizard,
          trialLeft: dataVal?.data.trialLeft,
          subscription: dataVal?.data.subscription,
          restore_id: dataVal?.data.restore_id
        };
        setUser(user);
        companyId.current = currentUser.companies[0].company_id;
        getProfileImg();
        execute(dataVal?.data.companies[0].user_id);
        setSelectedCompany({
          label: dataVal?.data.companies[0].company_name,
          value: dataVal?.data.companies[0].company_id
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    setBtnDropdown(null);
    setAnchorEl(null);
  }, [selectedCompany.value]);

  React.useEffect(() => {
    verify();
  }, []);

  const openProfile = (e: React.MouseEvent) => {
    if (e && e.currentTarget) {
      setAnchorEl(e.currentTarget);
      setShowProfileMenu(Boolean(e.currentTarget));
    } else {
      setShowProfileMenu(false);
    }
  };

  const switchCompany = async (id) => {
    const res = await postSwitchCompany(id);
    if (res.data.status === 'ok') {
      window.location.reload();
    }
  };

  const handleChangeChecked = async (e) => {
    let body = {
      email: 'onboarding.support@jubelio.com',
      fullName: 'Support Onboarding',
      password: '????',
      nik: '',
      roles: [1],
      settingAcls: [1, 6, 7],
      itemAcls: [100],
      inventoryAcls: [200],
      salesAcls: [300],
      purchaseAcls: [400],
      cashbankAcls: [500],
      reportAcls: [700],
      othersAcls: [610, 620, 630, 640],
      warehouseItemInAcls: [],
      warehouseOrderAcls: [],
      warehouseItemOutAcls: [],
      warehouseStockAdjustmentAcls: [],
      locations: [],
      posAcls: []
    };
    const res = await postAddSupport(body);
    if (res) {
      setDataAccountSupport({
        dataAccountSupport,
        isChecked: true
      });
      window.location.reload();
    }
  };

  return (
    <>
      <div
        className="bg-theme-white fixed-top flex-column navbar-light position-fixed"
        style={{ boxShadow: '0px 13px 19px rgba(0, 0, 0, 0.07)', zIndex: 999 }}
      >
        <div className="d-flex justify-content-between align-items-center pr-lg-5 pl-lg-5 pr-3 pl-3 border-bottom">
          <div className="d-flex align-items-center">
            <div className="app-nav-logo flex-grow-0">
              <div title="Jubelio" className="app-nav-logo app-nav-logo--dark">
                <div className="app-nav-logo--icon bg-transparent">
                  <img alt="Jubelio" width={80} src={JubelioIcon} />
                </div>
              </div>
            </div>
          </div>

          <div className="position-relative pl-3">
            <span className="h-100"></span>
            <div className="cursor-pointer d-flex text-nowrap align-items-center" onClick={openProfile}>
              <Image
                src={user.photoProfile}
                className="border-1 border-light-gray mr-2 object-contain rounded-circle"
                height={53}
                width={53}
              />
              <ArrowDownSVG />
            </div>
          </div>
        </div>
      </div>

      <div style={{ zIndex: 9999, width: 303, position: 'fixed', right: 50, top: 40 }}>
        <Popper
          open={showProfileMenu}
          anchorEl={anchorEl}
          role={undefined}
          transition
          style={{ zIndex: 25 }}
          disablePortal
          className="dropdown-profile"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={() => {
                    if (!btnDropdown) {
                      openProfile(null);
                    }
                  }}
                >
                  <div className="mt-2">
                    <>
                      <div className="pt-3 pr-4 pl-4 pb-2 border-bottom">
                        <div className="d-flex">
                          <Image
                            src={user.photoProfile ?? JubelioIcon}
                            className="border-1 border-light-gray mr-2 object-contain rounded-circle"
                            height={46}
                            width={46}
                          />
                          <div>
                            <h5 className="font-weight-bold text-capitalize mb-0" style={{ fontSize: 12 }}>
                              {user.fullName}
                            </h5>
                            <span className="text-light-gray" style={{ fontSize: 12 }}>
                              {user.email}
                            </span>
                            <div className="mb-2">
                              <ProAccountSVG />{' '}
                              <span className="text-light-gray ml-1" style={{ fontSize: 12 }}>
                                Paid Account
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="pt-2 pr-4 pl-4 cursor-pointer" role="button">
                        <div className="d-flex align-items-center">
                          <CompanySVG width={18} height={18} className="mr-2" />{' '}
                          <span style={{ fontSize: 12 }} className="text-black">
                            {selectedCompany.label}
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <Link
                            to="#"
                            onClick={(e: React.MouseEvent<HTMLElement>) => {
                              setBtnDropdown(e.currentTarget);
                              setAchorElCompany(e.currentTarget);
                            }}
                          >
                            <div className="pb-2 pt-2 pr-4 pl-4 cursor-pointer">
                              <SwapHorizOutlinedIcon style={{ fontSize: 'small' }} />
                              <span style={{ fontSize: 10 }}>Ganti Perusahaan</span>
                            </div>
                          </Link>
                        </div>
                      </div>

                      <FormGroup className="pr-4 pl-4">
                        <FormControlLabel
                          control={
                            <Checkbox
                              readOnly={dataAccountSupport.isChecked}
                              checked={dataAccountSupport.isChecked}
                              onChange={!dataAccountSupport.isChecked ? handleChangeChecked : null}
                              size="small"
                              color="success"
                            />
                          }
                          label={<span style={{ fontSize: '12px' }}>Aktifkan Akun Support</span>}
                        />
                      </FormGroup>

                      <a
                        href="https://wa.me/6285215839386"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: 'inherit' }}
                      >
                        <div className="pb-3 pt-2 pr-4 pl-4 cursor-pointer">
                          <HelpOutline sx={{ fontSize: '20px' }} className="mr-1" />{' '}
                          <span style={{ fontSize: 12 }} className="text-black">
                            Bantuan
                          </span>
                        </div>
                      </a>

                      <div
                        className="text-danger pb-3 pt-2 pr-4 pl-4 cursor-pointer border-top"
                        onClick={() => setLogoutConfirmation(true)}
                      >
                        <Logout sx={{ fontSize: '20px' }} className="mr-1" />{' '}
                        <span style={{ fontSize: 12 }}>Logout</span>
                      </div>
                    </>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

        <Popper
          open={Boolean(btnDropdown)}
          anchorEl={achorElCompany}
          role={undefined}
          transition
          placement="left-start"
          style={{ zIndex: 999, minWidth: 200, right: 20 }}
          modifiers={[
            {
              name: 'offset',
              enabled: true,
              options: {
                offset: [0, 22]
              }
            }
          ]}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom'
              }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={() => {
                    setBtnDropdown(null);
                  }}
                >
                  <div className="channel-filter p-3" style={{ width: 264, maxWidth: 264, boxShadow: 'none' }}>
                    <div className="search-input pb-2">
                      <div className="icon">
                        <SearchSVG height={18} width={18} />
                      </div>
                      <TextField
                        type="text"
                        value={search}
                        className="no-notch"
                        placeholder="Cari channel"
                        onChange={(e) => onSearchChanged(e.target.value)}
                        InputProps={
                          searchValue.length > 0 && {
                            endAdornment: (
                              <IconButton size="small" onClick={() => setSearchValue('')}>
                                <ClearIcon />
                              </IconButton>
                            )
                          }
                        }
                      />
                    </div>
                    <List
                      sx={{
                        width: '100%',
                        maxWidth: 360,
                        bgcolor: 'background.paper',
                        maxHeight: 270,
                        minHeight: 270,
                        overflowY: 'auto'
                      }}
                      component="nav"
                    >
                      {data.map((option, idx) => (
                        <ListItemButton
                          key={idx}
                          sx={{
                            padding: '4px',
                            borderRadius: '4px',
                            '&.Mui-selected': {
                              color: '#4b68ef'
                            }
                          }}
                          selected={option.value === selectedCompany.value}
                          onClick={() => {
                            if (option.value !== selectedCompany.value) {
                              setSelectedCompany(option);
                              switchCompany(option.value);
                            }
                          }}
                        >
                          {busy ? (
                            <ListItemText primary="harap tunggu..." />
                          ) : (
                            <>
                              <ListItemIcon style={{ minWidth: 30 }}>
                                <CompanySVG width={24} height={24} />
                              </ListItemIcon>
                              <ListItemText primary={option.label} />
                            </>
                          )}
                        </ListItemButton>
                      ))}
                    </List>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </>
  );
}
