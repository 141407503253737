import React, { useEffect, useState } from 'react';
import { PagePropsSetter } from '../StepSeven.types';
import { Alert, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';

import { createStyles, makeStyles } from '@mui/styles';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { channelDetail } from 'shared/constants';
import { ChannelDetail } from 'interfaces/Channels';
import { Image } from 'components/Image/Image';
import { getListMappingLocation, getLocationList, postMappingLocation } from 'api/locations';
import { QueryParameters } from 'lib/types';
import { OptionData } from 'lib/types';
import { showAlert } from 'components/Alert';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const useStyles = makeStyles(() =>
  createStyles({
    font14: {
      fontSize: '14px'
    },
    font12: {
      fontSize: '12px'
    },
    cardList: {
      background: '#FFFFFF',
      border: '1px solid #EBE9E9',
      boxShadow: '0px 2px 10px rgba(228, 226, 226, 0.36)',
      borderRadius: '15px',
      paddingLeft: '20px',
      paddingRight: '20px'
    }
  })
);

interface LocationInterface {
  location_id: number;
  location_name: string;
}

interface Allocation {
  location: any[];
}

const defaultAllocation: Allocation = {
  location: []
};

const AccountingList: React.FunctionComponent<PagePropsSetter> = ({ getSave, pageSetter }) => {
  const classes = useStyles();
  const [stockAllocation, setStockAllocation] = useState([]);
  const [locationAllocation, setLocationAllocation] = useState(defaultAllocation);

  const getAllocation = async () => {
    const res = await getListMappingLocation();
    setLocationAllocation({ location: res.data });
    setStockAllocation(res.data);
  };

  const handleSubmitForm = async () => {
    try {
      return true;
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  React.useEffect(() => {
    getSave(handleSubmitForm);
  }, [stockAllocation]);

  const handleSubmitLocationSwitch = async (e, store_id, key) => {
    setStockAllocation((prev) => {
      const newAllocation = [];
      prev.map((item) => {
        if (item.store_id === store_id) {
          if (e === 'false') {
            return newAllocation.push({
              ...item,
              location_code: null,
              location_id: null,
              location_name: null
            });
          } else {
            return newAllocation.push({
              ...item,
              location_code: null,
              location_id: true,
              location_name: null
            });
          }
        }
        return newAllocation.push(item);
      });
      return newAllocation;
    });
  };

  const formatLocationOptions = (location: LocationInterface) => {
    return {
      value: location.location_id,
      label: location.location_name
    } as unknown as OptionData<LocationInterface>;
  };

  const getLocation = async (options: QueryParameters) => {
    options.sortBy = 'location_id';
    options.pageSize = 200;
    const res = await getLocationList(options);
    let newRes = [];
    res?.data?.data?.map((r) => {
      if (r.location_id !== 0 && r.is_active) {
        newRes.push({
          location_id: r.location_id,
          location_name: r.location_name
        });
      }
    });
    const data = {
      data: newRes || [],
      totalCount: res?.data?.data?.totalCount || '0'
    };
    return data;
  };

  const handleStockTypeChange = (value, storeId, key) => {
    handleSubmitLocationSwitch(value, storeId, key);
  };

  const handleLocationChange = async ({ key, data }) => {
    try {
      const allocation = [...stockAllocation];
      allocation[key] = {
        ...allocation[key],
        location_id: data?.location_id || null,
        location_name: data?.location_name || null
      };
      let payload = {
        locationId: allocation[key]?.location_id || null,
        storeId: allocation[key]?.store_id || null
      };
      setStockAllocation(allocation);
      const res: any = await postMappingLocation(payload);
      if (res.status === 200) {
        showAlert('success', 'Lokasi terpilih!', 5000);
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  useEffect(() => {
    getAllocation();
  }, []);

  const channels = channelDetail as ChannelDetail;

  return (
    <>
      <Grid container className="mb-3">
        <Grid item md={12}>
          <Alert
            className="d-flex align-items-center"
            severity="info"
            icon={<InfoOutlinedIcon style={{ color: '#4B68EF' }} />}
          >
            <span className={classes.font14}>
              Pilih <b>Stok Total</b> jika stok dari toko berasal dari semua gudang dan pilih <b>Stok Lokasi</b> jika
              stok dari toko hanya berasal dari lokasi gudang tertentu.
            </span>
          </Alert>
        </Grid>
      </Grid>

      {locationAllocation &&
        stockAllocation.map((val, i) => (
          <Grid container className={`align-items-center mb-2 ${classes.cardList}`} key={i}>
            <Grid item md={4} className="py-4">
              <Image height={20} width={20} src={channels[val.channel_id].channelLogo} className="mr-1" />
              <span className={`${classes.font14} font-weight-bold`}>{val?.store_name}</span>
            </Grid>
            <Grid item md={4}>
              <FormControl>
                <RadioGroup
                  row
                  className="d-flex justify-content-end"
                  value={Boolean(val.location_id)}
                  onChange={(e) => handleStockTypeChange(e.currentTarget.value, val.store_id, i)}
                >
                  <FormControlLabel
                    value={false}
                    className="mb-1"
                    control={<Radio sx={{ padding: '2px', marginLeft: '10px' }} size="small" />}
                    label={<span className="font-size-sm">Stok Total</span>}
                  />
                  <FormControlLabel
                    value={true}
                    className="mb-1"
                    control={<Radio sx={{ padding: '2px', marginLeft: '10px' }} size="small" />}
                    label={<span className="font-size-sm">Stok Lokasi</span>}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item md={1} />
            <Grid item md={3} className="d-flex justify-content-end">
              {val?.location_id && (
                <Dropdown
                  label=""
                  value={{ location_id: val.location_id, location_name: val.location_name }}
                  className="w-100 notch-dropdown"
                  itemMapper={formatLocationOptions}
                  data={getLocation}
                  useOnChange
                  onChange={(data) => handleLocationChange({ key: i, data })}
                />
              )}
            </Grid>
          </Grid>
        ))}
    </>
  );
};

export default AccountingList;
