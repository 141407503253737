import React from 'react';

export const ErrorActionSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 16.6667C6.32502 16.6667 3.33335 13.6751 3.33335 10.0001C3.33335 6.32508 6.32502 3.33341 10 3.33341C13.675 3.33341 16.6667 6.32508 16.6667 10.0001C16.6667 13.6751 13.675 16.6667 10 16.6667ZM10 1.66675C5.39169 1.66675 1.66669 5.39175 1.66669 10.0001C1.66669 14.6084 5.39169 18.3334 10 18.3334C14.6084 18.3334 18.3334 14.6084 18.3334 10.0001C18.3334 5.39175 14.6084 1.66675 10 1.66675ZM12.1584 6.66675L10 8.82508L7.84169 6.66675L6.66669 7.84175L8.82502 10.0001L6.66669 12.1584L7.84169 13.3334L10 11.1751L12.1584 13.3334L13.3334 12.1584L11.175 10.0001L13.3334 7.84175L12.1584 6.66675Z"
        fill="#F44336"
      />
    </svg>
  );
};
