import { useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { getListCompanies } from 'api/companyProfile';

export const useGetCompany = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const companyId = useRef(null);

  const getCompanies = async (id: string, value: string = '') => {
    companyId.current = id;
    if (companyId.current && search != companyId.current) {
      setIsLoading(true);
      let body = `pageSize=200&page=1&q=${value}`
      const res = await getListCompanies(companyId.current, body);
      const newRes = res?.data?.map((item: any) => ({
        label: item.company_name,
        value: item.company_id,
      }));
      setData(newRes);
      setIsLoading(false);
    }
  };

  const searchCompany = useRef(debounce(getCompanies, 1000));

  const onSearchChanged = (value) => {
    setSearch(value);
    searchCompany.current(companyId.current, value);
  };

  return { data, busy: isLoading, search, onSearchChanged, execute: getCompanies };
};
