import styled from 'styled-components';
interface TitleDescProps {
    bolded?: boolean
  }

export const Title = styled.p`
    font-weight: 700;
    font-size: 20px;
`;
export const TitleDescription = styled.p<TitleDescProps>`
    font-weight: ${({ bolded }) => bolded ? 700 : 400};
    font-size: 14px;
`;
export const TextLink = styled.a`
    font-weight: 400;
    color: #4B68EF;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
`;