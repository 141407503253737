import { coreAPI, onBoardingAPI } from './axiosClient';

export const setMasterChannel = (master_channel_id: number, isDownloading) => {
  return onBoardingAPI({
    method: 'POST',
    url: 'wizard/save-setup',
    data: {
      data: {
        master_channel_id,
        import_stock: false,
        is_downloading: isDownloading,
      },
    },
  });
};

export const setWizardSetup = () => {
  return onBoardingAPI({
    method: 'POST',
    url: 'wizard/get-setup',
    data: {
      columns: ['master_channel_id', 'use_accounting']
    }
  });
};

export const getStoreLocation = (id) => {
  return coreAPI({
    method: 'GET',
    url: `store-locations/${id}`,
  });
};

export const getStoreLocationDropdown = (id) => {
  return coreAPI({
    method: 'GET',
    url: `store-locations/${id}?includeMasterChannel=false`,
  });
};

export const deleteDataProduct = (data) => {
  return onBoardingAPI({
    method: 'POST',
    url: 'product/delete-onboarding-products',
    data: {
      channelId: data.channelId,
      storeId: data.storeId,
      isMaster: false,
      isVariant: data.isVariant,
      ids: data.ids
    },
  });
};

export const postProductList = (channelId: number, storeId: number, isMaster: boolean, isContinue: boolean) => {
  return onBoardingAPI({
    method: 'POST',
    url: 'product/fetch-list',
    data: { channelId, storeId, isMaster: isMaster, isContinue },
  });
};

export const getProductListing = (storeId: number | undefined, options?: any, store_type?: 'master' | 'non_master') => {
  return onBoardingAPI({
    method: 'GET',
    url: `product/fetch-list-status/${storeId}?page=${options.page}&pageSize=${options.pageSize}&q=${options.q}&status=${options.status ? options.status : ''}&type=${options.type ? options.type : ''}`,
    params: {
      store_type: store_type
    }
  });
};

export const getVariationsList = (channelId: number, storeId: number, groupIds: number[]) => {
  return onBoardingAPI({
    method: 'GET',
    url: 'product/get-variation-by-group',
    params: {
      storeId,
      channelId,
      'ids[]': groupIds
    }
  });
}


export const getDataNonMaster = (isInclude: boolean) => {
  return onBoardingAPI({
    method: 'GET',
    url: `marketplace/channel?includeMasterChannel=${isInclude}`,
  });
};

export const getNewDataNonMaster = () => {
  return onBoardingAPI({
    method: 'GET',
    url: 'product/store-onboarding-product-status',
  });
};

export const getListProductChannel = (id: number, options: any) => {
  return onBoardingAPI({
    method: 'GET',
    url: `product/channel-list/${id}?showSameSku=${options.showSameSku}&page=${options.page}&pageSize=${options.pageSize}&q=${options.q}&sortBy=${options.sortBy}&sortDirection=${options.sortDirection}`,
  });
};

export const getListProductMaster = (options: any) => {
  return onBoardingAPI({
    method: 'GET',
    url: `product/master-list-for-merge?page=${options.page}&pageSize=${options.pageSize}&q=${options.q}&sortBy=${options.sortBy}&sortDirection=${options.sortDirection}`,
  });
};

export const getMasterSKUS = (options: any) => {
  return onBoardingAPI({
    method: 'GET',
    url: `product/master-list?page=${options.page}&pageSize=${options.pageSize}&q=${options.q}&sortBy=${options.sortBy}&sortDirection=${options.sortDirection}`,
  });
};

export const postSetMaster = (id: number) => {
  return onBoardingAPI({
    method: 'POST',
    url: `product/${id}/set-master`,
  });
}

export const postMergeItem = (data: any) => {
  return onBoardingAPI({
    method: 'POST',
    url: `product/${data.idSource}/update`,
    data: {
      iocId: data.iocId,
      itemIdNew: data.itemIdNew
    }
  })
}

export const postRetryDownload = (data: object) => {
  return onBoardingAPI({
    method: 'POST',
    url: 'product/retry-download',
    data,
  });
};

export const redownloadAllEmptySkus = (storeId: number, channelId: number, isMaster: boolean) => {
  return onBoardingAPI({
    method: 'POST',
    url: 'product/redownload-empty-skus',
    data: {
      storeId, isMaster, channelId
    }
  })
}

export const getListBundle = (options: any) => {
  let newOptions: any = {
    type: options.type,
    page: options.page,
    page_size: options.pageSize,
    q: options.q
  }
  if (options.bundle_item_id) {
    newOptions.bundle_item_id = options.bundle_item_id
  }
  if (options.filter !== 'all') {
    newOptions.filter = options.filter
  }
  return onBoardingAPI({
    method: 'GET',
    url: 'product/item-lists',
    params: newOptions
  });
};

export const postSaveBundle = (data: object) => {
  return onBoardingAPI({
    method: 'POST',
    url: 'product/save-bundle',
    data,
  });
};

export const postActivatedBundle = (id: number) => {
  return onBoardingAPI({
    method: 'POST',
    url: 'product/inactive-bundle',
    data: {
      item_id: id
    },
  });
};

export const getPrepareExport = (options: any) => {
  let newOptions: any = {
    q: options.q,
    page: options.page,
    pageSize: options.pageSize,
    bundleFilter: options.bundleFilter,
    export_id: options.export_id,
    csv: true,
  }
  return coreAPI({
    method: 'GET',
    url: 'inventory/item-bundles/',
    params: newOptions
  });
};

export const getUrlExport = (id: number) => {
  let newOptions: any = {
    export_id: id
  }
  return coreAPI({
    method: 'GET',
    url: 'reports/exports/',
    params: newOptions
  });
}

export const getDownloadFileExport = (url: string) => {
  let newOptions: any = {
    url
  }
  return coreAPI({
    method: 'GET',
    url: 'reports/exports/download',
    responseType: 'blob',
    params: newOptions
  });
}

export const postSyncAllStore = (data: any) => {
  return onBoardingAPI({
    method: 'POST',
    url: 'marketplace/channel-sync-order',
    data: {
      storeId: data
    }
  });
};

export const postSyncPerStore = (data: any) => {
  return onBoardingAPI({
    method: 'POST',
    url: `marketplace/channel-sync-order/${data.storeId}`,
    data: {
      syncOrder: data.syncOrder
    }
  });
};

export const postValidateStoreStock = (id: number) => {
  return onBoardingAPI({
    method: 'POST',
    url: 'marketplace/validate-store-stock',
    data: {
      storeId: id
    }
  });
};

export const postSaveCutoff = (data: any) => {
  return onBoardingAPI({
    method: 'POST',
    url: `marketplace/update-channel-order-sync/${data.id}`,
    data: {
      extraInfo: {
        cutoffDate: data.body
      }
    }
  });
};

export const getInvalidToken = (id: number) => {
  return onBoardingAPI({
    method: 'GET',
    url: `marketplace/get-channel-order-sync?storeId=${id}`,
  });
};


export const getRecommendationSkus = (skus: string[]) => {
  const skusQueryParam = skus.map(sku => `skus[]=${sku}`).join('&');
  return onBoardingAPI({
    method: 'GET',
    url: `product/recommendation-skus?${skusQueryParam}`,
  });
};
