import React, { useState, useEffect, useRef } from 'react';
import { Box, FormControl, Typography, FormControlLabel, Grid, RadioGroup, Radio } from '@mui/material';
import { useAppStore } from 'stores/appStore';
import { useForm, SubmitHandler } from 'react-hook-form';
import { UploadImage } from 'components/Form/UploadImage';
import { Input, InputGrid } from 'components/Form';
import { getCurrentStep, updateCurrentStep } from 'api/onboardingStep';
import { saveAccounting, saveProfile, getProfileSettings } from 'api/companyProfile';
import { AxiosError } from 'axios';
import { showAlert } from 'components/Alert';
import styles from './StepOne.module.scss';
import Divider from '@mui/material/Divider';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ErrorMessage from 'components/Form/ErrorMessage/ErrorMessage';
import WarningIcon from '@mui/icons-material/Warning';
import AddressModal from './AddressModal/AddressModal';
import UseDocumentTitle from 'hooks/UseDocumentTitle';
import AddressSaved from './AddressSaved/AddressSaved';
import { AddressForm, ProfileFormData } from 'lib/types';

interface Props {
  submitRef?: React.LegacyRef<HTMLButtonElement> | undefined;
}

interface FormValues {
  company_name: string;
  website: string;
  npwp: string;
}

const imageMimeType = /image\/(png|jpg|jpeg)/i;
const MAX_FILE_SIZE = 1048576;
const StepOne: React.FunctionComponent<Props> = ({ submitRef }) => {
  const { canSkip, cantSkip } = useAppStore();
  const [show, setShow] = useState(false);
  const [saved, setSaved] = useState(false);
  const [accoutingRadio, setAccountingRadio] = useState('0');
  const [formData, setFormData] = useState<ProfileFormData>({
    address: '',
    city: '',
    country: '',
    district: '',
    postcode: '',
    state: '',
    company_name: '',
    website: '',
    npwp: ''
  });
  const [isAddressCompleted, setIsAddressCompleted] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<FormValues>();
  const { activeStep, setActiveStep, onboardingModifiedDate, setOnboardingModifiedDate } = useAppStore();
  const [file, setFile] = useState<File | null>(null);
  const [dataFileUrl, setDataFileUrl] = useState(null);
  const [isImageFailed, setIsImageFailed] = useState('');
  const [profilleData, setProfilleData] = useState({
    address: '',
    city: '',
    country: '',
    district: '',
    postcode: '',
    state: ''
  });

  const isMounted = useRef(true);

  UseDocumentTitle('Informasi Perusahaan');

  const onSubmit: SubmitHandler<FormValues> = async (dataForm) => {
    setIsButtonClicked(true);
    let validate = file ? false : dataFileUrl ? false : true;
    if (!isAddressCompleted || validate) {
      setFormData((prev) => ({ ...prev, ...dataForm }));
      cantSkip();
    } else {
      try {
        const newFormData: any = { ...formData };

        delete newFormData.city_id;
        delete newFormData.district_id;
        delete newFormData.province;
        delete newFormData.province_id;

        const {
          data: { status: dataStatus },
          status,
          statusText
        } = await saveProfile({ model: { ...newFormData, ...dataForm }, logo: file });
        const { status: statusAccounting } = await saveAccounting(accoutingRadio);
        if (status === 200 && dataStatus === 'ok' && statusAccounting === 200) {
          const { status: statusUpdate, statusText: statusTextUpdate } = await updateCurrentStep(
            activeStep.toString(),
            onboardingModifiedDate,
            'INCREASE'
          );
          if (statusUpdate === 200) {
            const {
              data: { lastOnboardingStep, onboardingLastModified },
              status: statusGetCurrent,
              statusText: statusTextGetCurrent
            } = await getCurrentStep();
            if (statusGetCurrent === 200) {
              setActiveStep(lastOnboardingStep);
              setOnboardingModifiedDate(onboardingLastModified);
            } else {
              console.error(`Something went wrong ${statusTextGetCurrent}`);
            }
          } else {
            console.error(`Something went wrong ${statusTextUpdate}`);
          }
        } else {
          console.error(`Something went wrong ${statusText}`);
        }
      } catch (err) {
        const error = err as Error;
        console.log(error);
        showAlert('error', 'Terjadi kesalahan, mohon masukan alamat dengan lengkap!', 5000);
      }
    }
  };

  const handleAccountingRadio = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setAccountingRadio((e.target as HTMLInputElement).value);

  const handleAddress = (addressData: AddressForm) => {
    console.log('addressData', addressData);
    setIsAddressCompleted(true);
    setShow(false);
    setSaved(true);
    setFormData((prev) => ({ ...prev, ...addressData }));
  };

  const handleUploadFile = (e: React.BaseSyntheticEvent) => {
    const retrievedFile = e.target.files[0];
    if (!retrievedFile.type.match(imageMimeType)) {
      return setIsImageFailed('invalid');
    }
    if (retrievedFile.size >= MAX_FILE_SIZE) {
      e.target.value = null;
      setIsImageFailed('maximum');
      return setTimeout(() => {
        setIsImageFailed('');
      }, 4000);
    }
    setFile(retrievedFile);
    setIsImageFailed('');
  };

  const handleClose = () => setSaved(false);

  useEffect(() => {
    let fileReader: any,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = () => {
        const { result } = fileReader;
        if (result && !isCancel) {
          setDataFileUrl(result);
        }
      };
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file]);

  useEffect(() => {
    if (!isButtonClicked) {
      canSkip();
    }
    if (isButtonClicked && isAddressCompleted && file) {
      canSkip();
    }
  }, [isButtonClicked, isAddressCompleted, file]);

  useEffect(() => {
    const isError = Object.entries(errors);
    if (isError.length > 0) {
      cantSkip();
      setIsButtonClicked(true);
    }
  }, [errors]);

  const urlToFile = async (url, filename) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const data = new File([blob], filename, { type: blob.type });
    return data;
  };

  useEffect(() => {
    const getProfile = async () => {
      try {
        const { data, status, statusText } = await getProfileSettings();
        const emptyAccount =
          data.company_name === 'default' &&
          (!data.city || !data.address || data.country || data.district || data.postcode || data.state);
        if (status === 200 && !emptyAccount) {
          setValue('company_name', data.company_name);
          setValue('website', data.website);
          setValue('npwp', data.npwp);
          setFormData((prev) => ({
            ...prev,
            address: data.address,
            city: data.city,
            company_name: data.company_name,
            country: data.country,
            district: data.district,
            npwp: data.npwp,
            postcode: data.postcode,
            state: data.state,
            website: data.website
          }));
          setProfilleData((prev) => ({
            ...prev,
            address: data.address,
            city: data.city,
            country: data.country,
            district: data.district,
            postcode: data.postcode,
            state: data.state
          }));
          if (data.logo_url) {
            let newFile = await urlToFile(data.logo_url, 'profile');
            setFile(newFile);
          }
          if (data.address) {
            setIsAddressCompleted(true);
          }
          setDataFileUrl(data.logo_url || '');
        } else {
          console.error(`Something went wrong ${statusText}`);
        }
      } catch (err) {
        const error = err as Error | AxiosError;
        console.error(error);
      }
    };

    if (isMounted.current) {
      getProfile();
      isMounted.current = false;
    }
  }, []);

  const handleModalAddress = (isOpen) => {
    if (isOpen) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  return (
    <Box className={styles.step1Container}>
      <Typography className={styles.title}>Informasi Perusahaan</Typography>
      <Typography className={styles.titleDescription}>
        Lengkapi informasi umum perusahaan dan tentukan pencatatan keuangan Kamu.
      </Typography>
      <Divider sx={{ my: '23.5px' }} />
      <Typography className={styles.generatlInformation}>Informasi Umum</Typography>
      <Grid className={styles.formContainer} container>
        <Grid sx={{ height: 'auto' }} item xs={9}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate className={styles.generalInformationForm}>
            <InputGrid
              label={
                <Typography className={`${styles.inputLabel} ${styles.requiredField}`}>Nama Perusahaan</Typography>
              }
              input={
                <Input
                  isError={errors.company_name?.message ? true : false}
                  type={'text'}
                  placeholder={'Masukkan nama perusahaan'}
                  {...register('company_name', {
                    required: { value: true, message: 'Nama Perusahaan harus di isi' },
                    maxLength: { value: 50, message: 'Nama Perusahaan maksimal 50 karakter' }
                  })}
                />
              }
              errorMessage={
                errors?.company_name && (
                  <ErrorMessage
                    icon={<WarningIcon fontSize="small" style={{ color: '#df4d4d' }} />}
                    message={errors.company_name.message}
                  />
                )
              }
            />
            <InputGrid
              label={<Typography className={`${styles.inputLabel}`}>Website</Typography>}
              input={
                <Input
                  isError={errors.website?.message ? true : false}
                  type={'text'}
                  placeholder={'Masukkan situs website'}
                  {...register('website', {
                    pattern: {
                      value:
                        /^(((?!-))(xn--)?[a-z0-9\-_]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30})\.[a-z]{2,}$/,
                      message: 'Nama Website tidak valid'
                    }
                  })}
                />
              }
              errorMessage={
                errors?.website && (
                  <ErrorMessage
                    icon={<WarningIcon fontSize="small" style={{ color: '#df4d4d' }} />}
                    message={errors.website.message}
                  />
                )
              }
            />
            <InputGrid
              label={<Typography className={`${styles.inputLabel}`}>NPWP</Typography>}
              input={
                <Input
                  isError={errors.npwp?.message ? true : false}
                  type={'number'}
                  placeholder={'Masukkan nomor NPWP'}
                  {...register('npwp', {
                    maxLength: { value: 15, message: 'NPWP maksimal 15 digit' },
                    pattern: { value: /^[0-9]\d*$/, message: 'NPWP harus Numerik/Angka' }
                  })}
                />
              }
              errorMessage={
                errors?.npwp && (
                  <ErrorMessage
                    icon={<WarningIcon fontSize="small" style={{ color: '#df4d4d' }} />}
                    message={errors.npwp.message}
                  />
                )
              }
            />
            <InputGrid
              label={<Typography className={`${styles.inputLabel} ${styles.requiredField}`}>Alamat</Typography>}
              input={
                <div style={{ fontSize: '14px' }}>
                  {isAddressCompleted && (
                    <>
                      {`${formData.address}, ${formData.state}, ${formData.city}, ${formData.district}, ${formData.postcode}. `}
                      <span
                        onClick={() => handleModalAddress(true)}
                        style={{ color: 'blue', cursor: 'pointer', fontWeight: 'bold' }}
                      >
                        <br />
                        Edit Alamat
                      </span>
                    </>
                  )}
                  {!isAddressCompleted && (
                    <span
                      onClick={() => handleModalAddress(true)}
                      style={{ color: 'blue', cursor: 'pointer', fontWeight: 'bold' }}
                    >
                      Tambah Alamat
                    </span>
                  )}
                </div>
              }
              errorMessage={
                !isAddressCompleted &&
                isButtonClicked && (
                  <ErrorMessage
                    icon={<WarningIcon fontSize="small" style={{ color: '#df4d4d' }} />}
                    message={'Alamat belum di isi'}
                  />
                )
              }
            />
            <button ref={submitRef} type="submit" style={{ display: 'none' }} />
          </form>
        </Grid>
        <Grid sx={{ height: 'auto' }} item xs={3} className={styles.uploadImageContainer}>
          <UploadImage isUploaded={dataFileUrl ? true : false}>
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              style={{ display: 'none' }}
              id="contained-button-file"
              onChange={handleUploadFile}
            />
            <div className={styles.imgContent}>
              {dataFileUrl ? (
                <div className={styles.imgPreview}>
                  <img src={dataFileUrl} alt={'preview'} />
                </div>
              ) : (
                <>
                  <AddAPhotoIcon sx={{ color: '#D3D3D3', fontSize: 40 }} />
                  <Typography className={styles.uploadImageTitle}>Logo Perusahaan</Typography>
                </>
              )}
            </div>
            <label className={styles.imgButton} htmlFor="contained-button-file">
              {dataFileUrl ? 'Ganti Gambar' : 'Upload'}
            </label>
          </UploadImage>
          <Typography className={styles.uploadImageRule}>
            *Maksimal 1mb.
            <br />
            Format harus jpg/png. <br />
            Gambar wajib diisi. <br />
            {(!file && isButtonClicked) ||
              (!dataFileUrl && (
                <ErrorMessage
                  icon={<WarningIcon fontSize="small" style={{ color: '#df4d4d' }} />}
                  message={'Gambar belum di pilih!'}
                />
              ))}
            {isImageFailed === 'invalid' && (
              <ErrorMessage
                icon={<WarningIcon fontSize="small" style={{ color: '#df4d4d' }} />}
                message={'Gambar tidak valid!'}
              />
            )}
            {isImageFailed === 'maximum' && (
              <ErrorMessage
                icon={<WarningIcon fontSize="small" style={{ color: '#df4d4d' }} />}
                message={'Maksimal 1024KB!'}
              />
            )}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mt: '21px', mb: '25.5px' }} />
      <Typography className={styles.title}>Apakah Kamu Akan Menggunakan Modul Akunting?</Typography>
      <FormControl>
        <RadioGroup
          aria-labelledby="controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={accoutingRadio}
          onChange={(e) => handleAccountingRadio(e)}
        >
          <FormControlLabel
            value={'0'}
            control={<Radio sx={{ color: '#D4D5D6', '&$checked': { color: '#4B68EF' } }} />}
            label={<Typography className={`${styles.radioLabel}`}>Tidak memerlukan pencatatan akunting</Typography>}
          />
          <Typography className={styles.radioDescription}>
            Fitur-fitur di modul akunting akan diabaikan. Kamu tidak akan melakukan pencatatan terhadap laba/rugi,
            hutang/piutang, dan fitur akunting lainnya.{' '}
            <strong style={{ color: '#4d4848' }}>Kamu bisa import stok dari marketplace.</strong>
          </Typography>
          <FormControlLabel
            value={'1'}
            control={<Radio sx={{ color: '#D4D5D6' }} />}
            label={<Typography className={`${styles.radioLabel}`}>Gunakan akunting sebatas pencatatan HPP</Typography>}
          />
          <Typography className={`${styles.radioDescription}`}>
            Kamu akan mendapatkan fitur akunting dalam mode terbatas seperti : laporan persediaan barang, penjualan,
            laba-rugi serta hutang-piutang.
          </Typography>
          <FormControlLabel
            value={'2'}
            control={<Radio sx={{ color: '#D4D5D6' }} />}
            label={<Typography className={`${styles.radioLabel}`}>Gunakan modul akunting secara lengkap</Typography>}
          />
          <Typography className={`${styles.radioDescription}`}>
            Kamu akan mendapatkan fitur akunting secara keseluruhan seperti laporan laba rugi, hutang-piutang, laporan
            neraca, aktivitas kas dan bank (penerimaan, pengeluaran serta laporan mutasi akun)
          </Typography>
        </RadioGroup>
      </FormControl>
      <AddressModal
        isAddressCompleted={isAddressCompleted}
        open={show}
        handleAddress={handleAddress}
        handleClose={() => handleModalAddress(false)}
        profilleData={profilleData}
        parentData={formData}
      />
      <AddressSaved open={saved} handleClose={handleClose} />
    </Box>
  );
};

export default StepOne;
