import HorizontalDividers from 'components/CustomDividers/HorizontalDividers';
import VerticalDividers from 'components/CustomDividers/VerticalDividers';
import { DialogContentStyled, Label, LeftContentWrapper, RightContentWrapper } from 'components/Dialog/DialogContent';
import { LeftContent } from 'components/Dialog/DialogLeftContent';
import { Bottom, BottomActions, ConnectButton, RightForm } from 'components/Dialog/DialogRightContent';
import { ErrorMessage, Input, InputGrid } from 'components/Form';
import WarningIcon from '@mui/icons-material/Warning';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getAkulakuAuthorizeUrl } from 'api/marketPlaces';
import { showAlert } from 'components/Alert';
import { channels } from 'shared/constants';
import { useAppStore } from 'stores/appStore';
import config from 'config';

interface FormValues {
  store_name: string;
}

const AkulakuContent: React.FunctionComponent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>();
  const { indexAccordion, disablePrevButton } = useAppStore();

  const onSubmit: SubmitHandler<FormValues> = async (value) => {
    try {
      const res: any = await getAkulakuAuthorizeUrl(value.store_name, indexAccordion);
      if (res && res?.data && res?.data?.status == 'ok') {
        if (!disablePrevButton) {
          window.location.href = res?.data?.redirect;
        } else {
          location.replace(config.baseUrl);
        }
      } else {
        showAlert('error', `${res?.data?.message}`, 5000);
      }
    } catch (err) {
      const error = err as Error;
      showAlert('error', `${error}`, 5000);
    }
  };

  return (
    <DialogContentStyled>
      <LeftContentWrapper>
        <LeftContent channelId={channels.AKULAKU} />
      </LeftContentWrapper>
      <VerticalDividers />
      <RightContentWrapper>
        <RightForm id="akulaku_form" onSubmit={handleSubmit(onSubmit)}>
          <InputGrid
            label={<Label>Nama Toko</Label>}
            input={
              <Input
                isError={errors.store_name?.message ? true : false}
                type={'text'}
                placeholder={'Masukkan Nama Toko'}
                {...register('store_name', {
                  required: { value: true, message: 'Nama Toko harus di isi' }
                })}
              />
            }
            errorMessage={
              errors?.store_name && (
                <ErrorMessage
                  icon={<WarningIcon fontSize="small" color="error" />}
                  message={errors.store_name.message}
                />
              )
            }
          />
        </RightForm>
        <Bottom>
          <HorizontalDividers />
          <BottomActions>
            <ConnectButton form="akulaku_form">Sambungkan</ConnectButton>
          </BottomActions>
        </Bottom>
      </RightContentWrapper>
    </DialogContentStyled>
  );
};

export default AkulakuContent;
