/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button as MaterialButton, Icon, CircularProgress, ButtonProps } from '@mui/material';

import { AsyncButtonProps } from './Button.types';

import './Button.scss';

export function AsyncButton({
  variant = 'contained',
  children,
  color = 'inherit',
  icon,
  busyText,
  onClick,
  type,
  disabled,
  busy,
  ...args
}: AsyncButtonProps & ButtonProps): React.ReactElement<AsyncButtonProps & ButtonProps> {
  const { t } = useTranslation('components');
  const [loading, setLoading] = useState(busy);

  const handleOnClick = React.useCallback(
    async (e) => {
      if (onClick && !loading) {
        setLoading(true);
        try {
          await onClick(e);
        } finally {
          setLoading(false);
        }
      }
    },
    [onClick, loading]
  );

  let startIcon;
  if (icon && typeof icon === 'string') {
    startIcon = <Icon>{icon}</Icon>;
  } else {
    startIcon = icon;
  }

  let content: React.ReactNode = children;

  if (loading || busy) {
    startIcon = <CircularProgress size="1em" />;
    content = busyText || 'Harap tunggu...';
  }

  React.useEffect(() => {
    setLoading(busy);
  }, [busy]);

  return (
    <MaterialButton
      variant={variant}
      color={color}
      onClick={handleOnClick}
      disabled={disabled || loading || busy}
      startIcon={startIcon}
      type={type}
      {...args}
    >
      {content}
    </MaterialButton>
  );
}
