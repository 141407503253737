import React from 'react';

export const EditDataSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0464 2.50144L6.00535 8.51442V9.99788H7.4916L13.5019 3.95692L12.0464 2.50144ZM12.5189 1.08833L14.9149 3.48431C15.1747 3.74419 15.1753 4.16538 14.9161 4.42592L8.24133 11.1347C8.1162 11.2605 7.94613 11.3312 7.76873 11.3312H5.33867C4.97049 11.3312 4.67201 11.0327 4.67201 10.6645V8.23735C4.67201 8.06002 4.74267 7.89002 4.86837 7.76488L11.5771 1.08724C11.8377 0.827915 12.2589 0.828409 12.5189 1.08833Z"
        fill="#163A50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4585 2.60132L13.4013 5.54412L12.4585 6.48694L9.5156 3.54412L10.4585 2.60132Z"
        fill="#163A50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66666 2.33341C1.66666 1.96523 1.96514 1.66675 2.33333 1.66675H8V3.00008H3V13.0001H13V8.00008H14.3333V13.6667C14.3333 14.0349 14.0349 14.3334 13.6667 14.3334H2.33333C1.96514 14.3334 1.66666 14.0349 1.66666 13.6667V2.33341Z"
        fill="#163A50"
      />
    </svg>
  );
};
