import React from 'react';
import { DialogContentStyled, Label, LeftContentWrapper, RightContentWrapper } from 'components/Dialog/DialogContent';
import { LeftContent } from 'components/Dialog/DialogLeftContent';
import { Bottom, BottomActions, ConnectButton, RightForm } from 'components/Dialog/DialogRightContent';
import { ErrorMessage, Input, InputGrid } from 'components/Form';
import { SubmitHandler, useForm } from 'react-hook-form';
import { lazadaAuthorize } from 'api/marketPlaces';
import { channels } from 'shared/constants';
import { useAppStore } from 'stores/appStore';

import HorizontalDividers from 'components/CustomDividers/HorizontalDividers';
import VerticalDividers from 'components/CustomDividers/VerticalDividers';
import WarningIcon from '@mui/icons-material/Warning';
import config from 'config';

interface FormValues {
  store_name: string;
}

const LazadaContent: React.FunctionComponent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>();
  const { indexAccordion, disablePrevButton } = useAppStore();

  const onSubmit: SubmitHandler<FormValues> = async (formData) => {
    try {
      const { data, status, statusText } = await lazadaAuthorize(
        formData.store_name,
        indexAccordion,
        disablePrevButton
      );
      if (status === 200 && data.status === 'ok') {
        window.location.href = data.redirect;
      } else {
        console.error(`Something went wrong ${statusText}`);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <DialogContentStyled>
      <LeftContentWrapper>
        <LeftContent channelId={channels.LAZADA} />
      </LeftContentWrapper>
      <VerticalDividers />
      <RightContentWrapper>
        <RightForm id="lazada_form" onSubmit={handleSubmit(onSubmit)}>
          <InputGrid
            label={<Label className="requiredField">Email</Label>}
            input={
              <Input
                isError={errors.store_name?.message ? true : false}
                type={'store_name'}
                placeholder={'Masukkan email'}
                {...register('store_name', {
                  required: { value: true, message: 'Email harus di isi' },
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Email tidak valid'
                  }
                })}
              />
            }
            errorMessage={
              errors?.store_name && (
                <ErrorMessage
                  icon={<WarningIcon fontSize="small" color="error" />}
                  message={errors.store_name.message}
                />
              )
            }
          />
        </RightForm>
        <Bottom>
          <HorizontalDividers />
          <BottomActions>
            <ConnectButton form="lazada_form">Sambungkan</ConnectButton>
          </BottomActions>
        </Bottom>
      </RightContentWrapper>
    </DialogContentStyled>
  );
};

export default LazadaContent;
