import { updateCurrentStep, getCurrentStep } from 'api/onboardingStep';
import { Data } from 'pages/Onboarding/StepTwo/Accordion';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface IApp {
  activeStep: number;
  setActiveStep: (state: number) => void;
  onboardingModifiedDate: string;
  setOnboardingModifiedDate: (state: string) => void;
  isLoading: boolean;
  setAppLoading: (newState: boolean) => void;
  showGettingStarted: boolean;
  setShowGettingStarted: (newState: boolean) => void;
  showConnectedPopup: boolean;
  setShowConnectedPopup: (newState: boolean) => void;
  disableNextButton: boolean;
  canSkip: () => void;
  cantSkip: () => void;
  disablePrevButton: boolean;
  setDisablePrevButton: (newState: boolean) => void;
  masterShop: Data | null;
  setMasterShop: (item: Data) => void;
  step3DownloadConfirmation: boolean;
  toggleStep3DownloadConfirmation: () => void;
  step3ErrorMessage: boolean;
  toggleStep3ErrorMessage: () => void;
  step3CurrentContent: string;
  setStep3CurrentContent: (content: string) => void;
  step3SetupId: any;
  setStep3SetupId: (item) => void;
  showSubmitValidation: boolean;
  toggleSubmitValidation: (state: boolean) => void;
  validationComplete: boolean,
  setValidationComplete: (state: boolean) => void;
  showConfirmation: boolean,
  setShowConfirmation: (state: boolean) => void;
  updateStep: (moveTo: 'INCREASE' | 'DECREASE') => void;
  modeEdit: boolean;
  setModeEdit: (item) => void;
  detailParam: any;
  setDetailParam: (item) => void;
  clearParam: () => void;
  isAccounting: string;
  setIsAccounting: (item) => void;
  listWarehouse: any;
  setListWarehouse: (item) => void;
  indexAccordion: any;
  setIndexAccordion: (item) => void;
  channelIdOto: any;
  setChannelIdOto: (item) => void;
  integratedChannels: any;
  setIntegratedChannels: (item) => void;
  dataAccountSupport: any;
  setDataAccountSupport: (item) => void;
}
interface LocalStorageState {
  token: string,
  setToken: (params: string) => void,
}

export const useAppStore = create<IApp>((set, get) => ({
  activeStep: 1,
  setActiveStep: (newState: number) => set(state => ({ ...state, activeStep: newState })),
  onboardingModifiedDate: '',
  setOnboardingModifiedDate: (newState: string) => set(state => ({ ...state, onboardingModifiedDate: newState })),
  isLoading: true,
  setAppLoading: async (loadingState: boolean ) => {
    set(state => ({ ...state, isLoading: loadingState }));
  },
  showGettingStarted: false,
  setShowGettingStarted: async (newState: boolean) => {
    set(state => ({ ...state, showGettingStarted: newState }));
  },
  showConnectedPopup: false,
  setShowConnectedPopup: (newState: boolean) => {
    set(state => ({ ...state, showConnectedPopup: newState }));
  },
  disableNextButton: false,
  canSkip: () => set(state => ({ ...state, disableNextButton: false })),
  cantSkip: () => set(state => ({ ...state, disableNextButton: true })),
  disablePrevButton: false,
  setDisablePrevButton: (newState: boolean) => set(state => ({  ...state, disablePrevButton: newState })),
  masterShop: null,
  setMasterShop: (item: Data) => set(state => ({ ...state, masterShop: item })),
  step3DownloadConfirmation: false,
  toggleStep3DownloadConfirmation: () => set(state => ({ ...state, step3DownloadConfirmation: !state.step3DownloadConfirmation })),
  step3ErrorMessage: true,
  toggleStep3ErrorMessage: () => set(state => ({ ...state, step3ErrorMessage: !state.step3ErrorMessage })), 
  // step3CurrentContent: 'beginning',
  step3CurrentContent: 'initial_download',
  setStep3CurrentContent: (content: string) => set(state => ({ ...state, step3CurrentContent: content })),
  step3SetupId: {},
  setStep3SetupId: (item) => set(state => ({
    ...state, step3SetupId: item
  })),
  showSubmitValidation: false,
  toggleSubmitValidation: (newState) => set(state => ({ ...state, showSubmitValidation: newState })),
  validationComplete: false,
  setValidationComplete: (newState) => set(state => ({ ...state, validationComplete: newState })),
  showConfirmation: false,
  setShowConfirmation: (newState) => set(state => ({ ...state, showConfirmation: newState })),
  detailParam: {},
  modeEdit: true,
  channelIdOto: null,
  setChannelIdOto: (channelIdOto) => {
    set({ channelIdOto })
  },
  setModeEdit: (modeEdit: any) => {
    set({ modeEdit })
  },
  setDetailParam: (detailParam: any) => {
    set({ detailParam })
  },
  updateStep: async (moveTo) => {
    try {
      const { status: statusUpdate, statusText: statusTextUpdate } = await updateCurrentStep(
        get().activeStep.toString(),
        get().onboardingModifiedDate,
        moveTo
      );
      if (statusUpdate === 200) {
        const {
          data: { lastOnboardingStep, onboardingLastModified },
          status: statusGetCurrent,
          statusText: statusTextGetCurrent
        } = await getCurrentStep();
        if (statusGetCurrent === 200) {
          set(state => ({ ...state, activeStep: lastOnboardingStep, onboardingModifiedDate: onboardingLastModified }))
        } else {
          console.error(`Something went wrong ${statusTextGetCurrent}`);
        }
      } else {
        console.error(`Something went wrong ${statusTextUpdate}`);
      }
    } catch (error) {
      console.error(error);
    }
  },

  clearParam: () => {
    set({
      modeEdit: false,
      detailParam: {},
    })
  },
  isAccounting: '0',
  setIsAccounting: (isAccounting: any) => {
    set({ isAccounting })
  },
  listWarehouse: [],
  setListWarehouse: (listWarehouse: any) => {
    set({ listWarehouse })
  },
  indexAccordion: undefined,
  setIndexAccordion: (indexAccordion: any) => {
    set({ indexAccordion })
  },
  integratedChannels: [],
  setIntegratedChannels: (integratedChannels: any) => {
    set({ integratedChannels })
  },
  dataAccountSupport: {},
  setDataAccountSupport: (dataAccountSupport: any) => {
    set({ dataAccountSupport })
  },
}));

export const useLocalStorage = create<LocalStorageState>()(
  persist(
    (set) => ({
      token: '',
      setToken: (params: string) => set({ token: params }),
    }),
    {
      name: 'auth',
      partialize: (state) => ({ token: state.token }),
    },
  ),
);