import axios, { AxiosResponse } from 'axios';
import config from 'config';

interface Token {
  token: string;
}
interface JSONObject {
  state: Token;
  version: number;
}

const tokenString: string = window.localStorage.getItem('auth') || '{}';
const tokenObj: JSONObject = JSON.parse(tokenString);
const { state }: { state: { token: string } } = tokenObj;
const unAuthorizedResponse = 401;

export const onBoardingAPI = axios.create({
  baseURL: config.baseOnboardingAPI,
  headers: {
    'Authorization': `Bearer ${state?.token}`,
  },
});

onBoardingAPI.interceptors.response.use((response: AxiosResponse): AxiosResponse => {
  return response;
}, (error) => {
  if (error.response.status === unAuthorizedResponse) {
    location.replace(config.V2_URL);
  } else {
    console.log('error', error)
    throw new Error(error?.response?.data?.code || error.message)
  }
});

export const coreAPI = axios.create({
  baseURL: config.baseCoreAPI,
  headers: {
    'Authorization': `Bearer ${state?.token || ''}`,
  },
});

coreAPI.interceptors.response.use((response: AxiosResponse): AxiosResponse => {
  return response;
}, (error) => {
  if (error.response.status === unAuthorizedResponse) {
    location.replace(config.V2_URL);
  } else {
    console.log('error', error)
    throw new Error(error?.response?.data?.code || error.message)
  }
});


export const impexApi = axios.create({
  baseURL: config.EXIM_API_URL,
  headers: {
    'Authorization': `Bearer ${state?.token || ''}`,
  },
});

impexApi.interceptors.response.use((response: AxiosResponse): AxiosResponse => {
  return response;
}, (error) => {
  if (error.response.status === unAuthorizedResponse) {
    location.replace(config.V2_URL);
  } else {
    console.log('error', error)
    throw new Error(error?.response?.data?.code || error.message)
  }
});
