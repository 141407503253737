import React from 'react';

export const ImportSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.4001 2.3501V10.6001H8.90015V2.3501H10.4001Z" fill="#163A50" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7107 7.59997L10.1804 11.1303C10.0397 11.271 9.84895 11.35 9.64998 11.35C9.45108 11.35 9.26035 11.271 9.11965 11.1303L5.58936 7.59997L6.65001 6.53931L9.64998 9.53931L12.65 6.53931L13.7107 7.59997Z"
        fill="#163A50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5555 2.19477C16.4148 2.05412 16.224 1.9751 16.0251 1.9751H12.0876V3.4751H15.2751V14.7251H4.02515V3.4751H7.21265V1.9751H3.27515C2.86094 1.9751 2.52515 2.31089 2.52515 2.7251V15.4751C2.52515 15.8893 2.86094 16.2251 3.27515 16.2251H16.0251C16.4394 16.2251 16.7751 15.8893 16.7751 15.4751V2.7251C16.7751 2.52618 16.6961 2.33542 16.5555 2.19477Z"
        fill="#163A50"
      />
    </svg>
  );
};
