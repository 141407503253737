import React from 'react';

export const DeleteSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.42607 5.9314L7.43098 20.0001H16.5687L17.5736 5.9314L19.5686 6.07389L18.4973 21.0713C18.4599 21.5947 18.0245 22.0001 17.4999 22.0001H6.49986C5.97522 22.0001 5.53978 21.5947 5.5024 21.0713L4.43115 6.07389L6.42607 5.9314Z"
        fill="#163A50"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M3 5H21V7H3V5Z" fill="#163A50" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11 9.5V17.5H9V9.5H11ZM15 9.5V17.5H13V9.5H15Z" fill="#163A50" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.5C10.8954 3.5 10 4.39543 10 5.5V6.5H8V5.5C8 3.29086 9.79086 1.5 12 1.5C14.2091 1.5 16 3.29086 16 5.5V6.5H14V5.5C14 4.39543 13.1046 3.5 12 3.5Z"
        fill="#163A50"
      />
    </svg>
  );
};
