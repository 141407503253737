/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import TextField from '@mui/material/TextField';
import { useFormContext, Controller, FieldError } from 'react-hook-form';
import { InputFieldProps, DateFieldProps } from './DateInput.types';
import { CalenderSVG, HistorySVG } from '../../assets/img';

import { DatePickerProps } from '@mui/lab/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DateTimePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
// import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface ControlledProps {
  value?: any;
  onChange?: any;
}

export function DateInput<T>({
  name,
  onlyTime = false,
  showTime = false,
  onlyHour = false,
  value,
  onChange,
  margin = 'normal',
  ...args
}: DateFieldProps &
  InputFieldProps &
  Omit<DatePickerProps<T>, 'renderInput' | 'value' | 'onChange'> &
  ControlledProps) {
  const ctx = useFormContext();
  const error = (ctx && ctx.formState.errors && name && ctx.formState.errors[name]) as any as FieldError;

  const getErrorMessage = () => {
    let errorMessage = '';
    if (value !== '' && value !== null) {
      switch (error.type) {
        case 'date.base':
          errorMessage = `${args.label} eror`;
          break;
        default:
          errorMessage = error.message.replace(name, args.label as string);
          break;
      }
    } else {
      errorMessage = `${args.label} tidak boleh kosong`;
    }
    return errorMessage;
  };

  const onError = (err) => {
    if (ctx) {
      ctx.setError(name, { message: getErrorMessage() });
    }
  };

  const CalendarSVGComponent = () => <CalenderSVG width={18} className="mt-1" height={18} />;
  const HistorySVGComponent = () => <HistorySVG width={18} className="mt-1" height={18} />;

  function renderField(props: any) {
    let errorMessage = '';
    if (error) {
      errorMessage = getErrorMessage();
    }
    if (onlyTime) {
      return (
        <TimePicker
          ampm={false}
          format="HH:mm"
          error={Boolean(error)}
          helperText={errorMessage}
          className="MuiDatePicker"
          fullWidth
          components={{
            OpenPickerIcon: HistorySVGComponent
          }}
          renderInput={(params) => <TextField className="no-notch TimePicker" margin={margin} {...params} />}
          autoOk
          {...args}
          {...props.field}
        />
      );
    }

    if (onlyHour) {
      return (
        <TimePicker
          ampm={false}
          format="HH:mm"
          error={Boolean(error)}
          helperText={errorMessage}
          views={['hours']}
          className="MuiDatePicker"
          fullWidth
          components={{
            OpenPickerIcon: HistorySVGComponent
          }}
          inputProps={{
            placeholder: args.placeholder
          }}
          renderInput={(params) => (
            <TextField placeholder="Waktu mulai" className="no-notch TimePicker" margin={margin} {...params} />
          )}
          autoOk
          {...args}
          {...props.field}
        />
      );
    }

    return showTime ? (
      <DateTimePicker
        renderInput={(inputProps) => {
          return (
            <TextField
              className="no-notch input-date"
              {...inputProps}
              inputProps={{
                ...inputProps.inputProps,
                readOnly: true,
                placeholder: args.placeholder
              }}
              error={Boolean(error)}
              helperText={errorMessage}
              margin={margin}
            />
          );
        }}
        ampm={false}
        error={Boolean(error)}
        helperText={errorMessage}
        format="dd/MMM/yyyy HH:mm"
        className="MuiDatePicker"
        fullWidth
        components={{
          OpenPickerIcon: CalendarSVGComponent
        }}
        autoOk
        {...args}
        {...props.field}
      />
    ) : (
      <DesktopDatePicker
        renderInput={(inputProps) => {
          return (
            <TextField
              className="no-notch input-date"
              {...inputProps}
              style={inputProps.disabled ? { backgroundColor: '#f1f1f1', borderRadius: '8px' } : null}
              inputProps={{
                ...inputProps.inputProps,
                readOnly: true,
                placeholder: args.placeholder
              }}
              error={Boolean(error)}
              helperText={errorMessage}
              margin={margin}
              fullWidth
            />
          );
        }}
        disableMaskedInput
        format="dd/MMM/yyyy"
        autoOk
        fullWidth
        className="MuiDatePicker w-100"
        components={{
          OpenPickerIcon: CalendarSVGComponent
        }}
        {...args}
        {...props.field}
      />
    );
  }

  function renderControlled() {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          renderInput={(inputProps) => {
            return (
              <TextField
                className="no-notch input-date"
                {...inputProps}
                inputProps={{
                  ...inputProps.inputProps,
                  readOnly: true,
                  placeholder: args.placeholder
                }}
                margin={margin}
                fullWidth
              />
            );
          }}
          inputFormat="DD/MM/YYYY"
          onError={onError}
          className="MuiDatePicker"
          value={value}
          onChange={onChange}
          components={{
            OpenPickerIcon: CalendarSVGComponent
          }}
          InputProps={{
            sx: {
              '& .MuiIconButton-edgeEnd': {
                margin: 'unset !important'
              }
            }
          }}
          {...args}
        />
      </LocalizationProvider>
    );
  }

  if (name && ctx) {
    return <Controller name={name} control={ctx.control} render={renderField} />;
  }

  return renderControlled();
}
