import React from 'react';

export const AddSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.99967 0.833252C7.22069 0.833252 7.43265 0.921049 7.58893 1.07733C7.74521 1.23361 7.83301 1.44557 7.83301 1.66659V6.66658H12.833C13.054 6.66658 13.266 6.75438 13.4223 6.91066C13.5785 7.06694 13.6663 7.2789 13.6663 7.49992C13.6663 7.72093 13.5785 7.93289 13.4223 8.08917C13.266 8.24545 13.054 8.33325 12.833 8.33325H7.83301V13.3332C7.83301 13.5543 7.74521 13.7662 7.58893 13.9225C7.43265 14.0788 7.22069 14.1666 6.99967 14.1666C6.77866 14.1666 6.5667 14.0788 6.41042 13.9225C6.25414 13.7662 6.16634 13.5543 6.16634 13.3332V8.33325H1.16634C0.945327 8.33325 0.733366 8.24545 0.577086 8.08917C0.420805 7.93289 0.333008 7.72093 0.333008 7.49992C0.333008 7.2789 0.420805 7.06694 0.577086 6.91066C0.733366 6.75438 0.945327 6.66658 1.16634 6.66658H6.16634V1.66659C6.16634 1.44557 6.25414 1.23361 6.41042 1.07733C6.5667 0.921049 6.77866 0.833252 6.99967 0.833252Z"
        fill="#8999A5"
      />
    </svg>
  );
};
