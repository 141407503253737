import React, { useEffect, useRef } from 'react';
import Joi from 'joi';

import { Button, Chip, debounce, Grid, InputAdornment, MenuItem, Popover, Switch, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { ArrowDownSVG, SearchSVG } from 'assets/img';
import { PagePropsSetter } from './StepSeven.types';
import { LiteGrid } from 'components/LiteGrid/LiteGrid';
import { Column } from 'components/LiteGrid/DataGrid.types';
import { showConfirm } from 'components/Modal/ConfrimAlert';
import { deleteLocationSetting, getLocationList, newGetLocationList, postStatusLocation } from 'api/locations';
import { QueryParameters } from 'lib/types';
import { showAlert } from 'components/Alert';
import { TextInput } from 'components/Form/TextInput';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { AsyncButton } from 'components/ButtonNew/Button';
import { TYPE_WAREHOUSE_LOCATION } from './Editor/constant';
import { Form } from 'components/Form/Form';
import { useFormWithSchema } from 'hooks/useFormWithSchema';
import { useAppStore } from 'stores/appStore';
import { setWizardSetup } from 'api/masterStore';

import DotIcon from '@mui/icons-material/FiberManualRecord';
import useLocationStore from './Editor/location';
import { SinglePagination } from 'components/SinglePagination/SinglePagination';

const schema = Joi.object({
  q: Joi.string().allow(''),
  type: Joi.array().allow(null)
});

const defaultFilter = {
  q: '',
  type: null
};

type OtherFilter = {
  type?: string;
};

const StepSevenList: React.FunctionComponent<PagePropsSetter> = ({ pageSetter }) => {
  const { setDisablePrevButton, canSkip, cantSkip, setDetailParam, clearParam, setIsAccounting, setListWarehouse } =
    useAppStore();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [disabledActive, setDisabledActive] = React.useState<boolean>(false);

  const formhooks = useFormWithSchema({ schema, shouldUnregister: true });
  const refresher = useRef<() => void>();
  const query = useRef<QueryParameters & OtherFilter>();
  const setQuery = useRef<(e: QueryParameters) => QueryParameters>();

  const clearLocation = useLocationStore((state) => state.clearLocation);

  const onClickAdd = (value, data?) => {
    if (data) {
      setDetailParam(data);
    }
    cantSkip();
    setDisablePrevButton(true);
    setAnchorEl(null);
    pageSetter(value);
  };

  const ButtonChild = () => {
    return (
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{ sx: { mt: '10px' } }}
      >
        <div>
          <MenuItem value="1" onClick={() => onClickAdd('location')}>
            <div className="d-flex align-items-center font-size-table">
              <Add height={11} width={11} className="mr-2" style={{ color: '#000000' }} />
              Lokasi Sendiri
            </div>
          </MenuItem>
          <MenuItem value="2" onClick={() => onClickAdd('thirdparty_location')}>
            <div className="d-flex align-items-center font-size-table">
              <Add height={11} width={11} className="mr-2" style={{ color: '#000000' }} />
              Pihak Ketiga
            </div>
          </MenuItem>
        </div>
      </Popover>
    );
  };

  const ColTemplLocationName = (ctx, data) => {
    const destination = data.is_fbl || data.is_sbs || data.is_tcb ? 'thirdparty_location' : 'location';

    if (data.location_id === 0) {
      return <Typography className="text-second font-size-table">{data.location_name}</Typography>;
    }

    return (
      <div onClick={() => onClickAdd(destination, data)}>
        <span style={{ fontSize: 12 }} className="text-link">
          {data.location_name}
        </span>
      </div>
    );
  };

  const ColTemplService = (ctx, data) => {
    const isBroker = data.is_fbl || data.is_sbs || data.is_tcb;
    const type = data.location_type;
    let colorDot = '#4B68EF';
    let colorChip = '#AF89FF26';
    let name = 'Gudang';

    if (type === 'Fulfillment By Lazada' || data.is_fbl) {
      colorDot = '#F10055';
      colorChip = '#FFCDCD';
      name = 'Fulfillment By Lazada';
    } else if (data.is_sbs) {
      colorDot = '#FF5000';
      colorChip = '#FFE4D0';
      name = 'Service by Shopee';
    } else if (type === 'TokoCabang Tokopedia' || data.is_tcb) {
      colorDot = '#519B4C';
      colorChip = '#DDEFDD';
      name = 'Dilayani Tokopedia';
    }

    return (
      <div className="d-flex flex-column text-left">
        {isBroker ? (
          <span className="text-second">Pihak Ketiga</span>
        ) : (
          <span className="text-second">Lokasi Sendiri</span>
        )}
        <Chip
          label={name}
          className="text-left justify-content-start"
          icon={<DotIcon style={{ color: colorDot, fontSize: 'small' }} fontSize="small" />}
          sx={{ height: '21px', background: colorChip + ' !important', width: { md: '200px', sm: '150px' } }}
        />
      </div>
    );
  };

  const onActivateLocation = async (body, val) => {
    try {
      let newBody: any = { location_id: body.location_id, is_active: val };
      const res: any = await postStatusLocation(newBody);

      if (res.status === 200) {
        showAlert('success', 'Update data berhasil!', 5000);
        refresher.current();
      }
    } catch (err) {
      const error = err as Error;
      showAlert('error', `${error}`, 5000);
    }
  };

  const onLocationChange = (data, active) => {
    let title = '';
    let subtitle = '';
    if (active) {
      title = 'Aktifkan Lokasi ?';
      subtitle = 'Kamu dapat mengaktifkan lokasi ini kapan saja.';

      showConfirm({
        title,
        subtitle,
        onConfirm: async () => onActivateLocation(data, true),
        onCancel: null,
        showCancel: true,
        cancelText: 'Batal'
      });
    } else {
      title = 'Nonaktifkan Lokasi ?';
      subtitle = 'Kamu dapat menonaktifkan lokasi ini kapan saja.';

      showConfirm({
        title,
        subtitle,
        onConfirm: async () => onActivateLocation(data, false),
        onCancel: null,
        showCancel: true,
        cancelText: 'Batal'
      });
    }
  };

  const columns: Column[] = [
    {
      header: 'Nama Lokasi',
      binding: 'location_name',
      template: ColTemplLocationName
    },
    {
      header: 'Tipe',
      binding: 'location_type',
      template: ColTemplService
    },
    {
      header: 'Aktif',
      binding: 'is_active',
      width: 100,
      allowSorting: false,
      template: (ctx, data) => {
        return (
          <Switch
            disabled={disabledActive}
            checked={data.is_active}
            className="switch-small m-1"
            onChange={(e) => onLocationChange(data, e.currentTarget.checked)}
          />
        );
      }
    }
  ];

  const getQuery = (q: QueryParameters) => {
    query.current = q;
  };

  const getSetQuery = (fn) => {
    setQuery.current = fn;
  };

  const getFetcher = async (options: QueryParameters) => {
    options.sortBy = 'location_id';
    const res = await newGetLocationList(options);
    let newRes = [];
    const zeroAndMinusOne = res?.data?.data.filter((obj) => obj.location_id === 0 || obj.location_id === -1);
    const nonZeroAndMinusOne = res?.data?.data.filter((obj) => obj.location_id !== 0 && obj.location_id !== -1);
    newRes = [...zeroAndMinusOne, ...nonZeroAndMinusOne];
    const data = {
      data: newRes || [],
      totalCount: res?.data?.totalCount || '0'
    };
    setListWarehouse(newRes);
    return data;
  };

  const fetcher = React.useMemo(() => {
    return (options: QueryParameters) => {
      return getFetcher(options);
    };
  }, []);

  const onDelete = async (val) => {
    const payload = { ids: val?.map((obj) => obj.location_id) };
    const res: any = await deleteLocationSetting(payload);
    if (res) {
      showAlert('success', 'Hapus lokasi berhasil');
      refresher.current();
    }
    return true;
  };

  const isRowLocked = (row) => {
    return row.location_id === -1 || row.location_id === 0;
  };

  const getOnBoardingSetup = async () => {
    try {
      const res = await setWizardSetup();
      const setupData = res?.data?.setup?.use_accounting;
      setIsAccounting(setupData);
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  useEffect(() => {
    clearLocation();
    clearParam();
    setDisablePrevButton(false);
    canSkip();
    getOnBoardingSetup();
  }, []);

  const onSearch = (value) => {
    const newQuery = { ...query.current, q: value.q };
    if (value.type?.length > 0) {
      let newType = value.type.map((t) => t.value);
      newQuery.type = newType.toString();
    } else {
      delete newQuery.type;
    }

    setQuery.current(newQuery);
  };

  const debounceSearch = debounce(onSearch, 500);

  return (
    <>
      <Grid container>
        <Grid item md={12} className="font-size-md font-weight-bold">
          Daftar Lokasi Gudang
        </Grid>
      </Grid>
      <Form formHooks={formhooks} values={defaultFilter} onSubmit={debounceSearch}>
        <Grid container spacing={1} className="d-flex align-items-center">
          <Grid item md={3}>
            <TextInput
              variant="outlined"
              name="q"
              label=""
              fullWidth
              className="no-notch"
              placeholder="Cari lokasi"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchSVG />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item md={3}>
            <Dropdown
              label=""
              data={TYPE_WAREHOUSE_LOCATION}
              name="type"
              placeholder="Pilih tipe"
              className="notch-dropdown multiple-dropdown__primary"
              multiple
            />
          </Grid>
          <Grid item md={3}>
            <AsyncButton type="submit" className="px-4" variant="contained" color="primary">
              Terapkan
            </AsyncButton>
          </Grid>

          <Grid item md={3} className="text-right">
            <Button
              className="px-2"
              variant="contained"
              color="primary"
              onClick={(e) => setAnchorEl(e.currentTarget)}
              endIcon={<ArrowDownSVG className="text-white ml-2" />}
            >
              Tambah Baru
            </Button>
            <ButtonChild />
          </Grid>
        </Grid>
      </Form>
      <Grid container>
        <Grid item md={12}>
          <LiteGrid
            columns={columns}
            data={fetcher}
            getQuery={getQuery}
            getSetQuery={getSetQuery}
            getRefresher={(e) => (refresher.current = e)}
            showRowSelect
            allowDelete
            onDelete={onDelete}
            isRowLocked={isRowLocked}
            initialSort="location_name ASC"
            gridName="setting_Location"
            onSelectedChanged={(row) => setDisabledActive(row.length > 0)}
            // havePagination={false}
          />
          {/* <SinglePagination
              pagination={pagination}
              onPageSizeChange={onPageSizeChange}
              totalItems={totalItems}
              onChangePage={onChangePage}
            /> */}
        </Grid>
      </Grid>
    </>
  );
};

export default StepSevenList;
