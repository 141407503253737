import React from 'react';
import styled, { css } from 'styled-components';

interface Props {
  fullGrid?: boolean;
  marginBlock?: boolean;
}
const Dividers = styled.div<Props>`
    background-color: rgba(0, 0, 0, 0.15);
    height: 1px;
    width: 100%;
    margin: ${({ marginBlock }) => marginBlock ? '7px 0 1rem' : 'auto'};
    ${({ fullGrid }) => fullGrid && css`
      grid-column: 1 / -1;
    `}
`;
const HorizontalDividers: React.FunctionComponent<Props> = ({ fullGrid, marginBlock }) => {
  return (
    <Dividers fullGrid={fullGrid} marginBlock={marginBlock} />
  );
};

export default HorizontalDividers;