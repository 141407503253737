import React from 'react';
import { Pagination, PaginationItem } from '@mui/material';
import { PaginationLastSVG } from 'assets/img/pagination-last';
import { PaginationFirstSVG } from 'assets/img/pagination-first';
import { PaginationNextSVG } from 'assets/img/pagination-next';
import { PaginationPreviousSVG } from 'assets/img/pagination-previous';

type PaginationMasterProps = {
  position: string;
  pagination: any;
  totalItems: number;
  onChangePage: (e: any, value: any) => void;
};
export const PaginationMaster: React.FC<PaginationMasterProps> = ({
  position,
  pagination,
  totalItems,
  onChangePage
}) => {
  // const { t } = useTranslation('components');
  // const start = totalItems > 0 ? (pagination.page - 1) * pagination.pageSize + 1 : 0;
  // const end = pagination.page * pagination.pageSize;

  return (
    <div className={`border-light-gray d-flex align-items-center ${position}`}>
      {/* {totalItems > pagination.pageSize && ()} */}
      <Pagination
        className="d-inline-flex"
        count={Math.ceil(totalItems / pagination.pageSize)}
        onChange={onChangePage}
        page={pagination.page}
        showFirstButton
        showLastButton
        renderItem={(item) => (
          <PaginationItem
            className="p-2"
            components={{
              last: () => <PaginationLastSVG width={11} height={12} />,
              first: () => <PaginationFirstSVG width={11} height={12} />,
              next: () => <PaginationNextSVG width={11} height={12} />,
              previous: () => <PaginationPreviousSVG width={11} height={12} />
            }}
            {...item}
          />
        )}
      />
    </div>
  );
};
