/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useFormContext, Controller, FieldError } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';
import { InputFieldProps } from './Form.types';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import DOMPurify from 'dompurify';
interface CustomProps {
  onChange?: (event: { target: { name: string; value: string } }) => void;
  name?: string;
  error?: boolean;
}

export const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props: NumberFormatProps & CustomProps,
  ref
): React.ReactElement {
  return (
    <NumberFormat
      {...props}
      onChange={(value) => null}
      getInputRef={ref}
      onValueChange={(values) => {
        props.onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      allowNegative={false}
      thousandSeparator={'.'}
      decimalSeparator={','}
    />
  );
});

export const FormatNumber = React.forwardRef(function NumberFormatCustomm(
  props: NumberFormatProps & CustomProps,
  ref
): React.ReactElement {
  return (
    <NumberFormat
      {...props}
      onChange={(value) => null}
      getInputRef={ref}
      onValueChange={(values) => {
        props.onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      allowNegative={false}
    />
  );
});

NumberFormatCustom.defaultProps = {
  thousandSeparator: '.',
  decimalSeparator: ',',
  allowNegative: false,
  isNumericString: true
};

export const TextInput: React.FC<TextFieldProps & InputFieldProps> = ({
  name,
  variant = 'outlined',
  fullWidth = true,
  size = 'small',
  margin = 'normal',
  format,
  useNotch,
  value,
  defaultValue,
  ...args
}: TextFieldProps & InputFieldProps) => {
  const ctx = useFormContext();
  const {
    control,
    formState: { errors }
  } = ctx;
  const error = (errors && name && errors[name]) as any as FieldError;

  const handleFocus = (e) => {
    if (e.target.value == 0) {
      e.target.select();
    }
  };

  const handleBlur = (e) => {
    if (args.onBlur) {
      args.onBlur(e.target.value);
    }
  };

  function renderField({ field }) {
    field.value = DOMPurify.sanitize(field.value);
    let errorMessage = '';
    let rgx = '';
    if (error) {
      if (field.value !== '') {
        switch (error.type) {
          case 'number.base':
            errorMessage = `${args.label} Harus berupa angka`;
            break;
          case 'number.greater':
            rgx = error.message.match(/\d+/g).join('');
            errorMessage = `${args.label} Harus lebih besar dari ${rgx}`;
            break;
          case 'number.min':
            errorMessage = `${args.label} Harus lebih besar atau sama dengan 0`;
            break;
          case 'string.empty':
            errorMessage = `${args.label} Tidak boleh kosong`;
            break;
          case 'manual':
            errorMessage = error.message;
            break;
          default:
            errorMessage = error.message.replace(name, (args.label as string) || args.placeholder);
            break;
        }
      } else {
        if (error.type === 'manual') {
          errorMessage = error.message;
        } else {
          errorMessage = `${args.label} Tidak boleh kosong`;
        }
      }
    }

    let inputProps = { ...args.inputProps };
    if (format === 'currency' || format === 'number') {
      if (!isNaN(field.value)) {
        inputProps = {
          ...inputProps
        };
        inputProps.style = { textAlign: 'right' };
      }
    }

    const restOfProps = { ...args };
    delete restOfProps.inputProps;
    delete restOfProps.helperText;

    if (useNotch) {
      return (
        <TextField
          variant={variant}
          size={size}
          margin={margin}
          fullWidth={fullWidth}
          error={Boolean(error)}
          onFocus={handleFocus}
          inputProps={{ onBlur: handleBlur, ...inputProps }}
          // helperText={error ? error.message.replace(name, args.label) : null}
          helperText={errorMessage}
          InputProps={{
            inputComponent: restOfProps.InputProps.inputComponent,
            sx: {
              '& .MuiOutlinedInput-notchedOutline': {
                bgcolor: args.disabled ? 'rgb(0,0,0,0.05)' : 'none'
              }
            }
          }}
          {...restOfProps}
          {...field}
        />
      );
    }

    return (
      <TextField
        variant={variant}
        size={size}
        margin={margin}
        fullWidth={fullWidth}
        error={Boolean(error)}
        className="no-notch"
        onFocus={handleFocus}
        inputProps={{ onBlur: handleBlur, ...inputProps }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          sx: {
            '& .MuiOutlinedInput-notchedOutline': {
              bgcolor: args.disabled ? 'rgb(0,0,0,0.05)' : 'none'
            }
          }
        }}
        // helperText={error ? error.message.replace(name, args.label) : null}
        helperText={
          <div className="d-flex justify-content-between">
            <span style={{ flex: '1 1 auto' }}>{!error ? args.helperText : errorMessage}</span>
            <span className="text-right" style={{ flex: '1 1 auto' }}>
              {inputProps.maxLength && `${field.value.length}/${inputProps.maxLength}`}
            </span>
          </div>
        }
        {...restOfProps}
        {...field}
      />
    );
  }

  function renderControlled() {
    return (
      <TextField
        inputRef={args.inputRef}
        value={value}
        defaultValue={defaultValue}
        variant={variant}
        size={size}
        margin={margin}
        fullWidth={fullWidth}
        multiline={args.multiline}
        rows={args.rows}
        className="no-notch"
        placeholder={args.placeholder}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          sx: {
            '& .MuiOutlinedInput-notchedOutline': {
              bgcolor: args.disabled ? 'rgb(0,0,0,0.05)' : 'none'
            }
          }
        }}
      />
    );
  }

  if (ctx && name) {
    return <Controller name={name} control={control} render={renderField} />;
  }

  return renderControlled();
};
