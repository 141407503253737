import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  font12: {
    fontSize: '12px'
  },
  productText: {
    display: 'table',
    tableLayout: 'fixed',
    width: '100%',
    textAlign: 'start'
  },
  fontTable: {
    fontSize: '12px',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'start'
  },
  tabs: {
    '& .MuiTabs-flexContainer > .MuiTab-root': {
      padding: '16px 24px'
    },
    '& .MuiTabs-indicator': {
      backgroundColor: 'none',
      minHeight: '0px !important',
      height: '0px !important',
      borderBottom: '3px solid #4B68EF'
    },
    '& .MuiTab-wrapper': {
      textTransform: 'capitalize',
      fontWeight: 'bold',
      fontSize: 16
    },
    '& .Mui-selected': {
      color: '#4B68EF'
    }
  },
  alertContainer: {
    width: 434,
    height: 308,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  alertContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  alertMessage: {
    marginTop: 23,
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '19px',
    textAlign: 'center',
    color: '#163A50',
    justifyContent: 'center',
    marginBottom: 10
  },
  modalContainer: {
    padding: '20px 30px'
  },
  modalText: {
    fontWeight: '400',
    lineHeight: '19px',
    color: '#163A50'
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '25px 30px 25px 30px',
    borderTop: '1px solid #DDDCDC'
  },

  font14: {
    fontSize: '14px'
  },
  hide: {
    display: 'none'
  },
  sideMenu: {
    padding: '12px 22px',
    border: '1px solid #E5E3E3',
    borderRadius: 15,
    marginBottom: 11,
    color: '#163A50'
  },
  activeMenu: {
    background: '#F1F9FF'
  },
  label: {
    fontWeight: 600,
    fontFamily: 'Nunito Sans',
    lineHeight: '19px',
    color: '#163A50',
    fontSize: '14px'
  },
  description: {
    fontWeight: 400,
    fontFamily: 'Nunito Sans',
    lineHeight: '19px',
    color: '#163A50'
  },
  containerAddRowTable: {
    background: '#ffffff',
    border: '0.5px solid #D4D5D6',
    position: 'absolute',
    left: 0,
    width: '100%',
    zIndex: 30,
    paddingTop: '18px',
    paddingBottom: '18px',
    paddingLeft: '16px',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    marginTop: '8px'
  },
  multiWarehouseGrid: {
    '& .MuiTableContainer-root': {
      minHeight: 'unset!important'
    }
  },
  inputFile: {
    width: '100%'
  },
  inputFileLabel: {
    height: 48,
    width: '100%',
    background: '#F5FAFF',
    display: 'flex',
    alignItems: 'center',
    padding: 15,
    justifyContent: 'space-between'
  },
  downloadFileTemp: {
    height: 30,
    width: '100%',
    background: '#F5FAFF',
    display: 'flex',
    padding: '5px 0px'
  }
}));
