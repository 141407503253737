import HorizontalDividers from 'components/CustomDividers/HorizontalDividers';
import VerticalDividers from 'components/CustomDividers/VerticalDividers';
import { DialogContentStyled, Label, LeftContentWrapper, RightContentWrapper } from 'components/Dialog/DialogContent';
import { LeftContent } from 'components/Dialog/DialogLeftContent';
import { Bottom, BottomActions, ConnectButton, RightForm } from 'components/Dialog/DialogRightContent';
import { ErrorMessage, Input, InputGrid } from 'components/Form';
import WarningIcon from '@mui/icons-material/Warning';
import React, { useEffect, useState } from 'react';
import { FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getTokopediaIntegrations, integrateTokopediaOS, integrateTokopediaPM } from 'api/marketPlaces';
import { useAppStore } from 'stores/appStore';
import { DialogProps } from 'interfaces/Props';
import { showAlert } from 'components/Alert';
import { channels } from 'shared/constants';
import config from 'config';

interface OfficialStoreValues {
  store_name: string;
  store_id: string;
  app_id: string;
  client_id: string;
  client_secret: string;
}
interface PowerMerchantValues {
  store_name: string;
  store_id: string;
  integration_type: string;
}

interface Integration {
  integration_id: number;
  integration_name: string;
}

const TokopediaContent: React.FunctionComponent<DialogProps> = ({ handleClosePrimary, getListChannel }) => {
  const { setShowConnectedPopup, indexAccordion, disablePrevButton } = useAppStore();
  const [currentType, setCurrentType] = useState('0');
  const handleCurrentType = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setCurrentType((e.target as HTMLInputElement).value);
  const [integrationType, setIntegrationType] = useState('1');
  const [integrationList, setIntegrationList] = useState([]);

  const handleIntegration = (event: SelectChangeEvent) => {
    setIntegrationType(event.target.value);
  };

  const {
    register: registerOfficialStore,
    handleSubmit: handleSubmitOfficialStore,
    formState: { errors: errorsOfficialStore }
  } = useForm<OfficialStoreValues>();
  const {
    register: registerPowerMerchant,
    handleSubmit: handleSubmitPowerMerchant,
    formState: { errors: errorsPowerMerchant }
  } = useForm<PowerMerchantValues>();

  const onSubmitOfficialStore: SubmitHandler<OfficialStoreValues> = async (integration) => {
    try {
      const integrationData = {
        shopName: integration.store_name,
        isCustom: 'Custom',
        fsId: integration.app_id,
        shopId: integration.store_id,
        secret: integration.client_secret,
        clientId: integration.client_id,
        is_tokocabang: false
      };

      const res = await integrateTokopediaOS(integrationData);
      if (res && res.data && res.data.status !== 'error') {
        if (!disablePrevButton) {
          getListChannel();
          handleClosePrimary();
          location.replace(config.baseUrl + `/?integrated=true&activeTab=marketplace&id=${indexAccordion}`);
        } else {
          location.replace(config.baseUrl);
        }
      } else {
        showAlert('error', `${res?.data?.message}`, 5000);
      }
    } catch (err) {
      const error = err as Error;
      console.error(error);
      showAlert('error', `${error}`, 5000);
    }
  };
  const onSubmitPowerMerchant: SubmitHandler<PowerMerchantValues> = async (integration) => {
    try {
      const integrationData = {
        shopName: integration.store_name,
        shopId: integration.store_id,
        isCustom: integrationType
      };

      const res = await integrateTokopediaPM(integrationData);
      if (res && res.data && res.data.status !== 'error') {
        if (!disablePrevButton) {
          setShowConnectedPopup(true);
          getListChannel();
          handleClosePrimary();
        } else {
          location.replace(config.baseUrl);
        }
      } else {
        showAlert('error', `${res?.data?.message}`, 5000);
      }
    } catch (err) {
      const error = err as Error;
      console.error(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const initializationIntegration = async () => {
    try {
      const { data } = await getTokopediaIntegrations();
      setIntegrationList(data);
    } catch (err) {
      const error = err as Error;
      console.error(error);
    }
  };

  useEffect(() => {
    initializationIntegration();
  }, []);

  return (
    <DialogContentStyled>
      <LeftContentWrapper>
        <LeftContent channelId={channels.TOKOPEDIA} currentType={currentType} />
      </LeftContentWrapper>
      <VerticalDividers />
      <RightContentWrapper>
        <div style={{ padding: '2rem 1.3rem' }}>
          <InputGrid
            label={<Label className="requiredField">Tipe Toko</Label>}
            input={
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={currentType}
                  onChange={(e) => handleCurrentType(e)}
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio size="small" />}
                    label={<p style={{ fontSize: '12px', margin: '0px' }}>Official Store</p>}
                  />
                  <div style={{ width: '1.5rem' }} />
                  <FormControlLabel
                    value="1"
                    control={<Radio size="small" />}
                    label={<p style={{ fontSize: '12px', margin: '0px' }}>Power Merchant</p>}
                  />
                </RadioGroup>
              </FormControl>
            }
          />{' '}
          <br />
          {currentType === '0' && (
            <RightForm
              no_padding={true}
              id="tokopedia_form"
              onSubmit={handleSubmitOfficialStore(onSubmitOfficialStore)}
            >
              <InputGrid
                label={<Label className="requiredField">Nama Toko</Label>}
                input={
                  <Input
                    isError={errorsOfficialStore.store_name?.message ? true : false}
                    type={'text'}
                    placeholder={'Masukkan Nama Toko'}
                    {...registerOfficialStore('store_name', {
                      required: { value: true, message: 'Nama Toko harus di isi' }
                    })}
                  />
                }
                errorMessage={
                  errorsOfficialStore?.store_name && (
                    <ErrorMessage
                      icon={<WarningIcon fontSize="small" color="error" />}
                      message={errorsOfficialStore.store_name.message}
                    />
                  )
                }
              />
              <InputGrid
                label={<Label className="requiredField">ID Toko</Label>}
                input={
                  <Input
                    isError={errorsOfficialStore.store_id?.message ? true : false}
                    type={'number'}
                    placeholder={'Masukkan ID Toko'}
                    {...registerOfficialStore('store_id', {
                      required: { value: true, message: 'ID Toko harus di isi' }
                    })}
                  />
                }
                errorMessage={
                  errorsOfficialStore?.store_id && (
                    <ErrorMessage
                      icon={<WarningIcon fontSize="small" color="error" />}
                      message={errorsOfficialStore.store_id.message}
                    />
                  )
                }
              />
              <InputGrid
                label={<Label className="requiredField">App ID</Label>}
                input={
                  <Input
                    isError={errorsOfficialStore.app_id?.message ? true : false}
                    type={'number'}
                    placeholder={'App ID'}
                    {...registerOfficialStore('app_id', {
                      required: { value: true, message: 'App ID harus di isi' }
                    })}
                  />
                }
                errorMessage={
                  errorsOfficialStore?.app_id && (
                    <ErrorMessage
                      icon={<WarningIcon fontSize="small" color="error" />}
                      message={errorsOfficialStore.app_id.message}
                    />
                  )
                }
              />
              <InputGrid
                label={<Label className="requiredField">Client ID</Label>}
                input={
                  <Input
                    isError={errorsOfficialStore.client_id?.message ? true : false}
                    type={'text'}
                    placeholder={'arr.riana@jubelio.com'}
                    {...registerOfficialStore('client_id', {
                      required: { value: true, message: 'Client ID harus di isi' }
                    })}
                  />
                }
                errorMessage={
                  errorsOfficialStore?.client_id && (
                    <ErrorMessage
                      icon={<WarningIcon fontSize="small" color="error" />}
                      message={errorsOfficialStore.client_id.message}
                    />
                  )
                }
              />
              <InputGrid
                label={<Label className="requiredField">Client Secret</Label>}
                input={
                  <Input
                    isError={errorsOfficialStore.client_secret?.message ? true : false}
                    type={'password'}
                    placeholder={'Client Secret'}
                    {...registerOfficialStore('client_secret', {
                      required: { value: true, message: 'Client Secret harus di isi' }
                    })}
                  />
                }
                errorMessage={
                  errorsOfficialStore?.client_secret && (
                    <ErrorMessage
                      icon={<WarningIcon fontSize="small" color="error" />}
                      message={errorsOfficialStore.client_secret.message}
                    />
                  )
                }
              />
            </RightForm>
          )}
          {currentType === '1' && (
            <RightForm
              no_padding={true}
              id="tokopedia_form"
              onSubmit={handleSubmitPowerMerchant(onSubmitPowerMerchant)}
            >
              <InputGrid
                label={<Label className="requiredField">Nama Toko</Label>}
                input={
                  <Input
                    isError={errorsPowerMerchant.store_name?.message ? true : false}
                    type={'text'}
                    placeholder={'Masukkan Nama Toko'}
                    {...registerPowerMerchant('store_name', {
                      required: { value: true, message: 'Nama Toko harus di isi' }
                    })}
                  />
                }
                errorMessage={
                  errorsPowerMerchant?.store_name && (
                    <ErrorMessage
                      icon={<WarningIcon fontSize="small" color="error" />}
                      message={errorsPowerMerchant.store_name.message}
                    />
                  )
                }
              />
              <InputGrid
                label={<Label className="requiredField">ID Toko</Label>}
                input={
                  <Input
                    isError={errorsPowerMerchant.store_id?.message ? true : false}
                    type={'text'}
                    placeholder={'Masukkan ID Toko'}
                    {...registerPowerMerchant('store_id', {
                      required: { value: true, message: 'ID Toko harus di isi' }
                    })}
                  />
                }
                errorMessage={
                  errorsPowerMerchant?.store_id && (
                    <ErrorMessage
                      icon={<WarningIcon fontSize="small" color="error" />}
                      message={errorsPowerMerchant.store_id.message}
                    />
                  )
                }
              />
              <InputGrid
                label={<Label className="requiredField">Jenis Integrasi</Label>}
                input={
                  <FormControl fullWidth size="small" sx={{ padding: '0' }}>
                    <Select
                      value={integrationType}
                      onChange={handleIntegration}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      sx={{ padding: '0', fontSize: '13px' }}
                      placeholder={'Pilih Jenis Integrasi'}
                    >
                      {integrationList.map((integration: Integration, index) => (
                        <MenuItem key={index} sx={{ fontSize: '12px' }} value={integration.integration_id}>
                          {integration.integration_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
              />
            </RightForm>
          )}
        </div>
        <Bottom>
          <HorizontalDividers />
          <BottomActions>
            <ConnectButton form="tokopedia_form">Sambungkan</ConnectButton>
          </BottomActions>
        </Bottom>
      </RightContentWrapper>
    </DialogContentStyled>
  );
};

export default TokopediaContent;
