import styled from 'styled-components';

interface Props {
  top: boolean | undefined,
}
interface ContainerProps {
  wide: boolean | undefined,
}

export const InputContainer = styled.div<ContainerProps>`
    display: grid;
    grid-template-columns: ${(props) => props.wide ? '1fr 2fr' : '1fr 3fr'};
    grid-template-rows: auto auto;
`;
export const InputLabel = styled.div<Props>`
    padding-top: 7px;
    position: relative;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    display: flex;
    align-items: ${(props) => props.top ? 'flex-start' : 'center'};
`;
export const InputValue = styled.div`
    grid-column: 2 / 3;
    grid-row: 1 / 2;
`;
export const ErrorMessage = styled.div`
    grid-column: 2 / 3;
    grid-row: 2 / 3;
`;