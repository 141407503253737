import React from 'react';

export const WarningSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="52" height="45" viewBox="0 0 52 45" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M26.0006 0.666504L0.333984 44.9998H51.6673L26.0006 0.666504ZM26.0006 9.99984L43.5706 40.3332H8.43065L26.0006 9.99984ZM23.6673 19.3332V28.6665H28.334V19.3332H23.6673ZM23.6673 33.3332V37.9998H28.334V33.3332"
        fill="#FF9800"
      />
    </svg>
  );
};
