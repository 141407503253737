import React, { ReactElement } from 'react';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import { useAppStore } from 'stores/appStore';
import CloseIcon from '@mui/icons-material/Close';
import HorizontalDividers from 'components/CustomDividers/HorizontalDividers';
import config from 'config';

interface Props {
  open: boolean;
  handleClose: () => void;
  title: ReactElement | undefined;
  content: ReactElement | undefined;
}

const PrimaryDialog: React.FunctionComponent<Props> = ({ open, handleClose, title, content }) => {
  const { disablePrevButton } = useAppStore();

  return (
    <Dialog
      open={open}
      fullWidth={true}
      PaperProps={{
        style: {
          minWidth: '933px',
          minHeight: '610px'
        }
      }}
    >
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={!disablePrevButton ? handleClose : () => location.replace(config.baseUrl)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            fontWeight: 'bold',
            color: (theme) => theme.palette.grey[700]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <HorizontalDividers />
      {content}
    </Dialog>
  );
};

export default PrimaryDialog;
