import React, { useState } from 'react';
import { StepContainer, Title, TitleDescription } from 'components/atoms';
import { StepProps, ValidationConfirmation } from 'interfaces/Props';
import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { useAppStore } from 'stores/appStore';

import HorizontalDividers from 'components/CustomDividers/HorizontalDividers';
import UseDocumentTitle from 'hooks/UseDocumentTitle';

const StepNine: React.FunctionComponent<StepProps & ValidationConfirmation> = ({ submitRef }) => {
  UseDocumentTitle('Konfirmasi Data Onboarding');

  const { setShowConfirmation, updateStep } = useAppStore();

  const [allChecked, setAllChecked] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllChecked(event.target.checked && document.querySelectorAll('input[type="checkbox"]:checked').length === 4);
  };

  const handleSubmit = () => {
    if (!allChecked) {
      setShowConfirmation(true);
    } else {
      updateStep('INCREASE');
    }
  };

  return (
    <>
      <StepContainer>
        <Title>Konfirmasi Data Onboarding</Title>
        <TitleDescription>
          Periksa kesiapan Kamu sebelum memulai training dengan melengkapi data-data dibawah ini.
        </TitleDescription>
        <HorizontalDividers marginBlock={true} />
      </StepContainer>

      <TitleDescription>
        Mohon untuk melakukan konfirmasi dengan menchecklist step-step yang sudah Kamu lakukan:
      </TitleDescription>
      <FormGroup className="mb-4">
        <FormControlLabel
          control={<Checkbox defaultChecked={false} onChange={handleCheckboxChange} />}
          label="Saya telah selesai mendownload produk dengan SKU Produk yang sudah seragam."
        />
        <FormControlLabel
          control={<Checkbox defaultChecked={false} onChange={handleCheckboxChange} />}
          label="Saya telah selesai mengatur produk bundle."
        />
        <FormControlLabel
          control={<Checkbox defaultChecked={false} onChange={handleCheckboxChange} />}
          label="Saya telah selesai mengatur bagian gudang/lokasi."
        />
        <FormControlLabel
          control={<Checkbox defaultChecked={false} onChange={handleCheckboxChange} />}
          label="Saya telah selesai mengatur bagian import stok/HPP."
        />
      </FormGroup>
      <TitleDescription>
        Dengan mengkonfirmasi langkah-langkah yang sudah dilakukan di atas, Jubelio menyimpulkan data yang Kamu isi
        benar dan sudah sesuai adanya sehingga selanjutnya dapat dijadwalkan untuk onboarding mengenai penggunaan
        jubelio.
      </TitleDescription>
      <button onClick={handleSubmit} ref={submitRef} hidden>
        Submit
      </button>
    </>
  );
};

export default StepNine;
