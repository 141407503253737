import React from 'react';

export const ArrowDownSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.29297 0.29248L5.0003 3.58581L1.70764 0.29248L0.292969 1.70715L5.0003 6.41381L9.70763 1.70715L8.29297 0.29248Z"
        fill={props.fill ? props.fill : '#7F8084'}
      />
    </svg>
  );
};
