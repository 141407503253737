import React from 'react';

interface ImageProps {
  itemName: string;
  itemCode: string;
  variations?: string[];
  onClick?: (param: any) => void;
  image: string;
}

export const ItemDetails = (props: ImageProps) => {
  const { image, itemCode, itemName, variations, onClick } = props;

  return (
    <div className="d-flex" style={{ gap: '7px', width: '100%', marginTop: '10px', marginBottom: '10px' }} onClick={onClick}>
      {image ? (
        <img src={image} width="55px" height="55px" style={{ borderRadius: '6px' }} />
      ) : (
        <div style={{ backgroundColor: '#C4C4C4', width: '55px', height: '55px', borderRadius: '6px' }}></div>
      )}
      <div className="d-flex flex-column" style={{ gap: '2px', width: '100%' }}>
        <div style={{ color: '#163A50', fontSize: '14px', fontWeight: '600' }}>{itemName}</div>
        <div style={{ color: '#163A50', fontSize: '14px', fontWeight: 'normal' }}>{itemCode}</div>
        <div style={{ color: '#163A50', fontSize: '14px', fontWeight: 'normal' }}>
          {variations?.map((variation: any) => variation.label + ' : ' + variation.value + ' ')}
        </div>
      </div>
    </div>
  );
};
