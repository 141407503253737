/* eslint-disable no-bitwise */
import React from 'react';
import {
  Grid,
  Icon,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  AppBar,
  Toolbar,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Close } from '@mui/icons-material/';

import { ModalButton, ModalDialogProps } from '../ModalImport/Modal.types';
import { AsyncButton } from 'components/ButtonNew/Button';

function createButton(btn: string | ModalButton, onAction, inToolbar: boolean = false) {
  if (typeof btn === 'string') {
    return (
      <AsyncButton
        key={btn}
        type="button"
        busy={false}
        onClick={() => onAction(btn)}
        color={inToolbar ? 'inherit' : 'primary'}
        className="ml-2"
      >
        {btn}
      </AsyncButton>
    );
  }
  return (
    <AsyncButton
      key={btn.key}
      type={btn.type}
      disabled={btn.disabled}
      className="ml-2"
      busy={btn.busy}
      onClick={() => onAction(btn.key)}
      color={btn.color}
      icon={btn.icon}
      variant={btn.variant || 'contained'}
    >
      {btn.caption}
    </AsyncButton>
  );
}

const useIconStyles = makeStyles({
  root: {
    fontSize: '3em'
  }
});

const useAppBarStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    color: 'black',
    backgroundColor: 'white'
  },
  title: {
    marginLeft: '2px',
    flex: 1
  }
}));

export function ModalDialog(props: ModalDialogProps): React.ReactElement<ModalDialogProps> {
  const {
    open,
    children,
    title,
    onAction,
    buttons,
    icon,
    width,
    description,
    containerPaperClass,
    classNameActionContainer,
    sx
  } = props;
  const iconClasses = useIconStyles();
  const appBarClasses = useAppBarStyles();

  let maxWidth;
  let fullScreen = false;
  if (width !== 'full') {
    maxWidth = width || 'sm';
  } else {
    fullScreen = true;
  }

  return (
    <Dialog
      onClose={() => onAction(null)}
      disableEscapeKeyDown
      fullWidth
      open={open}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      classes={{ paper: containerPaperClass }}
      sx={sx}
    >
      {!fullScreen && (
        <>
          <DialogTitle>
            <div className="d-flex justify-content-between align-items-center">
              <span className="font-weight-bold" style={{ fontSize: 30 }}>
                {title}
              </span>
              <IconButton className="cursor-pointer" title="Close">
                <Close onClick={() => onAction(null)} />
              </IconButton>
            </div>
          </DialogTitle>
          {description && (
            <Typography variant="h6" className={appBarClasses.title}>
              {description}
            </Typography>
          )}
        </>
      )}
      {fullScreen && (
        <AppBar className={appBarClasses.appBar} elevation={0}>
          <Toolbar>
            <IconButton className="cursor-pointer" color="inherit" onClick={() => onAction(null)}>
              <Close />
            </IconButton>
            <Typography variant="h6" className={appBarClasses.title}>
              {title}
            </Typography>
            {description && <Typography variant="h6">{description}</Typography>}
            {buttons && buttons.map((btn) => createButton(btn, onAction, true))}
          </Toolbar>
        </AppBar>
      )}
      <DialogContent dividers className="p-0">
        <Grid container>
          {icon && (
            <Grid item md={3}>
              <Icon className={iconClasses.root}>{icon}</Icon>
            </Grid>
          )}
          <Grid item md={icon ? 9 : 12}>
            {children}
          </Grid>
        </Grid>
      </DialogContent>
      {!fullScreen && buttons && buttons.length ? (
        <DialogActions className={`${classNameActionContainer}`} style={{ padding: '23px 28px' }}>
          {buttons && buttons.map((btn) => createButton(btn, onAction))}
        </DialogActions>
      ) : null}
    </Dialog>
  );
}
