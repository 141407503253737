import React from 'react';

export const SearchSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 2.00008C7.21207 2.00008 6.43185 2.15527 5.7039 2.4568C4.97595 2.75833 4.31451 3.20028 3.75736 3.75744C3.20021 4.31459 2.75825 4.97602 2.45672 5.70398C2.15519 6.43193 2 7.21215 2 8.00008C2 8.78801 2.15519 9.56822 2.45672 10.2962C2.75825 11.0241 3.20021 11.6856 3.75736 12.2427C4.31451 12.7999 4.97595 13.2418 5.7039 13.5434C6.43185 13.8449 7.21207 14.0001 8 14.0001C9.5913 14.0001 11.1174 13.3679 12.2426 12.2427C13.3679 11.1175 14 9.59138 14 8.00008C14 6.40878 13.3679 4.88265 12.2426 3.75744C11.1174 2.63222 9.5913 2.00008 8 2.00008ZM4.94163e-08 8.00008C0.000182326 6.72694 0.304218 5.47222 0.886839 4.34022C1.46946 3.20821 2.31384 2.2316 3.3498 1.49156C4.38577 0.751517 5.5834 0.26941 6.84315 0.0853069C8.10291 -0.0987963 9.38842 0.0204215 10.5928 0.433052C11.7973 0.845683 12.8858 1.53981 13.768 2.45774C14.6502 3.37568 15.3006 4.49091 15.6651 5.71075C16.0296 6.9306 16.0977 8.21982 15.8638 9.47128C15.6298 10.7227 15.1006 11.9003 14.32 12.9061L17.707 16.2931C17.8892 16.4817 17.99 16.7343 17.9877 16.9965C17.9854 17.2587 17.8802 17.5095 17.6948 17.6949C17.5094 17.8803 17.2586 17.9855 16.9964 17.9878C16.7342 17.99 16.4816 17.8892 16.293 17.7071L12.906 14.3201C11.7235 15.2382 10.307 15.806 8.81777 15.9591C7.32851 16.1121 5.82619 15.8442 4.48165 15.1858C3.1371 14.5273 2.00429 13.5049 1.21202 12.2346C0.419759 10.9643 -0.00016632 9.49718 4.94163e-08 8.00008Z"
        fill="#BCBCBC"
      />
    </svg>
  );
};
