import { coreAPI } from './axiosClient';
import config from 'config';

interface TokopediaIntegrationOS {
  isCustom?: string,
  fsId: string,
  shopId: string,
  secret: string,
  clientId: string,
  shopName: string,
  is_tokocabang: boolean
}

interface TokopediaIntegrationPM {
  isCustom?: string,
  shopId: string,
  shopName: string,
}
interface BlibliIntegration {
  merchantId: string,
  apiSellerKey: string,
  mergeOrder: boolean,
  update: boolean
}

export const postStatusMarketplace = (data: any) => {
  return coreAPI({
    method: 'POST',
    url: '/marketplace/isactive',
    data: {
      storeId: data.storeId, 
      isActive: data.isActive,
    },
  });
};

export const lazadaAuthorize = (email: string, index, disablePrevButton) => {
  return coreAPI({
    method: 'POST',
    url: '/lazada/register',
    data: {
      email,
    },
    params: {
      state_url: !disablePrevButton ? encodeURIComponent(config.baseUrl + `/?integrated=true&activeTab=market_place&id=${index}`) : config.baseUrl,
    },
  });
};

export const getShopeeAuthorizeUrl = (index, disablePrevButton) => {
  return coreAPI({
    method: 'GET',
    url: '/shopee/authorize',
    params: {
      state_url: !disablePrevButton ? encodeURIComponent(config.baseUrl + `/?integrated=true&activeTab=market_place&id=${index}`) : config.baseUrl,
    },
  });
};

export const getAkulakuAuthorizeUrl = (store_name: string, index) => {
  return coreAPI({
    method: 'POST',
    url: '/akulaku/register',
    data: {
      store_name,
    },
    params: {
      state_url: encodeURIComponent(config.baseUrl + `/?integrated=true&activeTab=market_place&id=${index}`),
    },
  });
};

export const getTiktokAuthorizeUrl = (data: any, index, disablePrevButton) => {
  return coreAPI({
    method: 'GET',
    url: '/tiktok/authorize',
    data: {
      app_key: data.app_key,
      app_secret: data.app_secret,
    },
    params: {
      state_url: !disablePrevButton ? encodeURIComponent(config.baseUrl + `/?integrated=true&activeTab=social_commerce&id=${index}`) : config.baseUrl,
    },
  })
}

export const getTadaAuthorizeUrl = (data: any) => {
  return coreAPI({
    method: 'POST',
    url: '/tada/register',
    data: {
      store_name: data.store_name,
      apiKey: data.apiKey,
      apiSecret: data.apiSecret,
      programId: data.programId,
      catalogId: data.catalogId
    }
  })
}

export const getZaloraAuthorizeUrl = (data: any) => {
  return coreAPI({
    method: 'POST',
    url: '/zalora/register',
    data: {
      apiKey: data.apiKey,
      email: data.email,
    },
  })
}

export const getFacebookAuthorizeUrl = (business_id: string, index, disablePrevButton) => {
  return coreAPI({
    method: 'POST',
    url: '/facebook/authorize',
    data: {
      business_id,
    },
    params: {
      state_url: !disablePrevButton ? encodeURIComponent(config.baseUrl + `/?integrated=true&activeTab=social_commerce&id=${index}`) : config.baseUrl,
    },
  });
};

export const getEvermoseAuthorizeUrl = (data: any) => {
    return coreAPI({
    method: 'POST',
    url: '/evermos/register',
    data: {
      storeName: data.storeName,
      clientKey: data.clientKey,
      clientSecret: data.clientSecret,
      url: data.url,
      brandId: data.brandId,
      type: 'PRODUCTION'
    }
  })
}

export const getBukalapakAuthorizeUrl = (index) => {
  return coreAPI({
    method: 'GET',
    url: '/bukalapak/authorize-url',
    params: {
      state_url: encodeURIComponent(config.baseUrl + `/?integrated=true&activeTab=market_place&id=${index}&`)
    },
  });
};

export const bukalapakAuthorize = (code: string) => {
  return coreAPI({
    method: 'POST',
    url: '/bukalapak/authorize',
    data: {
      isNext: true,
      code,
      state_url: encodeURIComponent(config.baseUrl + '/?integrated=true&activeTab=market_place&id=2&')
    },
  });
};

export const getTokopediaIntegrations = () => coreAPI({
  method: 'GET',
  url: '/tokopedia/integrations',
});

export const integrateTokopediaOS = ({ isCustom, fsId, shopId, secret, clientId, shopName, is_tokocabang }: TokopediaIntegrationOS) => coreAPI({
  method: 'POST',
  url: '/tokopedia/register',
  data: {
    isCustom,
    fsId,
    shopId,
    secret,
    clientId,
    shopName,
    is_tokocabang,
  },
});

export const integrateTokopediaPM = ({ isCustom, shopId, shopName }: TokopediaIntegrationPM) => coreAPI({
  method: 'POST',
  url: '/tokopedia/register',
  data: {
    isCustom: isCustom.toString(),
    shopId,
    shopName,
  },
});

export const blibliAuthorize = ({ merchantId, apiSellerKey, mergeOrder, update }: BlibliIntegration) => {
  return coreAPI({
    method: 'POST',
    url: '/blibli/register',
    data: {
      merchantId, apiSellerKey, mergeOrder, update,
    },
  });
};
