import React from 'react';
import styled from 'styled-components';

const Dividers = styled.div`
    background-color: rgba(0, 0, 0, 0.2);
    height: 100%;
    width: 1.1px;
`;

const VerticalDividers: React.FunctionComponent = () => {
  return <Dividers />;
};

export default VerticalDividers;