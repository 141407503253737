import React, { useEffect, useState } from 'react';
import { DialogTitle } from 'components/Dialog';
import { ChannelType } from './Contents';
import {
  AddedList,
  AddNew,
  BtnWrap,
  DotIcon,
  IntegrationStatus,
  StoreName,
  TabContent,
  TabContentItem,
  TableWrap
} from './ContentStyle';
import { IconButton, Switch } from '@mui/material';
import { ChannelDetail } from 'interfaces/Channels';
import { channelDetail } from 'shared/constants';
import { showAlert } from 'components/Alert';
import { postStatusMarketplace } from 'api/marketPlaces';
import { useAppStore } from 'stores/appStore';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import styled from 'styled-components';

interface BukalapakExtraInfo {
  apiSellerKey?: string;
  storeId: number;
  storeName: string;
  token?: {
    access_token: string;
    refresh_token: string;
  };
  expired?: boolean;
}
interface BlibliExtraInfo {
  apiSellerKey?: string;
  feed: [];
  merchantId: string;
  mergeOrder: boolean;
  token?: object | null;
  expired?: boolean;
}
interface ShopeeExtraInfo {
  apiSellerKey?: string;
  access_token: string;
  logistics: object[];
  mode: string;
  partnerId: number;
  refresh_token: string;
  secret: string;
  shopId: number;
  version: string;
  token?: object | null;
  expired?: boolean;
}
interface TokopediaExtraInfo {
  apiSellerKey?: string;
  clientId: string;
  fsId: number;
  mode: string;
  secret: string;
  shopId: number;
  shopName: string;
  token?: object | null;
  token_refreshed: string;
  expired?: boolean;
}
interface WoocommerceExtraInfo {
  apiSellerKey?: string;
  exclude_from_update: boolean;
  host: string;
  isJubelioStore: boolean;
  is_use_custom_order_status: boolean;
  version: string;
  token?: object | null;
  expired?: boolean;
}
interface DealposExtraInfo {
  apiSellerKey?: string;
  access_token: string;
  adjust_inventory: boolean;
  token?: object | null;
  expired?: boolean;
}
export interface Data {
  channel_full_name: string;
  channel_id: number;
  channel_name: string;
  channel_user_id?: string;
  channel_user_secret?: string;
  extra_info:
    | null
    | BukalapakExtraInfo
    | BlibliExtraInfo
    | ShopeeExtraInfo
    | TokopediaExtraInfo
    | WoocommerceExtraInfo
    | DealposExtraInfo;
  is_active: boolean;
  pause_sales_download: boolean;
  store_id: number;
  store_name: string;
  info?: {
    token: string;
  };
}
interface Props {
  data: ChannelType[];
  handleShowModal: (el: ChannelType, idx) => void;
  channelList: Array<Data>;
  getListChannel: () => void;
}
export interface IconProps {
  opened: number | undefined;
  idx: number;
}

const channels = channelDetail as ChannelDetail;

const ExpandMoreIconStyled = styled(ExpandMoreIcon)<IconProps>`
  transform: rotate(${({ opened, idx }) => (opened === idx ? '-180deg' : '0')});
  transition: transform 0.5s ease;
`;

const Accordion: React.FunctionComponent<Props> = ({ data, handleShowModal, channelList, getListChannel }) => {
  const [opened, setOpened] = useState<undefined | number>();
  const [isSwitchLoading, setIsSwitchLoading] = useState(false);
  const { indexAccordion, setIndexAccordion, disablePrevButton, channelIdOto } = useAppStore();

  const showChannelLists = (idx: number | undefined) => {
    if (opened === idx) {
      setOpened(undefined);
      setIndexAccordion(undefined);
    } else {
      setOpened(idx);
      setIndexAccordion(idx);
    }
  };

  const getChannelList = (channelId: number) => {
    return channelList.filter((channel) => channel.channel_id === channelId);
  };

  const getIntegrationBermasalah = (sub: Data) => {
    let isError;
    switch (sub.channel_id) {
      case 2:
        isError = sub.extra_info && sub.extra_info?.token === null;
        break;
      case 64:
        isError = sub.extra_info?.expired === true;
        break;
      case 4:
        isError = !sub.extra_info && !sub.info?.token;
        break;
      case 32:
        isError = !sub.extra_info?.token && !sub.extra_info?.apiSellerKey;
        break;
      default:
        isError = false;
    }
    return isError;
  };

  const handleSwitchChange = async (val: boolean, storeId: number) => {
    try {
      setIsSwitchLoading(true);
      let payload = {
        storeId,
        isActive: !val
      };
      const res = await postStatusMarketplace(payload);
      if (res.status === 200) {
        setIsSwitchLoading(false);
        getListChannel();
      }
      showAlert('success', 'Status berhasil diubah!', 5000);
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  useEffect(() => {
    if (indexAccordion !== undefined) {
      let newIndex = data.findIndex((val, idx) => idx == indexAccordion);
      setOpened(newIndex);
    }
  }, [indexAccordion]);

  useEffect(() => {
    if (disablePrevButton === true) {
      let content = data.find((val) => val.channel_id === channelIdOto);

      handleShowModal(content, 0);
    }
  }, [disablePrevButton]);

  return (
    <>
      {data.map((el, idx) => (
        <TabContent key={idx}>
          <TabContentItem>
            <DialogTitle logoSize={el.logoSize} image={el.image} altName={el.channel_name} />
            <BtnWrap>
              <span style={{ fontSize: '12px' }}>{getChannelList(el.channel_id).length} &nbsp; Toko</span>
              <AddNew
                is_disabled={
                  getChannelList(el.channel_id).length > 0 && [524288, 262144].includes(el.channel_id) ? true : false
                }
                disabled={
                  getChannelList(el.channel_id).length > 0 && [524288, 262144].includes(el.channel_id) ? true : false
                }
                onClick={() => handleShowModal(el, idx)}
              >
                <AddIcon fontSize="small" />
                Tambah Baru
              </AddNew>
              <IconButton onClick={() => showChannelLists(idx)}>
                <ExpandMoreIconStyled opened={opened} idx={idx} />
              </IconButton>
            </BtnWrap>
          </TabContentItem>
          <AddedList opened={opened} idx={idx}>
            <TableWrap opened={opened} idx={idx}>
              <table>
                <thead>
                  <tr>
                    <th>Nama Toko</th>
                    <th>Status Integrasi</th>
                    <th>Toko Aktif</th>
                  </tr>
                </thead>
                <tbody>
                  {getChannelList(el.channel_id).length > 0 ? (
                    getChannelList(el.channel_id).map((sub, subIdx) => (
                      <tr key={subIdx}>
                        <td>
                          <StoreName>
                            <img src={channels[el.channel_id].channelLogo} alt={channels[el.channel_id].channelName} />{' '}
                            {sub.store_name}
                          </StoreName>
                        </td>
                        <td>
                          <IntegrationStatus
                            bg_color={getIntegrationBermasalah(sub) ? '#fed8d8' : '#ddefdd'}
                            text_color={getIntegrationBermasalah(sub) ? '#df4d4e' : '#509b4c'}
                          >
                            <DotIcon bg_color={getIntegrationBermasalah(sub) ? '#df4d4e' : '#509b4c'} />
                            {getIntegrationBermasalah(sub) ? 'Integrasi Bermasalah' : 'Normal'}
                          </IntegrationStatus>
                          {getIntegrationBermasalah(sub) && (
                            <span style={{ color: '#df4d4e', fontSize: '10px' }}>Perlu otorisasi ulang</span>
                          )}
                        </td>
                        <td>
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Switch
                              className="switch-extrasmall m-1"
                              disabled={isSwitchLoading}
                              checked={sub.is_active === true ? true : false}
                              onChange={() => handleSwitchChange(sub.is_active, sub.store_id)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={3}
                        style={{ textAlign: 'center', padding: '2rem 0', fontWeight: 'bold', fontSize: '1rem' }}
                      >
                        No Data Available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </TableWrap>
          </AddedList>
        </TabContent>
      ))}
    </>
  );
};

export default Accordion;
