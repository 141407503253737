import React from 'react';
import Joi from 'joi';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { Form } from 'components/Form/Form';
import { TextInput } from 'components/Form/TextInput';
import { Grid, MenuItem, Select } from '@mui/material';
import { contentBroker, optionsBrokerType } from './constant';
import { useStyles } from './style';
import { showAlert } from 'components/Alert';
import { Image } from 'components/Image/Image';
import { getListStoreIntegration, saveLocation } from 'api/locations';
import { useAppStore } from 'stores/appStore';

import LazadaFullfilment from 'assets/img/lazada-fullfilment.png';
import ShopeeFullfilment from 'assets/img/shopee-fullfilment.png';
import TokpedFullfilment from 'assets/img/tokopedia-fullfilment.png';
import useAddLocationHook from './useAddLocationHook';
import useModalConfirmSubmit from './useModalConfirmSubmit';

type LocationDetailInfoThirdPartyProps = {
  locationId: string;
  locationDetail: any;
  getLocationDetail: () => void;
  setSubmitForm?: React.MutableRefObject<any>;
  pageSetter: any;
};

export type FormType = {
  type?: string;
  code: string;
  name: string;
  // automaticTransfer?: number;
  id?: number;
};

const schema = Joi.object({
  type: Joi.string(),
  code: Joi.string(),
  name: Joi.string(),
  // automaticTransfer: Joi.number().allow(null),
  id: Joi.number().allow(null)
});

type FormBuilder = {
  label: string;
  field_name: string;
  value: string | number;
  isMandatory?: boolean;
  type?: string;
  selectData?: any[];
  valueDesc?: string;
};

const defaultValues = {
  type: '',
  code: '',
  name: '',
  // automaticTransfer: null,
  id: null
};

const LocationDetailInfoThirdParty: React.FC<LocationDetailInfoThirdPartyProps> = ({
  locationId,
  locationDetail,
  getLocationDetail,
  setSubmitForm,
  pageSetter
}) => {
  const classes = useStyles();
  const { modeEdit } = useAppStore();
  const [isEdit, setIsEdit] = React.useState(!locationId || locationId === '0');
  const [busy, setBusy] = React.useState(false);
  const [formValues, setFormValues] = React.useState<FormType>(defaultValues);
  const [selectedContent, setSelectedContent] = React.useState(contentBroker.TOKOPEDIA);
  // const [inboundList, setInboundList] = React.useState([]);
  const [storeList, setStoreList] = React.useState([]);
  const [isPopulatedData, setIsPopulatedData] = React.useState(false);
  const [selectedDetail, setSelectedDetail] = React.useState({}) as any;

  const submitRef = React.useRef<HTMLButtonElement>();

  const isAdd = locationId ? Number(locationId) <= 0 : true;

  const {
    infoBroker,
    multiWarehouse,
    isValidFormAdd,
    onChangeFormInfoBroker,
    thirdContent,
    setThirdContent: setContent,
    getThirdPartyReq
  } = useAddLocationHook(locationId);

  const getStoreIntegration = async () => {
    const res: any = await getListStoreIntegration();
    if (res.status === 200) {
      setStoreList(
        res.data?.map((obj) => ({
          value: obj.store_id,
          label: `${obj.channel_name} - ${obj.store_name}`
        }))
      );
    }
  };

  const getPayload = (formVal) => ({
    ...getThirdPartyReq(),
    location_id: isAdd ? 0 : Number(locationId),
    location_code: formVal.code,
    location_name: formVal.name,
    is_fbl: selectedContent.code === 'FBL',
    is_tcb: selectedContent.code === 'TOKOPEDIA',
    is_sbs: selectedContent.code === 'SHOPEE',
    warehouse_store_id: selectedContent.code === 'TOKOPEDIA' ? formVal.id : '',
    // source_replenishment: formVal.automaticTransfer,
    is_active: formVal.isActive
  });

  const onSubmitRequest = async (req) => {
    setBusy(true);
    try {
      const res: any = await saveLocation(req);
      setBusy(false);
      if (res.status !== 'error') {
        showAlert('success', 'Berhasil ditambahkan');
        pageSetter('main');
        if (!isAdd) {
          getLocationDetail();
        }
      } else {
        showAlert('error', 'Gagal menyimpan data');
      }
    } catch (err) {
      setBusy(false);
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const callbackSubmit = (formVal) => {
    let req = getPayload(formVal) as any;
    req.warehouse =
      multiWarehouse?.map((obj) => ({
        store_id: obj.store_id,
        warehouse_id: obj?.warehouse_id?.wh_id ? String(obj?.warehouse_id?.wh_id) : undefined,
        warehouse_name: obj.warehouse_id.wh_name ?? obj.warehouse_name,
        location_id: Number(locationId)
      })) || [];
    onSubmitRequest(req);
  };

  const { ModalConfirmSubmit, onShowModalConfrim } = useModalConfirmSubmit({
    isAdd,
    callbackSubmit,
    locationDetail
  });

  const onSaveForm = async (formVal) => {
    if (!isAdd) {
      let req = getPayload(formVal) as any;
      req.warehouse =
        locationDetail.warehouse?.map((obj) => ({
          store_id: obj.store_id,
          warehouse_id: obj?.warehouse_id?.wh_id ?? obj?.warehouse_id ?? undefined,
          warehouse_name: obj?.warehouse_id?.wh_name ?? obj?.warehouse_name
        })) || [];
      onSubmitRequest(req);
    } else if (isValidFormAdd()) {
      onShowModalConfrim(formVal);
    }
  };

  let formhooks: any;
  const getFormHooks = (formhook) => {
    formhooks = formhook;
    setSubmitForm.current = formhook.handleSubmit(onSaveForm);
  };

  const loadData = async () => {
    const locationResp = locationDetail;
    if (locationResp) {
      let newSelectedDetail: any = {};
      if (locationResp.is_tcb) {
        setSelectedContent(contentBroker.TOKOPEDIA);
        newSelectedDetail.storeName = locationResp.warehouse_store_name;
        await getStoreIntegration();
      } else if (locationResp.is_sbs) {
        setSelectedContent(contentBroker.SHOPEE);
      } else if (locationResp.is_fbl) {
        setSelectedContent(contentBroker.FBL);
      }
      setFormValues({
        code: locationResp.location_code,
        name: locationResp.location_name,
        id: locationResp.warehouse_store_id
        // automaticTransfer: locationResp.source_replenishment
      });
      setSelectedDetail(newSelectedDetail);
      formhooks.setValue('code', locationResp.location_code);
      formhooks.setValue('name', locationResp.location_name);
      formhooks.setValue('id', locationResp.warehouse_store_id);
    }
  };

  React.useEffect(() => {
    if (!locationId || locationId === '0') {
      setIsEdit(true);
    } else {
      setIsEdit(modeEdit);
    }
  }, [modeEdit]);

  // load from zustand for add
  React.useEffect(() => {
    if (isAdd && !isPopulatedData) {
      if (!infoBroker.type) {
        setFormValues({
          ...infoBroker,
          type: selectedContent.code,
          name: selectedContent.name
        });
      } else {
        setFormValues(infoBroker);
      }
      setIsPopulatedData(true);
    }
  }, [infoBroker]);

  // load from api response
  React.useEffect(() => {
    if (!isAdd) {
      loadData();
    }
    if (isAdd) {
      const getCurrentContent = optionsBrokerType.find((obj) => obj.id === Number(thirdContent.value));
      if (getCurrentContent?.value) {
        setSelectedContent(contentBroker[getCurrentContent.value]);
      }
      getStoreIntegration();
    }
  }, [locationId, locationDetail]);

  const onChangeType = (e) => {
    const currentContent = contentBroker[e.target.value];
    setSelectedContent(currentContent);
    setContent(currentContent);
    formhooks.clearErrors();
    formhooks.reset();
    setFormValues({
      ...defaultValues,
      type: currentContent.code,
      name: currentContent.name
    });
  };

  const FormThirdParty: FormBuilder[] = [
    {
      label: 'Kode Lokasi',
      field_name: 'code',
      value: formValues.code || '',
      isMandatory: true
    },
    {
      label: 'Nama Lokasi',
      field_name: 'name',
      value: formValues.name || '',
      isMandatory: true
    },
    ...(selectedContent.code === 'TOKOPEDIA'
      ? [
          {
            label: 'Toko',
            field_name: 'id',
            value: formValues.id,
            valueDesc: selectedDetail.storeName,
            type: 'select',
            selectData: storeList
          }
        ]
      : [])
  ];

  const rowStyle = !isEdit ? { paddingBottom: 20 } : {};

  const onChangeForm = (formVal) => {
    if (isPopulatedData) {
      onChangeFormInfoBroker(formVal);
    }
  };

  const renderContentImage = () => {
    let contentImage = '';
    if (selectedContent.code === 'FBL') {
      contentImage = LazadaFullfilment;
    } else if (selectedContent.code === 'TOKOPEDIA') {
      contentImage = TokpedFullfilment;
    } else if (selectedContent.code === 'SHOPEE') {
      contentImage = ShopeeFullfilment;
    }
    return <Image src={contentImage} width={168} height={'100%'} />;
  };

  return (
    <>
      <Grid style={{ flex: 1 }}>
        {isAdd && (
          <Select
            value={selectedContent.code}
            onChange={onChangeType}
            size="small"
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            className="notch-dropdown mt-2"
            sx={{ padding: '6.5px 5px', fontSize: '14px' }}
            style={{ width: 270 }}
          >
            {optionsBrokerType.map((obj, idx) => (
              <MenuItem key={idx} value={obj.value}>
                {obj.label}
              </MenuItem>
            ))}
          </Select>
        )}
        <Form
          schema={schema}
          values={formValues}
          getFormHooks={getFormHooks}
          shouldUnregister={false}
          onSubmit={onSaveForm}
          onChange={onChangeForm}
        >
          {isAdd && (
            <Grid container display="flex" alignItems="center" marginTop={3} paddingLeft={1} paddingRight={4}>
              <Grid item xs={3}>
                {renderContentImage()}
              </Grid>
              <Grid item xs={9} paddingLeft={2} className={`${classes.font14}`}>
                {selectedContent.content}
              </Grid>
            </Grid>
          )}
          {!isAdd && (
            <Grid container display="flex" alignItems="center" paddingRight={4} marginBottom={3}>
              <Grid item xs={3}>
                {renderContentImage()}
              </Grid>
              <Grid item xs={9} paddingLeft={2} className={`${classes.font14}`}>
                {selectedContent.content}
              </Grid>
            </Grid>
          )}
          {FormThirdParty.map((obj, indx) => (
            <Grid key={indx} container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={3} className={classes.label} style={rowStyle}>
                {obj.label}
                {obj.isMandatory && (isEdit || isAdd) && <span className="text-danger">*</span>}
              </Grid>
              <Grid item xs={9} className={classes.description} style={rowStyle}>
                {isEdit || isAdd ? (
                  <>
                    {obj.type !== 'select' ? (
                      <TextInput
                        name={obj.field_name}
                        type="text"
                        label={obj.label}
                        variant="outlined"
                        className="no-notch"
                      />
                    ) : (
                      <Dropdown
                        key={String(obj.selectData.length)}
                        placeholder={obj.label}
                        label={obj.label}
                        data={obj.selectData}
                        name={obj.field_name}
                        className="notch-dropdown multiple-dropdown__primary"
                      />
                    )}
                  </>
                ) : (
                  <p className="mb-0" style={{ fontSize: 12, color: '#163A50' }}>
                    {obj.valueDesc || obj.value || '-'}
                  </p>
                )}
              </Grid>
            </Grid>
          ))}
          <button type="submit" ref={submitRef} style={{ display: 'none' }}>
            submit
          </button>
        </Form>
      </Grid>
      {ModalConfirmSubmit}
    </>
  );
};

export default LocationDetailInfoThirdParty;
