import React from 'react';

export const FileTemplateSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="13" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.6663 1.6665L16.6663 6.6665V16.6665C16.6663 17.1085 16.4907 17.5325 16.1782 17.845C15.8656 18.1576 15.4417 18.3332 14.9997 18.3332H4.99967C4.55765 18.3332 4.13372 18.1576 3.82116 17.845C3.5086 17.5325 3.33301 17.1085 3.33301 16.6665V3.33317C3.33301 2.89114 3.5086 2.46722 3.82116 2.15466C4.13372 1.8421 4.55765 1.6665 4.99967 1.6665H11.6663ZM14.9997 16.6665V7.49984H10.833V3.33317H4.99967V16.6665H14.9997ZM9.99967 15.8332L6.66634 12.4998H8.74967V9.99984H11.2497V12.4998H13.333L9.99967 15.8332Z"
        fill="#8999A5"
      />
    </svg>
  );
};
