import { PropsWithError } from 'interfaces/Props';
import styled, { css } from 'styled-components';

interface Large {
  large?: boolean;
}

const Input = styled.input<PropsWithError & Large>`
    width: 100%;
    padding: 5px 8px;
    border-radius: 8px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
    &:hover {
        border: 1px solid rgba(0, 0, 0, 0.6);
    }
    &:focus {
        border: 1px solid blue;
    }
    &::placeholder {
        font-size: 14px
        color: #BCBCBC;
    }
    ${(props) => props.isError && css`
        && {
            border: 1px solid #df4d4d;
        }    
    `}
`;

export default Input;