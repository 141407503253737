import React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const ProgressLine = (val: LinearProgressProps & { value: number }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1, color: '#4B68EF' }}>
        <LinearProgress sx={{ colorSecondary: '#D9D9D9' }} color="inherit" variant="determinate" {...val} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography className="font-weight-bold" variant="body2" color="#000">{`${Math.round(val.value)}%`}</Typography>
      </Box>
    </Box>
  );
};

export default ProgressLine;
