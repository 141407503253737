import React, { useState, useRef, useLayoutEffect, useMemo } from 'react';
import Joi from 'joi';

import { Alert, Button, Dialog, Grid, TextField, Tooltip as TooltipMUI } from '@mui/material';
import { EditSVG, ExportSVG, FileTemplateSVG, ImportSVG, SaveSVG, SuccessFilled } from 'assets/img';
import { SUPPORTED_IMPORT_FROM_MP } from 'shared/constants';
import { LiteGrid } from 'components/LiteGrid/LiteGrid';
import { Column } from 'components/LiteGrid/DataGrid.types';
import {
  exportOpeningBalanceTemplate,
  getExportStock,
  getImportDetail,
  getImportFromMarketplaceProgress,
  getLatestImportHistory,
  getListImportStock,
  getLocationList,
  getUrlExportStock,
  postImportFromMarketplace,
  postSaveImportStock
} from 'api/locations';
import { OptionData, QueryParameters } from 'lib/types';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { AsyncButton } from 'components/ButtonNew/Button';
import { useAppStore } from 'stores/appStore';
import { Image } from 'components/Image/Image';
import { PagePropsSetter } from '../StepSeven.types';
import { Form } from 'components/Form/Form';
import { showAlert } from 'components/Alert';
import { createStyles, makeStyles } from '@mui/styles';
import { getDownloadFileExport, getStoreLocation, setWizardSetup } from 'api/masterStore';
import { ModalExport } from 'pages/Onboarding/StepSix/ModalExport';
import { Confirmation } from 'components/Modal/Confirmation';
import { ModalDialog } from 'components/ModalDialog/Modal';
import { SinglePagination } from 'components/SinglePagination/SinglePagination';

import ModalImport, { ImportHandles } from 'components/ModalImport/ModalImport';
import CircularProgressWithCount from 'components/ProgressBarCount/ProgressBarCount';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip, TooltipAction, TooltipActionButton, TooltipQuote } from 'components/ToolTip';

type OtherFilter = {
  locationId?: number;
};

interface LocationInterface {
  location_id: number;
  location_name: string;
}

const defaultPagination: QueryParameters = {
  q: '',
  page: 1,
  pageSize: 25,
  sortBy: '',
  sortDirection: 'ASC'
};

const schema = Joi.object({
  form: Joi.array().items(
    Joi.object()
      .keys({
        amount: Joi.any(),
        bin_id: Joi.any(),
        cost: Joi.any(),
        item_adj_detail_id: Joi.any(),
        item_adj_id: Joi.any(),
        item_id: Joi.any(),
        location_id: Joi.any(),
        qty_in_base: Joi.any(),
        unit: Joi.any()
      })
      .unknown(true)
  )
}).unknown(true);

const useStyles = makeStyles(() =>
  createStyles({
    downloadFileTemp: {
      height: 30,
      width: '100%',
      background: '#F5FAFF',
      display: 'flex',
      padding: '5px 0px'
    },
    font14: {
      fontSize: '14px'
    }
  })
);

const getPaginationCatalogTable = () => {
  const pagination = window.localStorage.getItem('pagination_catalog_table');

  try {
    if (!pagination) {
      return defaultPagination;
    }
    return JSON.parse(pagination ?? '{}');
  } catch (e) {
    console.error('Error loading state : ');
    return defaultPagination;
  }
};

const setPaginationCatalogTable = (data) => {
  window.localStorage.setItem('pagination_catalog_table', JSON.stringify(data));
};

let formhooks: any;

const ImportStock: React.FunctionComponent<PagePropsSetter> = ({ pageSetter }) => {
  const { setDisablePrevButton, canSkip, cantSkip, isAccounting } = useAppStore();
  const classes = useStyles();
  const [pagination, setPagination] = useState(getPaginationCatalogTable());
  const [totalItems, setTotalItems] = React.useState(0);
  const [masterChannelId, setMasterChannelId] = React.useState<number>(null)
  const [datas, setDatas] = useState({ form: [] });
  const [valLocation, setValLocation] = useState<{ value: number, label: string }>({ value: -1, label: 'Pusat' });
  const [listLocation, setListLocation] = useState<any>([]);
  const [showValid, setShowValid] = useState<boolean>(false);
  const [mounted, setMounted] = React.useState<boolean>(false);

  const [progress, setProgress] = React.useState({
    percent: 0,
    total: 0,
    downloaded: 0
  });
  const [manualImportProgress, setManualImportProgress] = React.useState({
    percent: 0,
    total: 0,
    downloaded: 0
  });
  const [busy, setBusy] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [modalExport, setModalExport] = useState(false);
  const [url, setUrl] = useState('');
  const [templateUrl, setTemplateUrl] = useState('');
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [showResult, setShowResult] = useState(false);

  const openProgress = useRef<Boolean>(false);
  const openManualImportProgress = useRef<Boolean>(false);
  const tempDatas = useRef<any>([]);
  const importRef = useRef<ImportHandles>(null);
  const tempId = useRef(-1);
  const refresher = useRef<() => void>();
  const query = useRef<QueryParameters & OtherFilter>();
  const setQuery = useRef<(e: QueryParameters) => QueryParameters>();
  const modeEdit = useRef(false);
  const currentPage = useRef(1);
  const newPagination = useRef({});

  const confirmActionBtn = {
    label: 'Import Stok',
    color: 'btn-primary'
  };

  const renderProduct = (
    _cellData: undefined,
    rD: { thumbnail: string; item_name: string; item_code: string; is_bundle: boolean; item_id: string }
  ) => {
    const name = rD.item_name.length > 55 ? `${rD.item_name.slice(0, 55)}...` : rD.item_name;
    return (
      <div className="d-flex align-items-center font-size-table">
        <Image src={rD.thumbnail} height={35} width={35} className="border 1 border-light-gray rounded-sm mt-1 mb-1" />
        <div className="d-flex flex-column ml-3">
          <TooltipMUI placement="top-start" title={rD.item_name}>
            <span className="text-nowrap">{name}</span>
          </TooltipMUI>
          {/* <span className="text-nowrap">{rD.item_name}</span> */}
          <span className="font-weight-normal">{rD.item_code}</span>
        </div>
      </div>
    );
  };

  const compareArrays = (arr1, arr2) => {
    let changedData = [];

    for (let i = 0; i < arr1.length; i++) {
      const data1 = arr1[i];
      const data2 = arr2[i];

      if (JSON.stringify(data1) !== JSON.stringify(data2)) {
        changedData.push(data2);
      }
    }

    return changedData;
  };

  const onSaveForm = async () => {
    let validate = compareArrays(tempDatas.current, datas.form);
    let result = [];

    if (validate?.length > 0) {
      validate.map((data) => {
        result.push({
          item_adj_detail_id: data.item_adj_detail_id ? data.item_adj_detail_id : 0,
          bin_id: data.bin_id ? data.bin_id : 1,
          amount: Number(data.cost) * Number(data.qty_in_base),
          cost: Number(data.cost),
          item_adj_id: data.item_adj_id,
          item_id: data.item_id,
          location_id: data.location_id,
          qty_in_base: Number(data.qty_in_base),
          unit: data.unit
        });
      });
    }
    let res: any = [];
    result.forEach(async (val) => {
      res = await postSaveImportStock(val);
      if (res.status === 200) {
        showAlert('success', 'Berhasil menyimpan data');
      }
    });
    tempDatas.current = [];
    modeEdit.current = false;
    setTimeout(() => {
      setDisablePrevButton(false);
      canSkip();
      refresher.current();
    }, 1000);
  };

  const onPageSizeChange = (e, value) => {
    setPagination((prevState) => ({
      ...prevState,
      page: 1,
      pageSize: value
    }));

    const newQuery: any = {
      ...query.current,
      pageSize: value
    };

    setQuery.current = newQuery;
  };

  const onPaginationChanged = (value) => {
    setPaginationCatalogTable(value);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    refresher.current();
  };

  const onChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    const newQuery: QueryParameters = {
      sortBy: pagination.sortBy,
      pageSize: pagination.pageSize,
      page: value
    };

    if (modeEdit.current && currentPage.current !== value) {
      newPagination.current = newQuery;
      setShowValid(true);
    } else {
      setPagination(newQuery);
    }
  };

  const getStoreDetail = async (storeId) => {
    try {
      const res = await getStoreLocation(storeId);
      if (res.data) {
        return res.data;
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const getOnBoardingSetup = async () => {
    try {
      const res = await setWizardSetup();
      const setupData = res?.data?.setup;
      const storeId = setupData.master_channel_id;
      if (storeId) {
        const storeDetail = await getStoreDetail(storeId);
        return storeDetail
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  React.useEffect(() => {
    setMounted(true);
    if (mounted) onPaginationChanged(pagination);
  }, [pagination]);

  React.useEffect(() => {
    const initialization = async () => {
      const setupData = await getOnBoardingSetup();
      setPagination(defaultPagination);
      setMasterChannelId(setupData.channel_id);
    }
    initialization();
  }, []);


  const handleFocus = (idx, type) => {
    let newDatas = { ...datas };
    if (type === 'qty') {
      newDatas.form[idx].qty_focus = true;
    } else {
      newDatas.form[idx].cost_focus = true;
    }

    setDatas(newDatas);
  };

  const handleBlur = (idx, type) => {
    let newDatas = { ...datas };
    if (type === 'qty') {
      newDatas.form[idx].qty_focus = false;
    } else {
      newDatas.form[idx].cost_focus = false;
    }

    setDatas(newDatas);
  };

  const colHppView = (ctx, record) => {
    return <div>Rp {Number(record.cost) || 0}</div>;
  };

  const colEditHpp = (ctx, record) => {
    if (record.is_bundle) {
      return <div>Rp {Number(record.cost) || 0}</div>;
    } else {
      return (
        <TextField
          variant="outlined"
          size="small"
          className="rounded-sm w-100 no-notch"
          value={datas.form[ctx.row_id].cost}
          onChange={(e) => {
            let newDatas = { ...datas };
            newDatas.form[ctx.row_id].cost = e.target.value;

            setDatas(newDatas);
          }}
          inputProps={{
            style: {
              textAlign: record.cost_focus ? 'left' : 'right'
            }
          }}
          onFocus={() => handleFocus(ctx.row_id, 'cost')}
          onBlur={() => handleBlur(ctx.row_id, 'cost')}
        />
      );
    }
  };

  const colQtyView = (ctx, record) => {
    return <div>{Number(record.qty_in_base) || 0}</div>;
  };

  const colEditQty = (ctx, record) => {
    if (record.is_bundle) {
      return <div>{Number(record.qty_in_base) || 0}</div>;
    } else {
      return (
        <TextField
          variant="outlined"
          size="small"
          className="rounded-sm w-100 no-notch"
          value={datas.form[ctx.row_id].qty_in_base}
          onChange={(e) => {
            let newDatas = { ...datas };
            newDatas.form[ctx.row_id].qty_in_base = e.target.value;

            setDatas(newDatas);
          }}
          inputProps={{
            style: {
              textAlign: record.qty_focus ? 'left' : 'right'
            }
          }}
          onFocus={() => handleFocus(ctx.row_id, 'qty')}
          onBlur={() => handleBlur(ctx.row_id, 'qty')}
        />
      );
    }
  };

  const columns = useMemo(() => {
    const arrayColumn: Column[] = [
      {
        header: 'Produk',
        binding: 'item_name',
        format: 'string',
        allowSorting: true,
        freezeCol: true,
        template: renderProduct
      },
      {
        header: '',
        binding: 'item_id',
        format: 'string',
        headerClassName: 'text-second text-nowrap text-left',
        className: 'font-size-table text-left',
        template: (ctx, record) => {
          let content;
          if (record.is_bundle && !record.item_adj_detail_id) {
            content = <div style={{ color: '#DF4D4D' }}>Produk Bundle</div>;
          } else if (!record.item_adj_detail_id && !record.is_bundle) {
            content = <div style={{ color: '#DF4D4D' }}>Saldo awal belum terisi</div>;
          } else if (!record.is_bundle || !record.item_adj_detail_id) {
            content = <></>;
          }
          return content;
        }
      },
      {
        header: 'Qty',
        binding: 'qty_in_base',
        headerClassName: 'text-second text-nowrap text-right',
        className: 'font-size-table text-right',
        width: 100,
        template: modeEdit.current ? colEditQty : colQtyView
      },
      ...(Number(isAccounting) !== 0
        ? [
            {
              header: 'Hpp',
              binding: 'cost',
              headerClassName: 'text-second text-nowrap text-right',
              className: 'font-size-table text-right',
              width: 180,
              template: modeEdit.current ? colEditHpp : colHppView
            }
          ]
        : [])
    ];

    return arrayColumn;
  }, [datas, modeEdit.current]);

  const getQuery = (q: QueryParameters) => {
    query.current = q;
    setMounted(true);
  };

  const getSetQuery = (fn) => {
    setQuery.current = fn;
  };

  const getFetcher = async (options: QueryParameters & OtherFilter) => {
    const paginations = getPaginationCatalogTable();
    setBusy(true);
    options.locationId = tempId.current ? tempId.current : -1;
    options.page = mounted ? 1 : paginations.page;
    options.pageSize = paginations.pageSize;

    const res = await getListImportStock(options);

    const newRes = res?.data?.data.map((val) => ({
      ...val,
      cost_focus: false,
      qty_focus: false,
      amount: Number(val.cost) * val.qty_in_base,
      bin_id: 1,
      cost: Number(val.cost) || '0',
      item_adj_detail_id: val.item_adj_detail_id,
      item_adj_id: -1,
      item_id: val.item_id,
      location_id: val.location_id,
      qty_in_base: Number(val.qty_in_base) || '0',
      unit: 'Buah'
    }));
    tempDatas.current = JSON.parse(JSON.stringify(newRes));
    const data = {
      data: newRes || [],
      totalCount: res?.data?.totalCount || '0'
    };
    currentPage.current = mounted ? 1 : paginations.page;
    setDatas({
      ...datas,
      form: newRes
    });
    setTotalItems(res?.data?.totalCount);
    setBusy(false);
    return data;
  };

  const fetcher = React.useMemo(() => {
    return (options: QueryParameters) => {
      return getFetcher(options);
    };
  }, []);

  const getFormHooks = (formhook) => {
    formhooks = formhook;
  };

  const handleEditTable = () => {
    modeEdit.current = !modeEdit.current;
    setDisablePrevButton(true);
    cantSkip();
  };

  const getLocation = async () => {
    const newOptions = {
      page: 1,
      pageSize: 200,
      sortBy: 'location_id',
      sortDirection: 'ASC'
    };
    const res = await getLocationList(newOptions);
    let newRes = [];
    res?.data?.data?.map((r) => {
      if (r.location_id !== 0) {
        newRes.push({
          location_id: r.location_id,
          location_name: r.location_name
        });
      }
    });
    tempId.current = -1;
    setListLocation(newRes);
    setValLocation({ value: -1, label: 'Pusat' });
  };

  const formatLocationOptions = (location: LocationInterface) => {
    return {
      value: location.location_id,
      label: location.location_name
    } as unknown as OptionData<LocationInterface>;
  };

  const handleChangeLocation = (data) => {
    tempId.current = data.location_id;
    setValLocation({ label: data.location_name, value: data.location_id });
    setTemplateUrl('')
    refresher.current();
  };

  const handleImportMarketPlace = async () => {
    try {
      openProgress.current = true;
      const res: any = await postImportFromMarketplace();
      if (res.status === 200) {
        const downloaded = parseInt(res.data.downloaded);
        const total = parseInt(res.data.total);

        const counting = ((downloaded / total) * 100).toFixed(2);
        setProgress({
          percent: Number(counting),
          downloaded: Number(downloaded),
          total: Number(total)
        });
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const handleCloseModal = () => {
    openProgress.current = false;
    if (openManualImportProgress.current === true) setShowResult(true);
    openManualImportProgress.current = false;

    setManualImportProgress({
      percent: 0,
      total: 0,
      downloaded: 0
    });

    setProgress({
      percent: 0,
      total: 0,
      downloaded: 0
    });
  };

  const getExportUrl = async (time) => {
    try {
      const res: any = await getUrlExportStock();
      if (res.status === 200) {
        if (!res.data.jobs[0].result) {
          setTimeout(() => {
            getExportUrl(new Date());
          }, 1000);
          setBusy(true);
          return;
        }
        setUrl(res?.data?.jobs[0]?.result?.url);
        setBusy(false);
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const handleExport = async () => {
    try {
      setBusy(true);
      let newCurrentTime = new Date();
      let payload: any = { ...pagination };
      payload.locationId = tempId.current ? tempId.current : -1;
      setCurrentTime(newCurrentTime);
      setModalExport(true);
      const res: any = await getExportStock(payload);
      if (res.status === 200) {
        setTimeout(() => {
          getExportUrl(newCurrentTime);
        }, 1000);
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const onExportClose = () => {
    setModalExport(false);
  };

  const onSubmitExport = async () => {
    try {
      const res: any = await getDownloadFileExport(url);
      let fileName = url.split('/');

      const windowUrl = window.URL || window.webkitURL;
      if (typeof windowUrl.createObjectURL === 'function') {
        const blob = new Blob([res.data], { type: 'text/bin' });
        const URL = windowUrl.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = URL;
        anchor.setAttribute('download', `${fileName[5]}.csv`);
        anchor.click();
        windowUrl.revokeObjectURL(URL);
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const handleImportFromMarketplaceProgress = async () => {
    const res = await getImportFromMarketplaceProgress();
    const downloaded = parseInt(res.data.downloaded);
    const total = parseInt(res.data.total);

    const counting = ((downloaded / total) * 100).toFixed(2);
    setProgress({
      percent: Number(counting),
      downloaded: Number(downloaded),
      total: Number(total)
    });
  };

  React.useEffect(() => {
    let interval: NodeJS.Timer;
    if (openProgress.current && progress.percent < 100) {
      interval = setInterval(() => {
        handleImportFromMarketplaceProgress();
        refresher.current();
      }, 5000);
    }

    return () => clearInterval(interval);
  }, [openProgress.current && progress.percent < 100]);

  useLayoutEffect(() => {
    getLocation();
  }, []);

  const onConfirmModal = (value) => {
    if (value) {
      setIsShowConfirm(false);
      importRef.current.openImport();
    } else {
      setIsShowConfirm(false);
    }
  };

  const handleImportResult = async () => {
    setShowResult(true);
  };

  const getTemplateExportUrl = async () => {
    const res: any = await getUrlExportStock();
    if (res.status === 200) {
      if (!res.data.jobs[0].result) {
        setTimeout(() => {
          getTemplateExportUrl();
        }, 1000);
        setBusy(true);
        return;
      }
      const fileUrl = res?.data?.jobs[0]?.result?.url;

      const isOpeningBalanceTemplate = res?.data?.jobs[0]?.job?.type === 'inventory-stock-template';
      if (isOpeningBalanceTemplate) {
        setTemplateUrl(fileUrl);
      } else {
        setUrl(fileUrl);
      }
      setBusy(false);
    }
  };

  const handleExportOpeningBalanceTemplate = async () => {
    if (templateUrl) {
      const res: any = await getDownloadFileExport(templateUrl);
      let fileName = templateUrl.split('/');

      const windowUrl = window.URL || window.webkitURL;
      if (typeof windowUrl.createObjectURL === 'function') {
        const blob = new Blob([res.data], { type: 'text/bin' });
        const URL = windowUrl.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = URL;
        anchor.setAttribute('download', `${fileName[5]}.csv`);
        anchor.click();
        windowUrl.revokeObjectURL(URL);
      }
    } else {
      const res = await exportOpeningBalanceTemplate(valLocation.label);
      if (res.status === 200) {
        setTimeout(() => {
          getTemplateExportUrl();
        }, 1000);
      }
    }
  };

  const handleImportComplete = async () => {
    const latestImportHistory = await getLatestImportHistory();
    if (latestImportHistory.end_date !== null) {
      if (openManualImportProgress.current !== true && !latestImportHistory.is_success)
        openManualImportProgress.current = true;
      setManualImportProgress({
        percent: 100,
        total: 100,
        downloaded: 100
      });
    }
    if (latestImportHistory.end_date === null) {
      setTimeout(() => {
        handleImportComplete();
      }, 2000);
    }
  };

  const columnsModal: Column[] = [
    {
      header: 'ID',
      binding: 'activity_detail_id',
      format: 'string',
      className: 'text-left'
    },
    {
      header: 'Keterangan',
      binding: 'message'
    }
  ];

  const getFetcherModal = async () => {
    setBusy(true);
    const latestImportHistory = await getLatestImportHistory();
    const latestImportId = latestImportHistory.activity_id;
    const res = await getImportDetail(latestImportId);

    const data = {
      data: res?.data || [],
      totalCount: res?.data.length || '0'
    };
    setBusy(false);
    return data;
  };

  const fetcherModal = React.useMemo(() => {
    return () => {
      return getFetcherModal();
    };
  }, []);

  const confirmChangePage = () => {
    onSaveForm();
    setPagination(newPagination.current);
    newPagination.current = {};
    setShowValid(false);
  };

  const isImportMpEnabled = SUPPORTED_IMPORT_FROM_MP.some((supportedChannelId) => supportedChannelId === masterChannelId) && Number(isAccounting) === 0

  return (
    <>
      <Grid container>
        <Grid item md={12} className="font-size-md font-weight-bold">
          Import Stok
        </Grid>
      </Grid>

      {Number(isAccounting) === 0 ? (
        <Grid container className="my-1">
          <Grid item md={12}>
            <Alert
              className="d-flex align-items-center"
              severity="info"
              icon={<InfoOutlinedIcon style={{ color: '#4B68EF' }} />}
            >
              <div className={classes.font14}>
                Lakukan pengisian persediaan awal dengan mengisi kolom yang disediakan. <br />
                Kolom yang tidak diisi/kosong dianggap 0, sehingga system akan mengirim stok 0 ketika sinkronisasi
                diaktifkan. <br />
                Apabila pengisian persediaan dilakukan secara massal, silahkan pilih opsi berikut : <br />
                Import - Data SKU Produk dapat diunduh di template import <br />
                Import dari Marketplace - Pengisian persediaan dengan mengambil stok dari marketplace master yang live
                saat ini
              </div>
            </Alert>
          </Grid>
        </Grid>
      ) : (
        <Grid container className="my-1">
          <Grid item md={12}>
            <Alert
              className="d-flex align-items-center"
              severity="info"
              icon={<InfoOutlinedIcon style={{ color: '#4B68EF' }} />}
            >
              <span className={classes.font14}>
                Qty dan HPP yang bisa diedit hanya pada produk non-bundle dan stok awal sudah terisi.
              </span>
            </Alert>
          </Grid>
        </Grid>
      )}

      <Form values={datas} schema={schema} getFormHooks={getFormHooks} shouldUnregister={false}>
        <Grid container className="align-items-center">
          <Grid item md={3}>
            <Dropdown
              label=""
              value={valLocation}
              className="w-100 notch-dropdown"
              itemMapper={formatLocationOptions}
              data={listLocation}
              useOnChange
              onChange={(data) => handleChangeLocation(data)}
            />
          </Grid>
          <Grid item md={1} />
          <Grid item md={8}>
            <Grid container spacing={1} className="text-right">
              {/* <Grid item md={2} /> */}
              <Grid item md={2}>
                <AsyncButton
                  onClick={handleImportResult}
                  type="button"
                  variant="outlined"
                  color="inherit"
                  className="w-100"
                  // startIcon={<ExportSVG height={18} width={18} />}
                >
                  Hasil Import
                </AsyncButton>
              </Grid>
              <Grid item md={2}>
                <AsyncButton
                  onClick={handleExport}
                  type="button"
                  variant="outlined"
                  color="inherit"
                  className="w-100"
                  startIcon={<ExportSVG height={18} width={18} />}
                >
                  Export
                </AsyncButton>
              </Grid>
              <Grid item md={2}>
                <AsyncButton
                  // onClick={async () => importRef.current.openImport()}
                  onClick={async () => setIsShowConfirm(true)}
                  type="button"
                  variant="outlined"
                  color="inherit"
                  className="w-100"
                  startIcon={<ImportSVG height={18} width={18} />}
                >
                  Import
                </AsyncButton>
              </Grid>
              <Grid item md={4}>
                <AsyncButton
                  disabled={!isImportMpEnabled}
                  onClick={async () => handleImportMarketPlace()}
                  type="button"
                  variant="outlined"
                  color="inherit"
                  className="w-100"
                  startIcon={<ImportSVG height={18} width={18} />}
                >
                  Import dari Marketplace
                </AsyncButton>
              </Grid>
              <Grid item md={2}>
                <AsyncButton
                  className={`w-100 ${modeEdit.current && 'd-none'}`}
                  type="button"
                  color="primary"
                  busy={false}
                  startIcon={<EditSVG height={18} width={18} className="text-white" />}
                  onClick={async () => handleEditTable()}
                >
                  Edit
                </AsyncButton>
                <AsyncButton
                  className={`w-100 ${!modeEdit.current && 'd-none'}`}
                  onClick={async () => onSaveForm()}
                  type="button"
                  color="primary"
                  busy={false}
                  startIcon={<SaveSVG height={18} width={18} />}
                >
                  Simpan
                </AsyncButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="mt-3">
          <Grid item md={12}>
            <LiteGrid
              columns={columns}
              data={fetcher}
              getQuery={getQuery}
              getSetQuery={getSetQuery}
              getRefresher={(e) => (refresher.current = e)}
              initialSort="item_name ASC"
              havePagination={false}
              gridName="import_stock"
            />
            <Tooltip width="380px" style={{ zIndex: 999, right: 205 }} show={showValid} autoHeight={true}>
              <p style={{ fontSize: '16px', fontWeight: '600' }}>
                Kami akan menyimpan data anda setiap pemindahan halaman
              </p>
              <TooltipAction>
                <TooltipActionButton onClick={confirmChangePage} bgColor="white" textColor="#163A50">
                  Simpan
                </TooltipActionButton>
              </TooltipAction>
              <TooltipQuote />
            </Tooltip>
            <SinglePagination
              pagination={pagination}
              onPageSizeChange={onPageSizeChange}
              totalItems={totalItems}
              onChangePage={onChangePage}
            />
          </Grid>
        </Grid>
      </Form>

      <ModalImport
        onComplete={handleImportComplete}
        refresh={() => refresher.current()}
        ref={importRef}
        url={'opening-balance/serial-number/import-csv'}
      >
        <section id="subtitle">
          <div style={{ fontSize: '14px' }}>
            <span>
              Import hanya dapat dilakukan dengan format .csv. Untuk melihat panduan pengisian data,{' '}
              <a target="_blank" href="?page=import-guideline">
                klik di sini
              </a>
              .
            </span>
            <br />
            <span>Download template disini :</span>
          </div>
          <div>
            <a
              // href="/template_opening_balance_ob.xlsx"
              onClick={!busy && handleExportOpeningBalanceTemplate}
              className={`d-flex align-items-center cursor-pointer text-primary font-size-sm ${classes.downloadFileTemp} ${classes.font14}`}
              style={busy ? { pointerEvents: 'none', color: '#D4D5D6 !important' } : {}}
            >
              <FileTemplateSVG />
              <span className={`pl-2 pt-2 ${classes.font14}`}>
                {busy ? 'Mempersiapkan Template ...' : 'Template Pengisian Persediaan Awal'}
              </span>
            </a>
          </div>
        </section>
      </ModalImport>

      <ModalExport
        isDisabled={busy}
        currentTime={currentTime}
        isShow={modalExport}
        refreshTime={handleExport}
        onClose={onExportClose}
        onSubmit={onSubmitExport}
      />

      <Dialog open={openProgress.current === true || openManualImportProgress.current === true}>
        <div
          className="d-flex justify-content-center align-items-center flex-column"
          style={{ padding: '50px 150px', minHeight: '270px', minWidth: '600px' }}
        >
          {(progress.percent === 100 || manualImportProgress.percent === 100) && (
            <>
              <SuccessFilled height={60} width={60} />
              <span className={`text-center font-weight-bold my-3 ${classes.font14}`}>
                Berhasil mengimport stok dan sync stok sedang dalam proses.
              </span>
              <Button
                sx={{ width: '50px' }}
                onClick={handleCloseModal}
                type="button"
                variant="contained"
                color="primary"
              >
                Ok
              </Button>
            </>
          )}
          {((progress.percent !== 100 && openProgress.current === true) ||
            (openManualImportProgress.current === true && manualImportProgress.percent < 100)) && (
            <div className="pt-2 d-flex flex-column align-items-center">
              <CircularProgressWithCount
                sx={{ color: '#1D9BF0' }}
                size={60}
                variant="determinate"
                value={progress.percent || manualImportProgress.percent}
              />
              <span className={`font-weight-bold my-3 ${classes.font14}`}>
                Meingimport Stok... ({progress.downloaded || manualImportProgress.downloaded}/
                {progress.total || manualImportProgress.total})
              </span>
            </div>
          )}
        </div>
      </Dialog>

      <ModalDialog width={'md'} open={showResult} title={'Keterangan Import'} onAction={() => setShowResult(false)}>
        <div style={{ padding: 28 }}>
          <LiteGrid data={fetcherModal} columns={columnsModal} noSelectRowPerPage />
        </div>
      </ModalDialog>

      <Confirmation
        open={isShowConfirm}
        text="Pastikan stok telah sesuai"
        subText="Hal ini bertujuan untuk menghindari adjusment dikarenakan adanya perbedaan stok."
        subSubText="Lanjutkan mengimport?"
        maxWidth="sm"
        primaryButton={confirmActionBtn}
        onAction={onConfirmModal}
      />
    </>
  );
};

export default ImportStock;
