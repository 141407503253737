import React from 'react';

export const HistorySVG: React.FC<React.SVGProps<SVGSVGElement>> = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.00033 15.667C12.667 15.667 15.667 12.667 15.667 9.00033C15.667 5.33366 12.667 2.33366 9.00033 2.33366C5.33366 2.33366 2.33366 5.33366 2.33366 9.00033C2.33366 12.667 5.33366 15.667 9.00033 15.667ZM9.00033 0.666992C13.5837 0.666992 17.3337 4.41699 17.3337 9.00033C17.3337 13.5837 13.5837 17.3337 9.00033 17.3337C4.41699 17.3337 0.666992 13.5837 0.666992 9.00033C0.666992 4.41699 4.41699 0.666992 9.00033 0.666992ZM13.167 8.58366V9.83366H8.16699V4.83366H9.41699V8.58366H13.167Z"
        fill="#8999A5"
      />
    </svg>
  );
};
