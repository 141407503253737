import React from 'react';
import styled from 'styled-components';

interface Props {
  image: string;
  altName: string;
  logoSize: {
    width: string;
    height: string;
  };
}

interface ImgProps {
  custom_width: string;
  custom_height: string;
}

export const ImgWrap = styled.div<ImgProps>`
  width: ${({ custom_width }) => custom_width};
  height: ${({ custom_height }) => custom_height};
  display: flex;
  align-items: center;
  & > img {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
`;

const DialogTitle: React.FunctionComponent<Props> = ({ image, altName, logoSize }) => {
  const { width, height } = logoSize;
  return (
    <ImgWrap custom_width={width} custom_height={height}>
      <img style={{ width: 'auto', maxWidth: '100px', maxHeight: '25px' }} src={image} alt={altName} />
    </ImgWrap>
  );
};

export default DialogTitle;
