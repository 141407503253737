import React, { useState, useEffect, useRef } from 'react';
import {
  ActionButtonWrapper,
  DescTitle,
  FilterSearch,
  FilterSearchWrap,
  InitialDownloadContent,
  LeftContentWrap,
  MainContent,
  MainTitleWrap,
  RadioOption,
  StoreNameText,
  TextItem,
  TopContent
} from './StepThreeStyle';
import { useAppStore } from 'stores/appStore';
import { Title, TitleDescription } from 'components/atoms';

import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineRounded from '@mui/icons-material/ErrorOutlineRounded';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import {
  getInvalidToken,
  getProductListing,
  getStoreLocation,
  getVariationsList,
  postProductList,
  postRetryDownload,
  redownloadAllEmptySkus
} from 'api/masterStore';
import { LiteGrid } from 'components/LiteGrid/LiteGrid';
import { Column, RowButton } from 'components/LiteGrid/DataGrid.types';
import { QueryParameters } from 'lib/types';
import { showAlert } from 'components/Alert';
import { Image } from 'components/Image/Image';
import { Grid, InputAdornment, TextField } from '@mui/material';
import { DeleteSVG, SearchSVG } from 'assets/img';
import { AsyncButton } from 'components/ButtonNew/Button';
import { channelDetail } from 'shared/constants';
import { ChannelDetail } from 'interfaces/Channels';
import { ModalAction } from 'components/ModalAction/ModalAction';
import { useForm } from 'react-hook-form';
import LightTooltip from 'components/LightTooltip';
import Box from '@mui/material/Box';
import LineProgress from 'components/LineProgress';
import SelectButton from 'components/SelectButton/SelectButton';
interface FormValues {
  q: string;
}

interface OtherFilterProps {
  status?: string | null;
  type?: string | null;
}

const InitialDownload = (props: any) => {
  const query = useRef<QueryParameters>();
  const refresher = useRef<() => void>();
  const setQuery = useRef<(e: QueryParameters) => QueryParameters>();
  const { setCompleteValidation } = props;
  const [busy, setBusy] = useState(false);
  const [deletingItem, setDeletingItem] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [sku, setSku] = useState('all');
  const [statusDownload, setStatusDownload] = useState('all');
  const [detailStore, setDetailStore] = useState<any>({});
  const { setActiveStep, setDisablePrevButton, canSkip, cantSkip, setValidationComplete, setChannelIdOto } =
    useAppStore();
  const [downloadState, setDownloadState] = useState('idle');
  const [totalDownloaded, setTotalDownloaded] = useState(0);
  const [downloadPercentage, setDownloadPercentage] = useState(0);
  const [totalProduct, setTotalProduct] = useState(0);
  const [isError, setIsError] = useState(false);
  const [emptySkus, setEmptySkus] = useState(null);
  
  const { register, handleSubmit, setValue } = useForm<FormValues>({
    defaultValues: {
      q: ''
    }
  });

  const handleChangeStatusDownload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatusDownload((event.target as HTMLInputElement).value);
  };
  const handleChangeSKU = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSku((e.target as HTMLInputElement).value);
  };

  const getStoreDetail = async () => {
    try {
      const res = await getStoreLocation(props.data);
      setDetailStore(res.data);
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const handleActionTable = async (data) => {
    try {
      setBusy(true);
      const ids = data.map((row) => row.channel_group_id);
      let payload = {
        channelId: data[0].channel_id,
        storeId: data[0].store_id,
        isMaster: data[0].is_master,
        ids
      };
      const res: any = await postRetryDownload(payload);
      if (res.status === 200) {
        refresher.current();
        setBusy(false);
        showAlert('success', 'Berhasil download ulang');
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      setBusy(false);
      showAlert('error', `${error}`, 5000);
    }
  };

  const handleDelete = async (data) => {
    const newData = [];
    for (let idx = 0; idx < data.length; idx++) {
      const o = data[idx] as any;
      let result: any = {
        indx: idx,
        id: o.channel_item_id ? o.channel_item_id : o.channel_group_id,
        label: `${o.item_name}`,
        channelId: o.channel_id,
        storeId: o.store_id,
        isMaster: false,
        busy: null,
        success: null,
        isVariant: true,
        skus: [
          {
            item_code: o.item_code
          }
        ]
      };

      if (o.is_error) {
        const variations = await getVariationsList(o.channel_id, o.store_id, o.channel_group_id);
        result.skus = variations.data.data[0].skus;
        result.isVariant = false;
        result.id = o.channel_group_id;
      }

      newData.push(result);
    }

    setDeletingItem(newData);
    setDeleteConfirmation(true);
  };

  useEffect(() => {
    setDisablePrevButton(true);
    getStoreDetail();
  }, []);

  const renderProduct = (
    _cellData: undefined,
    rD: { img_url: string; item_name: string; item_code: string; is_bundle: boolean; item_id: string }
  ) => {
    const name = rD.item_name.length > 30 ? `${rD.item_name.slice(0, 30)}...` : rD.item_name;
    return (
      <div className="d-flex align-items-center font-size-table">
        <Image src={rD.img_url} height={52} width={52} className="border 1 border-light-gray rounded-sm mt-1 mb-1" />
        <div className="d-flex flex-column ml-3">
          <span className="text-nowrap">{name}</span>
          <span className="font-weight-normal">{rD.item_code}</span>
        </div>
      </div>
    );
  };

  const columns: Column[] = [
    {
      header: 'Produk',
      binding: 'item_name',
      format: 'string',
      allowSorting: true,
      freezeCol: true,
      template: renderProduct
    },
    {
      header: 'Status',
      headerClassName: 'text-center text-second text-nowrap',
      width: 240,
      allowSorting: false,
      binding: 'status',
      template: (row, data) => {
        const renderProgress = () => {
          if (data.status === 'failed') {
            return (
              <LightTooltip
                title={
                  data.error_message.includes('Cannot read property')
                    ? 'Kesalahan pada server'
                    : `${data.error_message}`
                }
                placement="bottom"
                arrow
              >
                <ErrorOutlineRounded fontSize="medium" className="text-danger" />
              </LightTooltip>
            );
          } else if (data.status === 'downloaded') {
            return <CheckCircleOutlineIcon fontSize="medium" style={{ color: '#4CAF50' }} />;
          } else {
            return <WarningAmberIcon fontSize="medium" style={{ color: 'rgb(255, 152, 0)' }} />;
          }
        };
        return <div className="text-center py-2">{renderProgress()}</div>;
      }
    },
    {
      header: 'Tindakan',
      binding: 'channel_item_id',
      headerClassName: 'text-center text-second text-nowrap',
      className: 'font-size-table text-center',
      allowSorting: false,
      template: (row, data, isChecked) => {
        const shouldRefresh = isChecked || data?.status === 'waiting';

        return (
          <RefreshIcon
            color={shouldRefresh ? 'disabled' : 'primary'}
            style={shouldRefresh ? {} : { cursor: 'pointer' }}
            onClick={() => {
              if (!shouldRefresh) {
                handleActionTable([data]);
              }
            }}
          />
        );
      }
    },
    {
      header: '',
      binding: 'action',
      template: (row, data, isChecked) => {
        const renderDelete = () => {
          if (isChecked) {
            return <DeleteSVG className="disabled" />;
          } else if (data.status !== 'waiting') {
            return <DeleteSVG className="text-danger" onClick={() => handleDelete([data])} />;
          } else if (isChecked || data?.status === 'waiting') {
            return <DeleteSVG className="disabled" />;
          }
        };
        return (
          <div className="text-center py-2" style={{ cursor: 'pointer' }}>
            {renderDelete()}
          </div>
        );
      }
    }
  ];

  const getQuery = (q: QueryParameters) => {
    query.current = q;
  };

  const getSetQuery = (fn) => {
    setQuery.current = fn;
  };

  const validationCheck = async (state) => {
    if (state === 'finished') {
      setCompleteValidation('DOWNLOADED');
    }

    const getEmptySku = await getProductListing(props.data, { page: 1, pageSize: 10, type: 1, q: '' });
    const response = getEmptySku.data.data;
    setEmptySkus(response);
    const emptySkusFound = Number(response.count) > 0;
    if (!emptySkusFound && state === 'finished') {
      setCompleteValidation('VALID_SKU');
      setValidationComplete(true);
    }

    const getDuplicateSku = await getProductListing(props.data, { page: 1, pageSize: 10, type: 3, q: '' });
    const responseDuplicate = getDuplicateSku.data.data;
    const duplicateSkuFound = Number(responseDuplicate.count) === 0;
    if (duplicateSkuFound) {
      setCompleteValidation('DUPLICATE_SKU');
    }
  };

  const onCheckInvalidToken = async (storeId) => {
    const res = await getInvalidToken(storeId);

    if (res.data[0].invalid_token) {
      setIsError(res.data[0].invalid_token);
    } else {
      return;
    }
  };

  const getFetcher = async (options: QueryParameters) => {
    setBusy(true);
    const res = await getProductListing(props.data, options);
    const responseData = res?.data;
    const responseProductCount = Number(responseData.data?.count);
    setTotalProduct(responseProductCount);
    setTotalDownloaded(Math.floor(Number(responseData.data?.count) * (responseData.data?.percent / 100)));
    const data = {
      ...res,
      data: res?.data?.data?.products || [],
      totalCount: res?.data?.data?.count || '0'
    };
    const currentStatus =
      responseData?.data.count > 0 && responseData?.data?.percent > 0 && responseData?.state === 'idle'
        ? 'finished'
        : responseData?.state;
    await validationCheck(currentStatus);
    setDownloadPercentage(responseData.data?.percent);
    setDownloadState(currentStatus);
    if ((responseProductCount > 0 && currentStatus === 'finished') || currentStatus === 'idle') {
      canSkip();
    } else {
      cantSkip();
    }
    if (currentStatus === 'idle' || currentStatus === 'finished') {
      onCheckInvalidToken(detailStore.store_id ?? props.data);
    }
    setBusy(false);
    return data;
  };

  const fetcher = React.useMemo(() => {
    return (options: QueryParameters) => {
      return getFetcher(options);
    };
  }, []);

  function handleRowAction(item: any, binding, index) {}

  const isRowLocked = (row) => {
    if (row.status === 'waiting') {
      return true;
    } else {
      return false;
    }
  };

  const rowButton: RowButton<any>[] = [
    {
      text: 'Hapus',
      icon: <DeleteSVG />,
      iconColor: '#DF4D4D',
      format: 'button',
      action: handleDelete,
      onlyOnSelect: true
    },
    {
      text: 'Download Ulang',
      icon: <RefreshIcon />,
      iconColor: '#4B68EF',
      iconHeaderWithText: true,
      format: 'button',
      action: handleActionTable,
      onlyOnSelect: true
    }
  ];

  const handleReset = async () => {
    const newQuery: QueryParameters & OtherFilterProps = {
      ...query.current,
      q: '',
      page: 1
    };

    newQuery.status = '';
    newQuery.type = '';

    setValue('q', '');
    setStatusDownload('all');
    setSku('all');

    setQuery.current(newQuery);
  };

  const handleSubmitSearch = async (formvalue) => {
    const newQuery: QueryParameters & OtherFilterProps = {
      ...query.current,
      q: formvalue.q,
      page: 1
    };

    newQuery.type = sku === 'all' ? '' : sku;
    newQuery.status = statusDownload === 'all' ? '' : statusDownload;

    setQuery.current(newQuery);
  };

  const handleDownloadAll = async () => {
    try {
      const res = await postProductList(detailStore.channel_id, detailStore.store_id, true, false);
      if (res.data.status === 'ok') {
        showAlert('success', `${res.data.message}`, 5000);
        refresher.current();
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const handleDownloadEmptySku = async () => {
    try {
      const storeId = detailStore.store_id;
      const res: any = await redownloadAllEmptySkus(storeId, detailStore.channel_id, true);
      if (res.data.status === 'ok') {
        showAlert('success', `${res.data.message}`, 5000);
        refresher.current();
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };



  const onDeleteClose = async () => {
    setDeleteConfirmation(false);
    refresher.current();
  };

  const channels = channelDetail as ChannelDetail;

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (downloadState === 'downloading' || downloadState === 'counting') {
      interval = setInterval(async () => {
        refresher.current();
      }, 10000);
    }

    return () => clearInterval(interval);
  }, [downloadState]);

  const handleOtoriation = () => {
    setChannelIdOto(detailStore.channel_id);
    setDisablePrevButton(true);
    cantSkip();
    setActiveStep(2);
  };

  const downloadButtonStatus = busy || downloadState === 'downloading' || downloadState === 'counting';
  const titleText = downloadState === 'downloading' || downloadState === 'counting' ? 'Proses' : 'Selesai';


  const downloadOptions = [
    {
      label: 'Download Semua Produk',
      handler: () => handleDownloadAll()
    },
    {
      label: 'Download Produk SKU Kosong',
      handler: () => handleDownloadEmptySku()
    }
  ];

  return (
    <div>
      <InitialDownloadContent>
        <TopContent>
          <div className="d-flex align-items-center">
            {channels[detailStore?.channel_id]?.channelLogo && (
              <Image
                height={25}
                width={25}
                src={channels[detailStore?.channel_id]?.channelLogo}
                className="rounded-sm mt-2 mb-2"
              />
            )}
            <StoreNameText font_size="14px">{detailStore?.store_name}</StoreNameText>
            {isError && (
              <LightTooltip
                title={
                  <span>
                    Toko ini perlu otorisasi ulang.{' '}
                    <span className="text-link" onClick={handleOtoriation}>
                      Klik disini
                    </span>{' '}
                    untuk melakukan otorisasi ulang toko
                  </span>
                }
                placement="bottom"
                arrow
              >
                <ErrorOutlineRounded fontSize="medium" className="text-danger ml-2" />
              </LightTooltip>
            )}
          </div>
          <ActionButtonWrapper>
            <AsyncButton
              onClick={async () => refresher.current()}
              variant="outlined"
              type="button"
              color="inherit"
              className="px-4"
            >
              Refresh
            </AsyncButton>
            {/* <AsyncButton
              onClick={async () => handleDownloadAll()}
              disabled={downloadButtonStatus}
              variant="contained"
              type="button"
              color="primary"
              className="px-4"
            >
              Download
            </AsyncButton> */}

            <SelectButton label={'Download'} busy={downloadButtonStatus} options={downloadOptions} />
          </ActionButtonWrapper>
        </TopContent>
        <MainContent>
          <MainTitleWrap>
            <div>
              <Title>
                {titleText} Mendownload Produk dari {detailStore?.store_name}
              </Title>
              {busy ? (
                <TitleDescription>{`${totalProduct} produk`}</TitleDescription>
              ) : (
                <TitleDescription>{`${totalDownloaded} dari ${totalProduct}`}</TitleDescription>
              )}
            </div>
          </MainTitleWrap>
        </MainContent>
      </InitialDownloadContent>

      <Grid container spacing={3}>
        <Grid item md={12}>
          <Box sx={{ width: '100%' }}>
            <LineProgress value={downloadPercentage} />
          </Box>
          {emptySkus?.count > 0 && (
            <Box sx={{ width: '100%' }}>
              <div className="text-danger" style={{ fontSize: '14px' }}>
                Terdapat {emptySkus.count} produk tanpa SKU.{' '}
                <span onClick={handleDownloadEmptySku} className="text-primary cursor-pointer">
                  Silahkan edit SKU di Seller Center kemudian klik disini untuk mendownload ulang.
                </span>
              </div>
            </Box>
          )}
        </Grid>
        <Grid item md={3}>
          <LeftContentWrap>
            <form onSubmit={handleSubmit(handleSubmitSearch)}>
              <FilterSearch>
                <FilterSearchWrap>
                  <p className="m-0">Filter</p>
                  <AsyncButton
                    color="inherit"
                    type="button"
                    className="text-danger px-0 hover-none"
                    onClick={handleReset}
                  >
                    Reset
                  </AsyncButton>
                </FilterSearchWrap>
                <TextField
                  disabled={busy}
                  variant="outlined"
                  size="small"
                  className="bg-white rounded-sm no-notch mb-3"
                  placeholder="Cari Produk, SKU Produk"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchSVG />
                      </InputAdornment>
                    )
                  }}
                  {...register('q')}
                />
              </FilterSearch>
              <RadioOption>
                <DescTitle>Status Download</DescTitle>
                <FormControl>
                  <RadioGroup value={statusDownload} onChange={handleChangeStatusDownload}>
                    <FormControlLabel
                      disabled={busy}
                      value="all"
                      className="mb-1"
                      control={<Radio sx={{ padding: '2px', marginLeft: '10px' }} size="small" />}
                      label={<TextItem>Semua</TextItem>}
                    />
                    <FormControlLabel
                      disabled={busy}
                      value="failed"
                      className="mb-1"
                      control={<Radio sx={{ padding: '2px', marginLeft: '10px' }} size="small" />}
                      label={<TextItem>Gagal Download</TextItem>}
                    />
                  </RadioGroup>
                </FormControl>
              </RadioOption>
              <RadioOption>
                <DescTitle>Kelengkapan SKU Produk</DescTitle>
                <FormControl>
                  <RadioGroup value={sku} onChange={handleChangeSKU}>
                    <FormControlLabel
                      disabled={busy}
                      value="all"
                      className="mb-1"
                      control={<Radio sx={{ padding: '2px', marginLeft: '10px' }} size="small" />}
                      label={<TextItem>Semua Produk</TextItem>}
                    />
                    <FormControlLabel
                      disabled={busy}
                      value="2"
                      className="mb-1"
                      control={<Radio sx={{ padding: '2px', marginLeft: '10px' }} size="small" />}
                      label={<TextItem>Produk dengan SKU Produk</TextItem>}
                    />
                    <FormControlLabel
                      disabled={busy}
                      value="1"
                      className="mb-1"
                      control={<Radio sx={{ padding: '2px', marginLeft: '10px' }} size="small" />}
                      label={<TextItem>Tidak ada SKU Produk</TextItem>}
                    />
                    <FormControlLabel
                      disabled={busy}
                      value="3"
                      className="mb-1"
                      control={<Radio sx={{ padding: '2px', marginLeft: '10px' }} size="small" />}
                      label={<TextItem>SKU Duplikat</TextItem>}
                    />
                  </RadioGroup>
                </FormControl>
              </RadioOption>
              <div className="mt-3">
                <AsyncButton fullWidth type="submit" color="primary" disabled={busy}>
                  Terapkan
                </AsyncButton>
              </div>
            </form>
          </LeftContentWrap>
        </Grid>
        <Grid item md={9}>
          <LiteGrid
            columns={columns}
            data={fetcher}
            showRowSelect
            getRefresher={(e) => (refresher.current = e)}
            rowButton={rowButton}
            getQuery={getQuery}
            getSetQuery={getSetQuery}
            onRowAction={handleRowAction}
            isRowLocked={isRowLocked}
            gridName="initial_download_grid"
            customHeight="calc(100vh - 327px)"
            isEmpty={
              statusDownload === 'all' && downloadState === 'idle'
                ? 'Produk yang belum ada disini akan di refresh oleh sistem per 10 detik atau silakan klik refresh untuk meloading data yang sudah terdownload.'
                : ''
            }
          />
        </Grid>
      </Grid>
      <ModalAction
        title="Hapus"
        method="POST"
        urlApi={'product/delete-onboarding-products'}
        btnSubmitColor="primary"
        mappingRequest={(data) => ({ ids: [data.channel_group_id] })}
        data={deletingItem}
        onClose={onDeleteClose}
        isShow={deleteConfirmation}
      />
    </div>
  );
};

export default InitialDownload;
