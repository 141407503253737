import {
  Card,
  CardContent,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip
} from '@mui/material';
import React, { CSSProperties, FC, useState } from 'react';
import { ConnectDropTarget, DropTarget } from 'react-dnd';
import { Types } from './Types';
import NoImage from 'assets/img/no-image.png';
import { OnlineStatus } from 'pages/Onboarding/StepFive/OnlineStatus';
import { channelDetail } from 'shared/constants';
import { ChannelDetail } from 'interfaces/Channels';
import { Image } from 'components/Image/Image';
import { makeStyles } from '@mui/styles';
import SkuChildItem from 'components/SkuChildItem'

const style: CSSProperties = {
  padding: '8px 10px',
  width: '100%'
};

export interface DropContainerProps {
  allowedDropEffect: string;
  connectDropTarget: ConnectDropTarget;
  canDrop: boolean;
  isOver: boolean;
  item: any;
  fnClickMaster: (item: any) => void;
  selectMaster: number;
}

const useStyles = makeStyles(() => ({
  itemCode: {
    fontSize: 12,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical'
  },
  itemEmail: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    maxWidth: 190
  }
}));

const DropContainer: FC<DropContainerProps> = ({
  canDrop,
  isOver,
  connectDropTarget,
  item,
  fnClickMaster,
  selectMaster
}) => {
  const classes = useStyles();
  const [anchorStoreEl, setAnchorStoreEl] = useState(null);

  const isActive = canDrop && isOver;
  const isSelected = selectMaster == item.item_group_id;
  const channels = channelDetail as ChannelDetail;

  let backgroundColor = '#FFF';
  if (isActive || isSelected) {
    backgroundColor = '#EBF5FA';
  }

  const handleClickStore = () => (e?: React.MouseEvent) => {
    if ((e && e.currentTarget) || (e === null && anchorStoreEl)) {
      setAnchorStoreEl(e ? e.currentTarget : null);
    }
  };

  const renderStorePopper = () => {
    return (
      <Popper
        open={Boolean(anchorStoreEl)}
        anchorEl={anchorStoreEl}
        transition
        placement="bottom"
        style={{ zIndex: 999 }}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'top'
            }}
          >
            <Paper style={{ width: 264 }}>
              <ClickAwayListener
                onClickAway={() => {
                  setAnchorStoreEl(null);
                }}
              >
                <MenuList id="split-button-menu">
                  <MenuItem disabled>
                    <Grid container className="w-100 justify-content-between">
                      <Grid item>
                        <span className="font-weight-bold m-0">{`Tergabung di ${item?.mp_status?.length} toko`}</span>
                      </Grid>
                      <Grid item></Grid>
                    </Grid>
                  </MenuItem>
                  <Divider />
                  <div style={{ maxHeight: 350, overflowY: 'auto' }}>
                    {item?.mp_status?.map((val, key) => <OnlineStatus store={val} key={key} size={1} />) || null}
                  </div>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  };

  return connectDropTarget(
    <div style={{ ...style }}>
      <Card
        style={{
          backgroundColor,
          background: '#FFFFFF',
          border: '1px solid #EAEAEA',
          boxShadow: '0px 2px 10px rgba(175, 137, 255, 0.15)',
          borderRadius: '15px'
        }}
      >
        <CardContent className="p-0">
            <Grid container>
              <Grid item md={12} onClick={() => fnClickMaster(item)} style={{ cursor: 'pointer' }}>
                <div className="d-flex px-2 py-3">
                  <img
                    src={item.skus[0].image ?? NoImage}
                    alt=""
                    width={53}
                    height={55}
                    style={{ objectFit: 'cover', marginLeft: '10px' }}
                  />
                  <div className="d-flex flex-column ml-3">
                    <div className="font-weight-bold mb-0" style={{ fontSize: '14px' }}>{`${item.item_group_name}`}</div>
                    <div className="d-flex flex-column" style={{ gap: '14px' }}>
                      {item.skus.length > 1 &&
                        item.skus.map((child, idx) => (
                          <SkuChildItem key={idx} child={child} />
                        ))}
                    </div>
                  </div>
                </div>
              </Grid>
              
              <Grid item md={12} className="border-top mt-2 mx-3">
                <Grid container>
                  <Grid item md={12}>
                  {item?.mp_status?.length > 1 && (
                    <div
                      onClick={handleClickStore()}
                      style={{ color: '#4B68EF', fontSize: '14px', cursor: 'pointer' }}
                      className="pt-2 pb-1 pl-2"
                    >{`Tergabung di ${item?.mp_status?.length} toko`}</div>
                  )}
                  {item?.mp_status?.length === 1 && (
                    <div className="d-flex align-items-center justify-content-start">
                      <div className="rounded-circle text-white px-2 text-center">
                        {channels[item.mp_status[0]?.channel_id]?.channelLogo && (
                          <Image
                            height={20}
                            width={20}
                            src={channels[item.mp_status[0]?.channel_id]?.channelLogo}
                            className="mt-2 mb-2"
                          />
                        )}
                      </div>
                      {item.mp_status[0].url && item.mp_status[0].url.indexOf('http') >= 0 ? (
                        <Tooltip arrow placement="bottom-start" title={`${item.mp_status[0].store_name}`}>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={item.mp_status[0].url}
                            className={`${classes.itemEmail} pl-1`}
                            style={{ fontSize: 14 }}
                          >
                            {`${item.mp_status[0].store_name}`}{' '}
                          </a>
                        </Tooltip>
                      ) : (
                        <div className="pl-1" style={{ fontSize: 14 }}>
                          {item.mp_status[0].store_name}
                        </div>
                      )}
                    </div>
                  )}
                  {item?.mp_status === null && (
                    <div style={{ color: '#4B68EF', fontSize: '14px' }} className="pt-2 pb-1 text-center">
                      -
                    </div>
                  )}
                    {renderStorePopper()}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
      </Card>
    </div>
  );
};

export default DropTarget(
  Types.Card,
  {
    drop: ({ allowedDropEffect, item }: DropContainerProps) => ({
      item: item,
      allowedDropEffect
    })
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  })
)(DropContainer);
