import styled, { css, keyframes } from 'styled-components';
import { ReactComponent as SearchIcon } from 'assets/temp/search.svg';

interface Props {
  current?: string;
  id?: string;
}
interface ImgWrapProps {
  width: string;
  height: string;
  mainBorder?: string;
  border?: string;
  isCenter?: boolean;
}
interface StoreNameTextProps {
  font_size: string;
}
interface ActionButtonProps {
  bg_color: string;
  text_color: string;
  border: string;
}
interface StatusProps {
  border: string;
  icon?: string;
}
export const BeginningContentList = styled.div`
    display: grid;
    gap: 10px;
    margin-top: 10px;
`;
export const ContentItem = styled.div<Props>`
    border: ${({ current, id }) => current === id ? '1px solid #4B68EF' : '1px solid #EBE9E9'};;
    box-shadow: 0px 2px 10px rgba(228, 226, 226, 0.36);
    border-radius: 15px;
    padding: 1rem 1.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
`;
export const LeftContent = styled.div`
    display: grid;
    grid-template-columns: auto auto 1fr;
    align-items: center;
    gap: 1rem;
`;
export const DotIcon = styled.div<Props>`
    width: 13px;
    height: 13px;
    border-radius: 50%;
    border: ${({ current, id }) => current === id ? '1.5px solid #4B68EF' : '1.5px solid #D4D5D6'};
    position: relative;
    padding: 1px;
`;
export const DotInside = styled.div<Props>`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #4B68EF;
    display: ${({ current, id }) => current === id ? 'block' : 'none'};
`;
export const ImgWrap = styled.div<ImgWrapProps>`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    position: relative;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        inset: 0;
    }
`;
export const StoreNameText = styled.p<StoreNameTextProps>`
    font-size: ${({ font_size }) => font_size};
    font-weight: 700;
    margin: 0;
    margin-left: 5px;
`;

export const InitialDownloadContent = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
`;
export const TopContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const StoreName = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;
export const ActionButtonWrapper = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    align-items: center;
`;
export const ActionButton = styled.button<ActionButtonProps>`
    padding: 8px 2rem;
    border-radius: 8px;
    text-transform: capitalize;
    border: ${({ border }) => border};
    outline: none;
    background-color: ${({ bg_color }) => bg_color};
    color: ${({ text_color }) => text_color};
    font-size: 12px;
    cursor: pointer;
`;
// background-color: ${({ is_disabled }) => is_disabled ? '#D4D5D6' : '#4B68EF'};
//     cursor: ${({ is_disabled }) => is_disabled ? 'not-allowed' : 'pointer'};
export const MainContent = styled.div`
    /* background-color: #eee; */
    display: grid;
    gap: 1rem;
    height: 100%;
`;
export const MainTitleWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const CheckWrap = styled.div`
  border-radius: 50%;
  background-color: #7DB942;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
export const Loader = styled.div<ImgWrapProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 50%;
  border: ${({ mainBorder }) => mainBorder};
  border-top: ${({ border }) => border};
  border-right: ${({ border }) => border};
  animation: ${rotate} 2s linear infinite;
  ${({ isCenter }) => isCenter && css`
    margin: 0 auto;
  `}
`;
export const MainContentWrap = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 3fr;
  gap: 1rem;
  border: 2px solid black;
  height: 100%;
`;
export const LeftContentWrap = styled.div`
  display: grid;
`;
export const FilterSearch = styled.div`
  display: grid;
  gap: 5px;
`;
export const FilterSearchWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`;
export const SearchBox = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
export const SearchIconStyled = styled(SearchIcon)`
  width: 20px;
  height: 20px;
`;
export const SearchInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 12px;
  &::placeholder {
    color: #D4D5D6;
    font-size: 12px;
  }
`;
export const RadioOption = styled.div`
  display: grid;
  gap: 5px;
  margin-bottom: 10px;
`;
export const DescTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
`;
export const TextItem = styled.p`
  font-size: 12px;
  margin: 0;
  margin-left: 5px;
`;
export const ApplyButton = styled.button`
  width: 100%;
  border: none;
  outline: none;
  color: #fff;
  background-color: #4B68EF;
  border-radius: 6px;
  font-size: 12px;
  padding: 6px 0;
`;
export const RightContentWrap = styled.div`
  border: 1px solid red;
  table {
      border: 1px solid #D4D5D6;
      border-radius: 10px;
      width: 100%;
      border-collapse: collapse;
      th, td {
        padding: 5px;
      }
      th {
          font-size: 14px;
          border-bottom: 1px solid #D4D5D6;
          /* background-color: lightgreen; */
      }
      td {
        /* background-color: lightblue; */
        font-size: 12px;
        border: 1px solid #D4D5D6;
        border-left: none;
        border-right: none;
      }
      tr th:nth-child(2) {
        text-align: center;
      }
      tr td:nth-child(3), tr th:nth-child(3) {
        text-align: center;
      } 
      tr td:nth-child(4), tr th:nth-child(4) {
        text-align: center;
      } 
  }
`;
export const ProductCells = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
export const StatusLoading = styled.div<StatusProps>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: ${({ border }) => border};
  margin: 0 auto;
`;
export const ActionWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;