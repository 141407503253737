import React from 'react';

import { StoreSVG } from 'assets/img';
import { makeStyles } from '@mui/styles';
import { channelDetail } from 'shared/constants';
import { ChannelDetail } from 'interfaces/Channels';
import { Image } from 'components/Image/Image';

type Props = {
  store: any;
  size: number;
  padding?: boolean;
  isTable?: boolean;
};

const useStyles = makeStyles((theme) => ({
  boxSpacing: {
    overflow: 'hidden',
    position: 'relative'
  },
  paddingBox: {
    padding: '10.5px 6px 10.5px 8px'
  }
}));

export const OnlineStatus: React.FC<Props> = ({ store, size, padding = true, isTable }) => {
  const classes = useStyles();
  let storeName: string = '';

  const channels = channelDetail as ChannelDetail;

  // if store name exist...
  if (store && store.store_name && store.store_name.split(' - ').length > 1) {
    storeName = isTable ? store.store_name : store.store_name.split(' - ')[1].trim();
  } else if (store && store.store_name) {
    storeName = store.store_name;
  } else if (store && store.channel_id) {
    storeName = store.channel_name;
  }

  if (size === null) {
    return (
      <div className={`d-flex align-items-center  ${classes.boxSpacing} ${padding && classes.paddingBox} `}>
        <StoreSVG />
        <span className="pl-1" style={{ fontSize: 12 }}>
          Belum terhubung
        </span>
      </div>
    );
  }

  if (size > 1) {
    return (
      <div
        className={`d-flex align-items-center text-primary font-weight-bold ${classes.boxSpacing} ${
          padding && classes.paddingBox
        } `}
        key={store.store_id}
      >
        <StoreSVG />
        <span className="pl-2" style={{ fontSize: 12 }}>{`Terdapat di ${size} toko`}</span>
      </div>
    );
  } else {
    return (
      <div key={store.store_id} className="d-flex align-items-center justify-content-start pl-2">
        <div className="rounded-circle text-white px-2 text-center">
          {channels[store?.channel_id]?.channelLogo && (
            <Image height={18} width={18} src={channels[store?.channel_id]?.channelLogo} className="mt-2 mb-2" />
          )}
        </div>
        {store.url && store.url.indexOf('http') >= 0 ? (
          <a target="_blank" rel="noreferrer" href={store.url} className="pl-1" style={{ fontSize: 14 }}>
            {`${store.store_name}`}{' '}
          </a>
        ) : (
          <div className="pl-1" style={{ fontSize: 14 }}>
            {store.store_name}
          </div>
        )}
      </div>
    );
  }
};
