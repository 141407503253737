import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Skeleton, Box, Grid, Typography, Tab } from '@mui/material';
import { CustomButton } from 'components/Button/Button';
import { VerticalStepper } from 'components/Stepper/VerticalStepper';
import { useAppStore, useLocalStorage } from 'stores/appStore';
import { getCurrentStep } from 'api/onboardingStep';
import { useLocation, useNavigate } from 'react-router-dom';
import { Logout } from '@mui/icons-material';
import { Confirmation } from 'components/Modal/Confirmation';
import { showAlert } from 'components/Alert';
import { doLogout, verifyAccountSupport, verifyToken } from 'api/auth';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import styles from './PageContainer.module.scss';
import CustomDialogs from 'components/Dialog/Dialog';
import JubelioLogo from 'assets/img/jubelio/jubelio.png';
import JubelioIcon from 'assets/img/jubelio/jubelio-icon.png';
import PageContentContainer from 'pages/Core/PageContentContainer/PageContentContainer';
import DummyLogin from 'pages/DummyLogin/DummyLogin';
import config from 'config';
import MenuAppBar from 'components/AppBar';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PageContainer: React.FunctionComponent = () => {
  const {
    isLoading,
    activeStep,
    setActiveStep,
    setOnboardingModifiedDate,
    showGettingStarted,
    setAppLoading,
    setShowGettingStarted,
    setDataAccountSupport
  } = useAppStore();
  const { token, setToken } = useLocalStorage();

  const params = useQuery();
  const navigate = useNavigate();
  const isMounted = useRef(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [page, setPage] = useState<'import-guideline' | null >(null);

  const useTokenFromUrlAndNavigateToHome = useCallback(async () => {
    const authToken = params.get('token');
    if (authToken) {
      setToken(authToken);
      location.href = '/';
    }
    if (!authToken && !token) {
      location.replace(config.V2_URL);
    }
  }, [navigate, params]);

  const useQueryPageNavigation = useCallback(async () => {
    const pageParams = params.get('page');
    if (pageParams === 'import-guideline') {
      setPage(pageParams);
    }
  }, [params]);

  const confirmActionBtn = {
    label: 'Logout',
    color: 'btn-primary'
  };

  const onCloseModalConfirm = async (value) => {
    if (value) {
      try {
        const res: any = await doLogout();
        if (res.data.status === 'ok') {
          setShowConfirm(false);
          showAlert('success', 'Logout berhasil!', 5000);
          location.reload();
        }
      } catch (err) {
        const error = err as Error;
        console.log(error);
        showAlert('error', `${error}`, 5000);
      }
    } else {
      setShowConfirm(false);
    }
  };

  const verifySupport = async () => {
    const res = await verifyAccountSupport();

    const supportAccountId = 75604;
    const findSupportAccount = res.data.data.find((val) => val.user_id === supportAccountId);
    let newRes = {
      dataOwner: res?.data?.data.find((val) => val.is_owner === true),
      isChecked: findSupportAccount ? true : false
    };
    
    return newRes;
  };

  useEffect(() => {
    setAppLoading(true);
    useTokenFromUrlAndNavigateToHome();
    useQueryPageNavigation();
  }, []);

  useEffect(() => {
    const initialization = async () => {
      try {
        setAppLoading(true);
        const res = await getCurrentStep();
        if (res.status !== 200) {
          throw new Error(`Something went wrong, ${res.statusText}`);
        }
        const { lastOnboardingStep, onboardingLastModified, showOnboardingWizard } = res.data;
        setActiveStep(lastOnboardingStep);
        setOnboardingModifiedDate(onboardingLastModified);
        const isVerif = await verifySupport();
        setDataAccountSupport(isVerif);

        if (!showOnboardingWizard) {
          window.location.replace(config.V2_URL);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setAppLoading(false);
      }
    };
    if (token) {
      initialization();
    }

    isMounted.current = false;
  }, [token]);

  const renderContent = () => {
    if (token === '' || isLoading) {
      return (
        <div className={styles.App}>
          <div className={styles.logoJubelio}>
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{
                minWidth: 300,
                minHeight: 45,
                borderRadius: 10
              }}
            />
          </div>
          <div className={styles.textLogo}>
            <Skeleton
              animation="wave"
              variant="rectangular"
              sx={{
                minWidth: 639,
                minHeight: 19,
                borderRadius: 10
              }}
            />
          </div>
          <div className={styles.onboardingContainer}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Skeleton animation="wave" height={'80vh'} variant="rectangular" />
              </Grid>
              <Grid item xs={8}>
                <Skeleton animation="wave" height={'70vh'} variant="rectangular" />
              </Grid>
            </Grid>
          </div>
        </div>
      );
    } else if (activeStep === 0) {
      return <DummyLogin />;
    } else if (activeStep > 0) {
      return (
        <>
          <div className={styles.App}>
            {/* <div className={styles.logoJubelio}>
              <img src={JubelioLogo} alt="logo" />
            </div> */}

            <div className={styles.textLogo}>
              <p>
                Pastikan Kamu mengisi secara teliti agar tidak terjadi pengulangan penginputan data ketika sudah live.
              </p>
            </div>
            <div className={styles.onboardingContainer}>
              <Grid container spacing={2}>
                {activeStep === 10 ? (
                  <Grid item xs={12}>
                    <PageContentContainer />
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={4}>
                      <VerticalStepper activeStep={activeStep} orientation={'vertical'} />
                    </Grid>
                    <Grid item xs={8}>
                      <PageContentContainer />
                    </Grid>
                  </>
                )}
              </Grid>
            </div>
          </div>
          <CustomDialogs
            open={showGettingStarted}
            fullWidth
            dividers={false}
            titleElement={<></>}
            maximumWidth={560}
            content={
              <Box
                sx={{
                  px: 6,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mt: 5
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: '100%',
                    maxWidth: 200,
                    mb: 5
                  }}
                  alt="The house from the offer."
                  src={JubelioIcon}
                />
                <Typography className={styles.dialogTitle}>Selamat Datang diproses Onboarding Jubelio</Typography>
                <Typography className={styles.dialogDescription}>Sebelum memulai proses onboarding</Typography>
                <Typography className={styles.dialogDescription}>
                  kami telah menghapus data data trial sebelumnya.
                </Typography>
                <CustomButton
                  className={styles.dialogButton}
                  variant={'contained'}
                  name={'Mulai Sekarang'}
                  size={'large'}
                  onClick={() => setShowGettingStarted(false)}
                />
              </Box>
            }
            actionContent={<></>}
          />
        </>
      );
    }
  };

  const importGuideLine = () => {
    return (
      <div className={styles.App}>
        <div className={styles.onboardingContainer}>
          <Box
            sx={{
              boxShadow: '0px 2px 10px rgba(175, 137, 255, 0.15)',
              borderRadius: 3,
              bgcolor: '#FFFFFF',
              display: 'flex',
              flexDirection: 'column',
              padding: '25px 35px',
              height: '100%',
              width: '100%'
            }}
          >
            <Typography variant="h6" fontWeight={'bold'} gutterBottom component="div">
              A. Tata Cara Pengisian Form Saldo Awal Persediaan
            </Typography>
            <ol style={{ marginLeft: '2%' }}>
              <li>Download template csv.</li>
              <li>Convert file csv menggunakan fitur &quot;Text to Column&quot; di Excel.</li>
              <li>Input template.
                <br />
                <Typography variant="body2" fontWeight={'bold'} gutterBottom component="div">
                  Contoh:
                </Typography>
                <TableContainer sx={{
                  border: '1px solid #e9e9e9',
                  borderRadius: 6,
                  overflow: 'auto',
                  overflowY: 'hidden',
                  width: '586px',
                }} component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>item_code</TableCell>
                        <TableCell>location_name</TableCell>
                        <TableCell>qty_in_base</TableCell>
                        <TableCell>cost</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          SAMSUNG-S20
                        </TableCell>
                        <TableCell>Gudang Elektronik Jakarta</TableCell>
                        <TableCell>100</TableCell>
                        <TableCell>16999000</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          SAMSUNG-S20
                        </TableCell>
                        <TableCell>Gudang Elektronik Jakarta</TableCell>
                        <TableCell>100</TableCell>
                        <TableCell>16999000</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </li>
              <li>
                Simpan file dengan format .csv
              </li>
            </ol>

            <Typography variant="h6" fontWeight={'bold'} gutterBottom component="div">
              B. Cara Menggunakan Fitur &quot;Text to Column&quot; di Excel
            </Typography>
            <ol style={{ marginLeft: '2%' }}>
              <li>Pilih sel atau kolom yang berisi teks yang ingin dipisahkan.</li>
              <li>Pilih <b>Data &gt; Text to Columns</b></li>
              <li>Di <b>Convert Text to Columns Wizard</b>, pilih <b>Delimited {'>'} Next</b>.</li>
              <li>Pilih <b>Delimiters</b> untuk data Kamu. Misalnya, <b>Comma</b> dan <b>Space</b>. Kamu bisa melihat pratinjau data dalam jendela <b>Data Preview.</b></li>
              <li>Pilih <b>Next.</b></li>
              <li>Pilih <b>Destination</b> dalam lembar kerja Kamu yang akan menjadi tempat Kamu ingin memisahkan data.</li>
              <li>Pilih <b>Finish.</b></li>
            </ol>


            <Typography variant="h6" fontWeight={'bold'} gutterBottom component="div">
              C. Label Pengisian Data
            </Typography>
            <TableContainer sx={{
              border: '1px solid #e9e9e9',
              borderRadius: 6,
              overflow: 'auto',
              overflowY: 'hidden',
              width: '100%',
            }} component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Nama Label</TableCell>
                    <TableCell>Definisi dan Penggunaan</TableCell>
                    <TableCell>Nilai Yang Diterima</TableCell>
                    <TableCell>Contoh</TableCell>
                    <TableCell>Keterangan</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      item_code
                    </TableCell>
                    <TableCell>Kode barang yang akan di import</TableCell>
                    <TableCell>Dapat diisi sesuai dengan keperluan</TableCell>
                    <TableCell>ITM-002</TableCell>
                    <TableCell>Wajib diisi</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      location_name
                    </TableCell>
                    <TableCell>Nama lokasi barang tersebut masuk</TableCell>
                    <TableCell>Diisi sesuai dengan lokasi yang ada di sistem Jubelio</TableCell>
                    <TableCell>Pusat</TableCell>
                    <TableCell>Wajib diisi</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      qty_in_base
                    </TableCell>
                    <TableCell>Banyaknya barang yang akan di import pada saldo awal.</TableCell>
                    <TableCell>Pengisian hanya menerima format date/tanggal, didalam setiap cell sudah di set format satuan berat dalam gram.</TableCell>
                    <TableCell>4</TableCell>
                    <TableCell>Wajib diisi</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      cost
                    </TableCell>
                    <TableCell>Harga untuk barang tersebut</TableCell>
                    <TableCell>Pengisian hanya menerima format angka tanpa titik atau koma.</TableCell>
                    <TableCell>1200000</TableCell>
                    <TableCell>Wajib diisi</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      serial_number
                    </TableCell>
                    <TableCell>Nomor seri atau identifikasi unik dari barang</TableCell>
                    <TableCell>Dapat diisi dengan nomor unik yang mengidentifikasi masing-masing barang.</TableCell>
                    <TableCell>SN-001</TableCell>
                    <TableCell>Tidak wajib diisi</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      batch_no
                    </TableCell>
                    <TableCell>Nomor batch atau kelompok produksi barang</TableCell>
                    <TableCell>Dapat diisi dengan nomor batch atau kelompok produksi barang.</TableCell>
                    <TableCell>ITM-B001</TableCell>
                    <TableCell>Tidak wajib diisi</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      expired_date
                    </TableCell>
                    <TableCell>Tanggal kedaluwarsa atau tanggal kadaluarsa barang</TableCell>
                    <TableCell>Dapat diisi dengan tanggal kedaluwarsa barang dalam format tanggal.</TableCell>
                    <TableCell>2023-12-31</TableCell>
                    <TableCell>Tidak wajib diisi</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      kode_rak
                    </TableCell>
                    <TableCell>Kode rak penyimpanan barang di gudang</TableCell>
                    <TableCell>Dapat diisi dengan kode rak penyimpanan barang di gudang.</TableCell>
                    <TableCell>RK-01</TableCell>
                    <TableCell>Tidak wajib diisi</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>
      </div>
    )
  }

  const render = () => {
    if (page === 'import-guideline') {
      return importGuideLine();
    } 

    return renderContent();
  };

  return (
    <>
      <MenuAppBar setLogoutConfirmation={setShowConfirm} />
      {render()}
      <Confirmation
        open={showConfirm}
        text="Kamu belum menyelesaikan step ini."
        subText="Data yang masih belum dilengkapi tidak akan tersimpan sebelum step ini diselesaikan."
        subSubText="Apakah Kamu yakin untuk keluar sekarang?"
        maxWidth="sm"
        primaryButton={confirmActionBtn}
        onAction={onCloseModalConfirm}
      />
    </>
  );
};

export default PageContainer;
