import React from 'react';

export const SuccessFilled: React.FC<React.SVGProps<SVGSVGElement>> = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || 53}
      height={props.height || 53}
      viewBox="0 0 53 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.5 0.6875C33.3459 0.6875 39.9114 3.40702 44.7522 8.24781C49.593 13.0886 52.3125 19.6541 52.3125 26.5C52.3125 33.3459 49.593 39.9114 44.7522 44.7522C39.9114 49.593 33.3459 52.3125 26.5 52.3125C19.6541 52.3125 13.0886 49.593 8.24781 44.7522C3.40702 39.9114 0.6875 33.3459 0.6875 26.5C0.6875 19.6541 3.40702 13.0886 8.24781 8.24781C13.0886 3.40702 19.6541 0.6875 26.5 0.6875ZM23.2845 31.5924L17.5504 25.8547C17.3449 25.6491 17.1008 25.4861 16.8323 25.3748C16.5637 25.2636 16.2758 25.2063 15.9851 25.2063C15.6944 25.2063 15.4065 25.2636 15.1379 25.3748C14.8694 25.4861 14.6253 25.6491 14.4198 25.8547C14.0046 26.2698 13.7714 26.8329 13.7714 27.42C13.7714 28.0072 14.0046 28.5702 14.4198 28.9854L21.721 36.2866C21.926 36.4932 22.1698 36.6572 22.4385 36.7691C22.7072 36.881 22.9953 36.9386 23.2863 36.9386C23.5774 36.9386 23.8655 36.881 24.1342 36.7691C24.4028 36.6572 24.6467 36.4932 24.8517 36.2866L39.9704 21.1642C40.1787 20.9595 40.3445 20.7155 40.458 20.4465C40.5716 20.1774 40.6308 19.8885 40.6321 19.5964C40.6335 19.3044 40.577 19.0149 40.4659 18.7448C40.3549 18.4747 40.1914 18.2292 39.9851 18.0226C39.7787 17.816 39.5334 17.6522 39.2634 17.5408C38.9934 17.4295 38.704 17.3726 38.412 17.3737C38.1199 17.3747 37.8309 17.4335 37.5617 17.5468C37.2925 17.66 37.0484 17.8254 36.8434 18.0335L23.2845 31.5924Z"
        fill="#7DB942"
      />
    </svg>
  );
};
