import React from 'react';

export const EditSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0696 3.75216L9.00803 12.7716V14.9968H11.2374L20.2528 5.93537L18.0696 3.75216ZM18.7783 1.63249L22.3723 5.22646C22.7621 5.61628 22.7629 6.24806 22.3741 6.63888L12.362 16.7021C12.1743 16.8907 11.9192 16.9968 11.6531 16.9968H8.00802C7.45574 16.9968 7.00803 16.5491 7.00803 15.9968V12.356C7.00803 12.09 7.11402 11.835 7.30257 11.6473L17.3657 1.63085C17.7565 1.24187 18.3884 1.24261 18.7783 1.63249Z"
        fill="#163A50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6877 3.9021L20.1019 8.31631L18.6877 9.73053L14.2734 5.31631L15.6877 3.9021Z"
        fill="#163A50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 3.5C2.5 2.94772 2.94772 2.5 3.5 2.5H12V4.5H4.5V19.5H19.5V12H21.5V20.5C21.5 21.0523 21.0523 21.5 20.5 21.5H3.5C2.94772 21.5 2.5 21.0523 2.5 20.5V3.5Z"
        fill="#163A50"
      />
    </svg>
  );
};
