import React, { useState } from 'react';
import { useAppStore } from 'stores/appStore';
import { wipeData } from 'api/onboardingStep';
import { AsyncButton } from 'components/ButtonNew/Button';
import JubelioLogo from 'assets/img/jubelio/jubelio-icon.png';
import whatsappLogo from 'assets/img/whatsapp.png';
import styles from './DummyLogin.module.scss';
import Link from '@mui/material/Link';

const DummyLogin: React.FunctionComponent = () => {
  const { updateStep } = useAppStore();
  const [busy, setBusy] = useState(false);

  const handleWipe = async () => {
    setBusy(true);
    const { data: wipeDataResponse, status: wipeStatus, statusText: wipeStatusText } = await wipeData();
    if (wipeDataResponse.status !== 'ok' || wipeStatus !== 200) {
      throw new Error(`Something went wrong, ${wipeStatusText}`);
    } else {
      setBusy(false);
      updateStep('INCREASE');
    }
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.box}`}>
        <div className={`${styles.jubelio_logo}`}>
          <img src={JubelioLogo} alt="logo" />
        </div>
        <div className="d-flex flex-column align-items-center">
          <div className="font-weight-bold" style={{ fontSize: '30px', marginBottom: '40px', marginTop: '45px' }}>
            Selamat Datang di Onboarding Jubelio
          </div>
          <p style={{ fontSize: '14px', textAlign: 'center', fontWeight: '400' }}>
            Jika Kamu setuju untuk melanjutkan proses onboarding, Kami akan menghapus data - data trial di Jubelio
            sebelumnya.
            <br /> <br />
            Apakah kamu yakin untuk memulai proses onboarding wizard?
          </p>

          <AsyncButton
            className="mt-4"
            busy={busy}
            sx={{ width: '136px' }}
            variant="contained"
            type="submit"
            onClick={handleWipe}
            color="primary"
          >
            Lanjutkan
          </AsyncButton>
        </div>
      </div>
      <div className="d-flex align-items-center mt-4">
        <img className={styles.whatsappLogo} src={whatsappLogo} alt="whatsapp" />
        <Link
          target={'_blank'}
          href="https://wa.me/6285215839386"
          className={`ml-1 ${styles.footerText}`}
          variant="body2"
        >
          Butuh Bantuan?
        </Link>
      </div>
    </div>
  );
};
export default DummyLogin;
