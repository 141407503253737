import React from 'react';
import { showAlert } from 'components/Alert';
import { shallow } from 'zustand/shallow';
import useLocationStore from './location';

export default function useAddLocationHook(locationId) {
  const {
    infoBroker,
    info,
    pinMap,
    thirdContent,
    multiWarehouse,
    locationDetail,
    // automaticTransfer,
    setInfoBroker,
    setInfo,
    setPinMap,
    setThirdContent,
    setMultiWarehouse,
    setLocationDetail,
    // setAutomaticTransfer,
  } = useLocationStore(
    (state) => ({
      infoBroker: state.infoBroker,
      info: state.info,
      pinMap: state.pinMap,
      thirdContent: state.thirdContent,
      // automaticTransfer: state.automaticTransfer,
      multiWarehouse: state.multiWarehouse,
      locationDetail: state.locationDetail,
      setInfoBroker: state.setInfoBroker,
      setInfo: state.setInfo,
      setPinMap: state.setPinMap,
      setThirdContent: state.setThirdContent,
      // setAutomaticTransfer: state.setAutomaticTransfer,
      setMultiWarehouse: state.setMultiWarehouse,
      setLocationDetail: state.setLocationDetail,
    }),
    shallow
  );
  const isThirdParty = infoBroker.type ? true : false;

  const onChangeForm = (formValue, setStorage) => {
    if (Number(locationId) <= 0 && formValue) {
      setStorage(formValue);
    }
  };

  const onChangeFormInfoBroker = (formValue) => onChangeForm(formValue, setInfoBroker);
  const onChangeFormInfo = (formValue) => onChangeForm(formValue, setInfo);
  // const onChangeFormAutomaticTransfer = (formValue) => onChangeForm(formValue.locationTransfer, setAutomaticTransfer);
  const onChangeFormMultiWarehouse = (formValue) => onChangeForm(formValue.form, setMultiWarehouse);

  const isAddLocation = React.useMemo(() => {
    return !locationId || locationId === '0';
  }, [locationId]);

  const isValidInfo = () =>
    info.location_name &&
    info.location_code &&
    info.address &&
    info.province_id &&
    info.city_id &&
    info.district_id &&
    info.subdistrict_id &&
    info.post_code;

  const isValidInfoBroker = () => infoBroker.code && infoBroker.name;

  const isValidFormAdd = () => {
    if ((!isThirdParty && !isValidInfo()) || (isThirdParty && !isValidInfoBroker())) {
      showAlert('error', 'Harap lengkapi data Informasi Lokasi');
      return false;
    }
    return true;
  };

  // payload for submit location
  const getPayloadReq = () => {
    const req = {
      location_id: Number(locationId),
      ...info,
      area: info.district,
      // source_replenishment: automaticTransfer,
      warehouse: multiWarehouse.map((obj) => ({
        store_id: obj.store_id,
        warehouse_id: obj.warehouse_id,
        location_id: Number(locationId),
      })),
      registers: [],
      is_pos_outlet: false,
      is_fbl: false,
      is_tcb: false,
      is_sbs: false,
      is_warehouse: true,
      is_multi_origin: false,
      default_warehouse_user: '',
      lz_warehouse_id: '',
      stores: null,
      warehouse_id: '',
      warehouse_store_id: '',
    };
    delete req.district;
    return req;
  };

  const getThirdPartyReq = () => {
    return {
      address: ' ',
      subdistrict: ' ',
      area: ' ',
      city: ' ',
      province: ' ',
      province_id: ' ',
      city_id: ' ',
      district_id: ' ',
      subdistrict_id: ' ',
      post_code: ' ',
      phone: ' ',
      email: ' ',
      is_pos_outlet: false,
      is_warehouse: false,
      is_multi_origin: false,
      stores: null,
      warehouse_id: '',
      lz_warehouse_id: '',
      is_active: true,
      default_warehouse_user: '',
      // source_replenishment: null,
      registers: [],
      coordinate: null,
      warehouse: multiWarehouse.map((obj) => ({
        store_id: obj.store_id,
        warehouse_id: String(obj.warehouse_id.wh_id) ?? undefined,
        warehouse_name: obj.warehouse_name,
        location_id: Number(locationId),
      })),
    };
  };

  return {
    isAddLocation,
    infoBroker,
    info,
    pinMap,
    thirdContent,
    // automaticTransfer,
    multiWarehouse,
    locationDetail,
    setPinMap,
    setThirdContent,
    onChangeFormInfoBroker,
    onChangeFormInfo,
    // onChangeFormAutomaticTransfer,
    onChangeFormMultiWarehouse,
    isValidFormAdd,
    getPayloadReq,
    setLocationDetail,
    getThirdPartyReq,
  };
}
