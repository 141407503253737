import React from 'react';
import Joi from 'joi';
import { Grid } from '@mui/material';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { Form } from 'components/Form/Form';
import { AsyncButton } from 'components/ButtonNew/Button';
import { TextInput } from 'components/Form/TextInput';
import { ModalMaps } from 'components/ModalMaps/ModalMaps';
import { useStyles } from './style';
import { getListRegions, saveLocation } from 'api/locations';
import { showAlert } from 'components/Alert';
import { useAppStore } from 'stores/appStore';
import { getCommonsReq, getLayoutReq } from './constant';

import useAddLocationHook from './useAddLocationHook';
import useModalConfirmSubmit from './useModalConfirmSubmit';

type LocationDetailInfoProps = {
  locationId: string;
  locationDetail: any;
  getLocationDetail: () => void;
  setSubmitForm?: React.MutableRefObject<any>;
  pageSetter: any;
};

export type LocationFormType = {
  location_name: string;
  location_code: string;
  coordinate: string;
  address: string;
  province: string;
  province_id: string;
  city: string;
  city_id: string;
  district: string;
  district_id: string;
  subdistrict: string;
  subdistrict_id: string;
  post_code: string;
  phone: string;
  email: string;
};

const schema = Joi.object({
  location_name: Joi.string(),
  location_code: Joi.string(),
  coordinate: Joi.string().allow(null).allow(''),
  address: Joi.string(),
  province: Joi.string(),
  province_id: Joi.string(),
  city: Joi.string(),
  city_id: Joi.string(),
  district: Joi.string(),
  district_id: Joi.string(),
  subdistrict: Joi.string(),
  subdistrict_id: Joi.string(),
  post_code: Joi.string(),
  phone: Joi.string().allow(''),
  email: Joi.string().allow('')
});

type FormBuilder = {
  label: string;
  field_name: string;
  value: string | any;
  valueDesc?: string;
  type?: string;
  selectData?: any[];
  disabled?: boolean;
  isMandatory?: boolean;
  separator?: string;
  placeholder?: string;
};

const defaultValues = {
  location_name: '',
  location_code: '',
  coordinate: '',
  address: '',
  province: '',
  province_id: '',
  city: '',
  city_id: '',
  district: '',
  district_id: '',
  subdistrict: '',
  subdistrict_id: '',
  post_code: '',
  phone: '',
  email: ''
};

let prevCoordinate: string = null;
const LocationDetailInfo: React.FC<LocationDetailInfoProps> = ({
  locationId,
  locationDetail,
  getLocationDetail,
  setSubmitForm,
  pageSetter
}) => {
  const classes = useStyles();
  const { modeEdit } = useAppStore();
  const [isEdit, setIsEdit] = React.useState(!locationId || locationId === '0');
  const [busy, setBusy] = React.useState(false);
  const [data, setData] = React.useState<LocationFormType>(defaultValues);
  const [isPopulatedData, setIsPopulatedData] = React.useState(false);

  const [selectedRegions, setSelectedRegions] = React.useState({}) as any;
  const [optProvince, setOptProvince] = React.useState([]);
  const [optCity, setOptCity] = React.useState([]);
  const [optDistrict, setOptDistrict] = React.useState([]);
  const [optSubDistrict, setOptSubDistrict] = React.useState([]);
  const [modalMap, setModalMap] = React.useState(false);
  const [pinMap, setPinMapp] = React.useState<any>({
    s_coordinate: null,
    pin_maps: null
  });

  const {
    isAddLocation,
    info,
    pinMap: pinMapStore,
    setPinMap: setPinMapStore,
    onChangeFormInfo,
    isValidFormAdd,
    getPayloadReq
  } = useAddLocationHook(locationId);

  const getRegions = async (apiUrl, keyValue, setOpt) => {
    const res = await getListRegions(apiUrl);
    if (res.status === 200) {
      const opt = res?.data?.map((obj) => ({ value: obj[keyValue], label: obj.name }));
      setOpt(opt);
    }
  };

  const getListSelectedRegions = async (province_id?, city_id?, district_id?) => {
    if (province_id) {
      await getRegions(`region/cities?province_id=${province_id}`, 'city_id', setOptCity);
    }
    if (city_id) {
      await getRegions(`region/districts?city_id=${city_id}`, 'district_id', setOptDistrict);
    }
    if (district_id) {
      await getRegions(`region/subdistricts?district_id=${district_id}`, 'subdistrict_id', setOptSubDistrict);
    }
  };

  const getLocationMap = async (value) => {
    let initValue = value
      ? value.replace('(', '').replace(')', '').split(',')
      : data.coordinate.replace('(', '').replace(')', '').split(',');
    if (initValue !== prevCoordinate) {
      prevCoordinate = pinMap.s_coordinate;
      const coordinate = initValue;
      const latlng: any = new window.google.maps.LatLng(parseFloat(coordinate[0]), parseFloat(coordinate[1]));
      const geocoder: any = new window.google.maps.Geocoder();
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            const address = results[0].formatted_address;
            setPinMapp({
              s_coordinate: coordinate,
              pin_maps: address
            });
          }
        }
      });
    }
    return null;
  };

  const loadData = async () => {
    const locationResp = locationDetail;
    if (locationResp) {
      getListSelectedRegions(locationResp.province_id, locationResp.city_id, locationResp.district_id);
      setSelectedRegions({
        province: locationResp.province,
        city: locationResp.city,
        district: locationResp.district,
        subdistrict: locationResp.subdistrict
      });
      let newData = {
        location_code: locationResp.location_code,
        location_name: locationResp.location_name,
        address: locationResp.address,
        city: locationResp.city,
        city_id: locationResp.city_id,
        province: locationResp.province,
        province_id: locationResp.province_id,
        district: locationResp.district,
        district_id: locationResp.district_id,
        subdistrict: locationResp.subdistrict,
        email: locationResp.email,
        phone: locationResp.phone,
        subdistrict_id: locationResp.subdistrict_id,
        post_code: locationResp.post_code,
        coordinate: locationResp.coordinate
      };
      if (!newData.phone) {
        delete newData.phone;
      }
      if (!newData.email) {
        delete newData.email;
      }
      setData(newData);
      getLocationMap(locationResp.coordinate);
    }
  };

  const loadStorageData = async () => {
    await getListSelectedRegions(info.province_id, info.city_id, info.district_id);
    setSelectedRegions({
      province: info.province,
      city: info.city,
      district: info.district,
      subdistrict: info.subdistrict
    });
    setPinMapp(pinMapStore);
    setData({
      ...info,
      coordinate: pinMapStore.s_coordinate
    });
    setIsPopulatedData(true);
  };

  // load from zustand for add
  React.useEffect(() => {
    if (isAddLocation && !isPopulatedData) {
      loadStorageData();
    }
  }, [info]);

  // load from api response
  React.useEffect(() => {
    if (!isAddLocation) {
      loadData();
    }
    if (optProvince.length === 0) {
      getRegions('region/provinces', 'province_id', setOptProvince);
    }
  }, [locationDetail]);

  React.useEffect(() => {
    if (!locationId || locationId === '0') {
      setIsEdit(true);
    } else {
      setIsEdit(modeEdit);
    }
  }, [modeEdit]);

  const onSubmitRequest = async (req) => {
    setBusy(true);
    try {
      const res: any = await saveLocation(req);
      setBusy(false);
      if (res.status === 200) {
        getLocationDetail();
        showAlert('success', 'Berhasil ditambahkan');
        pageSetter('main');
      } else {
        showAlert('error', 'Gagal menyimpan data');
      }
    } catch (err) {
      setBusy(false);
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const callbackSubmit = (formVal) => {
    const req = { ...getPayloadReq(), is_active: formVal.isActive };
    if (!req.email) {
      delete req.email;
    }
    if (!req.phone) {
      delete req.phone;
    }
    onSubmitRequest(req);
  };

  const { ModalConfirmSubmit, onShowModalConfrim } = useModalConfirmSubmit({
    isAdd: isAddLocation,
    callbackSubmit,
    locationDetail
  });

  const onSaveForm = async (formValue) => {
    if (!isAddLocation) {
      const tempLocation = getCommonsReq(locationDetail, locationId);
      const req = {
        ...tempLocation,
        location_id: Number(locationId),
        layout: getLayoutReq(locationDetail?.layout?.floors),
        area: formValue.district,
        ...formValue
      };
      delete req.district;
      if (!req.email) {
        delete req.email;
      }
      if (!req.phone) {
        delete req.phone;
      }
      delete req.source_replenishment_name;
      onSubmitRequest({ ...req, ...getPayloadReq() });
    } else if (isValidFormAdd()) {
      callbackSubmit(formValue);
    }
  };

  let formhooks: any;
  const getFormHooks = (formhook) => {
    formhooks = formhook;
    setSubmitForm.current = formhook.handleSubmit(onSaveForm);
  };

  const LocationForm: FormBuilder[] = [
    {
      label: 'Nama Lokasi',
      field_name: 'location_name',
      value: data.location_name || '',
      isMandatory: true,
      type: 'text',
      placeholder: 'Nama lokasi'
    },
    {
      label: 'Kode Lokasi',
      field_name: 'location_code',
      value: data.location_code || '',
      isMandatory: true,
      type: 'text',
      placeholder: 'Kode lokasi'
    },
    {
      separator: 'Alamat',
      label: 'Pin Lokasi',
      field_name: 'coordinate',
      value: data.coordinate || '-',
      type: 'maps'
    },
    {
      label: 'Detail Alamat',
      field_name: 'address',
      value: data.address || '',
      isMandatory: true,
      type: 'text',
      placeholder: 'Cth: Blok, Unit No, Patokan '
    },
    {
      label: 'Provinsi',
      field_name: 'province_id',
      value: data.province_id || '-',
      valueDesc: selectedRegions.province,
      type: 'select',
      isMandatory: true,
      selectData: optProvince,
      placeholder: 'Pilih provinsi'
    },
    {
      label: 'Kota',
      field_name: 'city_id',
      value: data.city_id || '-',
      valueDesc: selectedRegions.city,
      type: 'select',
      isMandatory: true,
      selectData: optCity,
      placeholder: 'Pilih kota'
    },
    {
      label: 'Kecamatan',
      field_name: 'district_id',
      value: data.district_id || '-',
      valueDesc: selectedRegions.district,
      type: 'select',
      isMandatory: true,
      selectData: optDistrict,
      placeholder: 'Pilih kecamatan'
    },
    {
      label: 'Kelurahan',
      field_name: 'subdistrict_id',
      value: data.subdistrict_id || '-',
      valueDesc: selectedRegions.subdistrict,
      type: 'select',
      isMandatory: true,
      selectData: optSubDistrict,
      placeholder: 'Pilih kelurahan'
    },
    {
      label: 'Kode Pos',
      field_name: 'post_code',
      value: data.post_code || '-',
      type: 'text',
      isMandatory: true,
      placeholder: 'Kode pos'
    },
    {
      separator: 'Kontak',
      label: 'No. Telepon',
      field_name: 'phone',
      value: data.phone || '-',
      type: 'text',
      placeholder: '+628xxxxx'
    },
    {
      label: 'Email',
      field_name: 'email',
      value: data.email || '-',
      type: 'text',
      placeholder: 'email@jubelio.com'
    }
  ];

  const onSaveMaps = (selected, value) => {
    const mapData = {
      ...pinMap,
      s_coordinate: selected?.lat ? `(${selected?.lat + ',' + selected?.lng})` : ' ',
      pin_maps: value
    };
    formhooks.setValue('coordinate', selected?.lat ? `(${selected?.lat + ',' + selected?.lng})` : ' ');
    setPinMapp(mapData);
    setPinMapStore(mapData);
    setModalMap(false);
  };

  const onChangeRegions = (obj, name) => {
    if (name === 'province_id') {
      setOptCity([]);
      setOptDistrict([]);
      setOptSubDistrict([]);
      formhooks.setValue('city_id', '');
      formhooks.setValue('district_id', '');
      formhooks.setValue('subdistrict_id', '');
      formhooks.setValue('province', obj.label);
      getRegions(`region/cities?province_id=${obj.value}`, 'city_id', setOptCity);
    } else if (name === 'city_id') {
      setOptDistrict([]);
      setOptSubDistrict([]);
      formhooks.setValue('district_id', '');
      formhooks.setValue('subdistrict_id', '');
      formhooks.setValue('city', obj.label);
      getRegions(`region/districts?city_id=${obj.value}`, 'district_id', setOptDistrict);
    } else if (name === 'district_id') {
      setOptSubDistrict([]);
      formhooks.setValue('subdistrict_id', '');
      formhooks.setValue('district', obj.label);
      getRegions(`region/subdistricts?district_id=${obj.value}`, 'subdistrict_id', setOptSubDistrict);
    } else if (name === 'subdistrict_id') {
      formhooks.setValue('subdistrict', obj.label);
    }
  };

  const rowStyle = !isEdit ? { paddingBottom: 20 } : {};

  const onChangeForm = (formVal) => {
    if (isPopulatedData) {
      onChangeFormInfo(formVal);
    }
  };

  return (
    <>
      <Form schema={schema} values={data} getFormHooks={getFormHooks} shouldUnregister={false} onChange={onChangeForm}>
        <Grid style={{ flex: 1 }}>
          <>
            {LocationForm.map((item, i) => (
              <div key={i}>
                {item.separator && (
                  <div className="pt-4 pb-3">
                    <p className="mb-0 font-weight-bold" style={{ fontSize: 20 }}>
                      {item.separator}
                    </p>
                  </div>
                )}
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={3} className={classes.label} style={rowStyle}>
                    {item.label}
                    {isEdit && item.isMandatory && <span className="text-danger">*</span>}
                  </Grid>
                  <Grid item xs={9} className={classes.description} style={rowStyle}>
                    {!isEdit ? (
                      <>
                        {item.type === 'maps' && data.coordinate ? (
                          <p className="mb-0" style={{ color: '#878A8E', fontSize: '14px' }}>
                            {data.coordinate || pinMap.pin_maps ? pinMap.pin_maps : '-'}
                          </p>
                        ) : (
                          <p className="mb-0" style={{ maxWidth: 386, fontSize: '14px' }}>
                            {item.valueDesc || item.value}
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        {item.type === 'maps' && (
                          <AsyncButton
                            busy={busy}
                            color="primary"
                            type="button"
                            variant="text"
                            className="text-left"
                            style={{ marginLeft: -8 }}
                            onClick={async () => setModalMap(true)}
                          >
                            {data.coordinate || pinMap.pin_maps ? pinMap.pin_maps : 'Pilih Map'}
                          </AsyncButton>
                        )}
                        {item.type === 'select' && (
                          <Dropdown
                            key={`${data[item.field_name]}_${item.selectData.length}`}
                            disabled={item.selectData.length === 0}
                            placeholder={item.placeholder}
                            label={item.label}
                            data={item.selectData}
                            name={item.field_name}
                            useOnChange
                            onChange={(obj) => onChangeRegions(obj, item.field_name)}
                            className="notch-dropdown multiple-dropdown__primary"
                          />
                        )}
                        {item.type === 'text' && (
                          <TextInput
                            placeholder={item.placeholder}
                            name={item.field_name}
                            value={item.value}
                            label={item.label}
                            variant="outlined"
                            className="no-notch"
                            disabled={item.disabled}
                          />
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </div>
            ))}
          </>
        </Grid>
      </Form>
      <ModalMaps
        close={() => setModalMap(false)}
        open={modalMap}
        onSave={onSaveMaps}
        initCoordinate={pinMap.s_coordinate}
        isInit={Number(locationId) === 0 ? false : true}
        id={Number(locationId) === 0 ? 'new' : Number(locationId)}
      />
      {ModalConfirmSubmit}
    </>
  );
};

export default LocationDetailInfo;
