import { Button, Dialog, DialogContent } from '@mui/material';
import React from 'react';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { useStyles } from './style';

type ModalType = {
  open: boolean;
  title?: string;
  description?: string;
  btnCancelText: string;
  btnOkText: string;
  btnOkIcon?: React.ReactNode;
  onConfirm: () => void;
  onClose: () => void;
  onCancel?: () => void;
  children?: React.ReactNode;
};

const ModalConfirmation = ({
  open,
  title,
  description,
  onConfirm,
  onClose,
  onCancel,
  btnCancelText,
  btnOkText,
  btnOkIcon,
  children,
}: ModalType) => {
  const classes = useStyles();

  const onCancelBtn = () => {
    if (onCancel) {
      onCancel()
    } else {
      onClose()
    }
  }

  return (
    <Dialog onClose={onClose} open={open}>
      <div className={classes.alertContainer}>
        <div>
          <DialogContent className={classes.alertContent}>
            <div className="text-center">
              <ReportProblemOutlinedIcon style={{ width: 52, height: 42, color: 'orange' }} />
              <div className={classes.alertMessage}>{title}</div>
              <div>{description}</div>
              {children}
            </div>
          </DialogContent>
          <div className="d-flex justify-content-center align-items-center pt-3">
            <Button variant="outlined" onClick={onCancelBtn} className="w-50 mr-3">
              {btnCancelText}
            </Button>
            <Button variant="contained" startIcon={btnOkIcon} onClick={onConfirm} className="w-50 ml-3">
              {btnOkText}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalConfirmation;
