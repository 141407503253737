import React from 'react';
import { EmptyImgSVG } from '../../assets/img/empty-img';

interface ImageProps {
  height?: string | number;
  width?: string | number;
  className?: string;
  src: string;
}

export const Image = (props: ImageProps) => {
  const { width, height, className, src } = props;
  const [isError, setError] = React.useState(false);

  return (
    <>
      {!src || isError ? (
        <div
          className={className}
          style={{
            width: width || '100%',
            height: height || '100%',
            objectFit: 'cover'
          }}
        >
          <EmptyImgSVG width="100%" height="100%" />
        </div>
      ) : (
        <img
          src={src}
          className={className}
          key={src}
          alt=""
          style={{
            width: width || '100%',
            height: height || '100%',
            objectFit: 'cover'
          }}
          onError={(e) => {
            setError(true);
          }}
        />
      )}
    </>
  );
};
