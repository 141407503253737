import { coreAPI, onBoardingAPI } from './axiosClient';

interface Model {
  address: string,
  city: string | null;
  country: string | null;
  district: string | null;
  postcode: string | null;
  state: string | null;
  company_name: string | null;
  website: string | null;
  npwp: string | null;
}
interface ProfileSettings {
  logo: Blob,
  model: Model,
}

interface AddressParam {
  level: string, 
  parent?: string,
  parent_id?: string,
}

export const getAddress = ({ level, parent, parent_id }: AddressParam ) => {
  return coreAPI({
    method: 'GET',
    url: `region/${level}?${parent}=${parent_id}`,
  });
};

export const saveProfile = (profileSettings: ProfileSettings) => {
  const form = new FormData();
  form.append('logo', profileSettings.logo);
  form.append('model', JSON.stringify(profileSettings.model));
  return coreAPI({
    method: 'POST',
    url: 'systemsetting/profile',
    data: form,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const saveAccounting = (accounting: string) => {
  return onBoardingAPI({
    method: 'POST',
    url: 'wizard/save-setup',
    data: {
      data: {
        use_accounting: accounting,
      },
    },
  });
};

export const getProfileSettings = () => {
  return coreAPI({
    method: 'GET',
    url: 'systemsetting/profile',
  });
};

export const getCompanies = (userId: number) => coreAPI({
  method: 'GET',
  url: `v2/companies/${userId}`
})

export const getListCompanies = (id, data) => {
  return coreAPI({
    method: 'GET',
    url: `v2/companies/${id}?${data}`
  });
};

export const postSwitchCompany = (data) => {
  return coreAPI({
    method: 'POST',
    url: 'switch-company',
    data: {
      companyId: data
    },
  });
};