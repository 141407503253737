import React, { useState } from 'react';
import { Title, TitleDescription } from 'components/atoms';
import { StepProps, ValidationConfirmation } from 'interfaces/Props';
import { Box, Dialog, Grid, Typography } from '@mui/material';
import { useAppStore } from 'stores/appStore';

import UseDocumentTitle from 'hooks/UseDocumentTitle';
import CustomButton from 'components/Button';
import JubelioIcon from 'assets/img/jubelio/jubelio-icon.png';

import EduKatalog from 'assets/img/edu-katalog.jpeg';
import Gudang from 'assets/img/edu/gudang.jpeg';
import JubeliChat from 'assets/img/edu/jubelio-chat.jpeg';
import JubelioStore from 'assets/img/edu/jubelio-store.jpeg';
import Keuangan from 'assets/img/edu/keuangan.jpeg';
import Pembelian from 'assets/img/edu/pembelian.jpeg';
import PengaturanSistem from 'assets/img/edu/pengaturan-sistem.jpeg';
import Penjualan from 'assets/img/edu/penjualan.jpeg';
import Persediaan from 'assets/img/edu/persediaan.jpeg';
import PosBackoffice from 'assets/img/edu/pos-backoffice.jpeg';
import PosKasir from 'assets/img/edu/pos-kasir.jpeg';
import PosMesinKasir from 'assets/img/edu/pos-mesin-kasir.jpeg';
import PosPengaturan from 'assets/img/edu/pos-pengaturan.jpeg';
import Shipment from 'assets/img/edu/shipment.jpeg';

import styles from 'pages/Core/PageContainer.module.scss';
import config from 'config';

const StepTen: React.FunctionComponent<StepProps & ValidationConfirmation> = ({ submitRef }) => {
  UseDocumentTitle('Kenali Fitur Fitur Jubelio');

  const { updateStep } = useAppStore();
  const [isOpen, setOpen] = useState(false);

  const handleSubmit = () => {
    setOpen(true);
  };

  const handleConfirmSubmit = async () => {
    setOpen(false);
    await updateStep('INCREASE');
    window.location.replace(config.V2_URL);
  };

  return (
    <>
      <div className="d-flex justify-content-between mt-4">
        <Grid container spacing={5} direction="row" height="fit-content">
          <Grid item xs={12} height="auto">
            <Title>Kenali fitur fitur jubelio dengan melihat tutorial Jubelio dibawah ini.</Title>
          </Grid>
          <Grid item xs={3}>
            <a
              href="https://v2.edu.jubelio.com/documentation/fitur-katalog/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="imageWrapper">
                <img src={EduKatalog} />
                <TitleDescription bolded>Katalog</TitleDescription>
              </div>
            </a>
          </Grid>
          <Grid item xs={3}>
            <a
              href="https://v2.edu.jubelio.com/documentation/fitur-persediaan/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="imageWrapper">
                <img src={Persediaan} />
                <TitleDescription bolded>Persediaan</TitleDescription>
              </div>
            </a>
          </Grid>
          <Grid item xs={3}>
            <a
              href="https://v2.edu.jubelio.com/documentation/fitur-penjualan/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="imageWrapper">
                <img src={Penjualan} />
                <TitleDescription bolded>Penjualan</TitleDescription>
              </div>
            </a>
          </Grid>
          <Grid item xs={3}>
            <a
              href="https://v2.edu.jubelio.com/documentation/fitur-pembelian/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="imageWrapper">
                <img src={Pembelian} />
                <TitleDescription bolded>Pembelian</TitleDescription>
              </div>
            </a>
          </Grid>
          <Grid item xs={3}>
            <a
              href="https://v2.edu.jubelio.com/documentation/berkenalan-dengan-fitur-gudang/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="imageWrapper">
                <img src={Gudang} />
                <TitleDescription bolded>Gudang</TitleDescription>
              </div>
            </a>
          </Grid>
          <Grid item xs={3}>
            <a
              href="https://v2.edu.jubelio.com/documentation/fitur-keuangan/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="imageWrapper">
                <img src={Keuangan} />
                <TitleDescription bolded>Keuangan</TitleDescription>
              </div>
            </a>
          </Grid>
          <Grid item xs={3}>
            <a
              href="https://v2.edu.jubelio.com/documentation/pengaturan-sistem/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="imageWrapper">
                <img src={PengaturanSistem} />
                <TitleDescription bolded>Pengaturan Sistem</TitleDescription>
              </div>
            </a>
          </Grid>
          <Grid item xs={3}>
            <a
              href="https://edu.jubelio.com/jubelio-shipment/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="imageWrapper">
                <img src={Shipment} />
                <TitleDescription bolded>Jubelio Shipment</TitleDescription>
              </div>
            </a>
          </Grid>
          <Grid item xs={3}>
            <a
              href="https://v2.edu.jubelio.com/documentation/dashboard-kasir-android-ios/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="imageWrapper">
                <img src={PosKasir} />
                <TitleDescription bolded>Jubelio POS - Kasir (Android & IOS)</TitleDescription>
              </div>
            </a>
          </Grid>
          <Grid item xs={3}>
            <a
              href="https://v2.edu.jubelio.com/documentation/memulai-dengan-jubelio-pos/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="imageWrapper">
                <img src={PosPengaturan} />
                <TitleDescription bolded>Jubelio POS - Pengaturan Awal</TitleDescription>
              </div>
            </a>
          </Grid>
          <Grid item xs={3}>
            <a
              href="https://v2.edu.jubelio.com/documentation/dashboard-kasir/transaksi-mesin-kasir-penjualan-retur/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="imageWrapper">
                <img src={PosMesinKasir} />
                <TitleDescription bolded>Jubelio POS - Mesin Kasir</TitleDescription>
              </div>
            </a>
          </Grid>
          <Grid item xs={3}>
            <a
              href="https://v2.edu.jubelio.com/documentation/dashboard-backoffice/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="imageWrapper">
                <img src={PosBackoffice} />
                <TitleDescription bolded>Jubelio POS - Backoffice</TitleDescription>
              </div>
            </a>
          </Grid>
          <Grid item xs={3}>
            <a
              href="https://edu.jubelio.com/jubelio-store/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="imageWrapper">
                <img src={JubelioStore} />
                <TitleDescription bolded>Jubelio Store</TitleDescription>
              </div>
            </a>
          </Grid>
          <Grid item xs={3}>
            <a
              href="https://edu.jubelio.com/documentation/memulai-dengan-jubelio-chat/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="imageWrapper">
                <img src={JubeliChat} />
                <TitleDescription bolded>Jubelio Chat</TitleDescription>
              </div>
            </a>
          </Grid>
        </Grid>
      </div>

      <Dialog maxWidth="sm" open={isOpen}>
        <Box
          sx={{
            px: 6,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 5
          }}
        >
          <Box
            component="img"
            sx={{
              height: '100%',
              maxWidth: 200,
              mb: 5
            }}
            alt="The house from the offer."
            src={JubelioIcon}
          />
          <Typography className={styles.dialogTitle}>Selamat! Akun Jubelio Kamu sudah aktif</Typography>
          <Typography className={styles.dialogDescription}>
            Kamu akan menerima email konfirmasi mengenai onboarding dan jadwal training akan diinformasikan lebih lanjut
            dari tim Jubelio.
          </Typography>
          <CustomButton
            className={styles.dialogButton}
            variant={'contained'}
            name={'Ya, Saya Mengerti'}
            size={'large'}
            onClick={handleConfirmSubmit}
          />
        </Box>
      </Dialog>

      <button onClick={handleSubmit} ref={submitRef} hidden>
        Submit
      </button>
    </>
  );
};

export default StepTen;
