import styled from 'styled-components';

interface IconProps {
  opened: number | undefined;
  idx: number;
}
export interface ButtonProps {
  is_disabled: boolean;
}
interface Props {
  activeTab: string,
  tabId: string,
}
interface IntegrationProps {
  bg_color?: string;
  text_color?: string;
}
export const ContentContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 1.5rem;
`;
export const TabWrap = styled.div`
    display: grid;
    gap: 10px;
    align-content: start;
`;
export const TabTitle = styled.div<Props>`
    background-color: ${(props) => props.activeTab === props.tabId ? '#F1F9FF' : 'transparent' };
    border: 1px solid #E5E3E3;
    padding: 10px 1rem;
    cursor: pointer;
    border-radius: 1rem;
    font-weight: bold;
    font-size: 14px;
`;
export const TabContentWrap = styled.div`
    min-height: 100%;
    display: grid;
    align-content: start;
    gap: 10px;
`;
export const TabContent = styled.div`
    /* background-color: red; */
    /* display: grid; */
    /* gap: 10px; */
`;
export const TabContentItem = styled.div`
    padding: 10px 12px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(228, 226, 226, 0.78);
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const AddedList = styled.div<IconProps>`
    height: ${({ opened, idx }) => opened === idx ? '180px' : '0'};
    opacity: ${({ opened, idx }) => opened === idx ? '1' : '0'};
    transition: height 500ms, opacity 500ms;
`;
export const TableWrap = styled.div<IconProps>`
    border: 2px solid #E5E3E3;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 1rem;
    margin-top: ${({ opened, idx }) => opened === idx ? '10px' : '0'};
    transition: margin-top 1000ms;
    font-size: 14px;
    table {
        /* tr:nth-child(even){background-color: #f2f2f2} */
        border-collapse: collapse;
        width: 100%;
        th {
            border-bottom: 2px solid  #E5E3E3;
            font-size: 14px;
        }
        td {
            font-size: 12px;
        }
        th, td {
            padding: 10px;
            /* border: 1px solid black; */
        }
        tr td:nth-child(2) {
            width: 1%;
            white-space: nowrap;
        }
        tr th:nth-child(2) {
            max-width: 100%;
            white-space: nowrap;
        }
        tr td:nth-child(3), tr th:nth-child(3) {
            text-align: center;
        }
    }
    &::-webkit-scrollbar {
        width: 12px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #ddd;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
`;
export const StoreName = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    img {
        width: 15px;
    }
`;
export const IntegrationStatus = styled.div<IntegrationProps>`
    background-color: ${({ bg_color }) => bg_color ? bg_color : '#4B68EF'};
    color: ${({ text_color }) => text_color ? text_color : 'white'};
    padding: 5px 12px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
`;
export const DotIcon = styled.div<IntegrationProps>`
    background-color: ${({ bg_color }) => bg_color ? bg_color : 'red'};
    height: 7px;
    width: 7px;
    border-radius: 50%;
`;
export const AddNew = styled.button<ButtonProps>`
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    padding: 4px 6px;
    border-radius: 5px;
    color: white;
    gap: 5px;
    font-size: 12px;
    background-color: ${({ is_disabled }) => is_disabled ? '#D4D5D6' : '#4B68EF'};
    cursor: ${({ is_disabled }) => is_disabled ? 'not-allowed' : 'pointer'};
`;
export const BtnWrap = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;