import React from 'react';

export const EmptyImgSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={props.width} height={props.width} style={{ borderRadius: 6 }} viewBox="0 0 400 400" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={props.width} height={props.width} fill="#C4C4C4" />
      <path
        d="M297.562 105.92H102.438C87.104 105.92 74.56 118.464 74.56 133.798V266.227C74.56 281.562 87.104 294.106 102.438 294.106H297.562C312.896 294.106 325.44 281.562 325.44 266.227V133.798C325.44 118.464 312.896 105.92 297.562 105.92ZM314.995 263.309C314.995 277.363 308.723 283.635 294.669 283.635H105.357C91.3024 283.635 85.0304 277.363 85.0304 263.309V136.691C85.0304 122.637 91.3024 116.365 105.357 116.365H294.669C308.723 116.365 314.995 122.637 314.995 136.691V263.309V263.309ZM152.973 179.085L179.085 200L247.027 142.502L299.302 189.555V267.968H100.698V220.915L152.973 179.085ZM121.613 132.058C110.067 132.058 100.698 141.427 100.698 152.973C100.698 164.518 110.067 173.888 121.613 173.888C133.158 173.888 142.528 164.518 142.528 152.973C142.528 141.427 133.158 132.058 121.613 132.058Z"
        fill="#DEDEDE"
      />
    </svg>
  );
};
