import React from 'react';

export const CalenderSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 4.5C1.5 3.67157 2.17157 3 3 3H15C15.8285 3 16.5 3.67157 16.5 4.5V14.25C16.5 15.0785 15.8285 15.75 15 15.75H3C2.17157 15.75 1.5 15.0785 1.5 14.25V4.5ZM15 4.5H3V14.25H15V4.5Z"
        fill="#808589"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M6 1.5V4.5H4.5V1.5H6ZM13.5 1.5V4.5H12V1.5H13.5Z" fill="#808589" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1.875 6H15.75V7.5H1.875V6Z" fill="#808589" />
    </svg>
  );
};
