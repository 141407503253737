import React from 'react';

export const SaveSVG: React.FC<React.SVGProps<SVGSVGElement>> = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16.172C16.7024 3.00011 17.211 3.2109 17.586 3.586L20.414 6.414C20.7891 6.78899 20.9999 7.29761 21 7.828V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M8.6 9H15.4C15.5591 9 15.7117 8.93679 15.8243 8.82426C15.9368 8.71174 16 8.55913 16 8.4V3.6C16 3.44087 15.9368 3.28826 15.8243 3.17574C15.7117 3.06321 15.5591 3 15.4 3H8.6C8.44087 3 8.28826 3.06321 8.17574 3.17574C8.06321 3.28826 8 3.44087 8 3.6V8.4C8 8.55913 8.06321 8.71174 8.17574 8.82426C8.28826 8.93679 8.44087 9 8.6 9V9Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M6 13.6V21H18V13.6C18 13.4409 17.9368 13.2883 17.8243 13.1757C17.7117 13.0632 17.5591 13 17.4 13H6.6C6.44087 13 6.28826 13.0632 6.17574 13.1757C6.06321 13.2883 6 13.4409 6 13.6V13.6Z"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
};
