import React from 'react';
import Joi from 'joi';
import { Add } from '@mui/icons-material';
import { Grid, Tooltip } from '@mui/material';
import { useStyles } from './style';
import { AsyncButton } from 'components/ButtonNew/Button';
import { Column } from 'components/LiteGrid/DataGrid.types';
import { Dropdown } from 'components/Dropdown/Dropdown';
import { Form } from 'components/Form/Form';
import { LiteGrid } from 'components/LiteGrid/LiteGrid';
import { showAlert } from 'components/Alert';
import { TextInput } from 'components/Form/TextInput';
import { contentBroker, getCommonsReq, getLayoutReq, optionsBrokerType } from './constant';
import { getChannelList, getWarehouseList } from 'api/integrationStep';
import { channelDetail } from 'shared/constants';
import { saveLocation } from 'api/locations';
import { useAppStore } from 'stores/appStore';

import useAddLocationHook from './useAddLocationHook';
import useModalConfirmSubmit from './useModalConfirmSubmit';
import { QueryParameters } from 'lib/types';
import { useFormWithSchema } from 'hooks/useFormWithSchema';

type MultiWarehouseProps = {
  locationId: string;
  locationDetail: any;
  getLocationDetail: () => void;
  setSubmitForm?: React.MutableRefObject<any>;
  pageSetter: any;
};

const schema = Joi.object({
  form: Joi.array().items(
    Joi.object().keys({
      store_id: Joi.number().required(),
      warehouse_id: Joi.object().required(),
      warehouse_name: Joi.string().min(1).required(),
      idx: Joi.number().allow(0)
    })
  )
});

const MultiWarehouse: React.FC<MultiWarehouseProps> = ({
  locationId,
  locationDetail,
  getLocationDetail,
  setSubmitForm,
  pageSetter
}) => {
  let formhooks = useFormWithSchema({ schema, shouldUnregister: false });
  const classes = useStyles();
  const { modeEdit } = useAppStore();
  const isAdd = locationId != '-1' ? Number(locationId) <= 0 : false;
  const [optStore, setOptStore] = React.useState([]);
  const [isEdit, setIsEdit] = React.useState(!locationId || isAdd);
  const [busy, setBusy] = React.useState(false);
  const [data, setData] = React.useState<any>([]);
  const [errors, setErrors] = React.useState<any>({});
  const [isPopulatedData, setIsPopulatedData] = React.useState(false);

  const isFirst = React.useRef(true);
  const valueHook = formhooks.watch('form');

  const {
    infoBroker,
    multiWarehouse,
    onChangeFormMultiWarehouse,
    isValidFormAdd,
    getPayloadReq,
    thirdContent,
    setLocationDetail,
    getThirdPartyReq
  } = useAddLocationHook(locationId);

  const isThirdParty =
    locationDetail.is_fbl || locationDetail.is_sbs || locationDetail.is_tcb || infoBroker.type ? true : false;

  const selectedBroker = React.useMemo(() => {
    if (isAdd) {
      const code = optionsBrokerType.find((obj) => obj.id === Number(thirdContent.value));
      return contentBroker[code?.value] || {};
    }
    return {};
  }, [locationId]);

  const gridData = data.length === 0 && isEdit ? [{ store_id: '', warehouse_id: '', warehouse_name: '' }] : data;

  const getListStore = async (option: QueryParameters) => {
    try {
      const res: any = await getChannelList();
      if (res.status === 200) {
        const temp = [];
        res.data.map((obj) => {
          if (obj.channel_id === 4 || obj.channel_id === 32 || obj.channel_id === 64 || obj.channel_id === 128) {
            temp.push({
              channel_id: obj.channel_id,
              channel_name: obj.channel_name,
              value: obj.store_id,
              label: obj.store_name
            });
          }
        });
        if (temp.length > 0) {
          setOptStore(temp);
        }
      }
    } catch (err) {
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const getListWarehouse = async (option, value) => {
    if (value === undefined) {
      return [];
    } else {
      option.sortBy = 'warehouse_name';
      option.sortDirection = 'ASC';
      const resp = await getWarehouseList(value);
      if (resp?.data?.data) {
        const temp = resp?.data?.data?.map((obj) => {
          return {
            wh_id: obj.warehouse_id,
            wh_name: obj.warehouse_name
          };
        });
        return temp;
      } else {
        return [];
      }
    }
  };

  const populateFormFields = (dataFields) => {
    const tempData = [];
    dataFields?.warehouse?.forEach((obj, idx) => {
      formhooks.setValue(`form.${idx}.store_id`, obj.store_id);
      formhooks.setValue(
        `form.${idx}.warehouse_id`,
        typeof obj?.warehouse_id !== 'string'
          ? obj?.warehouse_id?.wh_id
            ? { wh_id: obj?.warehouse_id?.wh_id, wh_name: obj?.warehouse_id?.wh_name }
            : {}
          : { wh_id: obj?.warehouse_id, wh_name: obj?.warehouse_name }
      );
      formhooks.setValue(
        `form.${idx}.warehouse_name`,
        typeof obj?.warehouse_id !== 'string' ? obj.warehouse_name : String(obj?.warehouse_id)
      );
      formhooks.setValue(`form.${idx}.idx`, idx);
      tempData.push({
        store_id: obj.store_id,
        store_name: obj.store_name,
        warehouse_id:
          typeof obj?.warehouse_id !== 'string'
            ? obj?.warehouse_id?.wh_id
              ? { wh_id: obj?.warehouse_id?.wh_id, wh_name: obj?.warehouse_id?.wh_name }
              : {}
            : { wh_id: obj?.warehouse_id, wh_name: obj?.warehouse_name },
        warehouse_name: typeof obj?.warehouse_id !== 'string' ? obj.warehouse_name : String(obj?.warehouse_id),
        idx: idx
      });
    });
    return tempData;
  };

  const loadData = async () => {
    const locationResp = locationDetail;
    if (locationResp) {
      const tempData = populateFormFields(locationResp);
      setData(tempData);
    }
  };

  // load from zustand for add
  React.useEffect(() => {
    if (isAdd && multiWarehouse?.length > 0 && optStore.length > 0 && !isPopulatedData) {
      const tempData = populateFormFields({ warehouse: multiWarehouse });
      setData(tempData);
      setIsPopulatedData(true);
    }
  }, [multiWarehouse, optStore]);

  // load from api response
  React.useEffect(() => {
    if (optStore.length === 0 && isFirst.current) {
      getListStore({});
    }
    if (!isAdd) {
      loadData();
    }
    isFirst.current = false;
  }, [locationId, locationDetail]);

  React.useEffect(() => {
    if (valueHook && !isFirst.current) {
      setLocationDetail({
        ...locationDetail,
        warehouse: data
      });
    }
  }, [valueHook]);

  React.useEffect(() => {
    if (!locationId || isAdd) {
      setIsEdit(true);
    } else {
      setIsEdit(modeEdit);
    }
  }, [modeEdit]);

  const getPayload = (formValue) => ({
    is_fbl: locationDetail.is_fbl || (isThirdParty && selectedBroker.code === 'FBL'),
    is_tcb: locationDetail.is_tcb || (isThirdParty && selectedBroker.code === 'TOKOPEDIA'),
    is_sbs: locationDetail.is_sbs || (isThirdParty && selectedBroker.code === 'SHOPEE'),
    warehouse: formValue.form
      ? formValue.form?.map((obj) => ({
          store_id: obj.store_id,
          warehouse_id: isThirdParty
            ? obj?.warehouse_id?.wh_name ?? obj?.warehouse_name
            : obj?.warehouse_id?.wh_id
            ? String(obj?.warehouse_id?.wh_id)
            : undefined,
          warehouse_name: isThirdParty ? undefined : obj?.warehouse_id?.wh_name ?? obj?.warehouse_name
        }))
      : []
  });

  const onSubmitRequest = async (req) => {
    setBusy(true);
    try {
      if (req.is_warehouse) {
        delete req.source_replenishment_name;
      }
      const res: any = await saveLocation(req);
      setBusy(false);
      if (res.status === 200) {
        getLocationDetail();
        showAlert('success', 'Berhasil ditambahkan');
        pageSetter('main');
      } else {
        showAlert('error', 'Gagal menyimpan data');
      }
    } catch (err) {
      setBusy(false);
      const error = err as Error;
      console.log(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  const callbackSubmit = (formValue) => {
    const formReq = getPayload(formValue);
    const defaultReq = isThirdParty
      ? {
          ...getThirdPartyReq(),
          location_id: infoBroker.id || 0,
          location_code: infoBroker.code || '',
          location_name: infoBroker.name || selectedBroker.name
        }
      : getPayloadReq();
    const req = {
      ...defaultReq,
      ...formReq,
      is_active: formValue.isActive
    };
    if (!req.email) {
      delete req.email;
    }
    if (!req.phone) {
      delete req.phone;
    }
    onSubmitRequest(req);
  };

  const { ModalConfirmSubmit, onShowModalConfrim } = useModalConfirmSubmit({
    isAdd,
    callbackSubmit,
    locationDetail
  });

  const onSaveForm = async (formValue) => {
    if (!isAdd) {
      const formReq = getPayload(formValue);
      const defaultReq = isThirdParty
        ? {
            ...getThirdPartyReq(),
            location_id: locationId,
            location_code: locationDetail.location_code || '',
            location_name: locationDetail.location_name || selectedBroker.name
          }
        : {
            ...getCommonsReq(locationDetail, locationId),
            layout: getLayoutReq(locationDetail?.layout?.floors)
          };
      const req = {
        ...defaultReq,
        ...formReq
      };
      if (!req.email) {
        delete req.email;
      }
      if (!req.phone) {
        delete req.phone;
      }
      onSubmitRequest(req);
    } else if (isValidFormAdd()) {
      onShowModalConfrim(formValue);
    }
  };

  const onHandleError = (err) => {
    setErrors(err);
  };

  const getFormHooks = (formhook) => {
    formhooks = formhook;
    setSubmitForm.current = formhook.handleSubmit(onSaveForm, onHandleError);
  };

  const onAddForm = async (val, isMore) => {
    const tempData = [...data];
    tempData.push({ store_id: null, store_name: '', warehouse_id: {}, warehouse_name: '', idx: data.length++ });
    formhooks.setValue(
      'form',
      tempData.map((newVal) => ({
        store_id: newVal.store_id,
        warehouse_id: newVal.warehouse_id,
        warehouse_name: newVal.warehouse_name,
        idx: newVal.idx
      }))
    );
    setData(tempData);
  };

  const colStoreNameEmpty = (ctx, record) => {
    return (
      <div style={{ padding: '10px 0px' }}>
        <AsyncButton
          color="primary"
          variant="contained"
          type="button"
          onClick={() => onAddForm(ctx.row_id, 'empty')}
          className="mx-1"
          icon={<Add width={10} height={10} />}
        >
          Tambah Baru
        </AsyncButton>
      </div>
    );
  };

  const colStoreNameView = (ctx, record) => {
    return <div>{record.store_name}</div>;
  };

  const storeFormat = (value) => {
    return (
      <div className="d-flex align-items-center">
        <img className="d-20" src={channelDetail[value.channel_id].channelLogo} alt={value.channel_name} />
        <Tooltip arrow placement="bottom-start" title={value?.label}>
          <div
            className="ml-1"
            style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: 120 }}
          >
            {value?.label}
          </div>
        </Tooltip>
      </div>
    );
  };

  const colStoreName = (ctx, record) => {
    const isError = !!errors?.form?.[ctx.row_id]?.store_id;

    const handleChange = async (item) => {
      if (isError) {
        formhooks.trigger();
      }
      formhooks.setValue(`form.${ctx.row_id}.store_id`, item?.value ?? null);
      formhooks.setValue(`form.${ctx.row_id}.warehouse_id`, {});
      formhooks.setValue(`form.${ctx.row_id}.warehouse_name`, undefined);
      let hookData = formhooks.getValues('form');

      data[ctx.row_id].store_id = item?.value ?? null;
      data[ctx.row_id].store_name = `${item?.label}` ?? '';
      data[ctx.row_id].warehouse_id = {};
      data[ctx.row_id].warehouse_name = undefined;

      await formhooks.setValue('form', hookData);
      await setErrors({});
      await setData(data);
    };

    return (
      <>
        <div>
          <Dropdown
            key={record.store_id}
            placeholder="Pilih Toko"
            label="Nama Toko"
            data={optStore}
            name={`form.${ctx.row_id}.store_id`}
            className="notch-dropdown"
            error={isError}
            helperText={isError && <span style={{ color: '#D32F2F' }}>Nama Toko tidak boleh kosong</span>}
            InputProps={{
              className: isError ? 'Mui-error' : ''
            }}
            useOnChange
            onChange={handleChange}
            itemFormatter={storeFormat}
          />
        </div>
        {ctx.row_id === gridData.length - 1 && (
          <div className={classes.containerAddRowTable}>
            <AsyncButton
              color="primary"
              variant="contained"
              type="button"
              onClick={() => onAddForm(ctx.row_id, 'more')}
              className="mx-1"
              icon={<Add width={10} height={10} />}
            >
              Tambah Baru
            </AsyncButton>
          </div>
        )}
      </>
    );
  };

  const colWarehouseNameEmpty = (ctx, record) => {
    return <div></div>;
  };

  const colWarehouseNameView = (ctx, record) => {
    let valueWarehouseName = formhooks.getValues(`form.${ctx.row_id}.warehouse_id`);
    return <div>{valueWarehouseName?.wh_name}</div>;
  };

  const colWarehouseName = (ctx, record) => {
    const isError = !!errors?.form?.[ctx.row_id]?.warehouse_name && isThirdParty;

    let valueStore = formhooks.getValues(`form.${ctx.row_id}.store_id`);

    const formatWarehouseName = (warehouse) => {
      if (warehouse.wh_name) {
        return { label: warehouse.wh_name, value: warehouse.wh_id };
      }
      return warehouse;
    };

    const handleChangeWarehouse = async (item) => {
      if (isError) {
        formhooks.trigger();
      }
      formhooks.setValue(`form.${ctx.row_id}.warehouse_id`, item?.wh_id ?? '');
      formhooks.setValue(`form.${ctx.row_id}.warehouse_name`, item?.wh_id.toString() ?? '');
      let hookData = formhooks.getValues('form');

      // data[ctx.row_id].store_id = data[ctx.row_id].store_id;
      // data[ctx.row_id].store_name = data[ctx.row_id].store_name;
      data[ctx.row_id].warehouse_id = item ? { wh_id: item?.wh_id, wh_name: item?.wh_name } : {};
      data[ctx.row_id].warehouse_name = item?.wh_id.toString() ?? '';

      await setData(data);
      await formhooks.setValue('form', hookData);
      await setErrors([]);
    };

    return (
      <>
        <div>
          <Dropdown
            disabled={!valueStore || isThirdParty}
            key={record.warehouse_id}
            placeholder="Pilih Gudang"
            label="Nama Gudang"
            data={(option: QueryParameters) => getListWarehouse(option, valueStore)}
            name={`form.${ctx.row_id}.warehouse_id`}
            className="notch-dropdown"
            error={isError}
            helperText={isError && <span style={{ color: '#D32F2F' }}>Nama Gudang tidak boleh kosong</span>}
            InputProps={{
              className: isError ? 'Mui-error' : ''
            }}
            itemMapper={formatWarehouseName}
            useOnChange
            onChange={handleChangeWarehouse}
          />
        </div>
      </>
    );
  };

  const colWarehouseIdEmpty = (ctx, record) => {
    return <div></div>;
  };

  const colWarehouseIdView = (ctx, record) => {
    // return <div>{record.warehouse_id}</div>;
    let valueWarehouseId = formhooks.getValues(`form.${ctx.row_id}.warehouse_name`);

    return <div>{valueWarehouseId}</div>;
  };

  const colWarehouseId = (ctx, record) => {
    const isError = !!errors?.form?.[ctx.row_id]?.warehouse_name;

    return (
      <TextInput
        placeholder="Masukkan Warehouse ID"
        name={`form.${ctx.row_id}.warehouse_name`}
        label="Warehouse ID"
        variant="outlined"
        className="no-notch"
        error={isError}
        helperText={isError ? 'Warehouse ID tidak boleh kosong' : null}
        onKeyUp={async (item: React.KeyboardEvent<HTMLInputElement>) => {
          if (isError) {
            formhooks.trigger();
          }

          const { value } = item.target as HTMLInputElement;
          formhooks.setValue(`form.${ctx.row_id}.warehouse_name`, value ?? undefined);
          let hookData = formhooks.getValues('form');

          // data[ctx.row_id].store_id = data[ctx.row_id].store_id;
          // data[ctx.row_id].store_name = data[ctx.row_id].store_name;
          data[ctx.row_id].warehouse_name = value ?? undefined;

          await setErrors({});
          await formhooks.setValue('form', hookData);
          await setData(data);
        }}
      />
    );
  };

  const columns: Column[] = [
    {
      header: 'Nama Toko',
      binding: 'store_id',
      className: 'text-left',
      template: isEdit ? (data.length > 0 ? colStoreName : colStoreNameEmpty) : colStoreNameView
    },
    {
      header: 'Nama Gudang',
      binding: 'warehouse_id',
      className: 'text-left',
      template: isEdit ? (data.length > 0 ? colWarehouseName : colWarehouseNameEmpty) : colWarehouseNameView
    },
    {
      header: 'Warehouse ID',
      binding: 'warehouse_name',
      format: 'dropdown',
      className: 'text-left',
      template: isEdit && isThirdParty ? (data.length > 0 ? colWarehouseId : colWarehouseIdEmpty) : colWarehouseIdView
    }
  ];

  const handleDeleteGrid = async (value) => {
    let hookData = formhooks.getValues('form');

    let filteredHookData = hookData.filter((hook) => {
      return !value.some((val) => val.idx === hook.idx && val.store_id === hook.store_id);
    });

    let filteredGridData = gridData.filter((hook) => {
      return !value.some((val) => val.idx === hook.idx && val.store_id === hook.store_id);
    });

    formhooks.setValue('form', filteredHookData);
    setData(filteredGridData);

    return true;
  };

  return (
    <>
      <Form formHooks={formhooks} getFormHooks={getFormHooks} onChange={onChangeFormMultiWarehouse}>
        <Grid style={{ flex: 1, margin: '0 24px' }} className={isEdit && classes.multiWarehouseGrid}>
          <LiteGrid
            columns={columns}
            data={gridData}
            getQuery={() => {}}
            getSetQuery={() => {}}
            getRefresher={() => {}}
            showRowSelect={data.length > 0 && isEdit}
            havePagination={false}
            onDelete={handleDeleteGrid}
            allowDelete={isEdit}
            scrollTopOnRrefresh={false}
          />
        </Grid>
      </Form>
      {ModalConfirmSubmit}
    </>
  );
};

export default MultiWarehouse;
