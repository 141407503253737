import React, { useState } from 'react';
import {
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  FormControl,
  TextField,
  InputAdornment,
  Grid,
  Tooltip
} from '@mui/material';
import { Image } from 'components/Image/Image';
import { SearchSVG } from 'assets/img';
import { channelDetail } from 'shared/constants';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
interface SelectedStore {
  store_name: string;
  channel_id: number;
  store_id: number;
  is_downloaded?: false;
  total_downloaded?: any;
  all_product?: any;
  on_download_process: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    storeName: {
      fontSize: 12,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical'
    }
  })
);

function StoreDetail({ handleOnClick, logo, dataStore, ...props }) {
  const classes = useStyles();

  return (
    <Grid
      container
      className="d-flex align-items-center justify-content-between"
      onClick={handleOnClick}
      style={{ padding: '10px 0', cursor: 'pointer', width: '300px', fontSize: '12px' }}
      {...props}
    >
      <Grid item md={6} className="d-flex align-items-center">
        <span className="mr-2">
          <Image width={18} src={logo} height={18} />
        </span>
        <div className="d-flex flex-column">
          <Tooltip arrow placement="bottom-start" title={dataStore.store_name}>
            <span
              className="pr-2"
              style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '150px' }}
            >
              {dataStore.store_name}
            </span>
          </Tooltip>
          {dataStore.is_downloaded && <span style={{ fontSize: 8, color: '#7DB942' }}>Berhasil</span>}
        </div>
      </Grid>
      {dataStore.is_downloaded && (
        <Grid item md={6} className="d-flex align-items-center justify-content-end">
          <div>
            {dataStore.total_downloaded} / {dataStore.all_product}
          </div>
          <CheckCircleOutlineIcon fontSize="medium" style={{ color: '#4CAF50', marginLeft: '10px' }} />
        </Grid>
      )}
      {dataStore.on_download_process && (
        <Grid item md={6} className="d-flex align-items-center justify-content-end">
          <div>
            {dataStore.total_downloaded} / {dataStore.all_product}
          </div>
        </Grid>
      )}
    </Grid>
  );
}

function FilterBar({ stores, handleSelectedStore, totalDownloaded, totalProduct }) {
  const [btnDropdown, setBtnDropdown] = useState<HTMLElement | null>(null);
  const [isOpen, setOpen] = useState(false);
  const [searchStore, setSearchStore] = useState('');
  const [selectedStore, setSelectedStore] = useState<SelectedStore | null>(null);

  const FilSearchStore = (options) => {
    return options.filter((opt) => {
      return opt.store_name?.toLowerCase().includes(searchStore.toLowerCase());
    });
  };

  const handleClickStore = (storeData) => {
    const selected = {
      store_name: storeData.store_name,
      store_id: storeData.store_id,
      channel_id: storeData.channel_id,
      is_downloaded: storeData.is_downloaded,
      total_downloaded: storeData.total_downloaded,
      all_product: storeData.all_product,
      on_download_process: storeData.on_download_process
    };
    setSelectedStore(selected);
    handleSelectedStore(selected);
    setOpen(false);
  };

  React.useEffect(() => {
    let onSelect = stores.find((val) => val.store_id === selectedStore.store_id);
    setSelectedStore(onSelect);
  }, [totalProduct, totalDownloaded]);

  return (
    <div>
      <div
        className="select-filter px-1 py-4"
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          setBtnDropdown(e.currentTarget);
          setOpen(true);
        }}
      >
        {selectedStore?.store_name ? (
          <>
            <StoreDetail
              open={isOpen}
              handleOnClick={() => setOpen(true)}
              dataStore={selectedStore}
              logo={channelDetail[selectedStore.channel_id].channelLogo}
            />
            <KeyboardArrowDownIcon fontSize="small" />
          </>
        ) : (
          <>
            <span className="p-2">Pilih toko</span>
            <KeyboardArrowDownIcon fontSize="small" />
          </>
        )}
      </div>

      <Popper
        open={isOpen}
        anchorEl={btnDropdown}
        role={undefined}
        transition
        placement="bottom-start"
        className="popup-filter-channel"
        modifiers={[
          {
            name: 'flip',
            enabled: false
          }
        ]}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'right top' : 'left top'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <div>
                  <FormControl className="channel-filter p-3" style={{ maxWidth: '400px' }}>
                    <div className="search-input pb-2">
                      <TextField
                        type="text"
                        value={searchStore}
                        className="no-notch"
                        placeholder="Cari toko"
                        onChange={(e) => setSearchStore(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchSVG />
                            </InputAdornment>
                          )
                        }}
                      />
                    </div>
                    <div className="mt-2"></div>

                    {FilSearchStore(stores).map((store, index) => (
                      <StoreDetail
                        key={index}
                        handleOnClick={() => handleClickStore(store)}
                        logo={channelDetail[store.channel_id].channelLogo}
                        dataStore={store}
                      />
                    ))}
                  </FormControl>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

export default FilterBar;
