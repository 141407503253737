import React from 'react';
import {
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  LinearProgress,
  Link,
  IconButton,
  Select,
  Button,
  Popper,
  Grow,
  Pagination,
  Paper,
  ClickAwayListener,
  Tooltip,
  MenuList,
  MenuItem,
  Badge,
  PaginationItem,
  Switch,
  Popover
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LockOutlined, CheckBoxOutlineBlank, CheckBox as CheckBoxIcon, KeyboardArrowDown } from '@mui/icons-material';

import { format } from 'date-fns';
import { id, enUS } from 'date-fns/locale';
import { getI18n, useTranslation } from 'react-i18next';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { debounce, isArray } from 'lodash';

import { QueryParameters, DataFetcher } from '../../lib/types';
import { DataGridProps, Column, RowButton } from './DataGrid.types';

import { DeleteSVG } from '../../assets/img/delete';
import { PaginationLastSVG } from '../../assets/img/pagination-last';
import { PaginationFirstSVG } from '../../assets/img/pagination-first';
import { PaginationNextSVG } from '../../assets/img/pagination-next';
import { PaginationPreviousSVG } from '../../assets/img/pagination-previous';
import { EmptyFolderSVG, EmptySearchSVG, OptionSVG } from '../../assets/img';

import { Confirmation } from '../Modal/Confirmation';
import { GridToolbar } from './Toolbar';
import PopoverSeeMore from '../PopoverSeeMore/PopoverSeeMore';

const useStyles = makeStyles(() => ({
  busyRoot: {
    width: '100%',
    marginBottom: '-10px',
    height: '100%',
    background: 'rgba(250, 250, 250, 0.3)',
    position: 'absolute',
    zIndex: 998
  },
  stickyHeadCol: {
    zIndex: 9,
    position: 'sticky',
    left: '0',
    background: '#fff'
  },
  stickyCol: {
    position: 'sticky',
    left: '0',
    zIndex: 99,
    background: '#fff'
  },
  smallIcon: {
    width: '20px'
  },
  popover: {
    '& .MuiPopover-paper': {
      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
      borderRadius: 4
    }
  },
  buttonPopover: {
    padding: '8px 14px',
    borderRadius: 4,

    '&:hover': {
      backgroundColor: '#F5FAFF',
      cursor: 'pointer'
    }
  }
}));

const defaultQuery: QueryParameters = {
  page: 1,
  pageSize: 25,
  q: '',
  sortDirection: 'NONE'
};

export function LiteGrid<T = any>(props: DataGridProps<T>): React.ReactElement<DataGridProps<T>> {
  const {
    columns,
    data,
    showSearch,
    showRowNumber,
    showRowSelect,
    onDelete,
    havePagination,
    allowDelete,
    rowTotal,
    primaryButtons,
    secondaryButtons,
    customSelect,
    rowButton,
    titleRowBtn,
    onSelectedChanged,
    height,
    busy,
    onRowAction,
    isRowLocked,
    isDisabled,
    haveQuery,
    emptyState,
    filter,
    noBorder,
    noSelectRowPerPage,
    extQuery,
    gridName,
    initialSort,
    getQuery,
    getSetQuery,
    getRefresher,
    onSelectButton,
    showToolbar,
    scrollTopOnRrefresh = true,
    getPageData,
    isEmpty
  } = props;
  const mergedQuery = { ...defaultQuery, ...extQuery };
  const [dataSource, setDataSource] = React.useState<any>({ data: [], count: 0 });
  const [query, setQuery] = React.useState<any>(mergedQuery);
  const [loading, setLoading] = React.useState(false);
  const [btnGroupAnchorEl, setBtnGroupAnchorEl] = React.useState([]);
  const [pagination, setPagination] = React.useState(true);
  const [singleDelete, setSingleDelete] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState({ anchor: null, data: null });
  const [openAction, setOpenAction] = React.useState({ anchor: null, data: null });
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(false);
  const classes = useStyles();
  // const utils = useUtils();
  const [lockedRows, setLockedRows] = React.useState([]);
  // const lockedRows = React.useRef([]);
  // const currentLang = getI18n().language;
  const lang = id;

  const { t } = useTranslation('components');
  async function fetchData() {
    setLoading(true);
    setSelectedRows([]);
    if (getRefresher) {
      getRefresher(fetchData);
    }
    try {
      const fetcher: DataFetcher<T> = data as DataFetcher<T>;
      const res = await fetcher(query);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      getPageData && getPageData(res);
      setDataSource({ data: res.data, count: Number(res.totalCount) });
    } finally {
      setLoading(false);
    }
  }

  function getLockedRows() {
    var rows: any = [];
    dataSource.data.forEach((item: T, idx: number) => {
      const locked: boolean | undefined = isRowLocked && isRowLocked(item);
      if (locked) {
        rows.push(idx);
      }
    });

    setLockedRows(rows);
  }

  const handleArrayData = () => {
    const dataArray: Array<T> = data as Array<T>;
    const renderData = [];
    if (query.sortDirection === 'DESC' || query.sort_direction == 'DESC') {
      dataArray.sort((a, b) => {
        const x =
          a[query.sortBy || query.sort_by] !== null ? a[query.sortBy || query.sort_by]?.toString().toLowerCase() : null;
        const y =
          b[query.sortBy || query.sort_by] !== null ? b[query.sortBy || query.sort_by]?.toString().toLowerCase() : null;
        if (x > y) {
          return -1;
        }
        if (x < y) {
          return 1;
        }
        return 0;
      });
    } else if (query.sortDirection === 'ASC' || query.sort_direction == 'ASC') {
      dataArray.sort((a, b) => {
        const x =
          a[query.sortBy || query.sort_by] !== null ? a[query.sortBy || query.sort_by]?.toString().toLowerCase() : null;
        const y =
          b[query.sortBy || query.sort_by] !== null ? b[query.sortBy || query.sort_by]?.toString().toLowerCase() : null;
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
    }
    if (typeof havePagination !== 'undefined' && !havePagination) {
      for (let i = 0; i < dataArray.length; i += 1) {
        renderData.push(dataArray[i]);
      }
    } else {
      for (
        let i = (query.page - 1) * (query.pageSize || query.page_size);
        i < dataArray.length && i < query.page * (query.pageSize || query.page_size);
        i += 1
      ) {
        renderData.push(dataArray[i]);
      }
    }

    if (selectedRows.length !== 0) {
      setSelectedRows([]);
    }
    setDataSource({ data: renderData, count: dataArray.length });
  };

  const handleDataAndQuery = () => {
    if (scrollTopOnRrefresh) window.scrollTo(0, 0);
    if (Array.isArray(data)) {
      handleArrayData();
    } else {
      fetchData();
    }
    if (typeof havePagination !== 'undefined') {
      setPagination(havePagination);
    }
  };

  React.useEffect(() => {
    if (gridName || initialSort) {
      const onStorage: QueryParameters = JSON.parse(localStorage.getItem(gridName));
      const newQuery: QueryParameters = query;
      if (!onStorage && initialSort) {
        const initialSortArray = initialSort.split(' ');
        newQuery.sortBy = initialSortArray[0];
        newQuery.sortDirection = initialSortArray[1] as 'NONE' | 'ASC' | 'DESC';
        if (gridName) {
          localStorage.setItem(
            gridName,
            JSON.stringify({
              ...newQuery,
              page: 1
            })
          );
        }
      } else if (onStorage && (onStorage.sortBy || onStorage.sort_by)) {
        newQuery.sortBy = onStorage.sortBy || onStorage.sort_by;
        newQuery.sortDirection = onStorage.sortDirection || onStorage.sort_direction;
      }
      if (onStorage && (onStorage.pageSize || onStorage.page_size) !== query.pageSize) {
        newQuery.pageSize = onStorage.pageSize || onStorage.page_size;
      }

      if (
        newQuery[newQuery.sortBy ? 'sortBy' : 'sort_by'] !== query[query.sortBy ? 'sortBy' : 'sort_by'] ||
        newQuery[newQuery.pageSize ? 'pageSize' : 'page_size'] !== query[query.pageSize ? 'pageSize' : 'page_size'] ||
        newQuery[newQuery.sortDirection ? 'sortDirection' : 'sort_direction'] !==
          query[query.sortDirection ? 'sortDirection' : 'sort_direction']
      ) {
        setQuery((prevQuery) => ({
          ...prevQuery,
          ...newQuery
        }));
      } else {
        handleDataAndQuery();
      }
    } else {
      handleDataAndQuery();
    }
    if (getQuery) {
      getQuery(query);
    }
    if (getSetQuery) {
      getSetQuery(setQuery);
    }
  }, [data, query]);

  React.useEffect(getLockedRows, [dataSource]);

  React.useEffect(() => {
    if (typeof busy !== 'undefined') setLoading(busy);
  }, [busy]);

  React.useEffect(() => {
    if (!showRowSelect && !rowButton) {
      setSelectedRows([]);
    }
  }, [showRowSelect, rowButton]);

  const onPageSizeChanged = (e) => {
    const { value } = e.target;
    setQuery((prevState) => {
      if (gridName) {
        localStorage.setItem(
          gridName,
          JSON.stringify({
            ...prevState,
            page: 1,
            [`${prevState.pageSize && typeof prevState.pageSize !== 'undefined' ? 'pageSize' : 'page_size'}`]: value
          })
        );
      }
      return {
        ...prevState,
        page: 1,
        [`${prevState.pageSize && typeof prevState.pageSize !== 'undefined' ? 'pageSize' : 'page_size'}`]: value
      };
    });
  };

  function movePage(e, value: number) {
    const page = value;
    setQuery((prevState) => ({
      ...prevState,
      page
    }));
  }

  function callRefresh() {
    fetchData();
  }

  async function handleSwitchChange(e, binding, rowIdx) {
    setLoading(true);
    const newDataSource = [];
    for (let i = 0; i < JSON.parse(JSON.stringify(dataSource)).data.length; i += 1) {
      if (rowIdx === i) {
        dataSource.data[i][binding] = e.target.checked;
      }
      newDataSource.push(dataSource.data[i]);
    }
    setDataSource({
      ...dataSource,
      data: newDataSource
    });
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }

  async function onDeleteConfirm(confirmed: boolean) {
    setDeleteConfirmation(false);
    if (confirmed) {
      if (singleDelete.length) {
        if (onDelete) {
          const wait = await onDelete(singleDelete);
          if (!wait) return false;
        }
        const newDataSource = [];
        for (let i = 0; i < dataSource.data.length; i += 1) {
          if (JSON.stringify(singleDelete[0]) !== JSON.stringify(dataSource.data[i])) {
            newDataSource.push(dataSource.data[i]);
          }
        }
        setSingleDelete([]);
        setDataSource({
          ...dataSource,
          data: newDataSource
        });
      } else {
        if (onDelete) {
          const wait = await onDelete(selectedRows);
          if (!wait) return false;
        }
        const newDataSource = [];
        for (let i = 0; i < dataSource.data.length; i += 1) {
          let find = false;
          for (let x = 0; x < selectedRows.length; x += 1) {
            if (selectedRows[x] === dataSource.data[i]) {
              find = true;
              break;
            }
          }
          if (!find) {
            newDataSource.push(dataSource.data[i]);
          }
        }
        setDataSource({
          ...dataSource,
          data: newDataSource
        });
        setSelectedRows([]);
      }
    } else {
      setSingleDelete([]);
    }
  }

  function handleToolbarSearch(q: string) {
    setQuery((prevQuery) => ({
      ...prevQuery,
      page: 1,
      q
    }));
  }

  const debounceSearch = debounce(handleToolbarSearch, 500);

  const handleToggle = (index) => (event) => {
    setBtnGroupAnchorEl(() => {
      const newAnchor = [];
      for (let i = 0; i <= index; i += 1) {
        if (i === index) {
          newAnchor.push(event.currentTarget);
        } else {
          newAnchor.push(null);
        }
      }
      return newAnchor;
    });
  };

  function formatItem(column: Column, dataItem, rowIdx): string | React.ReactNode {
    if (column.format === 'text-truncate') {
      return (
        <div style={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
          <p
            className="mb-0"
            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'start' }}
          >
            {dataItem}
          </p>
        </div>
      );
    }
    if (dataItem instanceof Date && column.format === 'datetime') {
      // return utils.format(dataItem, 'fullDateTime24h');
      return format(dataItem, 'dd MMM yyyy HH:mm:ss', { locale: lang as any });
    }

    if (dataItem instanceof Date && column.format === 'newdatetime') {
      return (
        <div className="d-flex flex-column">
          <span>{format(dataItem, 'dd MMM yyyy', { locale: lang as any })}</span>
          <span>{format(dataItem, 'HH:mm', { locale: lang as any })}</span>
        </div>
      );
    }

    if (dataItem instanceof Date || column.format === 'date') {
      try {
        const date = new Date(dataItem);
        return format(dataItem, 'dd MMM yyyy', { locale: lang as any });
      } catch {
        return dataItem;
      }
    }

    if (column.format === 'currency') {
      if (!Number.isNaN(dataItem) && dataItem !== null) {
        const num = parseFloat(dataItem);
        return `Rp ${num.toLocaleString('id-ID', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
      }
      return dataItem;
    }
    if (typeof dataItem === 'number' || column.format === 'number') {
      const num = parseInt(dataItem as unknown as string, 10);
      if (!Number.isNaN(num)) {
        return num.toLocaleString('id-ID', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
      }
    }
    if (column.format == 'switch') {
      return (
        <Switch
          checked={dataItem}
          className="switch-small"
          onChange={(e) => handleSwitchChange(e, column.binding, rowIdx)}
        />
      );
    }

    if (isArray(dataItem) || column.format === 'array') {
      let tempData = [];
      if (typeof dataItem === 'string') {
        tempData = Array.from(new Set(dataItem.split(',')));
      } else {
        tempData = Array.from(new Set(dataItem));
      }
      return (
        <div>
          <PopoverSeeMore
            label={`Terdapat ${tempData.length} ${column.header}`}
            dataArray={tempData.map((temp) => {
              return {
                label: temp
              };
            })}
          />
        </div>
      );
    }

    return dataItem;
  }

  function handleClick(e, dataItem: T, binding, rowKey) {
    e.preventDefault();
    onRowAction(dataItem, binding, rowKey);
  }

  const handleGroupClose = () => {
    handleToggle(-1)(null);
  };

  const handleMenuItemClick = (childItemAction) => {
    childItemAction();
    handleToggle(-1)(null);
  };

  function handleRowSelect(e, item) {
    if (e.target.checked) {
      item.can_bundle = item.is_bundle ? false : true;
      const newSelectedRows = [];
      selectedRows.forEach((row, key) => {
        newSelectedRows.push(row);
      });
      newSelectedRows.push(item);
      setSelectedRows(newSelectedRows);
      if (onSelectedChanged) {
        onSelectedChanged(newSelectedRows);
      }
    } else {
      const newSelectedRows = [];
      for (let i = 0; i < selectedRows.length; i += 1) {
        if (selectedRows[i] !== item) {
          newSelectedRows.push(selectedRows[i]);
        }
      }
      setSelectedRows(newSelectedRows);
      if (onSelectedChanged) {
        onSelectedChanged(newSelectedRows);
      }
    }
  }

  function handleSelectAll(e) {
    if (selectedRows.length < dataSource.data.length - lockedRows.length) {
      const newDataSource = [];
      for (let i = 0; i < dataSource.data.length; i += 1) {
        if (!isRowLocked || !isRowLocked(dataSource.data[i])) {
          newDataSource.push(dataSource.data[i]);
        }
      }
      setSelectedRows(newDataSource);
      if (onSelectedChanged) {
        onSelectedChanged(newDataSource);
      }
    } else {
      setSelectedRows([]);
      if (onSelectedChanged) {
        onSelectedChanged([]);
      }
    }
  }

  const handleSingleDelete = (item, index?) => () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let item_with_idx = { ...item };
    // if (index) item_with_idx['index'] = index;
    setAnchorEl((prev) => ({
      ...prev,
      anchor: null
    }));
    setSingleDelete([item_with_idx]);
  };

  const changeSort = (binding: string) => () => {
    setQuery((prevQuery) => {
      if (prevQuery.sortDirection === 'ASC' || prevQuery.sort_direction === 'ASC') {
        if (gridName) {
          localStorage.setItem(
            gridName,
            JSON.stringify({
              ...prevQuery,
              [`${prevQuery.sortBy && typeof prevQuery.sortBy !== 'undefined' ? 'sortBy' : 'sort_by'}`]: binding,
              [`${
                prevQuery.sortDirection && typeof prevQuery.sortDirection !== 'undefined'
                  ? 'sortDirection'
                  : 'sort_direction'
              }`]: 'DESC'
            })
          );
        }
        return {
          ...prevQuery,
          [`${prevQuery.sortBy && typeof prevQuery.sortBy !== 'undefined' ? 'sortBy' : 'sort_by'}`]: binding,
          [`${
            prevQuery.sortDirection && typeof prevQuery.sortDirection !== 'undefined'
              ? 'sortDirection'
              : 'sort_direction'
          }`]: 'DESC'
        };
      }

      if (gridName) {
        localStorage.setItem(
          gridName,
          JSON.stringify({
            ...prevQuery,
            [`${prevQuery.sortBy && typeof prevQuery.sortBy !== 'undefined' ? 'sortBy' : 'sort_by'}`]: binding,
            [`${
              prevQuery.sortDirection && typeof prevQuery.sortDirection !== 'undefined'
                ? 'sortDirection'
                : 'sort_direction'
            }`]: 'ASC'
          })
        );
      }
      return {
        ...prevQuery,
        [`${prevQuery.sortBy ? 'sortBy' : 'sort_by'}`]: binding,
        [`${prevQuery.sortDirection && typeof prevQuery.sortDirection ? 'sortDirection' : 'sort_direction'}`]: 'ASC'
      };
    });
  };

  function EnhancedTableHead() {
    let sortDirection: 'asc' | 'desc';
    if (query.sortDirection === 'DESC' || query.sort_direction === 'DESC') {
      sortDirection = 'desc';
    } else {
      sortDirection = 'asc';
    }
    return (
      <TableHead className="border-bottom__table">
        <TableRow>
          {(showRowSelect || allowDelete) && (
            <TableCell className="z-below left-0 pr-0 position-sticky bg-white border-0" style={{ width: 0 }}>
              <Checkbox
                checked={selectedRows.length > 0 && selectedRows.length === dataSource.data.length - lockedRows.length}
                onClick={handleSelectAll}
                color="primary"
              />
            </TableCell>
          )}
          {/* TODOS */}
          {columns.map((headCell: Column, i) => {
            let stickyWidth: string | number = '0px';
            if (i == 0 && (showRowSelect || allowDelete || showRowNumber) && headCell.freezeCol) {
              stickyWidth = '55px';
            }

            // for now, its only handle <= 2 freeze column
            if (i != 0 && headCell.freezeCol) {
              if (columns[i - 1].freezeCol) {
                stickyWidth = columns[i - 1].width;
                if (showRowSelect || allowDelete || showRowNumber) {
                  let lastWidth = parseInt(columns[i - 1].width.toString());
                  const padVerWidth = 40;
                  lastWidth += 55 + padVerWidth;
                  stickyWidth = lastWidth;
                }
              }
            }
            return (
              <TableCell
                className={`${headCell.freezeCol && classes.stickyHeadCol} font-size-table-head ${
                  headCell.headerClassName
                } ${typeof headCell.width !== 'undefined' ? 'text-wrap' : 'text-nowrap'} border-0`}
                style={{ width: typeof headCell.width !== 'undefined' ? headCell.width : 'auto', left: stickyWidth }}
                key={`head_${headCell.binding}`}
                align={headCell.format === 'number' || headCell.format === 'currency' ? 'right' : 'left'}
              >
                {headCell.allowSorting === false ? (
                  <div>
                    {headCell.headerIcon && <img src={headCell.headerIcon} width={18} className={'mr-1'} />}
                    {headCell.header}
                  </div>
                ) : (
                  <TableSortLabel
                    className="show-on-hover"
                    active={
                      (query.sortDirection !== 'NONE' || query.sort_direction !== 'NONE') &&
                      headCell.binding === query.sortBy
                    }
                    direction={sortDirection}
                    onClick={changeSort(headCell.binding)}
                  >
                    {headCell.headerIcon && <img src={headCell.headerIcon} width={18} className={'mr-1'} />}
                    {headCell.header}
                  </TableSortLabel>
                )}
              </TableCell>
            );
          })}
          {rowButton && rowButton.length > 0 && (
            <TableCell className={'text-nowrap text-center border-0'}>
              <span>{titleRowBtn ? titleRowBtn : null}</span>
            </TableCell>
          )}
          {allowDelete && (
            <TableCell className={'font-size-md text-nowrap text-center font-weight-lightbold border-0'} />
          )}
        </TableRow>
      </TableHead>
    );
  }

  const handleClickOption = (item?: any) => (e?: React.MouseEvent) => {
    if ((e && e.currentTarget) || (e === null && anchorEl.anchor)) {
      setAnchorEl({ anchor: e ? e.currentTarget : null, data: item || null });
    }
  };

  const renderOptPopper = () => {
    return (
      <>
        <Popover
          id={'menu'}
          open={Boolean(anchorEl.anchor)}
          anchorEl={anchorEl.anchor}
          onClose={() => setAnchorEl({ anchor: null, data: null })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          className={classes.popover}
        >
          <div style={{ minWidth: 200 }} className="p-2">
            {rowButton &&
              rowButton.some((some) => some.format === 'dropdown') &&
              rowButton
                .find((c) => c.format === 'dropdown')
                .child.filter((el) => !el.onlyOnSelect)
                .map((button, index) => {
                  return (
                    <div
                      className={`${classes.buttonPopover} ${button.className}`}
                      onClick={() => {
                        button.action([anchorEl.data]);
                        setAnchorEl({ anchor: null, data: null });
                      }}
                      key={index}
                    >
                      {button.icon && <span className="mr-3">{button.icon}</span>}
                      {typeof button.text === 'function' ? button.text(anchorEl.data) : button.text}
                    </div>
                  );
                })}
            {rowButton &&
              !rowButton.some((some) => some.format === 'dropdown') &&
              rowButton.map((button, index) => {
                if (!button.onlyOnSelect) {
                  if (button.child) {
                    return (
                      <div
                        key={index}
                        className={classes.buttonPopover}
                        onClick={(event) =>
                          setOpenAction({
                            anchor: event.currentTarget,
                            data: button.child
                          })
                        }
                      >
                        <div className={`d-flex align-items-center justify-content-between ${button.className}`}>
                          <div>
                            {button.icon && <span className="mr-3">{button.icon}</span>}
                            {typeof button.text === 'function' ? button.text(anchorEl.data) : button.text}
                          </div>
                          <ChevronRightIcon />
                        </div>
                      </div>
                    );
                  } else {
                    let disabled = false;
                    if (anchorEl.data) {
                      disabled = button.disabled ? button.disabled(anchorEl.data) : false;
                    }
                    return (
                      <div
                        className={`${classes.buttonPopover} ${button.className}`}
                        style={disabled ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
                        onClick={() => {
                          button.action([anchorEl.data]);
                          setAnchorEl({ anchor: null, data: null });
                        }}
                        key={index}
                      >
                        {button.icon && <span className="mr-3">{button.icon}</span>}
                        {typeof button.text === 'function' ? button.text(anchorEl.data) : button.text}
                      </div>
                    );
                  }
                }
              })}
          </div>
        </Popover>

        <Popover
          id={'cetak'}
          open={Boolean(openAction.anchor)}
          anchorEl={openAction.anchor}
          onClose={() =>
            setOpenAction({
              anchor: null,
              data: null
            })
          }
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          className={classes.popover}
        >
          <div style={{ minWidth: 200 }} className="p-2">
            {openAction.data &&
              openAction.data.map((button, index) => {
                return (
                  <div className={classes.buttonPopover} onClick={() => button.action([anchorEl.data])} key={index}>
                    {typeof button.text === 'function' ? button.text(anchorEl.data) : button.text}
                  </div>
                );
              })}
          </div>
        </Popover>
      </>
    );
  };

  const renderLoading = () => {
    return (
      <>
        <TableRow>
          {showRowSelect || allowDelete ? (
            <TableCell className="pt-2" key={'shimmer'}>
              <div className="shine shine-checkbox"></div>
            </TableCell>
          ) : null}
          {columns.map((_item: undefined, key: number) =>
            !key ? (
              <TableCell className="pt-2" key={`shimmer-${key}`}>
                <div className="d-flex w-100 align-items-center">
                  <div className="mr-2">
                    <div className="shine shine-image"></div>
                  </div>
                  <div className="w-100">
                    <div className="shine shine-line"></div>
                    <div className="shine shine-line"></div>
                  </div>
                </div>
              </TableCell>
            ) : (
              <TableCell className="pt-2" key={`shimmer-${key}`}>
                <div className="shine shine-line"></div>
                <div className="shine shine-line"></div>
              </TableCell>
            )
          )}
          {rowButton && (
            <TableCell className="pt-2 text-center" key={'shimmer'}>
              <div className="shine shine-checkbox"></div>
            </TableCell>
          )}
          {allowDelete && (
            <TableCell className="pt-2 text-center" key={'shimmer'}>
              <div className="shine shine-checkbox"></div>
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          {showRowSelect || allowDelete ? (
            <TableCell className="pt-2" key={'shimmer'}>
              <div className="shine shine-checkbox"></div>
            </TableCell>
          ) : null}
          {columns.map((_item: undefined, key: number) =>
            !key ? (
              <TableCell className="pt-2" key={`shimmer-${key}`}>
                <div className="d-flex w-100 align-items-center">
                  <div className="mr-2">
                    <div className="shine shine-image"></div>
                  </div>
                  <div className="w-100">
                    <div className="shine shine-line"></div>
                    <div className="shine shine-line"></div>
                  </div>
                </div>
              </TableCell>
            ) : (
              <TableCell className="pt-2" key={`shimmer-${key}`}>
                <div className="shine shine-line"></div>
                <div className="shine shine-line"></div>
              </TableCell>
            )
          )}
          {rowButton && (
            <TableCell className="pt-2 text-center" key={'shimmer'}>
              <div className="shine shine-checkbox"></div>
            </TableCell>
          )}
          {allowDelete && (
            <TableCell className="pt-2 text-center" key={'shimmer'}>
              <div className="shine shine-checkbox"></div>
            </TableCell>
          )}
        </TableRow>
      </>
    );
  };

  function renderPager() {
    let pageSize = Number(query.pageSize) || Number(query.page_size) || '10';
    const start = dataSource.count > 0 ? (query.page - 1) * query.pageSize + 1 : 0;
    const end = query.page * query.pageSize;

    return (
      <div className="border-light-gray d-flex align-items-center justify-content-between">
        {noSelectRowPerPage ? (
          <div></div>
        ) : (
          <div
            style={{
              fontSize: '14px',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            Baris per halaman &nbsp;
            <Select
              native
              value={pageSize}
              variant="outlined"
              className="no-hover ml-2 bg-secondary no-notch"
              style={{
                justifyContent: 'space-between',
                gap: '19px'
              }}
              onChange={onPageSizeChanged}
              inputProps={{ className: 'font-size-sm p-4' }}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
            </Select>
          </div>
        )}
        {Number(dataSource.count) > Number(pageSize) && (
          <Pagination
            className="d-inline-flex"
            count={Math.ceil(dataSource.count / Number(pageSize))}
            onChange={movePage}
            page={query.page}
            showFirstButton
            showLastButton
            renderItem={(item) => (
              <PaginationItem
                sx={{
                  gap: '10px'
                }}
                components={{
                  last: () => <PaginationLastSVG width={11} height={12} />,
                  first: () => <PaginationFirstSVG width={11} height={12} />,
                  next: () => <PaginationNextSVG width={11} height={12} />,
                  previous: () => <PaginationPreviousSVG width={11} height={12} />
                }}
                {...item}
              />
            )}
          />
        )}
      </div>
    );
  }

  function renderConfirmation() {
    return (
      <Confirmation
        open={deleteConfirmation || Boolean(singleDelete.length)}
        text="Apa kamu yakin akan menghapus data ini?"
        onAction={onDeleteConfirm}
        maxWidth={'xs'}
        subText="Data yang telah dihapus tidak bisa dikembalikan"
      />
    );
  }

  const renderRowButton = React.useMemo(() => {
    return ({ btn, item, locked, idx }: { btn: RowButton<any>; item?: any; locked?: boolean; idx: number }) => {
      let content: React.ReactElement;

      const multi = (btn.onlyOnSelect && !btn.onlyOnRow) ?? true;
      const single = (!btn.onlyOnSelect && btn.onlyOnRow) ?? true;
      const isShown = btn.show ? btn.show(item) : true;

      if (btn.format === 'button' && isShown && !locked && single) {
        content = (
          <Button
            key={idx}
            variant={`${btn.variant ? btn.variant : 'contained'}`}
            // fullWidth
            onClick={() => btn.action([item])}
            sx={btn.sxButton}
            disabled={(btn.disabled ? btn.disabled(item) : false) || locked}
            startIcon={btn.startIcon ? btn.startIcon : null}
            endIcon={btn.endIcon ? btn.endIcon : null}
            className="ml-2"
          >
            {typeof btn.text === 'function' ? btn.text(item) : btn.text}
          </Button>
        );
      } else if (btn.format === 'dropdown' && isShown && !locked && single) {
        content = (
          <Button
            key={idx}
            variant={`${btn.variant ? btn.variant : 'contained'}`}
            // fullWidth
            onClick={handleClickOption(item)}
            sx={btn.sxButton}
            disabled={(btn.disabled ? btn.disabled(item) : false) || locked}
            startIcon={btn.startIcon ? btn.startIcon : null}
            endIcon={<KeyboardArrowDown />}
            className="ml-2"
          >
            {typeof btn.text === 'function' ? btn.text(item) : btn.text}
          </Button>
        );
      } else if (btn.format === 'iconButton' && isShown && !locked && single) {
        content = (
          <Tooltip key={idx} placement="top" title={typeof btn.text === 'function' ? btn.text(item) : btn.text}>
            <IconButton
              onClick={() => {
                btn.action(item);
              }}
              disabled={(btn.disabled ? btn.disabled(item) : false) || locked}
              className="ml-2"
            >
              {btn.icon}
            </IconButton>
          </Tooltip>
        );
      }

      if (selectedRows.length === 0) {
        return content;
      }
      return null;
    };
  }, [selectedRows]);

  function renderCell(column: Column, item, rowIdx, checked?: boolean): React.ReactNode {
    if (column.as === 'link') {
      if (column.linkText) {
        return (
          <Link
            className="text-link font-weight-bold"
            href={`#${rowIdx}`}
            onClick={(e) => handleClick(e, item, column.binding, rowIdx)}
          >
            {column.linkText}
          </Link>
        );
      }
      return (
        <Link
          className="text-link font-weight-bold"
          href={`#${rowIdx}`}
          onClick={(e) => handleClick(e, item, column.binding, rowIdx)}
        >
          {column.template
            ? column.template({ item: { [`${column.binding}`]: item[column.binding] }, row_id: rowIdx }, item, checked)
            : formatItem(column, item[column.binding], rowIdx)}
        </Link>
      );
    }
    if (column.template) {
      return column.template({ item: { [`${column.binding}`]: item[column.binding] }, row_id: rowIdx }, item, checked);
    }
    return formatItem(column, item[column.binding], rowIdx);
  }

  const isActiveBundle = selectedRows.find((val) => val?.can_bundle === false);

  // let minHeightTable = dataSource.count > 2 || dataSource.count === 0 ? height || 'calc(100vh - 435px)' : 'auto'
  let minHeightTable = height || 'calc(100vh - 435px)';

  return (
    <>
      {showToolbar || showSearch || primaryButtons || customSelect ? (
        <GridToolbar
          selectedRows={selectedRows}
          onDelete={() => {
            setDeleteConfirmation(true);
          }}
          primaryButtons={primaryButtons}
          secondaryButtons={secondaryButtons}
          customSelect={customSelect}
          showSearch={showSearch}
          allowDelete={allowDelete}
          isRemote={!Array.isArray(data)}
          callRefresh={callRefresh}
          query={query}
          type="litegrid"
          filter={filter}
          onToolbarSearch={debounceSearch}
        />
      ) : null}
      <div className="position-relative">
        {dataSource.count && loading ? (
          <div className={classes.busyRoot}>
            <LinearProgress style={{ height: '2px' }} />
          </div>
        ) : null}
        {!dataSource.count && query.q === '' && !loading && typeof emptyState !== 'undefined' && (
          <div className="align-items-center bg-white d-flex flex-column h-100 justify-content-center position-absolute w-100 z-over">
            {emptyState.icon}
            <span className="font-size-xl font-weight-bold">{emptyState.title}</span>
            <span className="font-size-md text-black-50 mt-3">{emptyState.subtitle}</span>
            <span className="mt-3">
              {emptyState.buttons &&
                emptyState.buttons.map((item, key) => (
                  <Button
                    variant={item.color === 'secondary' ? 'outlined' : 'contained'}
                    key={`empty_button${key}`}
                    className={`ml-3 shadow-none ${item.color === 'secondary' ? 'btn-outline-dark' : 'text-white'}`}
                    color={item.color}
                    disabled={item.disabled}
                    title={(item.title && item.title) || (item.child && item.child[0] && item.child[0].title)}
                    onClick={(e) => {
                      if (item.label && !item.action) {
                        return handleToggle(key)(e);
                      }
                      if (item.action) {
                        return item.action(query, e);
                      }
                      item.child[0].action(query, e);
                    }}
                  >
                    {item.label || item.child[0].label}
                  </Button>
                ))}
            </span>
          </div>
        )}
        {!dataSource.count && query.q === '' && !loading && typeof emptyState === 'undefined' && (
          <div className="align-items-center bg-white d-flex flex-column h-100 justify-content-center position-absolute w-100 z-over">
            <EmptyFolderSVG />
            {!isEmpty ? (
              <span className="font-size-xl font-weight-bold mt-3">Tidak ada data</span>
            ) : (
              <span className="font-size-xl font-weight-bold mt-3 text-center">{isEmpty}</span>
            )}
          </div>
        )}
        {!dataSource.count && (query.q !== '' || haveQuery) && !loading && (
          <div className="align-items-center bg-white d-flex flex-column h-100 justify-content-center position-absolute w-100 z-over">
            <EmptySearchSVG />
            <span className="font-size-xl font-weight-bold mt-3">Oops, item tidak ditemukan</span>
            <span className="font-size-md text-black-50">Coba kurangi filter pencarian kamu.</span>
          </div>
        )}

        <TableContainer
          style={{
            minHeight: minHeightTable,
            border: '1px solid #e9e9e9',
            borderRadius: 6,
            overflow: 'auto',
            overflowY: 'hidden'
          }}
          // style={{ border: '1px solid #D4D5D6', borderRadius: 6, overflow: 'auto', overflowY: 'hidden' }}
          className="mb-3"
          id="table-container-grid"
          onScroll={() => {
            handleClickOption(null)(null);
          }}
        >
          <Table size="small" id="table-content">
            <TableHead
              className={`font-weight-lightbold absolute-top w-auto transition-base ${
                selectedRows.length ? 'fade-exit ' : ' fade-enter pointer-event-none'
              } `}
              style={{ borderRadius: 6, overflow: 'auto hidden' }}
            >
              <TableRow className="w-100" style={{ border: '1px solid #e9e9e9' }}>
                <TableCell className="bg-default pr-0 border-0" style={{ width: 0 }}>
                  <Checkbox
                    checked={selectedRows.length > 0}
                    indeterminate={selectedRows.length < dataSource.data.length - lockedRows.length}
                    onClick={handleSelectAll}
                    color="primary"
                  />
                </TableCell>
                <TableCell colSpan={columns.length + 28} className="bg-default w-100 border-0">
                  <div
                    className="font-weight-lightbold position-relative d-inline-block mr-3 pr-3"
                    style={{ alignItems: 'center' }}
                  >
                    {selectedRows.length} Item terpilih
                    {allowDelete && (
                      <span className="ml-2">
                        <Tooltip arrow title="Hapus" placement="top">
                          <IconButton
                            className="text-danger"
                            onClick={() => {
                              setDeleteConfirmation(true);
                            }}
                          >
                            <DeleteSVG height={18} className={`${classes.smallIcon}`} />
                          </IconButton>
                        </Tooltip>
                      </span>
                    )}
                    <span className="ml-2">
                      {rowButton &&
                        rowButton.map((item) =>
                          !item.onlyOnRow && (item.show ? item.show(anchorEl.data ?? selectedRows) : true) ? (
                            <>
                              {item.child ? (
                                item.child.map((child) => (
                                  <Tooltip
                                    arrow
                                    title={typeof child.text === 'function' ? child.text(anchorEl.data) : child.text}
                                    key={typeof child.text === 'function' ? child.text(anchorEl.data) : child.text}
                                    placement="top"
                                  >
                                    <IconButton
                                      className="font-size-table mr-2"
                                      onClick={() => {
                                        child.action(selectedRows);
                                      }}
                                      sx={{
                                        borderRadius: child.iconHeaderWithText ? '10px' : '50%',
                                        border: child.iconHeaderWithText ? '1px solid #D4D5D6' : 'none',
                                        height: child.iconHeaderWithText ? '28px' : 'auto'
                                      }}
                                    >
                                      {child.icon ? (
                                        <span className="text-black">
                                          {child.icon}
                                          {child.iconHeaderWithText && (
                                            <span className="ml-2 font-size-table text-second">{child.text}</span>
                                          )}
                                        </span>
                                      ) : (
                                        <span className="d-flex align-items-center justify-content-center">
                                          {child.iconHeaderWithText ? (
                                            <span className="ml-2  font-size-table text-second">
                                              {child.labelOnSelect ? child.labelOnSelect : child.text}
                                            </span>
                                          ) : typeof child.text === 'function' ? (
                                            child.text(anchorEl.data).split(' ')
                                          ) : (
                                            child.text.split(' ')
                                          )}
                                        </span>
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                ))
                              ) : (
                                <Tooltip
                                  arrow
                                  title={typeof item.text === 'function' ? item.text(anchorEl.data) : item.text}
                                  key={typeof item.text === 'function' ? item.text(anchorEl.data) : item.text}
                                  placement="top"
                                >
                                  <IconButton
                                    className="mr-2"
                                    onClick={() => {
                                      item.action(selectedRows);
                                    }}
                                    sx={{
                                      '& path': {
                                        fill: item.iconColor || '#163a50 !important'
                                      },
                                      borderRadius: item.iconHeaderWithText ? '10px' : '50%',
                                      border: item.iconHeaderWithText ? '1px solid #D4D5D6' : 'none',
                                      height: item.iconHeaderWithText ? '28px' : 'auto'
                                    }}
                                  >
                                    {item.icon}
                                    {item.iconHeaderWithText && (
                                      <span className="ml-2 font-size-table text-second">{item.text as string}</span>
                                    )}
                                  </IconButton>
                                </Tooltip>
                              )}
                            </>
                          ) : null
                        )}
                    </span>
                    {allowDelete || onSelectButton ? <span className="divider-right h-50 " /> : null}
                  </div>
                  {onSelectButton &&
                    onSelectButton.map((item, key) => {
                      let iconButton = (
                        <Button
                          disabled={item.disabled}
                          size="small"
                          variant="outlined"
                          className={`bg-white ${key > 0 && 'ml-3'}`}
                          key={`onselect-button-${key}`}
                          onClick={() => item.action(selectedRows)}
                        >
                          {item.text}
                        </Button>
                      );

                      if (item.badgeCount) {
                        return (
                          <Badge key={key} color="primary" badgeContent={item.badgeCount}>
                            {iconButton}
                          </Badge>
                        );
                      }
                      return iconButton;
                    })}
                </TableCell>
              </TableRow>
            </TableHead>
            <EnhancedTableHead />
            <TableBody>
              {!dataSource.count && loading && <>{renderLoading()}</>}
              {dataSource.data.map((item: any, key: number, index) => {
                // const locked: boolean = lockedRows.indexOf(key) >= 0;
                const locked: boolean = isRowLocked && isRowLocked(item);
                const checked = Boolean(selectedRows.find((selected) => selected === item));
                return (
                  <TableRow
                    key={`row_${key}`}
                    className={`${isDisabled && isDisabled(item) && 'opacity-5 pointer-event-none'} ${
                      checked ? 'bg-default' : 'bg-white'
                    } ${
                      noBorder
                        ? key < dataSource.data.length && 'border-bottom-0'
                        : key < dataSource.data.length - 1 && 'border-bottom__table'
                    }`}
                  >
                    {(showRowSelect || allowDelete || showRowNumber) && (
                      <TableCell
                        className={`${checked ? 'bg-default' : 'bg-white'} pr-0 left-0 position-sticky z-over`}
                        style={{ border: 'none' }}
                      >
                        {showRowNumber && key + 1}
                        {(showRowSelect || allowDelete) && (
                          <Checkbox
                            onClick={(e) => handleRowSelect(e, item)}
                            icon={
                              locked ? <LockOutlined fontSize="medium" /> : <CheckBoxOutlineBlank fontSize="medium" />
                            }
                            checkedIcon={<CheckBoxIcon fontSize="medium" />}
                            checked={checked}
                            disabled={locked}
                            color="primary"
                          />
                        )}
                      </TableCell>
                    )}
                    {columns.map((column: Column, i) => {
                      let stickyWidth: string | number = '0px';
                      if (i == 0 && (showRowSelect || allowDelete || showRowNumber) && column.freezeCol) {
                        stickyWidth = '55px';
                      }

                      // for now, its only handle <= 2 freeze column
                      if (i != 0 && column.freezeCol) {
                        if (columns[i - 1].freezeCol) {
                          stickyWidth = columns[i - 1].width;
                          if (showRowSelect || allowDelete || showRowNumber) {
                            let lastWidth = parseInt(columns[i - 1].width.toString());
                            const padVerWidth = 40;
                            lastWidth += 55 + padVerWidth;
                            stickyWidth = lastWidth;
                          }
                        }
                      }

                      return (
                        <TableCell
                          key={`row_${key}_cell_${column.binding}`}
                          style={{
                            width: typeof column.width !== 'undefined' ? column.width : 'auto',
                            left: stickyWidth,
                            border: 'none'
                          }}
                          align={
                            typeof item[column.binding] === 'number' ||
                            (!Number.isNaN(parseInt(item[column.binding] as string, 10)) &&
                              column.format !== 'string' &&
                              column.format !== 'date')
                              ? 'right'
                              : 'left'
                          }
                          className={`border-bottom-cell ${column.freezeCol && classes.stickyCol} ${
                            column.divider && ' position-relative'
                          } ${checked ? 'bg-default' : 'bg-white'} ${column.className}`}
                        >
                          {(column.divider === 'both' || column.divider === 'left') && (
                            <span className="divider-left h-50 mt-2" />
                          )}
                          <span>{renderCell(column, item, key, selectedRows.length > 0)}</span>
                          {(column.divider === 'both' || column.divider === 'right') && (
                            <span className="divider-right h-50 mt-2" />
                          )}
                        </TableCell>
                      );
                    })}
                    <TableCell
                      className={`${checked ? 'bg-default' : 'bg-white'} pt-2 text-nowrap pb-2 text-center`}
                      style={{ border: 'none' }}
                    >
                      {rowButton && rowButton.some((button) => button.format) ? (
                        rowButton.map((button, i) => {
                          return renderRowButton({ btn: button, locked, item, idx: i });
                        })
                      ) : rowButton &&
                        rowButton.every((button) => !button.format) &&
                        rowButton.length > 1 &&
                        selectedRows.length === 0 &&
                        !locked ? (
                        <IconButton
                          onClick={handleClickOption(item)}
                          className="border-light-gray rounded-sm border-1"
                          style={{ padding: '2px', transform: 'rotate(90deg)' }}
                        >
                          <OptionSVG height={15} width={15} />
                        </IconButton>
                      ) : (
                        selectedRows.length === 0 &&
                        !locked &&
                        rowButton &&
                        rowButton.map((btn, idx) => (
                          <Tooltip
                            key={idx}
                            placement="top"
                            title={typeof btn.text === 'function' ? btn.text(item) : btn.text}
                          >
                            <IconButton
                              onClick={() => {
                                btn.action(item);
                              }}
                              disabled={(btn.disabled ? btn.disabled(item) : false) || locked}
                            >
                              {btn.icon}
                            </IconButton>
                          </Tooltip>
                        ))
                      )}
                    </TableCell>
                    {allowDelete && (
                      <TableCell
                        className={`${checked ? 'bg-default' : 'bg-white'} text-nowrap text-danger text-right`}
                        style={{ border: 'none', padding: '8px 16px 8px 0', minWidth: 0 }}
                      >
                        {allowDelete && selectedRows.length === 0 && !locked && (
                          <Tooltip placement="top" title="Hapus">
                            <IconButton onClick={handleSingleDelete(item, key)} className="p-2">
                              <DeleteSVG height={18} className="text-danger" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
              {rowTotal &&
                rowTotal.length > 0 &&
                query.page === Math.ceil(Number(dataSource.count) / Number(query.pageSize || query.page_size)) && (
                  <TableRow>
                    {rowTotal.map((colum, index) => {
                      return (
                        <TableCell key={index} className={`font-weight-lightbold ${colum.className}`}>
                          {colum.value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {emptyState &&
        emptyState.buttons &&
        emptyState.buttons.map(
          (item, key) =>
            item.child &&
            item.child.length > 1 && (
              <Popper
                open={Boolean(btnGroupAnchorEl[key])}
                anchorEl={btnGroupAnchorEl[key]}
                role={undefined}
                transition
                key={key}
                placement="bottom-end"
                style={{ zIndex: 999 }}
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom'
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleGroupClose}>
                        <MenuList id="split-button-menu">
                          {item.child.map((childItem, childKey) => (
                            <MenuItem key={childKey} onClick={() => handleMenuItemClick(childItem.action)}>
                              {childItem.label}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            )
        )}

      {pagination && renderPager()}
      {renderConfirmation()}
      {rowButton && renderOptPopper()}
    </>
  );
}
