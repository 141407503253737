export const optionsBrokerType = [
  { value: 'TOKOPEDIA', label: 'Dilayani Tokopedia', id: 0 },
  { value: 'FBL', label: 'Fullfilment by Lazada', id: -2 },
  { value: 'SHOPEE', label: 'Fullfilment by Shopee', id: -3 }
];

export const contentBroker = {
  FBL: {
    value: -2,
    code: 'FBL',
    name: 'FBL - Lazada',
    content:
      'Lokasi ini untuk mewakili lokasi kamu di Fulfilment by Lazada (FBL). Hanya buat dan gunakan lokasi ini jika kamu menggunakan FBL.'
  },
  TOKOPEDIA: {
    value: 0,
    code: 'TOKOPEDIA',
    name: '',
    content:
      'Lokasi ini untuk mewakili lokasi kamu di Fulfilment by Tokopedia (TokoCabang). Hanya buat dan gunakan lokasi ini jika kamu menggunakan TokoCabang.'
  },
  SHOPEE: {
    value: -3,
    code: 'SHOPEE',
    name: 'Gudang Shopee',
    content:
      'Lokasi ini untuk mewakili lokasi kamu di Fulfilment by Shopee (SBS). Hanya buat dan gunakan lokasi ini jika kamu menggunakan SBS.'
  }
};

export const TYPE_WAREHOUSE_LOCATION = [
  { label: 'Gudang', value: 'warehouse' },
  { label: 'Fullfilment by Lazada', value: 'fbl' },
  { label: 'Dilayani Tokopedia', value: 'tcb' },
  { label: 'Service by Shopee', value: 'sbs' }
];

export const getWarehouseLayoutFloorsReq = (floors) => {
  const tempLayout = floors?.map((floor) => {
    return {
      id: floor.id,
      text: floor.text,
      rows: floor.rows?.map((row) => ({
        row_id: row.row_id,
        row_code: row.row_code,
        columns: row.columns.map((col) => ({
          column_id: col.column_id,
          column_code: col.column_code,
          bins: col.bins?.map((bin) => {
            delete bin.is_default;
            return bin;
          })
        }))
      }))
    };
  });
  return tempLayout;
};

export const getLayoutReq = (floors) => {
  const layout = getWarehouseLayoutFloorsReq(floors);
  return {
    floors: layout,
    delete: {
      floors: [],
      rows: [],
      columns: [],
      bins: []
    }
  };
};

export const getCommonsReq = (locationDetail, locationId) => {
  const tempLocation = { ...locationDetail };
  delete tempLocation.pos_discount;
  delete tempLocation.pos_discount_type;
  delete tempLocation.pos_tax;
  delete tempLocation.pos_discount_max;
  delete tempLocation.wms_migration_date;
  delete tempLocation.warehouse_store_name;
  delete tempLocation.is_fbb;
  delete tempLocation.district;
  return {
    ...tempLocation,
    warehouse:
      tempLocation.warehouse?.map((obj) => ({
        store_id: obj.store_id,
        warehouse_id: obj?.warehouse_id?.wh_id ?? obj?.warehouse_id,
        warehouse_name: obj?.warehouse_id?.wh_name ?? obj?.warehouse_name,
        location_id: locationId
      })) || [],
    registers:
      tempLocation.registers?.map((obj) => ({
        register_id: obj.register_id,
        register_name: obj.register_name,
        register_code: obj.register_code,
        status: obj.status
      })) || [],
    stores: tempLocation.stores?.map((obj) => obj.store_id) || [],
    area: tempLocation.area || tempLocation.location_name
  };
};
