import React, { CSSProperties, FC, useState } from 'react';
import { Button, Card, CardContent, Grid, Popover, Tooltip } from '@mui/material';
import { ConnectDragSource, DragSourceMonitor, useDrag } from 'react-dnd';
import { Types } from './Types';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { AsyncButton } from 'components/ButtonNew/Button';
import { makeStyles } from '@mui/styles';
import { Image } from 'components/Image/Image';
import { ChannelDetail } from 'interfaces/Channels';
import { channelDetail } from 'shared/constants';

const style: CSSProperties = {
  padding: '8px 10px',
  width: '100%'
};

export interface DragContainerProps {
  key?: any;
  isDragging?: boolean;
  connectDragSource?: ConnectDragSource;
  item: any;
  fnMerge: (callback: any, confirm: boolean, message: string, data?: any) => void;
  fnSearchSimilar?: (name: string) => void;
  fnClickStatus: (item: any) => void;
  selectStatus?: number;
  getSelected: (item: any) => boolean;
  handleMaster?: any;
}

const useStyles = makeStyles((theme) => ({
  itemCode: {
    fontSize: 12,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical'
  },
  itemEmail: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    maxWidth: 190
  },
  placeholderImage: {
    width: '53px',
    height: '55px',
    borderRadius: '6px',
    background: '#C4C4C4',
    marginLeft: '10px'
  }
}));

function SkuChildItem({ child }) {
  const hasVariations = child.variation_values?.length > 0
  const skuWithVariation = hasVariations ? child.item_code + ' - ' + child.variation_values.map((y) => y.value).join(', ') : child.item_code
  return (
    <div className="d-flex flex-column">
      <Tooltip arrow placement="bottom-start" title={skuWithVariation}>
        <div style={{ fontSize: '14px' }} className={'item-code'}>
          {skuWithVariation}
        </div>
      </Tooltip>
    </div>
  );
}

const renderStoreInfo = ({ hasMoreStore, item, channels, classes }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  if (item?.skus === null) {
    return <div style={{ color: '#4B68EF', fontSize: '14px' }} className="pt-2 pb-1 text-center">-</div>;
  }

  if (hasMoreStore) {
    return (
      <>
        <div onClick={(e) => handleClick(e)} aria-describedby={id} style={{ color: '#4B68EF', fontSize: '14px', cursor: 'pointer' }} className="py-2 pl-2">
          {`Terdapat di ${item?.online_status?.length} toko`}
        </div>
        <Popover 
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {item?.online_status.map((channel, index) => (
            <div key={index + channel.store_name} className="d-flex px-2 align-items-center justify-content-start">
              <div className="rounded-circle text-white px-2 text-center">
                {channels[item.online_status[0]?.channel_id]?.channelLogo && (
                  <Image
                    height={20}
                    width={20}
                    src={channels[item.online_status[0]?.channel_id]?.channelLogo}
                    className="mt-2 mb-2"
                  />
                )}
              </div>
              {item.channel_url && item.channel_url.indexOf('http') >= 0 ? (
                <Tooltip arrow placement="bottom-start" title={`${item.store_name}`}>
                  <a target="_blank" rel="noreferrer" href={item.channel_url} className={`${classes.itemEmail} py-2 pl-1`} style={{ fontSize: 14 }}>
                    {`${item.online_status[0].store_name}`}
                  </a>
                </Tooltip>
              ) : (
                <div className="pl-1" style={{ fontSize: 14 }}>
                  {item.online_status[0].store_name}
                </div>
              )}
            </div>
          ))}
        </Popover>
      </>
    );
  }

  return (
    <div className="d-flex align-items-center justify-content-start">
      <div className="rounded-circle text-white px-2 text-center">
        {channels[item.online_status[0]?.channel_id]?.channelLogo && (
          <Image
            height={20}
            width={20}
            src={channels[item.online_status[0]?.channel_id]?.channelLogo}
            className="mt-2 mb-2"
          />
        )}
      </div>
      {item.channel_url && item.channel_url.indexOf('http') >= 0 ? (
        <Tooltip arrow placement="bottom-start" title={`${item.store_name}`}>
          <a target="_blank" rel="noreferrer" href={item.channel_url} className={`${classes.itemEmail} py-2 pl-1`} style={{ fontSize: 14 }}>
            {`${item.online_status[0].store_name}`}
          </a>
        </Tooltip>
      ) : (
        <div className="pl-1" style={{ fontSize: 14 }}>
          {item.online_status[0].store_name}
        </div>
      )}
    </div>
  );
};

const DragContainer: FC<DragContainerProps> = ({
  key,
  item,
  fnMerge,
  fnSearchSimilar,
  fnClickStatus,
  selectStatus,
  getSelected,
  handleMaster
}) => {
  const classes = useStyles();
  const isSelected = getSelected != null ? getSelected(item) : selectStatus == item.item_group_id;
  const channels = channelDetail as ChannelDetail;

  function endDrag(val: any, monitor: DragSourceMonitor) {
    const dropResult = monitor.getDropResult<{
      item: any;
      itemDrop: any;
      allowedDropEffect: string;
      dropEffect: string;
    }>();

    if (dropResult) {
      const isDropAllowed =
        dropResult.allowedDropEffect === 'any' || dropResult.allowedDropEffect === dropResult.dropEffect;

      if (isDropAllowed) {
        let payload = {};
        fnMerge(payload, true, '', { source: item, destination: dropResult.item });
      }
    }
  }

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: Types.Card,
      item,
      canDrag: (monitor) => {
        console.log(item, 'itme')
        return !getSelected(item);
      },
      end: endDrag,
      collect: (monitor: any) => ({
        isDragging: monitor.isDragging()
      })
    }),
    [isSelected]
  );
  const opacity = isDragging ? 0 : 1;
  const cursor = isDragging ? 'grab' : 'pointer';
  const hasMoreStore = item.online_status.length > 1

  return (
    <div key={key}>
      {dragPreview(<div></div>)}
      <div ref={drag} style={{ ...style, opacity }}>
        <Card
          style={{
            backgroundColor: isSelected ? '#EBF5FA' : '#FFF',
            // background: '#FFFFFF',
            border: '1px solid #EAEAEA',
            boxShadow: '0px 2px 10px rgba(175, 137, 255, 0.15)',
            borderRadius: '15px'
          }}
        >
          <CardContent className="p-0">
            <Grid container>
              <Grid item md={12} onClick={() => fnClickStatus(item)} style={{ cursor }}>
                <div className="d-flex px-2 py-3">
                  {item.skus[0]?.image ? (
                    <img
                      src={item.skus[0]?.image}
                      alt="Thumbanil"
                      style={{ objectFit: 'cover', marginLeft: '10px', borderRadius: '6px', width: '53px', maxHeight: '55px' }}
                    />
                  ) : (
                    <div className={classes.placeholderImage}></div>
                  )}
                  <div className="d-flex flex-column ml-3">
                    <div className="font-weight-bold mb-0" style={{ fontSize: '14px' }}>{`${item.item_group_name}`}</div>
                    <div className="d-flex flex-column" style={{ gap: '14px' }}>
                      {item.skus.length > 1 &&
                        item.skus.map((child, idx) => (
                          <SkuChildItem key={idx} child={child} />
                        ))}
                    </div>
                  </div>
                </div>
              </Grid>
              
              <Grid item md={12} className="border-top mt-2 mx-3">
                <Grid container>
                  <Grid item md={6}>
                    {renderStoreInfo({ hasMoreStore, item, channels, classes })}
                  </Grid>
                  <Grid item md={6} className="px-4 py-2 text-center">
                    <Button
                      onClick={(e) => {
                        fnMerge({}, true, '', { source: item, destination: null });
                      }}
                      fullWidth
                      type="button"
                      variant="contained"
                      color="primary"
                    >
                      Rekomendasi Gabung
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item md={12}>
                <Grid container className="border-top">
                  <Grid item md={6} className="border-right px-4 py-2 text-center">
                    <AsyncButton
                      onClick={() => handleMaster(item.channel_group_id)}
                      fullWidth
                      type="button"
                      color="primary"
                    >
                      Jadikan Master
                    </AsyncButton>
                  </Grid>
                  <Grid item md={6} className="px-4 py-2 text-center">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        fnSearchSimilar(item.item_name);
                      }}
                      fullWidth
                      type="button"
                      variant="outlined"
                      color="primary"
                    >
                      Cari yang Serupa
                    </Button>
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default DragContainer;
