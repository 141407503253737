import React from 'react';
import { Button, Dialog } from '@mui/material';
import { SuccessFilled } from 'assets/img';

interface Props {
  open: boolean;
  handleClose: () => void;
}

const AddressSaved: React.FunctionComponent<Props> = ({ open, handleClose }) => {
  return (
    <Dialog
      open={open}
      fullWidth={true}
      PaperProps={{
        style: {
          maxWidth: 383,
          borderRadius: 20
        }
      }}
    >
      <div className="d-flex justify-content-center align-items-center flex-column py-4" style={{ maxWidth: '383px' }}>
        <SuccessFilled height={50} width={50} />
        <span className="text-center font-weight-bold my-3" style={{ fontSize: '14px' }}>
          Alamat Tersimpan
        </span>
        <Button sx={{ width: '50px' }} onClick={handleClose} type="button" variant="contained" color="primary">
          Ok
        </Button>
      </div>
    </Dialog>
  );
};

export default AddressSaved;
