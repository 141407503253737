import styled from 'styled-components';

interface Props {
  isUploaded: boolean,
}

export const UploadImage = styled.div<Props>`
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr 25px;
  gap: 10px;
  border: 1px solid #D4D5D6;
  width: 100%;
  height: 158px;
  padding: 10px;
  border-radius: 10px;
  background-color: ${(props) => props.isUploaded ? '#fbf9f5' : 'transparent'};
  margin-bottom: 6px;
`;