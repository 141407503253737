import React from 'react';
import { DialogContentStyled, Label, LeftContentWrapper, RightContentWrapper } from 'components/Dialog/DialogContent';
import { LeftContent } from 'components/Dialog/DialogLeftContent';
import { Bottom, BottomActions, ConnectButton, RightForm } from 'components/Dialog/DialogRightContent';
import { ErrorMessage, Input, InputGrid } from 'components/Form';
import { SubmitHandler, useForm } from 'react-hook-form';
import { shopifyAuthorize } from 'api/webstore';
import { useAppStore } from 'stores/appStore';
import { DialogProps } from 'interfaces/Props';
import { showAlert } from 'components/Alert';
import { channels } from 'shared/constants';

import HorizontalDividers from 'components/CustomDividers/HorizontalDividers';
import VerticalDividers from 'components/CustomDividers/VerticalDividers';
import WarningIcon from '@mui/icons-material/Warning';
import config from 'config';

interface FormValues {
  store_name: string;
  webstore_url: string;
  api_key: string;
  api_secret: string;
}

const ShopifyContent: React.FunctionComponent<DialogProps> = ({ handleClosePrimary, getListChannel }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>();
  const { setShowConnectedPopup, disablePrevButton } = useAppStore();

  const onSubmit: SubmitHandler<FormValues> = async (formData) => {
    try {
      const {
        data: { status: dataStatus, message }
      } = await shopifyAuthorize(formData.webstore_url, formData.api_key, formData.api_secret);

      if (dataStatus === 'ok') {
        if (!disablePrevButton) {
          setShowConnectedPopup(true);
          handleClosePrimary();
          getListChannel();
        } else {
          location.replace(config.baseUrl);
        }
      } else {
        showAlert('error', `${message}`, 5000);
      }
    } catch (err) {
      const error = err as Error;
      console.error(error);
      showAlert('error', `${error}`, 5000);
    }
  };

  return (
    <DialogContentStyled>
      <LeftContentWrapper>
        <LeftContent channelId={channels.SHOPLINE} />
      </LeftContentWrapper>
      <VerticalDividers />
      <RightContentWrapper>
        <RightForm id="shopify_form" onSubmit={handleSubmit(onSubmit)}>
          <InputGrid
            wide={true}
            label={<Label className="requiredField">Nama Toko</Label>}
            input={
              <Input
                isError={errors.store_name?.message ? true : false}
                type={'text'}
                placeholder={'Masukkan Nama Toko'}
                {...register('store_name', {
                  required: { value: true, message: 'Nama Toko harus di isi' }
                })}
              />
            }
            errorMessage={
              errors?.store_name && (
                <ErrorMessage
                  icon={<WarningIcon fontSize="small" color="error" />}
                  message={errors.store_name.message}
                />
              )
            }
          />
          <InputGrid
            wide={true}
            label={<Label className="requiredField">Alamat Webstore</Label>}
            input={
              <Input
                isError={errors.webstore_url?.message ? true : false}
                type={'text'}
                placeholder={'Masukkan Alamat Webstore'}
                {...register('webstore_url', {
                  required: { value: true, message: 'Alamat Webstore harus di isi' }
                })}
              />
            }
            errorMessage={
              errors?.webstore_url && (
                <ErrorMessage
                  icon={<WarningIcon fontSize="small" color="error" />}
                  message={errors.webstore_url.message}
                />
              )
            }
          />
          <InputGrid
            wide={true}
            label={<Label className="requiredField">API Key</Label>}
            input={
              <Input
                isError={errors.api_key?.message ? true : false}
                type={'text'}
                placeholder={'API Key'}
                {...register('api_key', {
                  required: { value: true, message: 'API Key harus di isi' }
                })}
              />
            }
            errorMessage={
              errors?.api_key && (
                <ErrorMessage icon={<WarningIcon fontSize="small" color="error" />} message={errors.api_key.message} />
              )
            }
          />
          <InputGrid
            wide={true}
            label={<Label className="requiredField">API Secret</Label>}
            input={
              <Input
                isError={errors.api_secret?.message ? true : false}
                type={'text'}
                placeholder={'API Secret'}
                {...register('api_secret', {
                  required: { value: true, message: 'API Secret harus di isi' }
                })}
              />
            }
            errorMessage={
              errors?.api_secret && (
                <ErrorMessage
                  icon={<WarningIcon fontSize="small" color="error" />}
                  message={errors.api_secret.message}
                />
              )
            }
          />
        </RightForm>
        <Bottom>
          <HorizontalDividers />
          <BottomActions>
            <ConnectButton form="shopify_form">Sambungkan</ConnectButton>
          </BottomActions>
        </Bottom>
      </RightContentWrapper>
    </DialogContentStyled>
  );
};

export default ShopifyContent;
